import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LogList } from '../Log';
import { shiftsActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';
import { miscService } from '../_services';

const Shifts = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [shifttoggle, setshifttoggle] = useState(true);
  const [historytoggle, sethistorytoggle] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [shifts, setShifts] = useState({ name: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  // effect to load shift info
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllShifts(1, query);
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 6) {
        setisStaff(true);
      }
    }
    if (props.id && props.id !== 0 && props.id !== '0') {
      query._id = props.id;
      setQuery(query);
      getAllShifts(1, query);
    }
  }, []);

  /**
   * Get all shiftss
   * */
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setShifts(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Shifts">
      {(props.requestStatus === reducerConstants.SHIFTS_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        {isAdmin && (
          <div className="d-flex justify-content-center ">
            <div className="ReportToggleMain">
              <div
                data-cy="shifttoggle"
                className={
                  shifttoggle
                    ? 'report_toggle_item_active'
                    : 'report_toggle_item_inactive'
                }
                onClick={() => {
                  setshifttoggle(true), sethistorytoggle(false);
                }}
              >
                {' '}
                <div className="pl-2 pr-2">Shift</div>{' '}
              </div>
              <div
                data-cy="historytoggle"
                className={
                  historytoggle
                    ? 'report_toggle_item_active'
                    : 'report_toggle_item_inactive'
                }
                onClick={() => {
                  setshifttoggle(false), sethistorytoggle(true);
                }}
              >
                History{' '}
              </div>
            </div>
          </div>
        )}
        {shifttoggle && (
          <div className="">
            <div className="card-body m-0 p-0">
              <div className="bag">
                <div className="d-flex flex-column ">
                  <div
                    className=" text-secondary mb-2"
                    style={{ lineHeight: '0px' }}
                  >
                    SHIFT ID
                  </div>
                  <div className="">
                    <label>{'FLX00' + shifts._id}</label>
                  </div>
                </div>
                <div className="d-flex flex-row align-item-center ">
                  <div className={'icon-div mt-1'} style={{ color: '#20B9B7' }}>
                    <img
                      src="/assets/img/cal_white.png"
                      style={{ height: '25px', width: '25px' }}
                    ></img>
                  </div>
                  <div className="d-flex align-items-center justify-content-center ml-10 font-weight-bold">
                    {new Date(shifts.check_in_date_string).toLocaleDateString(
                      'en-us',
                      { day: 'numeric', month: 'long', weekday: 'long' },
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row bd-highlight align-items-center">
                  <div className=" col-md-9 pt-2 pl-0 bd-highlight d-flex flex-row bd-highlight">
                    <div className=" bd-highlight d-flex flex-column bd-highlight mb-2">
                      <div
                        className="p-2 bd-highlight section_type "
                        style={{
                          backgroundColor: '#1AC8FF',
                          color: 'white',
                          borderRadius: '2px',
                        }}
                      >
                        {shifts.section_type + '  Shift'}
                      </div>
                    </div>
                    <div className=" ml-2 bd-highlight d-flex flex-column bd-highlight">
                      <div
                        className="p-2 bd-highlight compny_name_agency"
                        style={{
                          borderRadius: '2px',
                        }}
                      >
                        {shifts['Employer.company_name']}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dummy-view"></div>
                {shifts['Candidate.name'] && (
                  <div className="row mt-3">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label>Name</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {shifts['Candidate.name']}{' '}
                        {shifts['Candidate.last_name']
                          ? shifts['Candidate.last_name']
                          : ''}{' '}
                      </p>
                    </div>
                  </div>
                )}
                {shifts['Agency.company_name'] && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label>Agency</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{shifts['Agency.company_name']} </p>{' '}
                    </div>
                  </div>
                )}
                {shifts['Agencycandidate.name'] && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label>Agency Candidate</label>{' '}
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{shifts['Agencycandidate.name']} </p>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Shift ID</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{'FLX00' + shifts._id} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Shift</label>
                  </div>
                  <div className="">
                    <label>:</label>{' '}
                  </div>
                  <div className="col-6">
                    <p>{shifts.section_type} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Business Unit</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts['Businessunit.name']} </p>
                  </div>
                </div>
                {shifts['Employer.Parent.company_name'] && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label>Parent Employer</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{shifts['Employer.Parent.company_name']} </p>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Time</label>
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>
                      {miscService.time24to12(shifts.check_in_time_string) +
                        '-' +
                        miscService.time24to12(
                          shifts.check_out_time_string,
                        )}{' '}
                    </p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Attendance Check In</label>
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p> {miscService.time24to12(shifts.attendance_check_in)}</p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Attendance Check Out</label>
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>
                      {' '}
                      {miscService.time24to12(shifts.attendance_check_out)}
                    </p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Status</label>{' '}
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.status == 1 ? 'Active' : 'Inactive'} </p>{' '}
                  </div>
                </div>
                {(isAdmin || isStaff) && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label>Amount</label>{' '}
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{shifts.total_price} </p>{' '}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Total Hours</label>{' '}
                  </div>
                  <div className="">
                    <label>:</label>{' '}
                  </div>
                  <div className="col-6">
                    {' '}
                    <p>{shifts.total_hours}hrs </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Paid Hours</label>
                  </div>
                  <div className="">
                    <label>:</label>{' '}
                  </div>
                  <div className="col-6">
                    <p>{shifts.actual_hours_after_break_time}hrs </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Paid Status</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.paid_status == 1 ? 'Paid' : 'not Paid'} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    {' '}
                    <label> Attended </label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.is_attended == 1 ? 'Yes' : 'No'} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Break Paid</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.is_break_time_pay == 1 ? 'Yes' : 'No'} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Break Time</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.break_time} &nbsp;mins </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>City</label>{' '}
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts['Businessunit.city']} </p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    {' '}
                    <label> Postcode</label>{' '}
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts['Businessunit.postcode']} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Address</label>{' '}
                  </div>
                  <div className="">
                    <label>:</label>{' '}
                  </div>
                  <div className="col-7">
                    {' '}
                    <p>{shifts['Businessunit.address']} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Invoice</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{shifts.invoice_id ? 'yes' : 'No'} </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label>Self Sign</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>
                      {shifts.candidate_signature_url ? (
                        <img
                          className="sign-image"
                          src={
                            apiConstants.imgUrl + shifts.candidate_signature_url
                          }
                        />
                      ) : (
                        'No'
                      )}{' '}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    style={{ marginLeft: '8px' }}
                  >
                    <label> Employer Sign</label>
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    {' '}
                    <p>
                      {shifts.employer_signature_url ? (
                        <img
                          className="sign-image"
                          src={
                            apiConstants.imgUrl + shifts.employer_signature_url
                          }
                        />
                      ) : (
                        'No'
                      )}{' '}
                    </p>
                  </div>
                </div>
                {shifts.signature_employer_name && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      {' '}
                      <label> Authorised Person</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {shifts.signature_employer_name
                          ? shifts.signature_employer_name
                          : ''}{' '}
                      </p>
                    </div>
                  </div>
                )}
                {shifts.cancel_type && (
                  <div className="row">
                    <div
                      className="col-md-4 col-sm-6"
                      style={{ marginLeft: '8px' }}
                    >
                      <label> Reason for cancellation</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{shifts.cancel_type ? shifts.cancel_type : ''} </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* //------------showing log details of shift --------as history----------- */}
        {historytoggle && (
          <div>
            <LogList shift_id={props.id} showheadings={false}></LogList>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps() {}

const connectedShifts = connect(mapStateToProps)(Shifts);
export { connectedShifts as Shifts };
