import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const employerService = {
  employerAdd,
  employerList,
  employerListv2,
  // employerDelete,
  dashbordemployerreport,
  checkemailExist,
  // permanentemployerAdd,
  dashbordorganizationreport,
  getEmployerListforasync,
  addSubscription,
  // employerDetails,
  employerslistforshift,
};

function employerAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addemployer', requestOptions).then(
    miscService.handleResponse,
  );
}
function addSubscription(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addsubscription', requestOptions).then(
    miscService.handleResponse,
  );
}
// function permanentemployerAdd(data) {
//   // const requestOptions = {
//   //   method: 'POST',
//   //   headers: authHeader(),
//   //   body: JSON.stringify(data),
//   // };
//   // return fetch(
//   //   apiConstants.apiUrl + '/addpermanentemployer',
//   //   requestOptions,
//   // ).then(handleResponse);

//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/addpermanentemployer', requestOptions)
//     .then(handleResponse)
//     .then(user => {
//       console.log(user.data);

//       // login successful if there's a jwt token in the response
//       if (user.data.token) {
//         // store user details and jwt token in local storage to keep user logged in between page refreshes
//         localStorage.setItem('user', JSON.stringify(user.data));
//       }

//       return user;
//     });
// }

function checkemailExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/checkemployeremailexist',
    requestOptions,
  ).then(miscService.handleResponse);
}

function employerList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getemployerList', requestOptions).then(
    miscService.handleResponse,
  );
}

function getEmployerListforasync(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getEmployerListforasync',
    requestOptions,
  ).then(miscService.handleResponse);
}
function dashbordemployerreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordemployerreport',
    requestOptions,
  ).then(miscService.handleResponse);
}

function employerListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getemployerListv2', requestOptions).then(
    miscService.handleResponse,
  );
}
// function employerDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deleteemployer', requestOptions).then(
//     handleResponse,
//   );
// }

function dashbordorganizationreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordorganizationreport',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function employerDetails(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/getemployerdetails',
//     requestOptions,
//   ).then(miscService.handleResponse);
// }

function employerslistforshift(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getemployers', requestOptions).then(
    miscService.handleResponse,
  );
}
