import { format } from 'date-fns';
import { sumBy } from 'lodash';
import { memo, useState } from 'react';
import { connect } from 'react-redux';
import { LoadingIcon } from '../_components';
import { miscService, shiftsService } from '../_services';
import { PostLive } from './PostLive';

const ShiftSummary = props => {
  const [postLive, setPostLive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let subTotal = 0;
  let grandTotal = 0;
  let hours, minutes;

  const calculateDuration = (fromTime, toTime, breakTime) => {
    const currentDate = new Date();

    const from = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      fromTime.split(':')[0],
      fromTime.split(':')[1],
    );
    let to = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      toTime.split(':')[0],
      toTime.split(':')[1],
    );
    const breakDuration = breakTime ? parseInt(breakTime) : 0; // Assuming breakTime is in minutes

    if (to < from) {
      to.setDate(to.getDate() + 1); // Increment the day by 1
    }
    // Calculate total duration in milliseconds
    let duration = to - from;

    // Subtract break time from total duration
    duration -= breakDuration * 60000; // Convert minutes to milliseconds
    // Convert duration to hours and minutes
    hours = Math.floor(duration / (1000 * 60 * 60));
    minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  };

  const calculatePrice = price => {
    const convertedMinutesToHours = minutes / 60;
    const TotalHours = hours + convertedMinutesToHours;
    subTotal = TotalHours * price;
    grandTotal += subTotal;
  };

  const showVacancyEachShift = _data => {
    const _vacancies = sumBy(
      _data.continueAddingShift,
      shift => shift.vacancies,
    );
    return _vacancies;
  };

  const publishingVacancies = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = {
        vacancies: props.postVacancies?.map(_data => ({
          busniessUnitId: _data.selectedBusinessUnit._id,
          date: format(miscService.dateConvertion(_data.date), 'yyyy-MM-dd'),
          jobId: _data.position._id,
          shiftInfo: _data.continueAddingShift.map(_shiftDetails => ({
            sectionId: _shiftDetails.shift_time._id, // Assuming shift_time is a property of _shiftDetails
            rateCardId: _shiftDetails.price._id,
            price: _shiftDetails.price.price,
            vacancies: _shiftDetails.vacancies,
          })),
          benfitIds: _data.activeBenefits,
          defaultCandidateIds: _data.selectedCandidates,
        })),
        publish_status: 0,
      };
      const vacancy_data = await shiftsService.employerJobPosting(result);
      if (vacancy_data.data) {
        setPostLive(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleCloseSummary = () => {
    props.closeShiftSummary();
  };

  return (
    <>
      {!postLive && (
        <div className="container flx-job-post-main-container">
          <h2 className="text-center">Post Vacancies</h2>
          <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
            <p className="text-center mb-22">Summary</p>
            <div className="container mt-4">
              <div className="flx-table-res-container flx-summary-table-responsive">
                <table className="table flx-shift-table-summary">
                  <tbody>
                    {props.postVacancies?.map(_data =>
                      _data.continueAddingShift.map(
                        (shiftDetails, shiftIndex, arrayValues) => (
                          <tr key={shiftIndex}>
                            {shiftIndex === 0 ? (
                              <>
                                <td
                                  rowSpan={arrayValues.length}
                                  className="flex-colomn"
                                >
                                  <div className="flx-summary-table-date float-left">
                                    {calculateDuration(
                                      shiftDetails.shift_time.from_time,
                                      shiftDetails.shift_time.to_time,
                                      shiftDetails.shift_time.breakTime,
                                    )}{' '}
                                    {format(
                                      miscService.dateConvertion(_data.date),
                                      'dd-MMM-yyyy',
                                    )}
                                    ,
                                  </div>
                                  <br />
                                  <div className="flx-summary-table-position float-left text-left">
                                    {_data.position?.name}
                                    <span className="flx-summary-quantity-role">
                                      x{showVacancyEachShift(_data)}
                                    </span>
                                  </div>
                                </td>
                                <td rowSpan={arrayValues.length}>
                                  <div className="flx-shift-final-job-timings">
                                    {shiftDetails.shift_time.from_time}-
                                    {shiftDetails.shift_time.to_time}
                                  </div>
                                  <div className="flx-shift-final-job-shiftname">
                                    {shiftDetails.shift_time.section_type} shift
                                  </div>
                                </td>
                              </>
                            ) : null}

                            <td>
                              <div className="flx-summary-table-date">
                                {' '}
                                Hours <br />
                                {hours < 10 ? `0` + hours : hours}.
                                {minutes < 10 ? `0` + minutes : minutes}
                              </div>
                              <div className="flx-summary-table-position">
                                {shiftDetails.vacancies ? (
                                  <span className="flx-summary-quantity-role">
                                    x{shiftDetails.vacancies}
                                  </span>
                                ) : null}
                              </div>
                            </td>
                            <td className="flx-summary-rate-per-hour">
                              £{shiftDetails.price.price}/h
                            </td>
                            <td>
                              <div>
                                {shiftDetails.emergency && (
                                  <img
                                    className="flx-immediate-cover-icon"
                                    src="/assets/img/flx-immediate-cover.svg"
                                  />
                                )}
                                {_data.activeBenefits?.find(_d => _d === 8) && (
                                  <img
                                    className="flx-paid-break-icon"
                                    src="/assets/img/flx-paid-break.svg"
                                  />
                                )}
                              </div>
                            </td>
                            <td className="flx-sum-table-total-rate">
                              <div className="flx-summary-table-date">
                                {' '}
                                Total
                              </div>
                              <div className="flx-summary-table-position">
                                £{calculatePrice(shiftDetails.price.price)}
                                {subTotal.toFixed(2)}
                              </div>
                            </td>
                          </tr>
                        ),
                      ),
                    )}

                    <tr className="flx-subtotal-section">
                      <td colSpan="5" className="flx-shift-sub-total-text-end">
                        Sub total
                      </td>
                      <td className="flx-shift-total-rate">
                        £{grandTotal.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            <div className="flx-job-post-nxt-back-btn-section">
              <button
                className="btn btn-sm flx-job-post-back-btn"
                onClick={handleCloseSummary}
              >
                Back
              </button>
              <button
                className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
                onClick={e => publishingVacancies(e)}
                disabled={isLoading}
              >
                Publish Now <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
      {postLive && <PostLive vacancyPosting={props.postVacancies} />}
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedShiftSummary = memo(connect(mapStateToProps)(ShiftSummary));
export { connectedShiftSummary as ShiftSummary };
