import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { AgencyReport } from './AgencyReport';
import { AllShiftReport } from './AllShiftReport';
import { CandidateReport } from './CandidateReport';
import { Report } from './Report';

// eslint-disable-next-line no-unused-vars
let reportRows = [];
let isAdmin = null;
let isEmployer = false;
let isBunit = false;
let isStaff = null;
let employer_id = '';
let bunit_id = '';

const ReportList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [booking, setbooking] = useState({});
  const [reportdata, setReportdata] = useState([]);
  const [Reporttoggle, setReporttoggle] = useState(false);
  const [Employertoggle, setEmployertoggle] = useState(true);
  const [Candidatetoggle, setCandidatetoggle] = useState(false);
  const [printpdfLoading, setprintpdfLoading] = useState({
    modal: false,
    employer_id: 0,
  });
  const [Agencytoggle, setAgencytoggle] = useState(false);
  const [filter, setfilter] = useState({ employer_id: '', candidate_id: '' });
  const [viewshiftsEmployer_id, SetviewshiftsEmployer_id] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [selecteddate, setselecteddate] = useState({
    from_date: '',
    to_date: '',
  });
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        isAdmin = true;
        isEmployer = false;
        isBunit = false;
      }
      if (user.role == 4) {
        isEmployer = true;
        setReporttoggle(true);
        setEmployertoggle(false);
        setCandidatetoggle(false);
        setAgencytoggle(false);
        isAdmin = false;
        isBunit = false;
        if (user && user.employer_id) {
          employer_id = user.employer_id;
        }
      }
      if (user.role == 6) {
        isStaff = true;
      }
      if (user.role == 7) {
        setReporttoggle(true);
        setEmployertoggle(false);
        setCandidatetoggle(false);
        setAgencytoggle(false);
        isBunit = true;
        isEmployer = true;
        employer_id = user.employer_id;
        bunit_id = user.business_unit_id;
      }
    }
    getAllShifts(1);
  }, [
    selecteddate,
    query.employer_id,
    query.from_date,
    query.to_date,
    query.candidate_id,
    query,
    query.branch,
  ]);
  // handler for select candidate
  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    setfilter({ ...filter, candidate_id: selectedOption.value });
    let newquery = { ...query, candidate_id: selectedOption.value };
    getAllShifts(1, newquery);
  };
  // handler for select employer
  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    setfilter({ ...filter, employer_id: selectedOption.value });
    let newquery = { ...query, employer_id: selectedOption.value };
    getAllShifts(1, newquery);
  };
  // call for get shifts
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (selecteddate.from_date) {
      queryNew.from_date = selecteddate.from_date;
    } else {
      delete queryNew.from_date;
    }
    if (selecteddate.to_date) {
      queryNew.to_date = selecteddate.to_date;
    } else {
      delete queryNew.to_date;
    }
    setQuery(queryNew);
    setIsLoading(true);
    if (isAdmin == true || isStaff == true) {
      queryNew.adminreport = true;
    }
    if (isEmployer == true) {
      queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
      }
    }
    if (isBunit == true) {
      queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
        queryNew.business_unit = bunit_id;
      }
    }
    if (queryNew.from_date > queryNew.to_date) {
      setError({ common: 'Choose a valid date' });
      return;
    }
    const { dispatch } = props;
    dispatch(shiftsActions.reportShiftListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setReportdata(res.data.rows);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // handler for state date filter
  const handleChange = event => {
    const { name, value } = event.target;
    setselecteddate({
      ...selecteddate,
      [name]: value,
    });
    setError({ common: '' });
    setbooking({
      ...booking,
      [name]: value,
    });
  };
  // handler for change toggle window
  const handleChangetoggles = e => {
    if (e.currentTarget.value && e.target.name == 'tabs1') {
      setReporttoggle(false);
      setEmployertoggle(true);
      setCandidatetoggle(false);
      setAgencytoggle(false);
    } else if (e.currentTarget.value && e.target.name == 'tabs2') {
      setReporttoggle(true);
      setEmployertoggle(false);
      setCandidatetoggle(false);
      setAgencytoggle(false);
    } else if (e.currentTarget.value && e.target.name == 'tabs3') {
      setReporttoggle(false);
      setEmployertoggle(false);
      setCandidatetoggle(true);
      setAgencytoggle(false);
    } else {
      setReporttoggle(false);
      setEmployertoggle(false);
      setCandidatetoggle(false);
      setAgencytoggle(true);
    }
  };
  // custom styles for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '2px',
      paddingBottom: '6px',
      height: '6px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#F4F4F4',
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };
  // to print fn
  const printShiftpdf = employerid => {
    const querynew = {};
    if (filter.candidate_id) {
      querynew.candidate_id = filter.candidate_id;
    }
    if (employerid) {
      querynew.employer_id = employerid;
    }
    if (selecteddate.from_date) {
      querynew.from_date = selecteddate.from_date;
    }
    if (selecteddate.to_date) {
      querynew.to_date = selecteddate.to_date;
    }
    const { dispatch } = props;
    dispatch(shiftsActions.employerreportdownload(querynew)).then(
      res => {
        if (res && res.data && res.data.output_file_name) {
          window.open(apiConstants.shiftpdfUrl + res.data.output_file_name, '');
        }
        setprintpdfLoading({ modal: false });
      },
      () => {
        setprintpdfLoading({ modal: false });
      },
    );
  };
  // list and paginate report data
  const setListAndPagination = reportData => {
    let reportList = [];
    for (
      let j = 0;
      reportData && reportData.rows && j < reportData.rows.length;
      j = j + 1
    ) {
      reportList.push(
        <tr key={j} className="username">
          {isEmployer && (
            <td>
              {reportData.rows[j].name}{' '}
              <div
                className="categoryview"
                style={{ color: '#20B9B7', marginTop: '5px' }}
              >
                {reportData &&
                  reportData.rows[j] &&
                  reportData.rows[j].Category &&
                  reportData.rows[j].Category.name &&
                  reportData.rows[j].Category.name}
              </div>{' '}
            </td>
          )}
          {(isAdmin || isStaff) && (
            <td>
              {reportData.rows[j].company_name}
              {selecteddate.from_date && selecteddate.to_date && (
                <div className="button-row2">
                  <>
                    <div
                      className="d-flex justify-content-end pointer btn-action "
                      style={{ fontSize: '12px', color: 'blue' }}
                      onClick={() => {
                        setprintpdfLoading({
                          modal: true,
                          employer_id: reportData.rows[j].employer_id,
                        });
                        printShiftpdf(reportData.rows[j].employer_id);
                      }}
                    >
                      Download shifts{' '}
                    </div>
                    <div>
                      <img
                        style={{ height: 12, width: 12 }}
                        src="/assets/img/pdf.png"
                      />
                    </div>{' '}
                    {printpdfLoading && (
                      <div>
                        <img
                          style={{ height: 23, width: 23 }}
                          src="/assets/img/1497.gif"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </td>
          )}
          {(isAdmin || isStaff) && (
            <td>
              {reportData.rows[j].total_people
                ? reportData.rows[j].total_people
                : 0}
            </td>
          )}
          <td>
            {reportData.rows[j].total_shifts
              ? reportData.rows[j].total_shifts
              : 0}
          </td>
          <td>
            {reportData.rows[j].total_signed_hrs
              ? reportData.rows[j].total_signed_hrs + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td>
          <td>
            {reportData.rows[j].total_hours
              ? reportData.rows[j].total_hours + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td>
          <td>
            {reportData.rows[j].invoice_generated
              ? reportData.rows[j].invoice_generated
              : 0}
          </td>{' '}
          <td>
            {reportData.rows[j].payout_generated
              ? reportData.rows[j].payout_generated
              : 0}
          </td>
          <td>
            {reportData.rows[j].total_amount
              ? '£' + ' ' + reportData.rows[j].total_amount
              : '£' + ' ' + 0}
          </td>
          <td>
            Night &nbsp; - &nbsp;{' '}
            {reportData.rows[j].total_night && reportData.rows[j].total_night
              ? reportData.rows[j].total_night
              : 0}
            &nbsp; hrs
            <p>
              Morning&nbsp; - &nbsp;
              {reportData.rows[j].total_morning &&
              reportData.rows[j].total_morning
                ? reportData.rows[j].total_morning
                : 0}
              &nbsp; hrs
            </p>
            Custome&nbsp; - &nbsp;
            {reportData.rows[j].total_custome &&
            reportData.rows[j].total_custome
              ? reportData.rows[j].total_custome
              : 0}
            &nbsp; hrs
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <div
                className="  allshift-viewdt"
                onClick={() => {
                  SetviewshiftsEmployer_id(reportData.rows[j].employer_id),
                    setviewdetailsmodal(true);
                }}
              >
                view
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setCandidateData(reportData);
  };
  return (
    <div className="CandidateList ">
      {/* {con} */}
      <div className="row">
        <div className="col-md-12 col-sm-12 px-3">
          <div className="card regular-table-with-color mx-4 px-2">
            <div className="card-header page-header ">
              <div className="row">
                {/* <div className="col-md-8 ml-4">
                  <div className="page-title">Report</div>
                </div> */}
              </div>
            </div>
            {(isAdmin || isStaff) && false && (
              <div className="card-header page-header p-0">
                <div className="d-flex justify-content-center ">
                  <div className="ReportToggleMain">
                    <div
                      className={
                        Employertoggle
                          ? 'report_toggle_item_active '
                          : 'report_toggle_item_inactive '
                      }
                      data-cy="employerreport"
                      onClick={() => {
                        setEmployertoggle(true),
                          setReporttoggle(false),
                          setCandidatetoggle(false),
                          setAgencytoggle(false);
                      }}
                    >
                      Employer report{' '}
                    </div>
                    <div
                      className={
                        Reporttoggle
                          ? 'report_toggle_item_active '
                          : 'report_toggle_item_inactive '
                      }
                      data-cy="employerreport"
                      onClick={() => {
                        setEmployertoggle(false),
                          setReporttoggle(true),
                          setCandidatetoggle(false),
                          setAgencytoggle(false);
                      }}
                    >
                      Shift report{' '}
                    </div>
                    <div
                      className={
                        Candidatetoggle
                          ? 'report_toggle_item_active '
                          : 'report_toggle_item_inactive '
                      }
                      onClick={() => {
                        setEmployertoggle(false),
                          setReporttoggle(false),
                          setCandidatetoggle(true),
                          setAgencytoggle(false);
                      }}
                    >
                      Candidate report{' '}
                    </div>
                    <div
                      className={
                        Agencytoggle
                          ? 'report_toggle_item_active '
                          : 'report_toggle_item_inactive '
                      }
                      onClick={() => {
                        setEmployertoggle(false),
                          setReporttoggle(false),
                          setCandidatetoggle(false),
                          setAgencytoggle(true);
                      }}
                    >
                      Agency report{' '}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* //-----------new sanimated toggle bar starts here ------------ */}
            {(isAdmin || isStaff) && (
              <div className="tabsContainer">
                <div className="tabs">
                  {/* //-------------first tab---------- */}
                  <input
                    type="radio"
                    style={{ display: 'none' }}
                    id="radio-1"
                    name="tabs1"
                    onChange={handleChangetoggles}
                    checked={Employertoggle ? true : false}
                    data-cy="employer_report"
                  />
                  <label className="tab" htmlFor="radio-1">
                    Employer Report
                  </label>
                  {/* //----------second tab----------- */}
                  <input
                    type="radio"
                    style={{ display: 'none' }}
                    id="radio-2"
                    name="tabs2"
                    onChange={handleChangetoggles}
                    checked={Reporttoggle ? true : false}
                    data-cy="report"
                  />
                  <label className="tab" htmlFor="radio-2">
                    Report
                  </label>
                  <input
                    type="radio"
                    style={{ display: 'none' }}
                    id="radio-3"
                    name="tabs3"
                    onChange={handleChangetoggles}
                    checked={Candidatetoggle ? true : false}
                    data-cy="candidate_report"
                  />
                  <label className="tab" htmlFor="radio-3">
                    Candidate Report
                  </label>
                  <input
                    type="radio"
                    style={{ display: 'none' }}
                    id="radio-4"
                    name="tabs4"
                    onChange={handleChangetoggles}
                    checked={Agencytoggle ? true : false}
                    data-cy="agency_report"
                  />
                  <label className="tab" htmlFor="radio-4">
                    Agency Report
                  </label>
                  <span className="glider"></span>
                </div>
              </div>
            )}
            {/* //-----------new sanimated toggle bar ends here ------------ */}
            {Employertoggle && (
              <div className="row p-3">
                <div className=" filter-row-full ">
                  <div className="filter-item">
                    <div className="" style={{}}>
                      <div className="mt-2 pl-1">
                        {' '}
                        <label className="mr-3" htmlFor="type">
                          From
                        </label>
                      </div>
                      <div>
                        <input
                          type="date"
                          className=" input-design1"
                          style={{
                            border: 'none',
                            display: 'block',
                            width: '100%',
                            height: '40px',
                            padding: '0.375rem 0.75rem',
                            flexShrink: '0',
                            fontSize: '14px',
                            fontWeight: '500',
                            borderRadius: '6px',
                          }}
                          name="from_date"
                          placeholder=""
                          value={selecteddate.from_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-item">
                    <div className="">
                      <div className="mt-2 pl-1">
                        {' '}
                        <label className="" htmlFor="type">
                          To
                        </label>
                      </div>
                      <div>
                        <input
                          type="date"
                          className=" input-design1"
                          style={{
                            border: 'none',
                            display: 'block',
                            width: '100%',
                            height: '40px',
                            padding: '0.375rem 0.75rem',
                            flexShrink: '0',
                            fontSize: '14px',
                            fontWeight: '500',
                            borderRadius: '6px',
                          }}
                          /* padding: 5[x]; */
                          name="to_date"
                          placeholder=""
                          value={selecteddate.to_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {!isEmployer && (
                    <div className="filter-item">
                      <div className="" style={{}}>
                        <div className="mt-2 pl-1 ">
                          {' '}
                          <label className="" htmlFor="type">
                            Employer
                          </label>
                        </div>
                        <div>
                          <AsyncSelect
                            styles={customStyles}
                            name="employer_id"
                            onChange={setEmployer}
                            placeholder={'Select Employer'}
                            cacheOptions
                            defaultOptions
                            className="employerselect"
                            loadOptions={getEmployerlist}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="filter-item">
                    <div className="" style={{}}>
                      <div className="mt-2 ">
                        <label className="" htmlFor="type">
                          Candidate
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="candidate_id"
                          onChange={selectedcandidate}
                          placeholder={'Select Candidate '}
                          cacheOptions
                          defaultOptions
                          className="candidateselect"
                          loadOptions={getcandidatelist}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-item">
                    <div className="" style={{}}>
                      <div className="mt-2 pl-1">
                        {' '}
                        <label className="" htmlFor="type">
                          Branch
                        </label>
                      </div>
                      <div>
                        <select
                          className="select col-md-12 selectDesign report-input"
                          name="branch"
                          value={query.branch}
                          onChange={e => {
                            setQuery(prevquery => ({
                              ...prevquery,
                              branch: e.target.value,
                            }));
                          }}
                        >
                          <option value="" hidden={true}>
                            {' '}
                            Select
                          </option>
                          <option value="">All</option>
                          <option value={'Basildon'}>Basildon</option>
                          <option value={'Wales'}>Wales</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Employertoggle && (
              <>
                <div className="card-body  table-responsive main-card-body flx-table-border">
                  {!isLoading && (
                    <table className="table  flx-custom-table">
                      <thead>
                        <tr className="flx-table-header-clr">
                          <th
                            style={{ width: '200px' }}
                            onClick={() => handleSort('company_name')}
                            className="sortHead border-top1 table-header-ht"
                          >
                            <div className="d-flex align-items-center ">
                              <span>Name</span>

                              <span
                                style={{ width: '20px' }}
                                className="d-flex flex-column align-items-center"
                              >
                                {query.pageVo.sort === 'company_name' &&
                                query.pageVo.sort_order !== 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up-black.png"
                                    onClick={() => handleSort('company_name')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up.png"
                                    onClick={() => handleSort('company_name')}
                                  />
                                )}
                                {query.pageVo.sort === 'company_name' &&
                                query.pageVo.sort_order === 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down-black.png"
                                    onClick={() => handleSort('company_name')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down.png"
                                    onClick={() => handleSort('company_name')}
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                          {(isAdmin || isStaff) && <th>Total People Worked</th>}
                          <th>Total Shifts Worked</th>
                          <th
                            onClick={() => handleSort('total_signed_hrs')}
                            className="sortHead border-top1"
                          >
                            <div className="d-flex align-items-center ">
                              <span>Total Signed Hours</span>

                              <span
                                style={{ width: '20px' }}
                                className="d-flex flex-column align-items-center"
                              >
                                {query.pageVo.sort === 'total_signed_hrs' &&
                                query.pageVo.sort_order !== 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up-black.png"
                                    onClick={() =>
                                      handleSort('total_signed_hrs')
                                    }
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up.png"
                                    onClick={() =>
                                      handleSort('total_signed_hrs')
                                    }
                                  />
                                )}
                                {query.pageVo.sort === 'total_signed_hrs' &&
                                query.pageVo.sort_order === 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down-black.png"
                                    onClick={() =>
                                      handleSort('total_signed_hrs')
                                    }
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down.png"
                                    onClick={() =>
                                      handleSort('total_signed_hrs')
                                    }
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                          <th
                            onClick={() => handleSort('total_hours')}
                            className="sortHead border-top1"
                          >
                            <div className="d-flex align-items-center ">
                              <span>Total Hour Worked</span>

                              <span
                                style={{ width: '20px' }}
                                className="d-flex flex-column align-items-center"
                              >
                                {query.pageVo.sort === 'total_hours' &&
                                query.pageVo.sort_order !== 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up-black.png"
                                    onClick={() => handleSort('total_hours')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up.png"
                                    onClick={() => handleSort('total_hours')}
                                  />
                                )}
                                {query.pageVo.sort === 'total_hours' &&
                                query.pageVo.sort_order === 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down-black.png"
                                    onClick={() => handleSort('total_hours')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down.png"
                                    onClick={() => handleSort('total_hours')}
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                          <th>Invoice Generated</th>
                          <th>Payout Generated</th>
                          <th
                            onClick={() => handleSort('total_amount')}
                            className="sortHead border-top1"
                          >
                            <div className="d-flex align-items-center ">
                              <span>Total Amount </span>

                              <span
                                style={{ width: '20px' }}
                                className="d-flex flex-column align-items-center"
                              >
                                {query.pageVo.sort === 'total_amount' &&
                                query.pageVo.sort_order !== 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up-black.png"
                                    onClick={() => handleSort('total_amount')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-up.png"
                                    onClick={() => handleSort('total_amount')}
                                  />
                                )}
                                {query.pageVo.sort === 'total_amount' &&
                                query.pageVo.sort_order === 'desc' ? (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down-black.png"
                                    onClick={() => handleSort('total_amount')}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      height: 10,
                                      width: 10,
                                      cursor: 'pointer',
                                    }}
                                    src="/assets/img/sort-down.png"
                                    onClick={() => handleSort('total_amount')}
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                          <th>Worked Shifts</th>
                          <th className="border-top2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportdata.map(i => {
                          return (
                            <>
                              <tr className="username">
                                {isEmployer && (
                                  <td title={i?.name}>
                                    {i?.name?.length > 8
                                      ? `${i?.name.substring(0, 12)}...`
                                      : i?.name}{' '}
                                    <div
                                      className="categoryview"
                                      style={{
                                        color: '#20B9B7',
                                        marginTop: '5px',
                                      }}
                                    >
                                      {i.Category &&
                                        i.Category.name &&
                                        i.Category.name}
                                    </div>{' '}
                                  </td>
                                )}
                                {(isAdmin || isStaff) && (
                                  <td title={i?.company_name}>
                                    {i?.company_name?.length > 8
                                      ? `${i?.company_name.substring(0, 12)}...`
                                      : i?.company_name}{' '}
                                    {selecteddate.from_date &&
                                      selecteddate.to_date && (
                                        <div className="button-row2">
                                          <>
                                            <a
                                              data-cy="downloadshifts"
                                              href={`/shiftprint/0/0/${i.employer_id}/${selecteddate.from_date}/${selecteddate.to_date}`}
                                              target=""
                                            >
                                              {' '}
                                              <p className="f-12 fontwghtbold">
                                                {' '}
                                                Download Shifts{' '}
                                              </p>
                                            </a>
                                            <div>
                                              <img
                                                style={{
                                                  height: 20,
                                                  width: 20,
                                                }}
                                                src="/assets/img/black-download.png"
                                              />
                                            </div>{' '}
                                            {printpdfLoading.modal &&
                                              printpdfLoading.employer_id ==
                                                i.employer_id && (
                                                <div>
                                                  <img
                                                    style={{
                                                      height: 23,
                                                      width: 23,
                                                    }}
                                                    src="/assets/img/1497.gif"
                                                  />
                                                </div>
                                              )}
                                          </>
                                        </div>
                                      )}
                                  </td>
                                )}
                                {(isAdmin || isStaff) && (
                                  <td>{i.total_people ? i.total_people : 0}</td>
                                )}
                                <td>{i.total_shifts ? i.total_shifts : 0}</td>
                                <td>
                                  {i.total_signed_hrs
                                    ? i.total_signed_hrs + ' ' + 'hrs'
                                    : 0 + ' ' + 'hrs'}
                                </td>
                                <td>
                                  {i.total_hours
                                    ? i.total_hours + ' ' + 'hrs'
                                    : 0 + ' ' + 'hrs'}
                                </td>
                                <td>
                                  {i.invoice_generated
                                    ? i.invoice_generated
                                    : 0}
                                </td>{' '}
                                <td>
                                  {i.payout_generated ? i.payout_generated : 0}
                                </td>
                                <td>
                                  {i.total_amount
                                    ? '£' + ' ' + i.total_amount
                                    : '£' + ' ' + 0}
                                </td>
                                <td>
                                  Night &nbsp; - &nbsp;{' '}
                                  {i.total_night && i.total_night
                                    ? i.total_night
                                    : 0}
                                  &nbsp; hrs
                                  <p>
                                    Morning&nbsp; - &nbsp;
                                    {i.total_morning && i.total_morning
                                      ? i.total_morning
                                      : 0}
                                    &nbsp; hrs
                                  </p>
                                  Custome&nbsp; - &nbsp;
                                  {i.total_custome && i.total_custome
                                    ? i.total_custome
                                    : 0}
                                  &nbsp; hrs
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <div
                                      style={{ color: '#1ac8ff' }}
                                      className="allshift-viewdt"
                                      onClick={() => {
                                        SetviewshiftsEmployer_id(i.employer_id),
                                          setviewdetailsmodal(true);
                                      }}
                                      data-cy="viewshifts"
                                    >
                                      View
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
                  {reportdata && reportdata.length == 0 && !isLoading && (
                    <p className="margin-top-20 text-center">
                      {' '}
                      No result found{' '}
                    </p>
                  )}
                </div>
                <div className="card-footer">
                  <Pagination
                    pageOnClick={data => {
                      getAllShifts(data);
                    }}
                    paginationData={candidateData}
                  />
                </div>
              </>
            )}
            {Reporttoggle && isEmployer && <AllShiftReport></AllShiftReport>}
            {Reporttoggle && !isEmployer && <Report></Report>}
            {Candidatetoggle && <CandidateReport></CandidateReport>}
            {Agencytoggle && <AgencyReport></AgencyReport>}
            <div className="row">
              <div className="col-md-12 pr-1" style={{ marginLeft: '10px' }}>
                <span className="color-red">{error.common}</span>
              </div>
            </div>
          </div>
        </div>
        {viewdetailsmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content p-1">
                <div className="border-bot-0 d-flex justify-content-between align-items-center">
                  <div className="page-title-content">
                    <h4
                      className="card-title p-2 mt-4 mb-0 ml-4"
                      style={{ color: 'black', fontWeight: 'bold' }}
                    >
                      Employer Report
                    </h4>
                  </div>
                  <div className="">
                    <a
                      data-cy="close-modal"
                      className="p-4 mt-3 mr-2"
                      onClick={() => setviewdetailsmodal(false)}
                    >
                      <i className="fa fa-times-circle close-button-modal"></i>
                    </a>
                  </div>
                </div>

                <div className="modal-body mt-0 pt-0">
                  <AllShiftsList
                    is_report={true}
                    employer_id={viewshiftsEmployer_id}
                    from_date={selecteddate.from_date}
                    to_date={selecteddate.to_date}
                    candidate_id={filter.candidate_id}
                    is_verified="1"
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// loader for get employer list in select option
const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
        select: ['_id', 'company_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name ? company_name : 'Unknown',
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for candidate list in select option
const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        isverified: 1,
        select: ['_id', 'name', 'last_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name }) => ({
              value: _id,
              label: name + ' ' + (last_name ? last_name : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedReportList = connect(mapStateToProps)(ReportList);
export { connectedReportList as ReportList };
