import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  alertConstants,
  apiConstants,
  businessUnitPermissionsConstant,
  messageConstant,
  roleConstant,
} from '../../_constants';
import { authHeader } from '../../_helpers';
import { employerUserService, miscService } from '../../_services';

const AddNewModal = props => {
  const [agencyShift, setAgencyShift] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [recieveInvoice, setRecieveInvoice] = useState(false);
  const [wasAgencyChecked, setWasAgencyChecked] = useState(false);
  const [error, setError] = useState({
    common: '',
    name: '',
    businessUnit: '',
    email: '',
    phone: '',
    // postcode: '',
  });
  // const [coordinates, setCoordinates] = useState({ lat: '', long: '' });
  // const [PostcodeObject, setPostcodeObject] = useState({});
  const [query, setQuery] = useState({
    role: roleConstant.EMPLOYER_USER,
    permissions: [],
    name: '',
    email: '',
    phone: '',
    // postcode: '',
    // address: '',
  });

  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  // useEffect(() => {
  //   if (query?.postcode) {
  //     loadaddress();
  //   }
  // }, [query?.postcode]);

  useEffect(() => {
    if (props.employee) {
      setQuery({
        ...query,
        name: props.employee['Employer_user.name'] || '',
        email: props.employee['Employer_user.email'] || '',
        phone: props.employee['Employer_user.phone'] || '',
        // postcode: props.employee['Employer_user.postcode'] || '',
        // address: props.employee['Employer_user.address'] || '',
        id: props.employee.id || '',
        user_id: props.employee['Employer_user.user_id'] || '',
        employerUserId: props.employee['Employer_user.id'] || '',
        permissions: props.employee.permissions,
        business_unit_id: props.employee.business_unit_id,
        send_invoice: props.employee.send_invoice,
      });
    }
  }, [props.employee]);

  // const valid_postcode = str_postcode => {
  //   let postcode = str_postcode.toUpperCase();
  //   postcode = postcode?.toString().replace(/\s/g, '');
  //   let regex =
  //     /^[A-Za-z]{1,2}[0-9RCHNQrchnq][0-9A-Za-z]?\s?[0-9][ABD-HJLNP-UW-Zabc-hjlnp-z]{2}$/;
  //   return regex.test(postcode);
  // };

  // const loadaddress = async () => {
  //   const res = query?.postcode?.replace(/ /g, '');
  //   if (valid_postcode(res)) {
  //     let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
  //     let response = await fetch(posstcodeurl);
  //     response = await response.json();
  //     if (response && response.latitude && response.longitude) {
  //       coordinates.lat = response.latitude;
  //       coordinates.long = response.longitude;
  //       setCoordinates(coordinates);

  //       let addresses_rows = [];
  //       let regex = /\s+,/g;
  //       for (let s = 0; s < response.addresses.length; s++) {
  //         addresses_rows.push(
  //           <option>{response.addresses[s].replace(regex, ' ')}</option>,
  //         );
  //       }
  //       setPostcodeObject({
  //         isValied: true,
  //         addresses: addresses_rows,
  //       });
  //     }
  //   }
  // };

  const handleSubmit = event => {
    event.preventDefault();
    setError({
      common: '',
      name: '',
      email: '',
      phone: '',
      postcode: '',
    });
    let validationFailed = false;
    if (!query.name) {
      setError(prevState => ({ ...prevState, name: 'Name is required' }));
      validationFailed = true;
    }

    if (!query.email) {
      setError(prevState => ({ ...prevState, email: 'Email is required' }));
      validationFailed = true;
    } else {
      const checkEmailValidation = miscService.emailVerify(query.email);
      if (!checkEmailValidation) {
        setError(prevState => ({
          ...prevState,
          email: messageConstant.INVALID_EMAIL,
        }));
        validationFailed = true;
      }
    }

    const phoneString = query.phone.toString();
    if (!query.phone) {
      setError(prevState => ({ ...prevState, phone: 'Phone is required' }));
      validationFailed = true;
    } else if (query.phone.length === 11) {
      const numericPhone = query?.phone?.replace(/[^0-9]/g, '');
      if (numericPhone.length !== 11 || numericPhone.charAt(0) !== '0') {
        setError(prevState => ({
          ...prevState,
          phone: messageConstant.VALID_PHONE,
        }));
        validationFailed = true;
      }
    } else if (phoneString.length < 11) {
      setError(prevState => ({
        ...prevState,
        phone: messageConstant.VALID_PHONE,
      }));
      validationFailed = true;
    }
    if (validationFailed) {
      return;
    }

    const requiredPermissions = [
      businessUnitPermissionsConstant.POST_VACANCY,
      businessUnitPermissionsConstant.AUTHORISE_SHIFT,
      businessUnitPermissionsConstant.PERMANENT_SHIFT,
      businessUnitPermissionsConstant.INTERNATIONAL,
    ];

    // Check if at least one access type is selected
    if (
      !requiredPermissions.some(permission =>
        query.permissions.includes(permission),
      )
    ) {
      setModalVisible(true);
      return;
    }
    setLoading(true);
    query.employer_id = employerId;
    query.send_invoice = recieveInvoice;
    // query.lat = coordinates.lat;
    // query.long = coordinates.long;
    setQuery(query);
    employerUserService.employerUserAdd(query).then(
      res => {
        setLoading(false);
        props?.onSuccess();
        if (res && res.error_code == 0) {
          props?.handleUserAdd();
          props.setShowModal(false);
        }
      },
      err => {
        setError({
          common:
            typeof err == 'string'
              ? err
              : err?.message
              ? err.message
              : alertConstants.SERVER_ERROR,
        });
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (props?.employee) {
      setAgencyShift(
        props?.employee?.permissions.includes(
          businessUnitPermissionsConstant.POST_VACANCY,
        ) ||
          props?.employee?.permissions.includes(
            businessUnitPermissionsConstant.AUTHORISE_SHIFT,
          ),
      );
      setQuery(prevQuery => ({
        ...prevQuery,
        permissions: props?.employee?.permissions,
      }));
    }
    if (query?.send_invoice !== undefined) {
      setRecieveInvoice(query?.send_invoice);
    }
  }, [query?.send_invoice, props?.employee]);

  // Handle agencyShift checkbox change
  const handleAgencyShiftChange = () => {
    setAgencyShift(prevState => {
      const newState = !prevState;
      // Update permissions based on agencyShift state
      setQuery(prevQuery => {
        const currentPermissions = Array?.isArray(prevQuery?.permissions)
          ? prevQuery?.permissions
          : [];

        let permissions;

        if (newState) {
          // If agency is being checked for the first time
          if (!wasAgencyChecked) {
            permissions = [
              ...currentPermissions,
              businessUnitPermissionsConstant.AGENCY_PERMISSION,
              businessUnitPermissionsConstant.POST_VACANCY, // Add agency with postVacancy permission
            ];
            setWasAgencyChecked(true);
          } else {
            // If agency is already checked, add POST_VACANCY
            permissions = [
              ...currentPermissions,
              businessUnitPermissionsConstant.POST_VACANCY,
            ];
          }
        } else {
          // When agency is unchecked
          permissions = currentPermissions.filter(
            permission =>
              permission !==
                businessUnitPermissionsConstant.AGENCY_PERMISSION &&
              permission !== businessUnitPermissionsConstant.POST_VACANCY && // Remove both permissions
              permission !== businessUnitPermissionsConstant.AUTHORISE_SHIFT,
          );
          setWasAgencyChecked(false); // Reset the agency checked status
        }

        return {
          ...prevQuery,
          permissions,
        };
      });

      return newState;
    });
  };

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target;
    let newValue;
    if (name === 'name') {
      if (value.length > 64) {
        return; // Prevent updating the state if the name exceeds 10 characters
      } else {
        setError(prevState => ({ ...prevState, name: '' }));
      }
    }
    if (name === 'email') {
      const checkEmailValidation = miscService.emailVerify(value);
      if (!checkEmailValidation) {
        setError(prevState => ({
          ...prevState,
          email: messageConstant.INVALID_EMAIL,
        }));
      } else {
        setError(prevState => ({ ...prevState, email: '' }));
      }
    }
    if (name === 'phone') {
      const numericValue = value.replace(/[^0-9]/g, '');

      if (numericValue.length > 11) {
        // Prevent updating the state if the number exceeds 11 digits
        return;
      }

      if (numericValue.charAt(0) === '0') {
        if (numericValue.length !== 11) {
          setError(prevState => ({
            ...prevState,
            phone: messageConstant.VALID_PHONE,
          }));
        } else {
          setError(prevState => ({ ...prevState, phone: '' }));
        }
      } else {
        setError(prevState => ({
          ...prevState,
          phone: messageConstant.VALID_PHONE,
        }));
      }

      // Update the query with the valid numeric value
      setQuery({ ...query, phone: numericValue });
    }
    if (name === 'agencyShift') {
      // Toggle agencyShift state
      setAgencyShift(!agencyShift);
      setQuery(prevQuery => {
        const permissions = prevQuery.permissions.includes(value)
          ? prevQuery.permissions.filter(permission => permission !== value)
          : [...prevQuery.permissions, value];

        setQuery({
          ...prevQuery,
          permissions,
        });
      });
    } else {
      if (type === 'checkbox') {
        newValue = checked
          ? [...query.permissions, parseInt(value)]
          : query.permissions.filter(
              permission => permission !== parseInt(value),
            );
      } else if (type === 'toggle') {
        newValue = query.permissions.includes(parseInt(value))
          ? query.permissions.filter(
              permission => permission !== parseInt(value),
            )
          : [...query.permissions, parseInt(value)];
      } else {
        newValue = value;
      }

      setQuery(prevQuery => ({ ...prevQuery, [name]: newValue }));
    }
  };

  const handleKeyPress = event => {
    const charCode = event.charCode ? event.charCode : event.keyCode;
    // Only allow digits (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleBusinessUnitChange = selectedOption => {
    setQuery({ ...query, business_unit_id: selectedOption.value });
    setError({ ...error, businessUnit: '' });
  };

  const getBusinessUnitList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        employer_id: employerId,
        searchKey: inputvalue,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        let options = data.rows.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        if (options.length === 0) {
          return [];
        }
        // options.unshift({ value: 'all', label: 'All' });
        return options;
      });
  };

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      zIndex: '1',
      border: '1px solid #8a8a8a',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      fontSize: '14px',
      lineHeight: '25px',
      background: '#FFFFFF',
      borderRadius: '6px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
  };

  // Agency shift option is deselected automatically, even Post vacancy & Authorize shift is disabled
  useEffect(() => {
    const isPostVacancyActive = query?.permissions?.includes(
      businessUnitPermissionsConstant.POST_VACANCY,
    );
    const isAuthoriseShiftActive = query?.permissions?.includes(
      businessUnitPermissionsConstant.AUTHORISE_SHIFT,
    );

    if (!isPostVacancyActive && !isAuthoriseShiftActive) {
      if (agencyShift) {
        handleAgencyShiftChange({ target: { checked: false } });
      }
    }
  }, [query?.permissions, agencyShift]);

  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content add-new-modal-settings-user">
        <div className="modal-header" style={{ alignItems: 'center' }}>
          <h5 className="modal-title" id="modalLabel">
            {props?.title}
          </h5>
          <div className="close mb-1 pt-0 mx-0 pr-2">
            <a onClick={() => props.setShowModal(false)}>
              <i className=" fa fa-times-circle red fs-18"></i>
            </a>
          </div>
        </div>
        <div className="modal-body flx-business-unit-body">
          {modalVisible && (
            <div
              className="modal-dialog flx-modal-popup"
              role="document"
              tabIndex="-1"
              style={{
                position: 'absolute',
                zIndex: '2',
                right: '1px',
                borderRadius: '8px',
              }}
            >
              <div
                className="modal-content"
                style={{ overflowY: 'scroll', borderRadius: '8px' }}
              >
                <div
                  className="modal-header flx-add-shift-popup-header"
                  style={{ paddingRight: '0px' }}
                >
                  <button
                    type="button"
                    className="close flx-closebutton-popup flx-addshifttime-close-icon"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModalVisible(false)}
                  >
                    <span aria-hidden="true" className="flx-closeicon">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="accesstypeImage">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src={'/assets/img/AccessType.svg'}
                    alt="AccessType"
                  />
                </div>
                <div className="modal-body">
                  <h5 className="modal-title accesstypeMessage" id="modalLabel">
                    The user will not be able to access the system. Please
                    select the access type.
                  </h5>
                </div>
                <div className="accesstypefooter">
                  <span
                    className="flx-new-user-cancel pointer"
                    onClick={() => setModalVisible(false)}
                  >
                    Ok
                  </span>
                </div>
              </div>
            </div>
          )}
          <form>
            <div className="form-group">
              <label
                htmlFor="name"
                className="flx-job-posts-labels flx-recs-required"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control selectDesign"
                id="name"
                name="name"
                value={query?.name}
                onChange={handleInputChange}
                placeholder="Name"
                required
              />
              {error.name && <div className="text-danger">{error.name}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="businessUnit" className="flx-job-posts-labels">
                Choose Business Unit
              </label>
              <AsyncSelect
                styles={customSelectStyles}
                name="businessUnit"
                placeholder={'Select Business Unit'}
                cacheOptions
                defaultOptions
                noOptionsMessage={() => 'No results found'}
                defaultValue={
                  props?.employee?.business_unit_id
                    ? {
                        label: props.employee['Businessunit.name'],
                        value: props.employee.business_unit_id,
                      }
                    : null
                } // Set defaultValue conditionally
                loadOptions={getBusinessUnitList}
                onChange={handleBusinessUnitChange}
              />
              {error.businessUnit && (
                <div className="text-danger">{error.businessUnit}</div>
              )}
            </div>
            <div className="flx-toggle-container-add-new-user">
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input pointer mt-0"
                  type="checkbox"
                  id="agencyShift"
                  name="agencyShift"
                  checked={agencyShift}
                  onChange={handleAgencyShiftChange}
                />
                <label className="form-check-label" htmlFor="agencyShift">
                  Agency Shift
                </label>
              </div>
              {(agencyShift ||
                query?.permissions?.includes(
                  businessUnitPermissionsConstant.POST_VACANCY,
                ) ||
                query?.permissions?.includes(
                  businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                )) && (
                <>
                  <div
                    className={`toggle-bar ${
                      query?.permissions?.includes(
                        businessUnitPermissionsConstant.POST_VACANCY,
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      handleInputChange({
                        target: {
                          name: 'permissions',
                          value: businessUnitPermissionsConstant.POST_VACANCY,
                          type: 'toggle',
                        },
                      })
                    }
                  >
                    <div
                      className={`toggle-circle ${
                        query?.permissions?.includes(
                          businessUnitPermissionsConstant.POST_VACANCY,
                        )
                          ? 'active'
                          : ''
                      }`}
                    ></div>
                    <span className="toggle-label">Post Vacancy</span>
                  </div>
                  <div
                    className={`toggle-bar ${
                      query?.permissions?.includes(
                        businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      handleInputChange({
                        target: {
                          name: 'permissions',
                          value:
                            businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                          type: 'toggle',
                        },
                      })
                    }
                  >
                    <div
                      className={`toggle-circle ${
                        query?.permissions?.includes(
                          businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                        )
                          ? 'active'
                          : ''
                      }`}
                    ></div>
                    <span className="toggle-label">Authorise Shift</span>
                  </div>
                </>
              )}
            </div>

            <div className="flx-checkbox-container-settings-create-new-user mt-1">
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="permanentShift"
                  name="permissions"
                  value={businessUnitPermissionsConstant.PERMANENT_SHIFT}
                  checked={query?.permissions?.includes(
                    businessUnitPermissionsConstant.PERMANENT_SHIFT,
                  )}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="permanentShift">
                  Permanent Recruitment
                </label>
              </div>
              <div className="form-check my-1 d-flex align-items-center">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="international"
                  name="permissions"
                  value={businessUnitPermissionsConstant.INTERNATIONAL}
                  checked={query?.permissions?.includes(
                    businessUnitPermissionsConstant.INTERNATIONAL,
                  )}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="international">
                  International Recruitment
                </label>
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="email"
                className="flx-job-posts-labels flx-recs-required"
              >
                Email
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control selectDesign"
                  id="email"
                  name="email"
                  value={query?.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email"
                  autoComplete="off"
                  required
                />
              </div>
              {error.email && <div className="text-danger">{error.email}</div>}
            </div>
            <div className="form-group">
              <label
                htmlFor="phone"
                className="flx-job-posts-labels flx-recs-required"
              >
                Phone
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="flx-basic-addon1">
                    +44
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control selectDesign"
                  id="phone"
                  name="phone"
                  value={query?.phone}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter Phone"
                  aria-label="Phone number"
                  aria-describedby="basic-addon1"
                />
              </div>
              {error.phone && <div className="text-danger">{error.phone}</div>}
            </div>
            <div className="form-check d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input pointer"
                id="showAdditionalFields"
                checked={recieveInvoice}
                onChange={() => setRecieveInvoice(!recieveInvoice)}
              />
              <label
                className="form-check-label"
                htmlFor="showAdditionalFields"
              >
                Receive Invoice
              </label>
            </div>
            {/* <div className="form-group">
              <label
                htmlFor="postcode"
                className="flx-job-posts-labels flx-recs-required"
              >
                Postcode{' '}
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="postcode"
                  name="postcode"
                  value={query.postcode}
                  onChange={handleInputChange}
                  placeholder="Enter Postcode"
                  required
                />
                {error.postcode && (
                  <div className="text-danger">{error.postcode}</div>
                )}
              </div>
            </div> */}
            {/* <div className="form-group">
              <div className="form-group">
                <label
                  htmlFor="postcode"
                  className="form-label company-info-labels"
                >
                  Select Address
                </label>
                <select
                  className="form-control"
                  name="address"
                  placeholder="select address"
                  value={query?.address}
                  onChange={handleInputChange}
                >
                  <option value={''} hidden="true">
                    Select Address
                  </option>
                  {PostcodeObject.addresses}
                </select>
              </div>
            </div> */}
          </form>
          {error.common && (
            <div className="mt-3 text-danger">{error.common}</div>
          )}
        </div>
        <div className="modal-footer flx-popup-bottom mr-3">
          <span
            onClick={() => props.setShowModal(false)}
            className="flx-add-new-user pointer"
          >
            Cancel
          </span>
          <button
            type="button"
            className="btn btn-sm flx-popup-button float-end"
            disabled={loading}
            onClick={handleSubmit}
          >
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 1.25,
              }}
            >
              {loading && !props.employee
                ? 'Adding...'
                : loading && props.employee
                ? 'Saving...'
                : !loading && !props.employee
                ? 'Add Now '
                : 'Save'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewModal;
