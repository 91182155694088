import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { sectionActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';



const Section = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [section, setSection] = useState({ name: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllSection(1, query);
    }
  }, []);
  /**
   * Get all sections
   * */
  const getAllSection = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(sectionActions.sectionList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setSection(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Section">
      {(props.requestStatus === reducerConstants.SECTION_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Section view</h4>
            </div>
          </div>
          <div className="card-body"> </div>
          <div className="card-footer">
            <Link className="link" to={'/sectionadd/' + section._id}>
              {' '}
              Edit{' '}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, sectionData } = state.section;
  return {
    requestStatus,
    sectionData,
  };
}
const connectedSection = connect(mapStateToProps)(Section);
export { connectedSection as Section };
