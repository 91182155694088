import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { categoryService } from '../_services';
// const dispatch = useDispatch();
export const categoryActions = {
  categoryAdd,
  categoryList,
  // categoryDelete,
  categoryListNoAuth,
  dashbordCategoryReport,
};

function categoryAdd(categoryObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.CATEGORY_REQUEST, categoryObj });

      categoryService.categoryAdd(categoryObj).then(
        data => {
          dispatch({
            type: reducerConstants.CATEGORY_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.CATEGORY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function categoryList(categoryObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CATEGORY_REQUEST,
        categoryObj,
      });

      categoryService.categoryList(categoryObj).then(
        data => {
          dispatch({
            type: reducerConstants.CATEGORY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CATEGORY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function dashbordCategoryReport(categoryObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CATEGORY_REQUEST,
        categoryObj,
      });
      categoryService.dashbordCategoryReport(categoryObj).then(
        data => {
          dispatch({
            type: reducerConstants.CATEGORY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CATEGORY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function categoryListNoAuth(categoryObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CATEGORY_REQUEST,
        categoryObj,
      });

      categoryService.categoryListNoAuth(categoryObj).then(
        data => {
          dispatch({
            type: reducerConstants.CATEGORY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CATEGORY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function categoryDelete(categoryObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.CATEGORY_REQUEST,
//         categoryObj,
//       });

//       categoryService.categoryDelete(categoryObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.CATEGORY_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.CATEGORY_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
