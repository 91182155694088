import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { PriceService } from '../_services';
// const dispatch = useDispatch();
export const PriceActions = {
  PriceAdd,
  PriceList,
  // PriceDelete,
};

function PriceAdd(PriceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PRICE_REQUEST, PriceObj });

      PriceService.PriceAdd(PriceObj).then(
        data => {
          dispatch({
            type: reducerConstants.PRICE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PRICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function PriceList(PriceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.PRICE_REQUEST,
        PriceObj,
      });

      PriceService.PriceList(PriceObj).then(
        data => {
          dispatch({
            type: reducerConstants.PRICE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.PRICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function PriceDelete(PriceObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.PRICE_REQUEST,
//         PriceObj,
//       });

//       PriceService.PriceDelete(PriceObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.PRICE_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.PRICE_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
