/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { AllShiftsList } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

// eslint-disable-next-line no-unused-vars
let shiftsRows = [];
let employer_id = '';
let user_role = '';

const CandidateReport = props => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [viewshiftsCandidate_id, SetviewshiftsCandidate_id] = useState([]);
  const [selecteddate, setselecteddate] = useState({
    from_date: '',
    to_date: '',
  });
  const [filter, setfilter] = useState({ employer_id: '', candidate_id: '' });
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isEmployer, setisEmployer] = useState(false);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  useEffect(() => {
    if (id) {
      if (id == 'unverified') {
        query.is_accepted = 0;
      }
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
      }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        setisEmployer(true);
      }
    }
    getAllShifts(1);
  }, [
    selecteddate,
    query,
    query.shift_type,
    query.status,
    query.is_verified,
    isAdmin,
    query.employer_id,
    query.candidate_id,
  ]);

  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setfilter({ ...filter, candidate_id: selectedOption.value });
    let newquery = { ...query, candidate_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setfilter({ ...filter, employer_id: selectedOption.value });
    let newquery = { ...query, employer_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (id == 'unverified') {
      queryNew.is_accepted = 0;
    }
    if (selecteddate.from_date) {
      queryNew.from_date = selecteddate.from_date;
    } else {
      delete queryNew.from_date;
    }
    if (selecteddate.to_date) {
      queryNew.to_date = selecteddate.to_date;
    } else {
      delete queryNew.to_date;
    }
    if (!queryNew.is_verified) {
      delete queryNew.is_verified;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.Candidateshiftreport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  let shiftsList = [];
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      const candidateFirstName = shiftsData.rows[j].name;
      const candidateLastName = shiftsData.rows[j].last_name;
      const fullName =
        candidateFirstName && candidateLastName
          ? `${candidateFirstName} ${candidateLastName}`
          : candidateFirstName || candidateLastName || '';

      const displayName =
        fullName.length > 8 ? `${fullName.substring(0, 12)}...` : fullName;
      shiftsList.push(
        <tr key={j} className="row-body2">
          <td className="username">
            <div
              style={{ color: '#000', cursor: 'pointer' }}
              onClick={() => {
                setSelectedCandidateId(shiftsData.rows[j]._id),
                  setshowCandidateprofileModal(true);
              }}
              title={fullName}
            >
              {shiftsData.rows[j].profileimage ? (
                <img
                  className=" round-emp-image"
                  src={apiConstants.imgUrl + shiftsData.rows[j].profileimage}
                />
              ) : (
                <img
                  className=" round-emp-image"
                  src="/assets/img/recsy-fav-icon.png"
                />
              )}
              {displayName}
            </div>
          </td>
          <td>
            {shiftsData.rows[j].attended ? shiftsData.rows[j].attended : 0}
          </td>
          <td>
            {shiftsData.rows[j].not_attended
              ? shiftsData.rows[j].not_attended
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].canceled_shifts
              ? shiftsData.rows[j].canceled_shifts
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].deleted_shifts
              ? shiftsData.rows[j].deleted_shifts
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].total_signed_hrs
              ? shiftsData.rows[j].total_signed_hrs + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td>
          <td>
            {shiftsData.rows[j].invoice_generated
              ? shiftsData.rows[j].invoice_generated
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].payout_generated
              ? shiftsData.rows[j].payout_generated
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].paid_amount
              ? shiftsData.rows[j].paid_amount
              : 0}
          </td>
          <td>{shiftsData.rows[j].to_pay ? shiftsData.rows[j].to_pay : 0}</td>
          <td>
            {shiftsData.rows[j].total_hours
              ? shiftsData.rows[j].total_hours
              : 0}
          </td>
          <td>
            Night &nbsp; - &nbsp;{' '}
            {shiftsData.rows[j].total_night && shiftsData.rows[j].total_night
              ? shiftsData.rows[j].total_night
              : 0}
            &nbsp; hrs
            <p>
              Morning&nbsp; - &nbsp;
              {shiftsData.rows[j].total_morning &&
              shiftsData.rows[j].total_morning
                ? shiftsData.rows[j].total_morning
                : 0}
              &nbsp; hrs
            </p>
            Custome&nbsp; - &nbsp;
            {shiftsData.rows[j].total_custome &&
            shiftsData.rows[j].total_custome
              ? shiftsData.rows[j].total_custome
              : 0}
            &nbsp; hrs
          </td>
          <td>
            <div
              style={{ color: '#1ac8ff' }}
              data-cy="allshift-viewdt"
              className="allshift-viewdt"
              onClick={e => {
                SetviewshiftsCandidate_id(shiftsData.rows[j]._id),
                  setfilter({
                    ...filter,
                    candidate_id: shiftsData.rows[j].candidate_id,
                  });
                setviewdetailsmodal(true);
              }}
            >
              View
            </div>
          </td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setselecteddate({
      ...selecteddate,
      [name]: value,
    });
  };

  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #ccc',
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#F4F4F4',
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };

  return (
    <div className="ShiftList">
      <div className="col-md-12 p-0">
        <div className="card regular-table-with-color m-0">
          <div
            className="card-body  table-responsive main-card-body p-0"
            style={{ paddingTop: 0 }}
          >
            <div className="">
              <div className="filter-row">
                <div className="filter-item3">
                  <div className="" style={{}}>
                    <div className="mt-4 pl-1">
                      {' '}
                      <label className="" htmlFor="type">
                        From
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className=" input-design1 "
                        style={{
                          border: 'none',
                          display: 'block',
                          width: '100%',
                          height: '40px',
                          padding: '0.375rem 0.75rem',
                          flexShrink: '0',
                          fontSize: '14px',
                          fontWeight: '500',
                          borderRadius: '6px',
                        }}
                        name="from_date"
                        placeholder=""
                        value={selecteddate.from_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-item3">
                  <div className="" style={{}}>
                    <div className="mt-4 pl-1">
                      {' '}
                      <label className="" htmlFor="type">
                        To
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="input-design1"
                        style={{
                          border: 'none',
                          display: 'block',
                          width: '100%',
                          height: '40px',
                          padding: '0.375rem 0.75rem',
                          flexShrink: '0',
                          fontSize: '14px',
                          fontWeight: '500',
                          borderRadius: '6px',
                        }}
                        name="to_date"
                        placeholder=""
                        value={selecteddate.to_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {!isEmployer && (
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="mt-4 ">
                        {' '}
                        <label className="ml-1" htmlFor="type">
                          Employer
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="employer_id"
                          onChange={setEmployer}
                          placeholder={'Select Employer'}
                          cacheOptions
                          defaultOptions
                          loadOptions={getEmployerlist}
                          className="employerselect"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!isEmployer && (
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="mt-4 ">
                        {' '}
                        <label className="ml-1" htmlFor="type">
                          Candidate
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="candidate_id"
                          onChange={selectedcandidate}
                          placeholder={'Select Candidate'}
                          cacheOptions
                          defaultOptions
                          className="candidateselect"
                          // isMulti
                          loadOptions={getcandidatelist}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mt-4 pl-1">
                      {' '}
                      <label className="candidate-report-label" htmlFor="type">
                        Branch
                      </label>
                    </div>
                    <div>
                      <select
                        className="select col-md-12 selectDesign report-input"
                        name="branch"
                        value={query.branch}
                        // onChange={e => {
                        //   query.branch = e.target.value;
                        // }}
                        onChange={e => {
                          setQuery(prevquery => ({
                            ...prevquery,
                            branch: e.target.value,
                          }));
                        }}
                      >
                        <option value="" hidden={true}>
                          {' '}
                          Select
                        </option>
                        <option value="">All</option>
                        <option value={'Basildon'}>Basildon</option>
                        <option value={'Wales'}>Wales</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isLoading && (
              <table className="table  flx-custom-table mt-3 flx-table-border">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th
                      style={{ width: '220px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead border-top1 table-header-ht"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Name</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('name')}
                            />
                          )}
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('name')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th>Attended</th>
                    <th style={{ width: '120px' }}>Not Attended</th>
                    <th style={{ width: '100px' }}>Canceled</th>
                    <th style={{ width: '100px' }}>Deleted</th>
                    <th
                      style={{ width: '180px' }}
                      onClick={() => handleSort('total_signed_hrs')}
                      className="sortHead border-top1"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Total Signed Hours</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'total_signed_hrs' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('total_signed_hrs')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('total_signed_hrs')}
                            />
                          )}
                          {query.pageVo.sort === 'total_signed_hrs' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('total_signed_hrs')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('total_signed_hrs')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th style={{ width: '150px' }}>Invoice Generated</th>
                    <th style={{ width: '150px' }}>Payout Generated</th>
                    <th>Paid</th>
                    <th>To Pay</th>
                    <th style={{ width: '120px' }}>Total Hours</th>
                    <th style={{ width: '180px' }}>Worked Shifts</th>
                    <th className="border-top2"></th>
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {shiftsRows &&
              shiftsRows &&
              shiftsRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large">
            <div className="modal-content p-1">
              <div className="modal-header border-bot-0 d-flex justify-content-between mt-2">
                <div className="pl-1">
                  <h4
                    className="card-title pl-2"
                    style={{ color: 'black', fontWeight: 'bold' }}
                  >
                    Candidate Report
                  </h4>
                </div>
                <div className="close p-0 pb-3">
                  <a onClick={() => setviewdetailsmodal(false)} className="p-3">
                    <i className="fa fa-times-circle close-button-modal"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body py-0">
                <AllShiftsList
                  is_report={true}
                  id={viewshiftsCandidate_id}
                  employer_id={filter.employer_id}
                  from_date={selecteddate.from_date}
                  to_date={selecteddate.to_date}
                  candidate_id={filter.candidate_id}
                  // is_verified="1"
                  status="1"
                ></AllShiftsList>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name ? company_name : 'Unknown',
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name }) => ({
              value: _id,
              label: name + ' ' + (last_name ? last_name : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedCandidateReport = connect(mapStateToProps)(CandidateReport);
export { connectedCandidateReport as CandidateReport };
