import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { StaffAdd } from '../Staff/StaffAdd';
import { staffActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let staffRows = [];

const StaffList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [staffData, setStaffData] = useState({ rows: [] });
  const [staffRows, setStaffRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  // effect to call stafflist
  useEffect(() => {
    getAllStaff(1);
  }, [query]);
  /**
   * Get all staffs
   **/
  const getAllStaff = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(staffActions.staffList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = staffData => {
    let staffList = [];
    for (
      let j = 0;
      staffData && staffData.rows && j < staffData.rows.length;
      j = j + 1
    ) {
      staffList.push(
        <tr key={j}>
          <td className="username">
            <img
              className=" round-emp-image"
              src="/assets/img/un-avatar.png"
            ></img>
            {staffData.rows[j].name}{' '}
            {staffData.rows[j].last_name && staffData.rows[j].last_name}
          </td>
          <td>
            {staffData &&
              staffData.rows &&
              staffData.rows[j].createdAt &&
              moment(staffData.rows[j].createdAt).format('DD-MMM-YYYY')}
          </td>
          <td>{staffData.rows[j].email} </td>
          <td>{staffData.rows[j].phone} </td>
          <td className="eventCell  eventView">
            <Link
              className="staff_view"
              data-cy="viewstaff"
              to={'/staff/' + staffData.rows[j]._id}
            >
              {' '}
              View{' '}
            </Link>
          </td>
        </tr>,
      );
    }
    setStaffRows(staffList);
    setStaffData(staffData);
  };
  return (
    <div className="StaffList">
      <div className="col-md-12 p-0">
        <div className="card regular-table-with-color mx-4 px-2">
          <div className="card-header page-header pb-3">
            <div className="row mt-3">
              <div className="col-md-10 ml-2">
                <div className="page-title"> Staffs</div>
              </div>
              <div className="col d-flex justify-content-end">
                <Link className="link" data-cy="addstaff" to={'/staffadd/0'}>
                  <div className="btn btn-primary-blue float-right">
                    <i className="flx-assign-user-icon fa fa-user-plus "></i>
                    <span> Add staff</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body flx-table-border">
            {!isLoading && (
              <table className="table  flx-custom-table">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th
                      style={{ width: '380px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead border-top1 table-header-ht"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Name</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('name')}
                            />
                          )}
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('candidate_name')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th
                      onClick={() => handleSort('createdAt')}
                      className="sortHead"
                      style={{ width: '380px' }}
                    >
                      <div className="d-flex align-items-center ">
                        <span>Created</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'createdAt' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('createdAt')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('createdAt')}
                            />
                          )}
                          {query.pageVo.sort === 'createdAt' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('createdAt')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('createdAt')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th style={{ width: '380px' }}>Email</th>
                    <th style={{ width: '380px' }}>Phone</th>
                    <th className="eventCell border-top2 eventView" />
                  </tr>
                </thead>
                <tbody>{staffRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            {staffData &&
              staffData.rows &&
              staffData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllStaff(data);
              }}
              paginationData={staffData}
            />
          </div>
          {showmodal && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setshowmodal(false)}>
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    <StaffAdd id={query._id} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, staffData } = state.staff;
  return { requestStatus, staffData };
}

const connectedStaffList = connect(mapStateToProps)(StaffList);
export { connectedStaffList as StaffList };
