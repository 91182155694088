import React, { useEffect, useState } from 'react';

export const Pagination = props => {
  const [paginationItems, setPaginationItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const pageOnClick = data => {
    if (props.pageOnClick) {
      props.pageOnClick(data);
    }
  };
  const setListAndPagination = paginationData => {
    let paginationItems = [];
    if (paginationData && paginationData.pages > 1) {
      let start = 1;
      let end = paginationData.pages;
      let currentPageNo = paginationData.page;
      let totalPages = paginationData.pages;
      if (totalPages > 5) {
        if (currentPageNo + 4 > totalPages) {
          start = totalPages - 4;
          end = totalPages;
        } else {
          if (currentPageNo == end) {
            start = currentPageNo;
            end = parseInt(currentPageNo) + 4;
          } else if (currentPageNo == 1) {
            start = currentPageNo;
            end = start + 4;
          } else if (currentPageNo == start) {
            end = currentPageNo;
            start = currentPageNo - 4;
            if (currentPageNo - 4 <= 0) {
              start = 1;
              end = 5;
            }
          } else if (currentPageNo < start) {
            end = start;
            start = end - 4;
          } else if (currentPageNo > start) {
            if (currentPageNo - 2 <= 0) {
              start = 1;
              end = 5;
            } else {
              start = currentPageNo - 2;
              end = currentPageNo + 2;
            }
          } else if (!end && !start) {
            start = 1;
            end = 5;
          }
        }
      }
      paginationItems.push(
        <li
          className="page-item "
          onClick={() => {
            if (currentPageNo > 1) {
              pageOnClick(currentPageNo - 1);
            }
          }}
          key="prev"
        >
          {' '}
          <a
            className="page-link"
            style={{
              cursor: start < currentPageNo ? 'pointer' : 'not-allowed',
              pointerEvents: start < currentPageNo ? 'auto' : 'none',
              backgroundColor: start < currentPageNo ? 'inherit' : '#e4e6ec',
              color: start < currentPageNo ? 'inherit' : '#c0c0c0',
            }}
          >
            prev{' '}
          </a>{' '}
        </li>,
      );
      for (let i = start; i <= end; i = i + 1) {
        paginationItems.push(
          <li
            onClick={() => pageOnClick(i)}
            className={
              'page-item ' + (currentPageNo === i ? 'active ' : 'noactive')
            }
            key={i}
          >
            <a className="page-link">{i}</a>
          </li>,
        );
      }
      paginationItems.push(
        <li
          className="page-item "
          onClick={() => {
            if (totalPages > currentPageNo) {
              pageOnClick(currentPageNo + 1);
            }
          }}
          key="next"
        >
          {' '}
          <a
            className="page-link "
            style={{
              cursor: totalPages > currentPageNo ? 'pointer' : 'not-allowed',
              pointerEvents: totalPages > currentPageNo ? 'auto' : 'none',
              backgroundColor:
                totalPages > currentPageNo ? 'inherit' : '#1cc8ff',
              color: totalPages > currentPageNo ? 'inherit' : '#c0c0c0',
            }}
          >
            {' '}
            Next{' '}
          </a>{' '}
        </li>,
      );
    }

    setPaginationItems(paginationItems);
  };

  useEffect(() => {
    setCurrentPage(props?.paginationData?.page);
    setListAndPagination(props.paginationData);
  }, [props.paginationData]);

  useEffect(() => {
    if (props.paginationData?.rows?.length === 0 && currentPage > 1) {
      pageOnClick(currentPage - 1);
    }
  }, [props?.paginationData?.rows, currentPage]);

  return <ul className="pagination"> {paginationItems}</ul>;
};
