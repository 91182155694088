import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const blockcandidateService = {
  blockcandidateAdd,
  blockcandidateList,
  // blockcandidateDelete,
  unblockCandidate,
};

function blockcandidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/candidateblockadd', requestOptions).then(
    miscService.handleResponse,
  );
}
function unblockCandidate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/unblockcandidate', requestOptions).then(
    miscService.handleResponse,
  );
}
function blockcandidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getblockcandidatelist',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function blockcandidateDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/blockcandidatedelete',
//     requestOptions,
//   ).then(handleResponse);
// }
