import React, { useEffect, useState } from 'react';
import { apiConstants, messageConstant } from '../../_constants';
import { businessunitService, miscService } from '../../_services';

const AddNewBusinessUnitModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    common: '',
    username: '',
    name: '',
    email: '',
    password: '',
    postcode: '',
    phone: '',
  });
  const [coordinates, setCoordinates] = useState({ lat: '', long: '' });
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [recieveInvoice, setRecieveInvoice] = useState(false);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [query, setQuery] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    postcode: '',
    address: '',
    phone: '',
  });

  const [postcodeError, setPostcodeError] = useState('');
  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id
    ? userData.employer_id
    : props.employerId;

  useEffect(() => {
    if (query?.postcode) {
      loadaddress();
    }
  }, [query?.postcode]);

  useEffect(() => {
    if (props.employee) {
      setQuery({
        ...query,
        name: props.employee.name || '',
        email: props.employee.email || '',
        postcode: props.employee.postcode || '',
        address: props.employee.address || '',
        _id: props.employee._id || '',
        branch_manager_id: props.employee.branch_manager_id || '',
      });
    }
  }, [props.employee]);
  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    let regex =
      /^[A-Za-z]{1,2}[0-9RCHNQrchnq][0-9A-Za-z]?\s?[0-9][ABD-HJLNP-UW-Zabc-hjlnp-z]{2}$/;
    return regex.test(postcode);
  };

  const loadaddress = async () => {
    const res = query?.postcode?.replace(/ /g, '');
    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        coordinates.lat = response.latitude;
        coordinates.long = response.longitude;
        setCoordinates(coordinates);

        let addresses_rows = [];
        let regex = /\s+,/g;

        if (query.address) {
          addresses_rows.push(
            <option key="saved" value={query.address}>
              {query.address}
            </option>,
          );
        }

        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }

        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
      }
    }
  };

  // postcode change handler
  const handleChangepostcode = async event => {
    event.preventDefault();
    setError({ ...error, postcode: '' });
    let { name, value } = event.target;

    const postCode = value.slice(0, 8);
    setQuery({
      ...query,
      [name]: postCode,
    });

    if (!postCode) {
      setPostcodeObject({
        isValied: false,
        addresses: [],
      });
      setPostcodeError('');
      return;
    }

    const postCodeRegex =
      /^[A-Za-z]{1,2}[0-9RCHNQrchnq][0-9A-Za-z]?\s?[0-9][ABD-HJLNP-UW-Zabc-hjlnp-z]{2}$/;
    if (postCodeRegex.test(postCode)) {
      let posstcodeurl = `https://api.getaddress.io/find/${postCode}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        let regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }

        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setQuery({
          ...query,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: postCode,
        });
        setPostcodeError('');
      }
    } else {
      // Invalid postcode entered
      setPostcodeObject({
        isValied: false,
        addresses: [],
      });
      setPostcodeError('Invalid Postcode');
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    setError({ ...error });
    let validationFailed = false;
    if (!query.name) {
      setError(prevState => ({ ...prevState, name: 'Name is required' }));
      validationFailed = true;
    }

    if (!query.postcode) {
      setError(prevState => ({
        ...prevState,
        postcode: 'Postcode is required',
      }));
      validationFailed = true;
    }
    if (!query.address) {
      setError(prevState => ({ ...prevState, address: 'Address is required' }));
      validationFailed = true;
    }
    if (showAdditionalFields) {
      if (!query.username) {
        setError(prevState => ({
          ...prevState,
          username: 'User Name is required',
        }));
        validationFailed = true;
      }
      if (!query.email) {
        setError(prevState => ({
          ...prevState,
          email: 'Email is required',
        }));
        validationFailed = true;
      }
      if (!query.phone) {
        setError(prevState => ({
          ...prevState,
          phone: 'Phone is required',
        }));
        validationFailed = true;
      }
    }
    if (validationFailed) {
      return;
    }
    setLoading(true);
    query.employer_id = employerId;
    query.lat = coordinates.lat;
    query.long = coordinates.long;
    query.send_invoice = recieveInvoice;
    setQuery(query);

    businessunitService.businessunitAdd(query).then(
      res => {
        setLoading(false);
        if (res && res.error_code == 0) {
          props?.onSuccess(res.data);
          props?.setShowModal(false);
          props?.callBackBusinessunits();
        }
      },
      err => {
        setError({
          common: err,
        });
        setLoading(false);
      },
    );
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  // event handler to set details
  const handleInputChange = event => {
    const { name, value } = event.target;
    if (name === 'username') {
      if (value.length > 64) {
        return;
      } else {
        setError(prevState => ({ ...prevState, username: '' }));
      }
    }
    if (name === 'email') {
      const checkEmailValidation = miscService.emailVerify(value);
      if (!checkEmailValidation) {
        setError({ ...error, email: messageConstant.INVALID_EMAIL });
      } else {
        setError(prevState => ({ ...prevState, email: '' }));
      }
    }
    if (name === 'phone') {
      const numericValue = value.replace(/[^0-9]/g, '');

      if (numericValue.length > 11) {
        // Prevent updating the state if the number exceeds 11 digits
        return;
      }

      if (numericValue.charAt(0) === '0') {
        if (numericValue.length !== 11) {
          setError(prevState => ({
            ...prevState,
            phone: messageConstant.VALID_PHONE,
          }));
        } else {
          setError(prevState => ({ ...prevState, phone: '' }));
        }
      } else {
        setError(prevState => ({
          ...prevState,
          phone: messageConstant.VALID_PHONE,
        }));
      }

      // Update the query with the valid numeric value
      setQuery({ ...query, phone: numericValue });
    }
    if (name === 'name') {
      // Trim the value to the maximum length
      let trimmedValue = value.slice(0, 128);
      const nameRegex = /[^a-zA-Z0-9\s]/;
      setQuery({
        ...query,
        [name]: trimmedValue,
      });
      if (!nameRegex.test(trimmedValue)) {
        // Clear error if validation passes
        setError({ ...error, [name]: '' });
      } else {
        setError({
          ...error,
          [name]: 'Name is not valid {only A-Z,0-9 and space}.',
        });
      }
    }
    if (name === 'address') {
      setError({ ...error, address: '' });
    }
    // Update the query state
    setQuery(prevQuery => ({
      ...prevQuery,
      [name]: value,
    }));
  };

  const handleKeyPress = event => {
    const charCode = event.charCode ? event.charCode : event.keyCode;
    // Only allow digits (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modalLabel">
            {props?.title}
          </h5>
          <div className="close mr-1 mb-1 pt-0">
            <a onClick={() => props.setShowModal(false)}>
              <i className=" fa fa-times-circle red fs-18"></i>
            </a>
          </div>
        </div>
        <div className="modal-body flx-business-unit-body">
          <form>
            <div className="form-group">
              <label
                htmlFor="name"
                className="flx-job-posts-labels flx-recs-required"
              >
                Business Unit Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={query.name}
                onChange={handleInputChange}
                placeholder="Business Unit Name"
                required
              />
              {error.name && <div className="text-danger">{error.name}</div>}
            </div>

            {props?.title != 'Edit Business Unit' && (
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input pointer"
                  id="showAdditionalFields"
                  checked={showAdditionalFields}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="showAdditionalFields"
                >
                  I would like to create as a new user
                </label>
              </div>
            )}

            {/* Additional fields */}
            {showAdditionalFields && (
              <div>
                <div className="form-group">
                  <label htmlFor="name" className="name">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="employerUserName"
                    name="username"
                    value={query.username}
                    onChange={handleInputChange}
                    placeholder="User Name"
                  />
                  {error.username && (
                    <div className="text-danger">{error.username}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="additionalEmail" className="phone">
                    Contact Person
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="additionalEmail"
                    name="email"
                    value={query.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                  {/* Error handling for additional email */}
                  {error.email && (
                    <div className="text-danger">{error.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="flx-basic-addon1">
                        +44
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone"
                      aria-label="Phone number"
                      name="phone"
                      value={query.phone}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  {/* Error handling for additional phone */}
                  {error.phone && (
                    <div className="text-danger">{error.phone}</div>
                  )}
                </div>
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input pointer"
                    id="showAdditionalFields"
                    checked={recieveInvoice}
                    onChange={() => setRecieveInvoice(!recieveInvoice)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="showAdditionalFields"
                  >
                    Receive Invoice
                  </label>
                </div>
              </div>
            )}

            <div className="form-group">
              <label
                htmlFor="postcode"
                className="flx-job-posts-labels flx-recs-required"
              >
                Postcode{' '}
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="postcode"
                  name="postcode"
                  value={query.postcode}
                  onChange={handleChangepostcode}
                  placeholder="Postcode"
                  required
                />
              </div>
              {error.postcode && (
                <div className="text-danger">{error.postcode}</div>
              )}
              {postcodeError && (
                <div className="help-block">{postcodeError}</div>
              )}
            </div>
            <div className="form-group">
              <div className="form-group">
                <label
                  htmlFor="postcode"
                  className="form-label company-info-labels"
                >
                  Select Address
                </label>
                <select
                  className="form-control"
                  name="address"
                  placeholder="Select Address"
                  value={query.address}
                  onChange={handleInputChange}
                >
                  <option value={''} hidden="true">
                    Select Address
                  </option>
                  {PostcodeObject.addresses}
                </select>
                {error.address && (
                  <div className="text-danger">{error.address}</div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer flx-popup-bottom mr-3">
          <span
            onClick={() => props.setShowModal(false)}
            className="flx-add-new-user pointer"
          >
            Cancel
          </span>
          <button
            type="button"
            className="btn btn-sm flx-popup-button float-end"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading && !props.employee
              ? 'Adding...'
              : loading && props.employee
              ? 'Saving...'
              : !loading && !props.employee
              ? 'Add Now '
              : 'Save'}
          </button>
        </div>
        {error.common && <div className="mt-3 text-danger">{error.common}</div>}
      </div>
    </div>
  );
};

export default AddNewBusinessUnitModal;
