import { miscConstants, reducerConstants } from '../_constants';
import { miscService } from '../_services';
import { alertActions } from './';

export const miscActions = {
  fileupload,
  s3frontEndUploadUrl,
  // getAllActiveCountry,
  getgraphData,
  sharabletoken,
  handleSort,
  handleDateSort,
};

function handleSort(dataItem, dataState) {
  const sortedData = [...dataItem].sort((a, b) => {
    if (dataState) {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
  return sortedData;
}

function handleDateSort(dataItem, dataState) {
  const sortedData = [...dataItem].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    if (dataState) {
      return dateA - dateB; // Compare raw dates in ascending order
    } else {
      return dateB - dateA; // Compare raw dates in descending order
    }
  });
  return sortedData;
}

function getgraphData(graphObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        graphObj,
      });

      miscService.getgraphData(graphObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function getAllActiveCountry() {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch(request({}));

//       miscService.getAllActiveCountry({}).then(
//         data => {
//           resolve(data.data);
//           //  dispatch(success(data));
//           console.log(data);

//           dispatch({
//             type: reducerConstants.COUNTRYLIST_SUCCESS,
//             data,
//           });
//           // history.push('/');
//         },
//         error => {
//           reject(error);
//           dispatch(failure(error?.toString()));
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function s3frontEndUploadUrl(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      miscService.s3frontEndUploadUrl(candidateObj).then(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function sharabletoken(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      miscService.sharabletoken(candidateObj).then(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function fileupload(file, name, document_type, query) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: miscConstants.FILE_REQUEST,
        data: {},
      });
      miscService.fileupload(file, name, document_type, query).then(
        data => {
          //
          if (data.error_code === 0) {
            dispatch({
              type: miscConstants.FILE_SUCCESS,
              data: data.message.toString(),
            });
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
            dispatch({
              type: miscConstants.FILE_FAILURE,
              data: {},
            });
          }
        },
        error => {
          reject(error);
          dispatch(alertActions.error(error?.toString()));
          dispatch({
            type: miscConstants.FILE_FAILURE,
            data: {},
          });
        },
      );
    });
}

// function request(data) {
//   return { type: miscConstants.REQUEST, data };
// }

// function success(data) {
//   return { type: miscConstants.SUCCESS, data };
// }

// function failure(error) {
//   return { type: miscConstants.FAILURE, error };
// }
