import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmpassword: '',
      submitted: false,
      currentpassword: '',
      showmodal: false,
      showPassword: {
        currentpassword: false,
        newpassword: false,
        confirmpassword: false,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeText = this.changeText.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { confirmpassword, password, currentpassword } = this.state;
    const { dispatch } = this.props;
    if (password && password === confirmpassword && currentpassword) {
      dispatch(userActions.changePassword({ currentpassword, password })).then(
        res => {
          if (res) {
            this.setState({ showmodal: true });
          }
        },
        err => {
          console.error('Error updating password', err);
        },
      );
    }
  }

  togglePasswordVisibility(name) {
    this.setState(prevState => ({
      showPassword: {
        ...prevState.showPassword,
        [name]: !prevState.showPassword[name],
      },
    }));
  }

  changeText(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { requestStatus } = this.props;
    const { submitted, confirmpassword, currentpassword, password, showmodal } =
      this.state;
    const storedUser = JSON.parse(localStorage.getItem('user'));

    return (
      <div className="CandidateAdd d-flex justify-content-center">
        <div className="col-md-10 col-md-offset-2 p-0">
          <div className="card m-0">
            <div className=" page-header ">
              <div className="page-title-content pt-0">
                <p
                  className="card-title ml-2 pl-1"
                  style={{ fontSize: '20px', fontWeight: '500' }}
                >
                  Change Password{' '}
                </p>
              </div>
            </div>
            <div className="card-body">
              <form name="form" onSubmit={this.handleSubmit}>
                {(!storedUser.google_account_id ||
                  storedUser.google_account_id === '0') &&
                  (!storedUser.microsoft_account_id ||
                    storedUser.microsoft_account_id === '0') && (
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <div
                          className={
                            'form-group' +
                            (submitted && !currentpassword ? ' has-error' : '')
                          }
                        >
                          <label htmlFor="username">Current Password </label>
                          <div className="position-relative">
                            <input
                              type={
                                this.state.showPassword.currentpassword
                                  ? 'text'
                                  : 'password'
                              }
                              className="form-control selectDesign"
                              name="currentpassword"
                              value={currentpassword}
                              onChange={this.changeText}
                            />
                            <img
                              src={
                                this.state.showPassword.currentpassword
                                  ? '/assets/img/eyeclose.png'
                                  : '/assets/img/eye.png'
                              }
                              className="eye-icon-password"
                              onClick={() =>
                                this.togglePasswordVisibility('currentpassword')
                              }
                              alt="Toggle Current Password Visibility"
                            />
                          </div>
                          {submitted && !currentpassword && (
                            <div className="help-block">
                              Current password is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group ' +
                        (submitted && !password ? ' has-error' : '')
                      }
                    >
                      <label htmlFor="new password">New password </label>
                      <div className="position-relative">
                        <input
                          type={
                            this.state.showPassword.newpassword
                              ? 'text'
                              : 'password'
                          }
                          className="form-control selectDesign"
                          name="password"
                          value={password}
                          onChange={this.changeText}
                        />
                        <img
                          src={
                            this.state.showPassword.newpassword
                              ? '/assets/img/eyeclose.png'
                              : '/assets/img/eye.png'
                          }
                          className="eye-icon-password"
                          onClick={() =>
                            this.togglePasswordVisibility('newpassword')
                          }
                          alt="Toggle Confirm Password Visibility"
                        />
                      </div>
                      {submitted && !password && (
                        <div className="help-block">
                          New password is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group' +
                        (submitted &&
                        (!confirmpassword || confirmpassword !== password)
                          ? ' has-error'
                          : '')
                      }
                    >
                      <label htmlFor="username">Confirm password </label>
                      <div className="position-relative">
                        <input
                          type={
                            this.state.showPassword.confirmpassword
                              ? 'text'
                              : 'password'
                          }
                          className="form-control selectDesign"
                          name="confirmpassword"
                          value={confirmpassword}
                          onChange={this.changeText}
                        />
                        <img
                          src={
                            this.state.showPassword.confirmpassword
                              ? '/assets/img/eyeclose.png'
                              : '/assets/img/eye.png'
                          }
                          className="eye-icon-password"
                          onClick={() =>
                            this.togglePasswordVisibility('confirmpassword')
                          }
                          alt="Toggle Confirm Password Visibility"
                        />
                      </div>
                      {submitted && !confirmpassword && (
                        <div className="help-block">
                          Confirm password is required
                        </div>
                      )}
                      {submitted && confirmpassword !== password && (
                        <div className="help-block">
                          The password and confirmation password do not match.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row form-group d-flex justify-content-end">
                  <button
                    className="btn btn-primary-blue float-right mt-1 mr-1"
                    data-cy="confirmnow"
                  >
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.25,
                      }}
                    >
                      {' '}
                      Confirm Now
                    </span>
                  </button>
                  {requestStatus == 1 && (
                    <img
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      alt="Loading"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {showmodal && (
          <div
            className="modal sucessfulpopup"
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-sm ">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <div
                      className="hidemodal"
                      data-cy="hidemodal"
                      onClick={() => {
                        this.setState({ showmodal: false });
                        window.location.href = '/login';
                      }}
                    >
                      <i className="fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body ">
                  <div className="text-center">
                    <img
                      className="border-rad-55"
                      src="/assets/img/image.png"
                      alt="Success"
                    />
                    <h3
                      className="font-weight-bold"
                      style={{ fontSize: '20px' }}
                    >
                      Password Changed
                    </h3>
                    <h6 style={{ fontSize: '11px' }}>
                      Your password has been changed successfully
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {
    requestStatus,
  };
}

const connectedResetPassword = connect(mapStateToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };
