import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const logService = {
  // logAdd,
  logList,
  // logDelete,
};
// function logAdd(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/addlog', requestOptions).then(
//     handleResponse,
//   );
// }
function logList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getlogtablelist', requestOptions).then(
    miscService.handleResponse,
  );
}
// function logDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deletelog', requestOptions).then(
//     handleResponse,
//   );
// }
