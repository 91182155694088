/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { apiConstants } from '../_constants';
import { userService } from '../_services';
import ProfileUpload from './ProfileUpload';
// import { FileTransfer } from '../fileupload/FileTransfer';

const EmployerProfileMenu = props => {
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [showmodalfileEdit, setShowmodalfileEdit] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);
  const [profile, setprofile] = useState({});
  const [error, setError] = useState(null);

  // Function to set isImageSelected to true when an image is selected
  // const handleImageSelect = () => {
  //     setIsImageSelected(true);
  // };

  const calculateProgressBarWidth = () => {
    const filledKeys = Object.keys(props.profile).filter(
      key => props.profile[key],
    );
    const percentage =
      (filledKeys.length / Object.keys(props.profile).length) * 100;
    return Math.floor(percentage) + '%';
  };

  // upload file response handler
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setprofile(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
      (profile.profileimage = response.file_name), setprofile(profile);
      handleSubmit();
    }
    setshowmodalfileupload(false);
  };

  // hide file upload modal
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    profile._id = props?.id;
    // profile.role = 4;
    userService.userAdd(profile).then(
      () => {
        window.location.reload();
        // setIsDeleting(false)
      },
      () => {
        setError('Error updating user profile. Please try again.');
      },
    );
  };

  // const handleDeleteImage = () => {
  //   profile.profileimage = null;
  //   setprofile(profile);
  //   handleSubmit();
  // };

  return (
    <div className="flx-company-info container mt-3">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="col-md-12 mb-3 d-none d-md-block">
            <div className="d-flex flex-column">
              <div className="mb-2" style={{ position: 'relative' }}>
                {(props?.profile && props?.profile?.profileimage) ||
                profile.profileimage ? (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowmodalfileEdit(true)}
                  >
                    <img
                      className="profile-emp-image"
                      style={{
                        position: 'relative',
                        maxWidth: '100%',
                        maxHeight: '200px',
                      }}
                      src={
                        apiConstants.imgUrl +
                        (profile.profileimage || props?.profile?.profileimage)
                      }
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="flx-company-info initials-circle d-flex align-items-center justify-content-center"
                      style={{
                        width: '50px',
                        height: '50px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowmodalfileEdit(true)}
                    >
                      {props.profile.name
                        ? props.profile.name[0].toUpperCase()
                        : ''}
                    </div>
                  </>
                )}
              </div>
            </div>
            <h3 className="flx-company-name-text">
              {props?.profile?.company_name
                ? props.profile.company_name
                : props.profile.name}
            </h3>
            <ul className="flx-company-info-settings-main-links">
              {/* <div
                className="flx-emp-profile-menu-icons d-flex pointer"
                onClick={() => props.setCurrentPage(1)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/User.svg"
                />
                <li
                  className={`flx-company-info-settings-li ${
                    props.currentPage === 1
                      ? 'flx-company-info-settings-li-active'
                      : ''
                  }`}
                >
                  My Profile
                </li>
              </div> */}
              <div
                className="flx-emp-profile-menu-icons d-flex pointer"
                onClick={() => props.setCurrentPage(1)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Login.svg"
                />
                <li
                  className={`flx-company-info-settings-li ${
                    props.currentPage === 1
                      ? 'flx-company-info-settings-li-active'
                      : ''
                  }`}
                >
                  Login & Security
                </li>
              </div>
              <div>
                {(props.isAgency || props.isStaff || props.isAdmin) && (
                  <div
                    className="flx-emp-profile-menu-icons d-flex pointer"
                    onClick={() => props.setCurrentPage(2)}
                  >
                    <img
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/Company.svg"
                    />
                    <li
                      className={`flx-company-info-settings-li ${
                        props.currentPage === 2
                          ? 'flx-company-info-settings-li-active'
                          : ''
                      }`}
                    >
                      Profile Settings
                    </li>
                  </div>
                )}
                {(props.isAgency || props.isEmployer || props.isStaff) && (
                  <div
                    className="flx-emp-profile-menu-icons d-flex pointer"
                    onClick={() => props.setCurrentPage(3)}
                  >
                    <img
                      // fill={(color = 'gray')}
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/notification.svg"
                      style={{
                        height: '18px',
                        stroke: 'gray',
                      }}
                    />
                    <li
                      className={`flx-company-info-settings-li ${
                        props.currentPage === 3
                          ? 'flx-company-info-settings-li-active'
                          : ''
                      }`}
                    >
                      Notification Settings
                    </li>
                  </div>
                )}
              </div>
            </ul>
            {/* Progress bar (Bootstrap progress bar or custom) */}
            {/* <div className="flx-pgs-bar-text">
              {calculateProgressBarWidth().replace('%', '') < 100 ? (
                <p className="flx-complete-profile-text mb-2">
                  Complete Profile
                </p>
              ) : (
                <p className="flx-complete-profile-text">Profile</p>
              )}
              {calculateProgressBarWidth().replace('%', '') < 100 && (
                <p className="flx-complete-profile-subtext">
                  Add your details to complete
                </p>
              )}
            </div> */}
            {/* <div className="flx-progress-bar-and-text-company-info d-flex">
              <div className="progress w-100 flx-progress-company-info">
                <div
                  className="progress-bar flx-progress-bar-comp-info"
                  role="progressbar"
                  style={{ width: calculateProgressBarWidth() }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>

              <span className="flx-progress-bar-text">
                {' '}
                {calculateProgressBarWidth()}
              </span>
            </div> */}
          </div>
          <div className="flx-emp-profile-vertical-hr d-none d-md-block"></div>
        </div>
        <div className="container-fluid flx-emp-profile-mobile-menu-container d-block d-md-none">
          <div className="row flx-emp-profile-mobile-menu-row">
            <div className="col flx-emp-profile-mobile-menu-col">
              <div className="d-flex flex-row flex-nowrap">
                <div
                  className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                    props.currentPage === 0
                      ? 'flx-emp-profile-mob-menu-icon-set_active'
                      : ''
                  }`}
                  onClick={() => props.setCurrentPage(0)}
                >
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/comp-info.svg"
                  />
                  <span
                    className={`flx-company-info-settings-li ${
                      props.currentPage === 0
                        ? 'flx-company-info-settings-li-active'
                        : ''
                    }`}
                  >
                    My Profile
                  </span>
                </div>
                <div
                  className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                    props.currentPage === 1
                      ? 'flx-emp-profile-mob-menu-icon-set_active'
                      : ''
                  }`}
                  onClick={() => props.setCurrentPage(1)}
                >
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/login-sec.svg"
                  />
                  <span
                    className={`flx-company-info-settings-li ${
                      props.currentPage === 1
                        ? 'flx-company-info-settings-li-active'
                        : ''
                    }`}
                  >
                    Login & Security
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <p style={{ color: 'red' }}>
          {error.message
            ? error.message
            : typeof error === 'string'
            ? error
            : 'Please try again after some time'}
        </p>
      )}
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog " style={{ width: '477px' }}>
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  {/* <FileUpload
                    filename={'Logo'}
                    _id={props?.id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={response => {
                      getuploadresponse(response);
                    }}
                  ></FileUpload> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showmodalfileEdit && (
        <ProfileUpload
          setShowmodalfileEdit={setShowmodalfileEdit}
          profile={props.profile}
          getuploadresponse={getuploadresponse}
        />
      )}
    </div>
  );
};
export default EmployerProfileMenu;
