import React, { useEffect, useState } from 'react';
import { LoadingIcon, Pagination } from '../../_components';
import { benefitsService } from '../../_services/benefitsmaster.service';
import AddJobRoleModal from './AddJobRoleModal';

const OtherBenefits = () => {
  const [showModal, setShowModal] = useState(false);
  const [benefitsData, setBenefitsData] = useState([]);
  const [error, setError] = useState(null);
  const [activateQuery, setActivateQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [benefitRows, setBenefitRows] = useState([]);
  const [benefitData, setBenefitData] = useState({ rows: [] });
  const [successMessage, setSuccessMessage] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  useEffect(() => {
    getAllBenefits();
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'searchkey') {
      activateQuery.searchkey = value;
      setActivateQuery(activateQuery);
      getAllBenefits();
    }
  };

  const toggleSwitch = (item, status) => {
    activateQuery.status = status;
    activateQuery.id = item.id;
    setActivateQuery(activateQuery);
    setIsLoading(true);
    benefitsService.changeStatusBenefits(activateQuery).then(
      () => {
        getAllBenefits();
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setError('Error updating the benefit status. Please try again.');
      },
    );
  };

  const getAllBenefits = (pageNo, queryTemp) => {
    const queryNew = queryTemp ? queryTemp : query;
    // queryNew.pageVo.pageNo = pageNo;
    queryNew.pageNo = pageNo || 1;
    queryNew.employer_id = employerId;
    if (activateQuery.searchkey) {
      queryNew.searchkey = activateQuery.searchkey;
    }
    setQuery(queryNew);
    setIsLoading(true);
    benefitsService
      .benefitsList(queryNew)
      .then(res => {
        setIsLoading(false);
        setListAndPagination(res?.data);
        setBenefitsData(res?.data?.rows || []);
      })
      .catch(() => {
        setIsLoading(false);
        setError('Error fetching benefits list. Please try again.');
      });
  };

  const setListAndPagination = benefitData => {
    let benefitlist = [];
    let uniqueJobRoles = new Set();
    for (
      let j = 0;
      benefitData && benefitData.rows && j < benefitData.rows.length;
      j = j + 1
    ) {
      const uniqueKey = `${benefitData.rows[j].jobTitle}-${benefitData.rows[j].name}`;
      if (!uniqueJobRoles.has(uniqueKey)) {
        uniqueJobRoles.add(uniqueKey);

        benefitlist.push(
          <tr key={j}>
            <td data-title="User Name" className="td-flx-user-settings-table">
              <div className="d-flex">{benefitData.rows[j].jobTitle}</div>
            </td>

            <td data-title="Unit Name">
              <div className="d-flex">
                <input
                  type="checkbox"
                  className="flx-ag-settings-other-benefits-sub-text"
                  checked={benefitData.rows[j].name == 'PAID_BREAK'}
                />
                Yes
              </div>
            </td>

            <td data-title="Location">
              <div className="d-md-flex flx-other-benefits-ag-settings">
                <label className="flx-ag-settings-other-benefits-sub-text pick-up">
                  <input
                    type="checkbox"
                    aria-label="Pick up & Drop off"
                    className="flx-ag-settings-other-benefits-sub-text"
                    checked={benefitData.rows[j].name == 'PICKUP_DROP'}
                  />{' '}
                  Pick up &amp; Drop off
                </label>
                <div className="d-flex flex-column align-items-start">
                  <label className="flx-ag-settings-other-benefits-sub-text mb-2">
                    <input
                      type="checkbox"
                      aria-label="Travel Reimbursement"
                      className="flx-ag-settings-other-benefits-sub-text"
                      checked={benefitData.rows[j].name == 'REIMBURSEMENT'}
                    />
                    Travel Reimbursement
                  </label>
                  {benefitData.rows[j].name == 'REIMBURSEMENT' && (
                    <div className="d-md-flex  align-items-start">
                      <span className="flx-ag-settings-other-benefits-pay-per-mile-text mb-2 ml-0 mt-0">
                        How much do you pay per mile
                      </span>
                      <div className="input-group align-self-center">
                        <div className="input-group-prepend">
                          <span className="input-group-text currency-sign-other-benefits">
                            £
                          </span>
                        </div>
                        <input
                          type="text"
                          className="flx-pay-per-mile-text-box"
                          value={benefitData.rows[j].price_per_mile}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </td>

            <td
              onClick={() =>
                toggleSwitch(
                  {
                    id: benefitData.rows[j]._id,
                  },
                  benefitData.rows[j].status == -1 ? 1 : -1,
                )
              }
            >
              <div
                className={`switchUser ${
                  benefitData.rows[j].status == -1
                    ? 'disable pointer'
                    : 'enable pointer'
                }`}
              >
                <span className="statusText">
                  {benefitData.rows[j].status == -1 ? 'Enable' : 'Disable'}
                </span>
              </div>
            </td>
          </tr>,
        );
      }
    }
    setBenefitRows(benefitlist);
    setBenefitData(benefitData);
  };

  const handleSuccess = () => {
    setSuccessMessage(true);
    setShowModal(false);
    setTimeout(() => {
      setSuccessMessage(false);
      getAllBenefits();
    }, 5000);
  };

  return (
    <div>
      <div className="flx-ag-sett-other-benefits-header-container flx-ag-sett-other-benefits-header-containerr1 d-flex mt-4">
        {/* <div className="input-group flx-search-box-ag-shift flx-search-box-other-benefits-search-box">
          <span
            className="input-group-text search-icon-settings-area"
            style={{ height: '40px' }}
          >
            <i className="fa fa-search flx-settings-search-icon"></i>
          </span>
          <input
            type="text"
            className="form-control flx-search-box flx-ag-shift-search-box"
            placeholder="Search"
            name="searchkey"
            onChange={handleChange}
          />
        </div> */}
        <div className=" col-md-4 search-item pl-0">
          <div className="input-outer-div d-flex align-items-center input-design1 ">
            <input
              className="search-input-div"
              onChange={handleChange}
              type="text"
              name="searchKey"
              aria-label="Search"
              placeholder="Search Here"
            />
            <a className="search_bar">
              <i className="fa fa-search in_bar" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <button
          className="btn btn-create-shift-ag-settings btn-add-job-role-btn"
          onClick={() => setShowModal(true)}
        >
          {/* <i className="flx-plus-icon-create-user fa fa-plus"></i> */}
          <span
            style={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: 1.25,
            }}
          >
            Add Job Role
          </span>
        </button>
      </div>
      {/* {benefitData && benefitData.length > 0 && (
        <div className="pagination-top other-benefits-pagination mt-0">
          <PaginationNew
            pageOnClick={data => {
              getAllBenefits(data);
            }}
            paginationData={benefitData}
            itemsPerPage={10}
          />
        </div>
      )} */}

      {benefitsData.length > 0 && (
        <div className="flx-table-sett-user-responsive flx-table-border">
          <table
            className="table flx-custom-table"
            style={{ borderRadius: '10px' }}
          >
            <thead>
              <tr className="fw-bolder fs-1 flx-table-header-clr">
                <th className="fw-bold table-header-ht">Job Role</th>
                <th>Paid Break</th>
                <th>Other Benefits</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{benefitRows}</tbody>
          </table>
        </div>
      )}
      {benefitData &&
        benefitData.rows &&
        benefitData.rows.length === 0 &&
        !isLoading && (
          <p className="margin-top-20 text-center"> No result found </p>
        )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <LoadingIcon loadingText={'Loading...'} />
          </div>
        </div>
      )}
      {error && (
        <p style={{ color: 'red' }}>
          {error.message
            ? error.message
            : typeof error === 'string'
            ? error
            : 'Please try again after some time'}
        </p>
      )}

      {benefitsData.length != 0 && (
        <div className="card-footer">
          <Pagination
            pageOnClick={data => {
              getAllBenefits(data);
            }}
            paginationData={benefitData}
          />
        </div>
      )}

      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          role="dialog"
          style={{ display: 'block' }}
        >
          <AddJobRoleModal
            setShowModal={setShowModal}
            onSuccess={handleSuccess}
          />
        </div>
      )}
      {successMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>Successfully Created</p>
        </div>
      )}
    </div>
  );
};

export default OtherBenefits;
