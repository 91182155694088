import { format, getDate, getDay, getMonth, getYear, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LoadingIcon } from '../_components';
import { messageConstant } from '../_constants';
import {
  categoryService,
  miscService,
  sectionService,
  vacancyshiftService,
} from '../_services';
import { AddNewShift } from './AddNewVacancies';
import { AddShiftTimeForm } from './AddShiftTimePopup';
import { RateCardDetails } from './RateCardDetailsPopup';
import { SelectPrice } from './SelectPricePopup';
import { ShiftSummary } from './ShiftSummary';

const RateCard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [emergency, setEmergency] = useState(false);
  const [ratecardShowModal, setRateCardModal] = useState('');
  const [addshifttimeShowModal, setAddShiftTimeModal] = useState('');
  const [selectPirceShowModal, setSelectPirceShowModal] = useState('');
  const [defaultBenefits, setDefaultBenefits] = useState([]);
  const [activeBenefits, setActiveBenefits] = useState([]);
  const [postAgencyData, setPostAgencyData] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [error, setError] = useState({ common: '' });
  const [numberOfVacancies, setNumberOfVacancies] = useState(1);
  const [shiftTimeList, setShiftTimeList] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedrateCardDetails, setSelectedrateCardDetails] = useState(null);
  const [activeAnotherShiftButton, setActiveAnotherShiftButton] =
    useState(false);
  const [shiftTime, setShiftTime] = useState(null);
  const [rateCardList, setRateCardList] = useState([]);
  const [continueAddingShift, setContinueAddingShift] = useState([]);
  const [selectedShiftTime, setSelectedShiftTime] = useState(null);
  const [isSLAUser, setIsSLAUser] = useState(false);
  const [saveVacancies, setSaveVacancies] = useState([]);
  const [summaryList, setSummaryList] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [showCandidate, setShowCandidate] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [showAddNewShift, setShowAddNewShift] = useState(false);
  const [modifiedShiftData, setModifiedShiftData] = useState([]);
  const [modifiedDateAndPoistionData, setModifiedDateAndPoistionData] =
    useState([]);
  const [showvacancySummary, setShowVacancySummary] = useState(false);
  const [dateAndPosition, setDateAndPosition] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState({
    index: '',
    shiftIndex: '',
  });
  const [showBusinessUnit, setShowBusinessUnit] = useState(false);
  const [activeSaveButton, setActiveSaveButton] = useState(false);
  const [weekdays, setWeekdays] = useState([]);
  const [weekends, setWeekends] = useState([]);
  const [bankHoliday, setBankHoliday] = useState([]);
  const [weekendAndHolidays, setWeekendAndHolidays] = useState(false);

  useEffect(() => {
    if (props.postAgencyData) {
      setPostAgencyData(props.postAgencyData);
    }
  }, [props.postAgencyData]);
  const handleIncrement = e => {
    e.preventDefault();
    setNumberOfVacancies(numberOfVacancies + 1);
  };

  const handleDecrement = e => {
    e.preventDefault();
    if (numberOfVacancies > 1) {
      setNumberOfVacancies(numberOfVacancies - 1);
    }
  };

  useEffect(() => {
    const processDates = async () => {
      const newWeekdays = [];
      const newWeekends = [];
      const newHolidays = [];

      try {
        const holidayList = await vacancyshiftService.bankHolidayListUK();
        const events2024 = holidayList['england-and-wales'].events.filter(
          data => new Date(data.date).getFullYear() > 2023,
        );

        for (const date of props.postAgencyData.dates) {
          const convertedDate = miscService.dateConvertion(date);
          const dayOfWeek = getDay(convertedDate);

          if (dayOfWeek === 6 || dayOfWeek === 0) {
            newWeekends.push(date);
          } else {
            const formattedInputDate = format(convertedDate, 'dd-MM-yyyy');
            const isHoliday = events2024.some(event => {
              const eventDate = parseISO(event.date);
              const formattedEventDate = format(eventDate, 'dd-MM-yyyy');
              return formattedEventDate === formattedInputDate;
            });

            if (isHoliday) {
              newHolidays.push(date);
            } else {
              newWeekdays.push(date);
            }
          }
        }
        if (
          (newWeekdays.length > 0 &&
            (weekends.length > 0 || newHolidays.length > 0)) ||
          (newWeekends.length > 0 && newHolidays.length > 0)
        ) {
          setWeekendAndHolidays(true);
        }
        setBankHoliday([newHolidays]);
        setWeekdays(newWeekdays);
        setWeekends([newWeekends]);
      } catch (error) {
        console.error(error);
      }
    };

    processDates();
  }, [props.postAgencyData.dates]);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));

      if (user.role == 4) {
        if (user && user.employer_id) {
          setEmployerId(user.employer_id);
        }
      }
    }
  }, []);

  const getDefaultBenefits = async () => {
    try {
      if (employerId !== null) {
        const res = await vacancyshiftService.benefitMasterList();
        setDefaultBenefits(res.data.rows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBenefits = async () => {
    try {
      if (employerId !== null) {
        const res = await vacancyshiftService.benefitEmployerList({
          business_unit_id: postAgencyData.selectedBusinessUnit._id,
          job_role_id: postAgencyData.selectedJobTitle._id,
        });
        setActiveBenefits(res.data.rows.map(d => d.benefit_master_id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleBenefits = id => {
    const pd = defaultBenefits.find(a => a.name === 'PICKUP_DROP')?._id;
    const re = defaultBenefits.find(a => a.name === 'REIMBURSEMENT')?._id;

    if (id == pd || id == re) {
      if (activeBenefits.find(a => a === (id === pd ? re : pd))) {
        return;
      }
    }

    const isExists = activeBenefits.includes(id);
    if (isExists) {
      setActiveBenefits(activeBenefits.filter(b => b !== id));
    } else {
      setActiveBenefits([...activeBenefits, id]);
    }
  };

  const getCandidate = async () => {
    try {
      if (employerId !== null) {
        const Candidates = await vacancyshiftService.VacancyShiftCandidateList({
          employer_id: employerId,
          business_unit: postAgencyData.selectedBusinessUnit._id,
        });
        setCandidate(Candidates.data);
      }
    } catch (error) {
      console.error(error, 'Error fetching candidates:');
    }
  };

  const handleShowCandidates = () => {
    if (candidate.length > 0) {
      setShowCandidate(prevShowCandidate => !prevShowCandidate);
      setSelectedCandidates([]);
    }
  };

  const handleSelectedCandidates = id => {
    const isSelected = selectedCandidates.includes(id);
    if (isSelected) {
      setSelectedCandidates(
        selectedCandidates.filter(candidateId => candidateId !== id),
      );
    } else {
      setSelectedCandidates([...selectedCandidates, id]);
    }
  };
  const getShiftTimeList = async () => {
    try {
      if (employerId !== null) {
        const response = await sectionService.sectionList({
          employer_id: employerId,
        });
        if (response.error_code === 0) {
          if (response?.data?.rows?.length > 0) {
            setShiftTimeList(response.data.rows);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRateCardDetails = async dates => {
    try {
      if (shiftTime) {
        setIsLoading(true);
        const rateCardResponse = await categoryService.rateCardList({
          business_unit_id: postAgencyData.selectedBusinessUnit._id,
          job_role_id: postAgencyData.selectedJobTitle._id,
          section_id: shiftTime._id,
          dates,
        });
        if (rateCardResponse.data.data) {
          setRateCardList(rateCardResponse.data.data);
        }
        setIsLoading(false);
        if (rateCardResponse.data?.data[0]?.rate_card_type) {
          setIsSLAUser(false);
        } else {
          setIsSLAUser(true);
          setActiveAnotherShiftButton(true);
          setActiveSaveButton(true);
        }
      }
    } catch (error) {
      console.error(error);
      isLoading(false);
    }
  };
  useEffect(() => {
    emergencyShiftCalculation();
  }, [shiftTime]);

  useEffect(() => {
    getShiftTimeList();
    getDefaultBenefits();
    getBenefits();
    getCandidate();
  }, [employerId, addshifttimeShowModal]);

  const handleSelectshiftName = event => {
    event.preventDefault();

    const selectedShiftResponse = shiftTimeList?.find(
      unit => unit._id == event.target.value,
    );
    setShiftTime(selectedShiftResponse);
    setSelectedShiftTime(event.target.value);
    if (weekendAndHolidays) {
      setActiveSaveButton(true);
      return;
    } else getRateCardDetails(props.postAgencyData.dates);
  };

  const emergencyShiftCalculation = from_time => {
    setEmergency(false);
    let ermergency;
    setError({
      ...error,
      shiftTime: '',
    });
    const currDate = new Date(); // Current date
    const currentDate = currDate.toISOString().slice(0, 10);
    const nextDate = new Date(currDate.getTime() + 24 * 60 * 60 * 1000); // Adding 24 hours (in milliseconds)
    const nextdateFormat = nextDate.toISOString().slice(0, 10);

    const todayDate = props.postAgencyData?.dates.filter(
      date =>
        format(miscService.dateConvertion(date), 'yyyy-MM-dd') === currentDate,
    );
    const findNextDay = props.postAgencyData?.dates.filter(
      date =>
        format(miscService.dateConvertion(date), 'yyyy-MM-dd') <=
        nextdateFormat,
    );
    if (todayDate.length > 0) {
      ermergency = miscService.currentTimeCalculation(from_time);
      if (ermergency) {
        setError({
          ...error,
          shiftTime: messageConstant.SHIFT_TIME_EXP,
          isError: true,
        });
        setIsLoading(false);
        setRateCardList(null);
        setEmergency(false);
      } else {
        setEmergency(true);
      }
    } else if (findNextDay.length > 0) {
      ermergency = miscService.emergencyShift(from_time);
      if (ermergency) {
        setEmergency(true);
      } else {
        setEmergency(false);
      }
    } else {
      setEmergency(false);
    }

    if (weekendAndHolidays) {
      setActiveSaveButton(true);
    } else {
      getRateCardDetails(props.postAgencyData.dates);
    }
  };

  const handleSelectedRateCard = event => {
    // setting the selected card
    setSelectedrateCardDetails(event);
    setActiveSaveButton(true);
  };

  const hasDuplicateShift = (shiftTime, continueAddingShift) => {
    if (shiftTime === null) return false;
    if (!Array.isArray(continueAddingShift)) {
      return false; // Return false if continueAddingShift is not an array
    }
    return continueAddingShift?.some(
      shift => shift.shift_time._id === shiftTime._id,
    );
  };

  const handleAddAnotherShift = (shift_time, vacancies, price) => {
    // Check for duplicates before adding
    if (!hasDuplicateShift(shift_time, continueAddingShift)) {
      setError({
        ...error,
        shiftTime: '',
        isError: false,
      });
      setContinueAddingShift(prevArray => [
        ...prevArray,
        { shift_time, vacancies, price, emergency },
      ]);
      setShiftTimeList(null);
      setShiftTime(null);
      setSelectedShiftTime(null);
      getShiftTimeList();
      setNumberOfVacancies(1);
      setSelectedrateCardDetails(null);
      setRateCardList(null);
      setActiveAnotherShiftButton(false);
      setSelectedrateCardDetails(null);
      setSelectedCard(null);
      setEmergency(false);
    } else {
      // Handle duplicate case
      setError({
        ...error,
        shiftTime: messageConstant.DUPLICATE_SHIFT,
        isError: true,
      });
    }
  };
  // selecting the card onclick
  const handleCardClick = cardType => {
    setSelectedCard(cardType);
    if (shiftTime) {
      setActiveAnotherShiftButton(true);
    }
  };

  const handleDeleteShift = (index, event) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });
    // Implement your delete logic here
    const updatedShifts = [...continueAddingShift];
    updatedShifts.splice(index, 1);
    setContinueAddingShift(updatedShifts);
  };

  const handleEditShift = (index, event) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });

    const updatedShifts = [...continueAddingShift];
    setShiftTime(index.shift_time);
    setSelectedShiftTime(index.shift_time._id);
    setNumberOfVacancies(index.vacancies);

    setContinueAddingShift(updatedShifts);
    handleCardClick(index.price.rate_card_type);
    handleSelectedRateCard(index.price);
    updatedShifts.splice(index, 1);
  };

  const handleEditVacancy = (
    event,
    shiftIndex,
    mainIndex,
    shiftDetails,
    saveData,
  ) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });

    // Create copies of arrays before modifying
    let updatedSaveVacancy = [...saveVacancies];
    let updatedShifts = [...continueAddingShift];

    // Adjusting array based on conditions
    if (updatedSaveVacancy.length > 0) {
      const mainVacancy = updatedSaveVacancy[mainIndex];

      if (mainVacancy) {
        // Remove the selected shiftIndex from the continueAddingShift array in the mainIndex row
        updatedShifts = [...mainVacancy.continueAddingShift];
        setModifiedShiftData(updatedShifts);
        updatedShifts.splice(shiftIndex, 1);
        mainVacancy.continueAddingShift = updatedShifts;

        if (updatedShifts.length === 0) {
          updatedSaveVacancy.splice(mainIndex, 1);
        }
        if (updatedSaveVacancy.length === 0) {
          setSummaryList(false);
        }
        setSaveVacancies(updatedSaveVacancy);
        setContinueAddingShift(updatedShifts);
      }
    } else if (continueAddingShift.length > 1) {
      continueAddingShift.splice(shiftIndex, 1);
      setContinueAddingShift(continueAddingShift);
    } else {
      saveVacancies.splice(mainIndex, 1);
      setSummaryList(false);
      setSaveVacancies(saveVacancies);
    }
    setModifiedDateAndPoistionData(saveData);
    setModifiedShiftData(shiftDetails);
    setShowAddNewShift(true);
  };
  const handleDeleteVacancy = (event, shiftIndex, mainIndex) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });

    // Create copies of arrays before modifying
    let updatedSaveVacancy = [...saveVacancies];
    let updatedShifts = [...continueAddingShift];

    // Adjusting array based on conditions
    if (updatedSaveVacancy.length > 0) {
      const mainVacancy = updatedSaveVacancy[mainIndex];

      if (mainVacancy) {
        // Remove the selected shiftIndex from the continueAddingShift array in the mainIndex row
        updatedShifts = [...mainVacancy.continueAddingShift];
        setModifiedShiftData(updatedShifts);
        updatedShifts.splice(shiftIndex, 1);
        mainVacancy.continueAddingShift = updatedShifts;

        if (updatedShifts.length === 0) {
          updatedSaveVacancy.splice(mainIndex, 1);
        }
        if (updatedSaveVacancy.length === 0) {
          setSummaryList(false);
        }
        setSaveVacancies(updatedSaveVacancy);
        setContinueAddingShift(updatedShifts);
      }
    } else if (continueAddingShift.length > 1) {
      continueAddingShift.splice(shiftIndex, 1);
      setContinueAddingShift(continueAddingShift);
    } else {
      saveVacancies.splice(mainIndex, 1);
      setSummaryList(false);
      setSaveVacancies(saveVacancies);
    }
  };

  const handleUpdatePrice = data => {
    // Create copies of arrays before modifying
    let updatedSaveVacancy = [...saveVacancies];
    // updating the price as per their selected price
    updatedSaveVacancy[updatedPrice.index].continueAddingShift[
      updatedPrice.shiftIndex
    ].price = data;
    // updatedSaveVacancy[updatedPrice.index].showPrice = true;
    setSaveVacancies(updatedSaveVacancy);
  };

  const handleUpdatedPrice = (e, index, shiftIndex, date) => {
    e.preventDefault();
    getRateCardDetails(date);
    setSelectPirceShowModal('selectPrice');
    setUpdatedPrice({
      index: index,
      shiftIndex: shiftIndex,
    });
  };
  const handleCopyVacancy = (shiftIndex, dateIndex, event) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });
    setModifiedShiftData(shiftIndex);
    setModifiedDateAndPoistionData(dateIndex);
    setShowAddNewShift(true);
  };

  const handleSaveVacanciesWithMultipleShift = (
    date,
    position,
    selectedBusinessUnit,
    continueAddingShift,
    activeBenefits,
    weekendAndHoliday,
  ) => {
    setSaveVacancies(prevSaveVacancies => [
      ...prevSaveVacancies,
      ...date.map(d => ({
        date: d,
        position,
        selectedBusinessUnit,
        continueAddingShift,
        selectedCandidates,
        activeBenefits,
        weekendAndHoliday,
      })),
    ]);

    setSummaryList(true);
  };

  const handleAddNewVacancy = data => {
    const {
      date,
      position,
      selectedBusinessUnit,
      continueAddingShift,
      activeBenefits,
      weekendAndHoliday,
    } = data;
    handleSaveVacanciesWithMultipleShift(
      date,
      position,
      selectedBusinessUnit,
      continueAddingShift,
      activeBenefits,
      weekendAndHoliday,
    );
  };
  const hnadleAddNewShift = () => {
    setShowAddNewShift(true);
    setModifiedShiftData(null);
    setModifiedDateAndPoistionData(null);
    setDateAndPosition(null);
  };

  const handleSaveVacancies = e => {
    e.preventDefault();

    if (!hasDuplicateShift(shiftTime, continueAddingShift)) {
      setError({
        ...error,
        shiftTime: '',
        isError: false,
      });
      if (bankHoliday.length > 0) {
        let tempWeekdays = [
          {
            shift_time: shiftTime,
            vacancies: numberOfVacancies,
            price: selectedrateCardDetails,
            emergency,
          },
        ];
        handleSaveVacanciesWithMultipleShift(
          bankHoliday,
          postAgencyData.selectedJobTitle,
          postAgencyData.selectedBusinessUnit,
          continueAddingShift.length > 0 || selectedShiftTime
            ? [...continueAddingShift, ...tempWeekdays]
            : continueAddingShift,
          activeBenefits,
          'holiday',
        );

        continueAddingShift.length > 0
          ? setContinueAddingShift(prevArray => [...prevArray, ...tempWeekdays])
          : setContinueAddingShift(tempWeekdays);
      }
      if (weekdays.length > 0) {
        let tempWeekdays = [
          {
            shift_time: shiftTime,
            vacancies: numberOfVacancies,
            price: selectedrateCardDetails,
            emergency,
          },
        ];
        handleSaveVacanciesWithMultipleShift(
          weekdays,
          postAgencyData.selectedJobTitle,
          postAgencyData.selectedBusinessUnit,
          continueAddingShift.length > 0 || selectedShiftTime
            ? [...continueAddingShift, ...tempWeekdays]
            : continueAddingShift,
          activeBenefits,
          'weekday',
        );

        continueAddingShift.length > 0
          ? setContinueAddingShift(prevArray => [...prevArray, ...tempWeekdays])
          : setContinueAddingShift(tempWeekdays);
      }
      if (weekends.length > 0) {
        let tempWeekends = [
          {
            shift_time: shiftTime,
            vacancies: numberOfVacancies,
            price: selectedrateCardDetails,
            emergency,
          },
        ];
        handleSaveVacanciesWithMultipleShift(
          weekends,
          postAgencyData.selectedJobTitle,
          postAgencyData.selectedBusinessUnit,
          continueAddingShift.length > 0 || selectedShiftTime
            ? [...continueAddingShift, ...tempWeekends]
            : continueAddingShift,
          activeBenefits,
          'weekends',
        );

        continueAddingShift.length > 0
          ? setContinueAddingShift(prevArray => [...prevArray, ...tempWeekends])
          : setContinueAddingShift(tempWeekends);
      }
    } else {
      setError({
        ...error,
        shiftTime: messageConstant.DUPLICATE_SHIFT,
        isError: true,
      });
    }
    if (error.isError) return;
  };

  const handleShiftSummary = e => {
    e.preventDefault();
    setShowVacancySummary(true);
  };

  const handleCloseShiftSummary = () => {
    setShowVacancySummary(false);
  };

  const handleEditMain = e => {
    e.preventDefault();
    setDateAndPosition(postAgencyData);
    setModifiedShiftData(null);
    setModifiedDateAndPoistionData(null);
    setShowAddNewShift(true);
  };

  const handleDeleteMain = e => {
    e.preventDefault();
    setDateAndPosition(null);
    setModifiedShiftData(null);
    setModifiedDateAndPoistionData(null);
    setShowAddNewShift(true);
  };

  const handleBack = e => {
    e.preventDefault();
    if (summaryList && !showAddNewShift) {
      let lastSaveVacanciesDataIndex = saveVacancies.length - 1;
      setModifiedDateAndPoistionData(saveVacancies[lastSaveVacanciesDataIndex]);
      setShowAddNewShift(true);
    } else props.handleHideRateCard();
  };

  const handleSelectBusinessName = event => {
    event.preventDefault();
    setError({
      ...error,
      businessUnit: '',
    });
    const selectUnit = props.businessunitList.find(
      unit => unit._id == event.target.value,
    );
    setPostAgencyData(pre => ({ ...pre, selectedBusinessUnit: selectUnit }));
    setShowBusinessUnit(false);
  };

  const handleEditBusinessUnit = e => {
    e.preventDefault();
    setPostAgencyData(prev => ({ ...prev, selectedBusinessUnit: null }));
    props.handleBusinessUnit();
    setShowBusinessUnit(true);
  };

  const handleDate = (e, index) => {
    e.preventDefault();
    let updatedDate = [...postAgencyData.dates];
    updatedDate.splice(index, 1);
    setPostAgencyData(pre => ({ ...pre, dates: updatedDate }));
    if (updatedDate.length === 0) {
      hnadleAddNewShift();
    }
  };

  return (
    <>
      {!showvacancySummary && (
        <div className=" container flx-job-post-main-container">
          <h2 className="text-center">Post Vacancies</h2>
          <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
            <p className="text-center flx-recs-required mb-22">
              Add Job Details
            </p>
            <div className="container flx-job-post-job-details-section">
              <form>
                <div className="form-group">
                  <label
                    htmlFor="location"
                    className="flx-job-posts-labels flx-recs-required"
                  >
                    Business unit
                  </label>
                  {!postAgencyData?.selectedBusinessUnit && (
                    <button
                      type="button"
                      className="flx-add-location-popup-form"
                      // onClick={() => setShowModal('location')}
                    >
                      <i className="fas fa-plus flx-icon-plus"></i> Add New
                      Business Unit
                    </button>
                  )}
                  {(props.businessunitList == null ||
                    props.businessunitList?.length <= 1) && (
                    <input
                      type="text"
                      className="form-control mt-8"
                      id="flx-location"
                      value={
                        postAgencyData.selectedBusinessUnit
                          ? postAgencyData.selectedBusinessUnit.name
                          : ''
                      }
                      placeholder={
                        props.businessunitList?.length > 0
                          ? ''
                          : `You haven't added any business unit`
                      }
                    />
                  )}

                  {showBusinessUnit && props.businessunitList?.length > 1 && (
                    <select
                      className="form-control select-custom-style"
                      id="flx-location"
                      // onClick={handleDropdown}
                      onChange={handleSelectBusinessName}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        style={{
                          display: postAgencyData?.selectedBusinessUnit
                            ? 'block'
                            : 'none',
                        }}
                      >
                        Select your business
                      </option>
                      {props.businessunitList?.map((list, key) => (
                        <option key={key} value={list._id}>
                          {list.name}
                        </option>
                      ))}
                    </select>
                  )}

                  {!showBusinessUnit && (
                    <div className="list-group flx-card-business-unit-info">
                      <div
                        className="list-group-item"
                        style={{
                          borderRadius: '11px',
                          border: '1px solid #ECECEC',
                          boxShadow: '0px 0px 21px 0px rgba(0, 0, 0.7, 0)',
                        }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-1 flx-added-location-info">
                            {postAgencyData?.selectedBusinessUnit?.name || ''}
                          </h5>
                        </div>
                        <div className="d-md-flex justify-content-between align-items-center">
                          <p className="mb-0 flx-business-location-address">
                            {postAgencyData?.selectedBusinessUnit?.address ||
                              ''}
                          </p>
                          <div>
                            <button
                              type="button"
                              className="btn flx-edit-link btn-sm mr-2"
                              onClick={e => handleEditBusinessUnit(e)}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* rate card form*/}
                {summaryList && (
                  <table className="table custom-table">
                    <thead className="table flx-shift-final-header">
                      <tr className="border">
                        <th className="border">Date</th>
                        <th className="border">Position</th>
                        <tr>
                          <th
                            className="border-0"
                            style={{ minWidth: '10%', width: '11.7%' }}
                          >
                            Shift time
                          </th>
                          <th
                            className="border-0"
                            style={{ minWidth: '8%', width: '8%' }}
                          >
                            Vacancies
                          </th>
                          <th
                            className="border"
                            style={{ minWidth: '10%', width: '9.3%' }}
                          >
                            Price
                          </th>
                          <th
                            className="border"
                            style={{ minWidth: '10%', width: '14%' }}
                          ></th>
                        </tr>
                      </tr>
                    </thead>
                    <tbody className="custom-table">
                      {saveVacancies
                        ?.slice()
                        .sort((a, b) => new Date(a) - new Date(b))
                        .map((saveData, index) => (
                          <>
                            <tr key={index}>
                              <td className="flx-shift-final-date-container custom-table border">
                                <div className="text-center px-1">
                                  <div>
                                    {saveData.weekendAndHoliday ===
                                      'weekends' && (
                                      <span
                                        className="px-1"
                                        style={{
                                          backgroundColor: '#F19901',
                                          color: '#fff',
                                          borderRadius: '9px',
                                        }}
                                      >
                                        Weekend
                                      </span>
                                    )}
                                    {saveData.weekendAndHoliday ===
                                      'holiday' && (
                                      <span
                                        className="px-2"
                                        style={{
                                          backgroundColor: '#DD3A3A',
                                          color: '#fff',
                                          borderRadius: '9px',
                                        }}
                                      >
                                        Holiday
                                      </span>
                                    )}
                                  </div>
                                  <div className="flx-shift-final-date">
                                    {getDate(
                                      miscService.dateConvertion(saveData.date),
                                    )}
                                  </div>
                                  <div className="flx-shift-final-month m-1">
                                    {miscService.getMonthName(
                                      getMonth(
                                        miscService.dateConvertion(
                                          saveData.date,
                                        ),
                                      ),
                                    )}
                                  </div>
                                  <div className="flx-shift-final-year">
                                    {getYear(
                                      miscService.dateConvertion(saveData.date),
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flx-shift-final-job-title ">
                                  {saveData.position?.name}
                                </div>
                              </td>
                              {/* <tr> */}
                              {saveData?.continueAddingShift?.map(
                                (shiftDetails, ShiftIndex) => (
                                  <td key={ShiftIndex}>
                                    <td
                                      style={{
                                        minWidth: '12.5%',
                                        width: '25%',
                                      }}
                                      className="border"
                                    >
                                      <div className="flx-shift-final-job-timings">
                                        {shiftDetails.shift_time?.from_time}-
                                        {shiftDetails.shift_time?.to_time}
                                      </div>
                                      <div className="flx-shift-final-job-shiftname">
                                        {shiftDetails.shift_time?.section_type}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        minWidth: '12.5%',
                                        width: '25%',
                                      }}
                                      className="border"
                                    >
                                      <div className="flx-shift-final-vacancy-card">
                                        <div className="flx-shift-final-job-shift-vacancy-number text-center">
                                          {shiftDetails.vacancies}
                                        </div>
                                        <div>
                                          {emergency && (
                                            <img
                                              className="flx-immediate-cover-icon"
                                              src="/assets/img/flx-immediate-cover.svg"
                                            />
                                          )}
                                          {saveData.activeBenefits?.find(
                                            _d => _d === 8,
                                          ) && (
                                            <img
                                              className="flx-paid-break-icon"
                                              src="/assets/img/flx-paid-break.svg"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      style={{ minWidth: '10%', width: '19%' }}
                                      className="flx-shift-final-price-cell text-center border"
                                    >
                                      <div className="flx-shift-rate-and-icon-container flex-colomn">
                                        {shiftDetails?.price?.price && (
                                          <div>
                                            <div className="flx-shift-rate">
                                              £{shiftDetails.price.price}
                                            </div>
                                            <div>
                                              <i className="fa fa-eye flx-payment-view-icon"></i>
                                            </div>
                                          </div>
                                        )}
                                        <div>
                                          <button
                                            onClick={e => {
                                              e.preventDefault();
                                              handleUpdatedPrice(
                                                e,
                                                index,
                                                ShiftIndex,
                                                saveData.date,
                                              );
                                            }}
                                            style={{
                                              color: '#757aee',
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {shiftDetails?.price?.price
                                              ? 'Change'
                                              : 'Select Price'}
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className="border"
                                      style={{ minWidth: '15%', width: '30%' }}
                                    >
                                      <div className="flx-shift-final-actions d-flex flex-column">
                                        <button
                                          className="flx-shift-final-actions-copy"
                                          onClick={event =>
                                            handleCopyVacancy(
                                              shiftDetails,
                                              saveData,
                                              event,
                                            )
                                          }
                                        >
                                          <img
                                            className="flx-shift-final-actions-copy-icon"
                                            src="/assets/img/flx-shift-clone.svg"
                                          />
                                          Copy Shift
                                        </button>
                                        <button
                                          className="flx-shift-final-actions-edit"
                                          onClick={event =>
                                            handleEditVacancy(
                                              event,
                                              ShiftIndex,
                                              index,
                                              shiftDetails,
                                              saveData,
                                            )
                                          }
                                        >
                                          <img
                                            className="flx-shift-final-actions-edit-icon"
                                            src="/assets/img/flx-shift-edit.svg"
                                          />
                                          Edit
                                        </button>
                                        <button
                                          className="flx-shift-final-actions-delete float-right"
                                          onClick={event =>
                                            handleDeleteVacancy(
                                              event,
                                              ShiftIndex,
                                              index,
                                            )
                                          }
                                        >
                                          <br />
                                          <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                                          Delete
                                        </button>
                                      </div>
                                      {/* responsive screen icons */}
                                      {/* <div className="dropdown d-md-none">
                                        <button
                                          className="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fa fa-ellipsis-v"></i>
                                        </button>
                                        <ul
                                          className="dropdown-menu flx-edit-delete-icons"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Link 1
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Link 2
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Link 3
                                            </a>
                                          </li>
                                        </ul>
                                      </div> */}
                                    </td>
                                  </td>
                                ),
                              )}
                              {/* </tr> */}
                            </tr>
                          </>
                        ))}
                      {/* <div className="dropdown d-md-none">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <ul
                          className="dropdown-menu flx-edit-delete-icons"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              Link 1
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Link 2
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Link 3
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </tbody>
                  </table>
                )}
                {!showAddNewShift && !summaryList && (
                  <div className="flx-ratecard-container-shift">
                    <div className="d-flex justify-content-between align-items-center rate-card-title">
                      <div
                        className="flx-date-title-rate-card justify-content-between "
                        style={{
                          maxWidth: '90%',
                        }}
                      >
                        <div className="text-center d-flex flex-wrap">
                          {postAgencyData?.dates?.map((date, index) => (
                            <span
                              className="date-btn mr-4"
                              style={{
                                marginBottom: '2px',
                                maxWidth: '150px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                              key={index}
                            >
                              <div className="mr-1">
                                {format(
                                  miscService.dateConvertion(date),
                                  'dd-MMM-yyyy',
                                )}{' '}
                              </div>
                              <button>
                                <i
                                  className="m-auto fa fa-times flx-other-benefits-icons"
                                  style={{
                                    color: 'grey',
                                    fontWeight: 'bolder',
                                  }}
                                  onClick={e => handleDate(e, index)}
                                ></i>
                              </button>
                            </span>
                          ))}
                        </div>
                        {/* if another shift is added on the same day, different role, follow the below view ENDS*/}
                        {/* if holiday shift is selected, these text should appear */}
                        <div className="flx-holiday-shift-date-text-container">
                          {/* <i className="flx-holiday-date-icon fa fa-plane"></i> */}
                          {/* <span className="flx-holiday-shift-title">Holiday</span> */}
                        </div>
                        {/* if holiday shift is selected, these text should appear div ends */}
                        <p className="flx-card-text-job">
                          {postAgencyData?.selectedJobTitle?.name || ''}
                        </p>
                      </div>

                      <div className="mb-5">
                        <button onClick={e => handleEditMain(e)} className="">
                          <img
                            className="flx-rate-card-title-date-edit mr-2 mb-4"
                            src="/assets/img/job-post-edit.svg"
                          />
                        </button>
                        <button onClick={e => handleDeleteMain(e)}>
                          <img
                            className="flx-rate-card-title-date-delete mb-4"
                            src="/assets/img/job-post-delete.svg"
                          />
                        </button>
                      </div>
                    </div>
                    <hr />
                    {/* add shift timings */}
                    <div className="container my-3">
                      {/*If another shift button is clicked, the current shift will be saved and appear on the top of the form */}
                      {continueAddingShift?.map((shift, index) => (
                        <table
                          key={index}
                          className="table flx-current-shift-saved-table"
                        >
                          <thead>
                            <tr>
                              <th scope="col">Shift time</th>
                              <th scope="col">Vacancies</th>
                              <th scope="col">Price</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody key={index}>
                            <tr>
                              <td>
                                {shift.shift_time.name}
                                <br />
                                {shift.shift_time.from_time}-
                                {shift.shift_time.to_time}
                              </td>
                              <td className="">{shift.vacancies}</td>
                              <td>£{shift.price.price}</td>
                              <td>
                                <div>
                                  <button
                                    onClick={event =>
                                      handleEditShift(shift, event)
                                    }
                                  >
                                    <img
                                      className="flx-shift-date-edit"
                                      src="/assets/img/job-post-edit.svg"
                                    />
                                  </button>
                                  <button
                                    onClick={event =>
                                      handleDeleteShift(index, event)
                                    }
                                  >
                                    <img
                                      className="flx-shift-date-delete"
                                      src="/assets/img/job-post-delete.svg"
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                      <span className="color-red">{error.shiftTime}</span>
                      {/*If another shift button is clicked, the current shift will be saved block ends */}
                      <div className="row flx-rate-card-query-main-container">
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="shiftTime"
                            className="form-label flx-job-posts-labels flx-recs-required "
                          >
                            Shift time
                          </label>
                          <button
                            type="button"
                            className="flx-rate-card-add-shift"
                          >
                            <i
                              className="fas fa-plus flx-icon-plus"
                              onClick={e => {
                                e.preventDefault();
                                setAddShiftTimeModal('addshifttime');
                              }}
                            ></i>{' '}
                            <span className="flx-add-new-shift-text">
                              Create new shift
                            </span>
                          </button>
                          {(shiftTimeList == null ||
                            shiftTimeList?.length <= 1) && (
                            <input
                              type="text"
                              className="form-control"
                              id="flx-location"
                              value={shiftTimeList ? shiftTimeList.name : ''}
                              placeholder={
                                shiftTimeList?.length > 0
                                  ? ''
                                  : "You haven't added any shift list"
                              }
                            />
                          )}
                          {shiftTimeList?.length > 1 && (
                            <select
                              className="form-control"
                              id="flx-location"
                              value={selectedShiftTime}
                              onChange={handleSelectshiftName}
                            >
                              <option value="" selected disabled>
                                Select shift
                              </option>
                              {shiftTimeList.map((list, key) => (
                                <option value={list._id} key={key}>
                                  {list.name} - {list.from_time} to{' '}
                                  {list.to_time}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div className="col-md-6 mb-3 d-flex">
                          <div className="flx-num-vacancies-container">
                            <label
                              htmlFor="noOfVacancies"
                              className="form-label flx-job-posts-labels flx-recs-required"
                            >
                              No of vacancies
                            </label>

                            <div className="flx-num-vacancies-section">
                              <button
                                className="flx-button-grp-addition-minus"
                                onClick={handleDecrement}
                                disabled={numberOfVacancies === 1}
                              >
                                <i className="fa fa-minus flx-icon-rate-card-minus"></i>
                              </button>

                              <input
                                type="number"
                                className="text-center flx-no-vacancy-field"
                                value={numberOfVacancies}
                                aria-label="Number of vacancies"
                                id="noOfVacancies"
                              />
                              <button
                                className="flx-button-grp-addition-plus"
                                onClick={handleIncrement}
                                disabled={numberOfVacancies === 10}
                              >
                                <i className="fa fa-plus flx-icon-rate-card-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="flx-rate-card-add-another-shift">
                            <button
                              type="button"
                              className="flx-add-shift"
                              disabled={!activeAnotherShiftButton}
                              onClick={() => {
                                handleAddAnotherShift(
                                  shiftTime,
                                  numberOfVacancies,
                                  selectedrateCardDetails,
                                );
                              }}
                            >
                              <i
                                className={`fas fa-plus flx-icon-plus-another-shift ${
                                  activeAnotherShiftButton
                                    ? ''
                                    : 'flx-icon-plus-another-shift-diabled'
                                }`}
                              ></i>{' '}
                              <br />
                              <span className="flx-add-another-shift-text ">
                                Add another shift
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* rate cards*/}
                    {/* {shiftTime && (
                      <div className=" container  mt-5 flx-recs-required">
                        Select your Rate Card{' '}
                      </div>
                    )} */}
                    <div className="container flx-agency-job-post-regular-ratecards">
                      {isLoading && <LoadingIcon loadingText={'Loading...'} />}
                      {!weekendAndHolidays && (
                        <div className=" flx-recs-required">
                          Select your Rate Card{' '}
                        </div>
                      )}
                      {!weekendAndHolidays
                        ? !isSLAUser && (
                            <div className="flx-rate-card-container mt-2 text-center">
                              {rateCardList?.map((rateCard, index) => (
                                <div
                                  key={index}
                                  className={`flx-rate-card ${
                                    selectedCard === rateCard.rate_card_type
                                      ? 'flx-card-container-active'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    handleCardClick(rateCard.rate_card_type);
                                    handleSelectedRateCard(rateCard);
                                  }}
                                >
                                  {rateCard.most_used === 1 && (
                                    <span
                                      className={`flx-rate-card-most-used-label ${
                                        selectedCard === rateCard.rate_card_type
                                          ? 'bg-white text-primary'
                                          : ''
                                      }`}
                                    >
                                      {rateCard.most_used ? 'Most Used' : ''}
                                    </span>
                                  )}
                                  <h6 className="flx-rate-card-title">
                                    {rateCard.rate_card_type}
                                  </h6>
                                  <p className="flx-card-text">
                                    <div
                                      style={{
                                        width: '20px',
                                        fontSize: '8px',
                                        marginBottom: '-7%',
                                        marginLeft: '60%',
                                        color: 'rgba(221, 58, 58, 0.43)',
                                      }}
                                    >
                                      #
                                    </div>
                                    {`£${rateCard.price} `}
                                    {rateCard.originalPrice && (
                                      <span className="flx-original-price">
                                        <s>{`£${rateCard.originalPrice}`}</s>
                                      </span>
                                    )}
                                    <p className="flx-quantity-ratecard">
                                      per hour
                                    </p>
                                  </p>
                                  <hr />
                                  <p className="flx-admin-charge-title">
                                    Admin charge
                                  </p>
                                  <p className="flx-admin-charge">
                                    {rateCard.admin_fee_is_percentage
                                      ? `${rateCard.admin_fee}%`
                                      : `${rateCard.admin_fee}p`}
                                    <span className="flx-per-hour-text">
                                      / hour
                                    </span>
                                  </p>
                                  <button
                                    className="btn btn-details btn-sm ratecard-buttons m-auto"
                                    onClick={e => {
                                      e.preventDefault();
                                      setRateCardModal('ratecarddetails');
                                    }}
                                  >
                                    Details
                                  </button>
                                </div>
                              ))}
                            </div>
                          )
                        : null}
                    </div>
                    {rateCardList.length > 0 && (
                      <div
                        className="container"
                        style={{
                          color: 'rgba(221, 58, 58, 0.43)',
                        }}
                      >
                        #Additional charges applicable for the selected shift.
                        Click Details to view more.
                      </div>
                    )}
                    {/*rate card holiday shift start*/}
                    {!weekendAndHolidays
                      ? isSLAUser && (
                          <div className="flx-agency-shift-holiday-rate-card mt-5">
                            {/* <h6 className="flx-holiday-rate-card-title">
                    Your Holiday Price
                  </h6> */}
                            <div className="flx-holiday-rate-card-body d-flex">
                              <p className="flx-card-text">
                                £{rateCardList?.price}{' '}
                                {rateCardList?.originalPrice && (
                                  <span className="flx-original-price">
                                    <s>£{rateCardList.originalPrice}</s>
                                  </span>
                                )}
                                <p className="flx-quantity-ratecard">
                                  per hour
                                </p>
                              </p>
                              <div className="p-3">
                                <button
                                  className="btn btn-details btn-sm ratecard-buttons"
                                  onClick={e => {
                                    e.preventDefault();
                                    setRateCardModal('ratecarddetails');
                                  }}
                                >
                                  Details
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      : null}
                    {/*rate card holiday shift end*/}

                    {/* benefits */}
                    <div className="form-group flx-other-benefits-section ">
                      <label className="flx-job-posts-labels">
                        Other Benefits
                      </label>
                      <ul className="flx-job-post-other-benefits">
                        {defaultBenefits?.map((benefit, index) => (
                          <li
                            className={`flx-job-post-other-benefits-li ${
                              activeBenefits?.find(b => b === benefit._id)
                                ? 'flx-job-post-other-benefits-li-active'
                                : ''
                            }`}
                            key={index}
                            onClick={() => toggleBenefits(benefit._id)}
                          >
                            {benefit._id === 8 && (
                              <img
                                src="/assets/img/flx-paid-break-other-benefits.svg"
                                className="flx-other-benefits-icons"
                                alt="Paid Break"
                              />
                            )}
                            {benefit._id === 9 && (
                              <img
                                src="/assets/img/flx-travel-reimb.svg"
                                className="flx-other-benefits-icons"
                                alt="Pick-up & Drop-off"
                              />
                            )}
                            {benefit._id === 10 && (
                              <img
                                src="/assets/img/flx-other-benefits-cashback.svg"
                                className="flx-other-benefits-icons"
                                alt="travel teimbursement"
                              />
                            )}
                            {benefit.name}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="flx-prev-candidates">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        disabled={!candidate || candidate.length === 0}
                        onChange={handleShowCandidates}
                      />

                      <label
                        className="form-check-label prefer-prevoius-candidate"
                        htmlFor="flexCheckDefault"
                      >
                        Give priority to previously worked candidates
                      </label>
                    </div>
                    {/* prev worked candidates card */}

                    {showCandidate && (
                      <>
                        <h5 className="flx-prev-worked-candidate-selection-text mt-2">
                          Select from candidate who successfully completed the
                          previous shift
                        </h5>
                        <div className="d-flex flx-prev-candidate-container-main">
                          {candidate.slice(0, 10).map((list, index) => (
                            <div
                              className={`flx-prev-candidate-card-container ${
                                selectedCandidates.includes(list._id)
                                  ? 'flx-prev-candidate-active'
                                  : ''
                              }`}
                              key={index}
                              onClick={() => handleSelectedCandidates(list._id)}
                            >
                              <img
                                // src="/assets/img/demo-img-prev-worked-candidate.png"
                                src={
                                  list.profileimage ||
                                  '/assets/img/demo-img-prev-worked-candidate.png'
                                }
                                className="prev-candidate-img-top"
                              />
                              <div className="flx-prev-candidate-card-body">
                                <h6 className="flx-prev-candidate-card-title">
                                  {list.full_name}
                                </h6>

                                {/* implement the star rating */}
                                <span className="flx-prev-candidate-card-stars">
                                  {Array.from(
                                    { length: list.rating },
                                    (_, index) => (
                                      <i
                                        key={index}
                                        className="fa fa-star flx-prev-candidate-rating flx-rated"
                                      ></i>
                                    ),
                                  )}
                                  {Array.from(
                                    { length: 5 - list.rating },
                                    (_, index) => (
                                      <i
                                        key={list.rating + index}
                                        className="fa fa-star flx-prev-candidate-rating"
                                      ></i>
                                    ),
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    <button
                      type="button"
                      className={`btn border mt-2 ${
                        activeSaveButton ? 'verify-btn' : ''
                      }`}
                      disabled={!activeSaveButton}
                      onClick={e => handleSaveVacancies(e)}
                    >
                      Save
                    </button>
                  </div>
                )}

                {showAddNewShift && (
                  <div>
                    <AddNewShift
                      vacancyData={data => {
                        handleAddNewVacancy(data);
                      }}
                      closeAddNewVacancy={() => setShowAddNewShift(false)}
                      selectedShiftData={modifiedShiftData}
                      selectedDateAndPosition={modifiedDateAndPoistionData}
                      dateAndPositionData={dateAndPosition}
                      postAgencyData={postAgencyData}
                      handleSaveVacancies={handleSaveVacancies}
                    />
                  </div>
                )}
                {/*rate card holiday shift end*/}
                {/* rate card form ends*/}
                <button
                  type="button"
                  className="flx-add-shift mt-10"
                  onClick={hnadleAddNewShift}
                  disabled={saveVacancies.length === 0}
                >
                  <i className="fas fa-plus flx-icon-plus"></i>
                  <span className="flx-add-another-shift-text mr-5">
                    Add new vacancy
                  </span>
                </button>
                {/* rate card container ends */}
              </form>
              <div className="flx-job-post-nxt-back-btn-section">
                <button
                  className="btn btn-sm flx-job-post-back-btn"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
                  onClick={e => handleShiftSummary(e)}
                  disabled={saveVacancies.length === 0}
                >
                  Save &amp; Next -&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showvacancySummary && (
        <ShiftSummary
          postVacancies={saveVacancies}
          closeShiftSummary={handleCloseShiftSummary}
        />
      )}
      {/* Modal open up on view details on rate card */}
      <div
        id="RateCardModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          ratecardShowModal == 'ratecarddetails' ? 'show' : ''
        }`}
        style={{
          display: ratecardShowModal == 'ratecarddetails' ? 'block' : 'none',
        }}
      >
        <RateCardDetails
          handleCloseModal={setRateCardModal}
          detailsOfRateCard={selectedrateCardDetails}
        />
      </div>
      {/* Modal open up on add shift time button */}
      <div
        id="AddShiftTime"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          addshifttimeShowModal == 'addshifttime' ? 'show' : ''
        }`}
        style={{
          display: addshifttimeShowModal == 'addshifttime' ? 'block' : 'none',
        }}
      >
        <AddShiftTimeForm
          handleCloseModal={setAddShiftTimeModal}
          existingTimeList={shiftTimeList}
        />
      </div>
      <div
        id="SelectPrice"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          selectPirceShowModal == 'selectPrice' ? 'show' : ''
        }`}
        style={{
          display: selectPirceShowModal == 'selectPrice' ? 'block' : 'none',
        }}
      >
        <SelectPrice
          updatePrice={data => handleUpdatePrice(data)}
          handleClosePricePopUp={setSelectPirceShowModal}
          rateCardInfo={rateCardList}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedRateCard = connect(mapStateToProps)(RateCard);

export { connectedRateCard as RateCard };
