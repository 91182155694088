import React, { useState } from 'react';
import { ResetPassword } from '../../AuthPage';
import { userService } from '../../_services';

const LoginSecurity = props => {
  const [showmodal, setshowmodal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await userService.changEmailRequest().then(
        () => {
          setIsLoading(false);
          props?.setCurrentPage(5);
        },
        err => {
          setIsLoading(false);
          console.error('Error in email change request:', err);
          setError('Error in email change request. Please try again.');
        },
      );
    } catch (err) {
      setIsLoading(false);
      setError('Error in email change request. Please try again.');
    }
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  // const apple_account_id = parseInt(userData.apple_account_id);
  // const google_account_id = parseInt(userData.google_account_id);
  // const microsoft_account_id = parseInt(userData.microsoft_account_id);

  return (
    <div className="col-md-10 flx-emp-profile-main-form">
      <h2 className="flx-company-info-form-heading">Login & Security</h2>
      <form>
        <div className="mb-3">
          <label htmlFor="email" className="form-label company-info-labels">
            Email
          </label>{' '}
          <input
            style={{ background: 'white', border: '1px solid #A8A8A8' }}
            type="text"
            className="form-control selectDesign"
            id="emp-profile-email-login"
            value={props?.profile?.email}
            autoComplete="off"
            placeholder="Enter Email"
            readOnly
          />
          <a
            className="flx-billing-remove-card float-right mb-10 mt-1"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleSubmit}
          >
            {isLoading ? 'Requesting...' : 'Request to change email'}
          </a>
        </div>

        {(!userData.microsoft_account_id ||
          userData.microsoft_account_id === '0') &&
          (!userData.google_account_id || userData.google_account_id === '0') &&
          (!userData.apple_account_id || userData.apple_account_id === '0') && (
            <div>
              <label
                htmlFor="password"
                className="form-label company-info-labels"
              >
                Password
              </label>
              <input
                // type="password"
                style={{ background: 'white', border: '1px solid #A8A8A8' }}
                className="form-control selectDesign"
                id="password"
                value={props?.profile?.password}
                autoComplete="off"
                placeholder="********"
                readOnly
              />
              <a
                onClick={() => {
                  setshowmodal(true);
                }}
                className="flx-billing-remove-card float-right mb-10 mt-1"
                style={{
                  color: '#757aee',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Change Password
              </a>
            </div>
          )}
      </form>
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <ResetPassword
                    _id={props?.id}
                    showmodal={showmodal}
                    setshowmodal={setshowmodal}
                  ></ResetPassword>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {error && (
        <p style={{ color: 'red' }}>
          {error.message
            ? error.message
            : typeof error === 'string'
            ? error
            : 'Please try again after some time'}
        </p>
      )}
    </div>
  );
};

export default LoginSecurity;
