import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';

function shiftsAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addshifts', requestOptions).then(
    miscService.handleResponse,
  );
}
function shiftsEdit(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/editshifts', requestOptions).then(
    miscService.handleResponse,
  );
}
function updateShift(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/updateshift', requestOptions).then(
    miscService.handleResponse,
  );
}
function employerreportdownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/employerreportdownload',
    requestOptions,
  ).then(miscService.handleResponse);
}
function printShiftPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/shiftpdfprint', requestOptions).then(
    miscService.handleResponse,
  );
}
function printShift(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/shiftpdfprintnew', requestOptions).then(
    miscService.handleResponse,
  );
}
// function printInvoicePdf(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/invoicepdfprint', requestOptions).then(
//     handleResponse,
//   );
// }

function printAgencyinvoicePdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/agencyinvoicepdfprint',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function printPayoutPdf(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/payoutpdfprint', requestOptions).then(
//     handleResponse,
//   );
// }

//---add attendance-----
function addAttendance(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addattendance', requestOptions).then(
    miscService.handleResponse,
  );
}
function shiftsList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getshiftsList', requestOptions).then(
    miscService.handleResponse,
  );
}

function shiftsListforviewinvoice(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftsListforviewinvoice',
    requestOptions,
  ).then(miscService.handleResponse);
}

function Allshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/allshiftreport', requestOptions).then(
    miscService.handleResponse,
  );
}
function getAllCandiateWithShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallcandiatewithshiftexist',
    requestOptions,
  ).then(miscService.handleResponse);
}

function getAllCandiateWithShiftscount(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getAllCandiateWithShiftscount',
    requestOptions,
  ).then(miscService.handleResponse);
}
function getAllEmployerWithShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallemployerwithshiftexist',
    requestOptions,
  ).then(miscService.handleResponse);
}
function getAllAgencyShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallagencyshiftexist',
    requestOptions,
  ).then(miscService.handleResponse);
}
function upcomingandcompleteshiftsList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getUpcomingandCompleteShiftsList',
    requestOptions,
  ).then(miscService.handleResponse);
}

function shiftsListCalendar(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftslistcalendar',
    requestOptions,
  ).then(miscService.handleResponse);
}
function shiftsListWeekly(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftslistweekly',
    requestOptions,
  ).then(miscService.handleResponse);
}
function shiftsDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteshifts', requestOptions).then(
    miscService.handleResponse,
  );
}

function shiftsCancel(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/webcancelshift', requestOptions).then(
    miscService.handleResponse,
  );
}

function shiftsRestore(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/restoreshift', requestOptions).then(
    miscService.handleResponse,
  );
}
// function timesheetShiftList(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/timesheetShiftList',
//     requestOptions,
//   ).then(handleResponse);
// }
function timesheetShiftListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/timesheetShiftListv2',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function reportShiftList(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/getreportShiftList',
//     requestOptions,
//   ).then(handleResponse);
// }
function reportShiftListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getreportShiftListv2',
    requestOptions,
  ).then(miscService.handleResponse);
}

function getorganizationreportShiftList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getorganizationreportShiftList',
    requestOptions,
  ).then(miscService.handleResponse);
}
function Candidateshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidatereport',
    requestOptions,
  ).then(miscService.handleResponse);
}

function Agencyshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyreport', requestOptions).then(
    miscService.handleResponse,
  );
}

// function employerJobPosting(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/vacancy', requestOptions).then(
//     miscService.handleResponse,
//   );
// }
const getShiftsCsvData = shifts => {
  let shiftList = [];
  if (!shifts) {
    return shiftList;
  }

  //for (let c = 0; c < shifts.length; c++) {
  // shifts.map(shift => {
  //shift is object
  Object.keys(shifts).map(shiftid => {
    // new_selectedShiftIds.push(shifts[shiftid]._id);
    const csv_file_data = {
      'Shift ID': shifts[shiftid]._id,
      'Candidate Name': shifts[shiftid]['Candidate.name'],
      'Candidate Last Name': shifts[shiftid]['Candidate.last_name'],
      'Check In Date': shifts[shiftid].check_in_date,
      'Check Out Date': shifts[shiftid].check_out_date,
      'Company Name': shifts[shiftid]['Employer.company_name'],
      'Employer Location': shifts[shiftid]['Businessunit.postcode'],
      'Signature Employer Name': shifts[shiftid].signature_employer_name,
      'Section Type': shifts[shiftid].section_type,
      'Employer To Pay': shifts[shiftid].employer_to_pay,
      'Is Break Time Pay': shifts[shiftid].is_break_time_pay,
      'Break Time': shifts[shiftid].break_time,
      'Actual Hours After Break Time':
        shifts[shiftid].actual_hours_after_break_time,
      Price: shifts[shiftid].price,
      'Total Price': shifts[shiftid].total_price,
    };
    shiftList.push(csv_file_data);
  });

  return shiftList;
};

export const shiftsService = {
  shiftsAdd,
  shiftsEdit,
  shiftsList,
  shiftsDelete,
  shiftsListCalendar,
  shiftsListWeekly,
  upcomingandcompleteshiftsList,
  // timesheetShiftList,
  timesheetShiftListv2,
  // reportShiftList,
  reportShiftListv2,
  Candidateshiftreport,
  Agencyshiftreport,
  addAttendance,
  updateShift,
  printShiftPdf,
  getorganizationreportShiftList,
  // printInvoicePdf,
  Allshiftreport,
  shiftsCancel,
  shiftsRestore,
  getAllCandiateWithShiftExist,
  getAllEmployerWithShiftExist,
  // printPayoutPdf,
  getAllAgencyShiftExist,
  printAgencyinvoicePdf,
  getAllCandiateWithShiftscount,
  employerreportdownload,
  shiftsListforviewinvoice,
  printShift,
  getShiftsCsvData,
  // employerJobPosting,
};
