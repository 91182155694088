// import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { shiftsActions } from '../_actions';
import { LoadingIcon } from '../_components';
// eslint-disable-next-line no-unused-vars

const PayoutPrint = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shifts, setShift] = useState([]);
  const { payoutid } = useParams();

  // effect to load shifts
  useEffect(() => {
    getAllShifts(1);
  }, []);
  /**   * ----Get all shiftss-----   **/
  const getAllShifts = () => {
    setIsLoading(true);
    const { dispatch } = props;
    let querytemp = {};
    if (payoutid) {
      querytemp.payout_id = payoutid;
      querytemp.status = '';
    }
    dispatch(shiftsActions.printShift(querytemp)).then(
      res => {
        setIsLoading(false);

        if (res && res.data && res.data) {
          setShift(res.data);
        }
        setTimeout(() => {
          // Call your print function here
          window.print();
        }, 3000); // 10000 milliseconds = 10 seconds
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // render table headers
  const renderTbHeader = () => (
    <thead>
      <tr>
        <th>Id</th>
        <th>Candidate Name</th>
        <th>Day</th>
        <th>Date</th>
        <th>Time In</th>
        <th>Time Out</th>
        <th>Price</th>
        <th>Paid Hours</th>
        <th>Total</th>
      </tr>
    </thead>
  );

  return (
    <div className="StaffList" style={{ fontFamily: 'helvica-bold' }}>
      <div className="col-md-12">
        <div className="card regular-table-with-color mt-20">
          {isLoading && <LoadingIcon loadingText={"Loading..."}/>}
          {shifts.map(shiftGroup => {
            let total_hours = 0;
            let total_amount = 0;
            return (
              <>
                <div className="mt40px">
                  <div className="d-flex justify-content-between mb-30">
                    <div className=" print_shift_flexilogo ">
                      <img
                        src="/assets/img/recsy-temp-logo.svg"
                        className="printlogo"
                      />
                    </div>
                  </div>
                  <div className="mb-60">
                    <h1 style={{ textAlign: 'center' }}>PAYOUT</h1>
                  </div>
                  <div
                    className="d-flex mb-30"
                    style={{ textTransform: 'capitalize' }}
                  >
                    <div className="lh-sm">
                      To,
                      <br />
                      Client Name:
                      {' ' + shiftGroup.headerdetails.candidate_name}
                      <br />
                      Address :{' '}
                      {' ' + shiftGroup.headerdetails.candidate_address}
                      <br />
                      Payout Number :
                      {' ' + shiftGroup.headerdetails.payoutnumber}
                      <br />
                      Issued Date :
                      {' ' +
                        moment(shiftGroup.headerdetails.issueddate).format(
                          'DD-MMM-YYYY',
                        )}
                      <br />
                      Due Date :
                      {' ' +
                        moment(shiftGroup.headerdetails.issueddate)
                          .add(30, 'days')
                          .format('DD-MMM-YYYY')}
                    </div>
                    <div className="lh-sm ml-100 ">
                      From,
                      <br />
                      Recsy Ltd.
                      <br />
                      First Floor 10-12 <br />
                      Southernhay,Basildon
                      <br />
                      Essex England,SS14 1EL United Kingdom
                    </div>
                  </div>
                </div>
                <React.Fragment key={shiftGroup.headerdetails.candidate_id}>
                  <table
                    className="table-bordered mt-20 mb-30 "
                    style={{ breakAfter: 'page' }}
                  >
                    {renderTbHeader()}
                    {shiftGroup.shifts.map((shift, index) => {
                      total_hours =
                        total_hours + shift.actual_hours_after_break_time;
                      total_amount =
                        total_amount +
                        shift.actual_hours_after_break_time * shift.price;
                      return (
                        <>
                          {index !== 0 && index % 20 === 0 && (
                            <>
                              <tr style={{ breakAfter: 'page' }}></tr>
                            </>
                          )}
                          <tbody>
                            <tr>
                              <td>{'000' + shift._id}</td>
                              <td>
                                {shift.candidate_name
                                  ? shift.candidate_name
                                  : shift.agency_name
                                  ? shift.agency_name
                                  : ''}
                              </td>
                              <td>{shift.day}</td>
                              <td>
                                {moment(shift?.check_in_date_string).format(
                                  'DD-MMM-YYYY',
                                )}
                              </td>
                              <td>{shift.check_in_time_string}</td>
                              <td>{shift.check_out_time_string}</td>
                              <td>{shift.price}</td>
                              <td>
                                {shift.actual_hours_after_break_time.toFixed(2)}
                              </td>
                              <td>
                                {(
                                  shift.actual_hours_after_break_time *
                                  shift.price
                                ).toFixed(2)}
                              </td>
                            </tr>
                            {index == shiftGroup.shifts.length - 1 && (
                              <>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="fontwghtbold"
                                    style={{ textAlignLast: 'end' }}
                                  >
                                    TOTAL SHIFTS
                                  </td>
                                  <td className="fontwghtbold">
                                    {shiftGroup.shifts.length}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="fontwghtbold"
                                    style={{ textAlignLast: 'end' }}
                                  >
                                    TOTAL HOURS
                                  </td>
                                  <td className="fontwghtbold">
                                    {total_hours.toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="fontwghtbold"
                                    style={{ textAlignLast: 'end' }}
                                  >
                                    TOTAL AMOUNT
                                  </td>
                                  <td className="fontwghtbold">
                                    {total_amount.toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </>
                      );
                    })}
                  </table>
                </React.Fragment>
              </>
            );
          })}
          <footer>
            {/* You can use a library like react-paginate to paginate and display page numbers */}
          </footer>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedPayoutPrint = connect(mapStateToProps)(PayoutPrint);
export { connectedPayoutPrint as PayoutPrint };
