/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { SectionAdd } from '../Section';
import { PriceActions, employerActions, shiftsActions } from '../_actions';
import { apiConstants, reducerConstants, roleConstant } from '../_constants';
import { authHeader } from '../_helpers';

import { sectionActions } from '../_actions';
import { LoadingIcon } from '../_components';
import { miscService } from '../_services';

const ShiftEdit = props => {
  const [shifts, setShifts] = useState({
    shift_start_from: '',
    shift_start_to: '',
    check_in_time: '',
    check_out_time: '',
    shift_status: '',
    employer_id: '', //---carehome id--------
    business_unit: '',
    // check_in_mills: '',
    check_out_mills: '',
    isShift_multiple: false,
    from_time_string: '',
    to_time_string: '',
    from_date_string: '',
    to_date_string: '',
    section_type: '',
    employer_to_pay: '',
    dates: '',
    is_break_time_pay: 0,
    pickup_and_dropoff: 0,
    travel_reimbursement: 0,
    shift_type: 0,
    user: [], //----this is candidates to ehich shifts are allocated-----
  });
  const [selectedSectionType, setselectedSectionType] = useState('');

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [sectionrows, setsectionrows] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionData, setsectionData] = useState([]);
  const [shiftsoptionsData, setshiftsoptionsData] = useState([]);
  const [isPastday, setisPastday] = useState(false);
  const [IsEmployer, setIsEmployer] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsStaff, setIsStaff] = useState(false);
  const [SectionAddModal, setSectionAddModal] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [error, setError] = useState({ common: '', categoryMismatch: '' });
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-CA');
  const formattedTime = currentDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.role && user.role == roleConstant.EMPLOYER) {
        setIsEmployer(true);
      }

      if (user && user.role && user.role == roleConstant.ADMIN) {
        setIsAdmin(true);
      }
      if (user && user.role && user.role == roleConstant.STAFF) {
        setIsStaff(true);
      }
      if (user && user.employer_id) {
        getemployer(user.employer_id);
        getbusinessunit(user.employer_id);
      }
    }

    if (props._id) {
      getAllShifts(1, { _id: props._id });
    }

    if (props && props && props.shiftsData && props.shiftsData._id) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            value: props.shiftsData.name,
            _id: props.shiftsData._id,
            candidate_id: props.shiftsData._id,
            user_id: props.shiftsData.user_id,
          },
        ],
        from_date_string: props.date,
        shift_start_from: props.date,
        //check_in_mills: new Date(props.date).getTime(),
      }));
    }

    if (props.daymonthyear) {
      // setdaymonthyear(props.daymonthyear);

      getcheck_in();
      checkPastDay();
    }

    //-------if shift is being added by carehome set carehome id / employer_id from localstorage else select from asyn
    if (IsEmployer) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        setShifts(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
      }
    }

    // getAllSection();

    //------------------get business unit---------------------
    getbusinessunit(shifts.employer_id);
  }, []);

  useEffect(() => {
    getAllSection();
  }, [shifts.employer_id]);

  const getemployer = employer_id => {
    const { dispatch } = props;
    dispatch(employerActions.employerList({ _id: employer_id })).then(
      res => {
        setIsLoading(false);

        setShifts(prevState => ({
          ...prevState,
          break_time: res?.data?.rows?.[0].break_time,
        }));
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //------------------get candiate---------------------
  const getcandidatelist = inputvalue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcandidatelist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          active_status: 1,
          isverified: 1,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            resolve(
              data.rows.map(item => ({
                value: item['_id'],
                label:
                  item['name'] +
                  ' ' +
                  (item['last_name'] ? item['last_name'] : ' ') +
                  ' ' +
                  '(' +
                  (item['Category.name'] ? item['Category.name'] : ' ') +
                  ' ' +
                  (item['city'] ? '-' + item['city'] : ' ') +
                  ')',
                user_id: item['user_id'],
                candidate_mail: item['email'],
                candidate_name: item['name'],
                category: item['Category._id'],
                weekend_day_price: item['weekend_day_price'],
                weekend_night_price: item['weekend_night_price'],
                day_price: item['day_price'],
                night_price: item['night_price'],
                evening_price: item['evening_price'],
              })),
            );
          }),
        )
        .catch(reject);
    });

  //------------------get business unit---------------------
  const getbusinessunit = employer_id => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          select: ['_id', 'name'],
        }),
      }).then(response =>
        response
          .json()

          .then(({ data }) => {
            if (data && data.rows) {
              let shift_option_rows = [];
              for (let s = 0; s < data.rows.length; s++) {
                shift_option_rows.push(
                  <option
                    selected={s == 0 ? true : false}
                    value={data.rows[s]._id}
                  >
                    {data.rows[s].name}
                  </option>,
                );
              }
              setshiftsoptionsData(shift_option_rows);
            }
          }),
      );
    }
  };

  //--------get date in string format--------
  const timeparse = datestr => {
    let timesplitted = datestr.split('T');
    return timesplitted[1];
  };

  //---------get date in string format =----------
  const dateparse = datestr => {
    let datesplitted = datestr.split('T');
    return datesplitted[0];
  };
  /**
   * Get all shiftss
   * */
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    // queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          let shiftsData = res.data.rows[0];
          if (shiftsData && shiftsData.employer_id) {
            getbusinessunit(shiftsData.employer_id);
            getAllSection(shiftsData.employer_id);
          }
          if (shiftsData) {
            const dateArray = [shiftsData.check_in_date_string];
            shifts.dates = dateArray;

            res.data.row = [{}]; // Initialize res.data.row if it's undefined
            res.data.rows[0].section_type = shiftsData['Section.section_type'];
            setShifts(shiftsData);
          }
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleWheel = event => {
    event.target.blur();
  };
  //---get all section when carehome is selected--------
  const getAllSection = () => {
    setsectionrows([]);

    setIsLoading(true);
    const { dispatch } = props;
    if (shifts && shifts.employer_id && shifts.employer_id != 0) {
      dispatch(
        sectionActions.sectionList({ employer_id: shifts.employer_id }),
      ).then(
        res => {
          let sectionlist = [];
          setIsLoading(false);
          // setListAndPagination(res.data);
          // let sectionData = res.data.rows;
          setsectionData(res.data.rows);
          for (let i = 0; i < res?.data?.rows?.length; i++) {
            sectionlist.push(
              <option
                selected={i == 0 ? true : false}
                key={res?.data?.rows?.[i].section_type}
                value={res?.data?.rows?.[i]._id}
              >
                {res?.data?.rows?.[i].name}{' '}
                {'(' +
                  res?.data?.rows?.[i].from_time +
                  '-' +
                  res?.data?.rows?.[i].to_time +
                  ')'}
              </option>,
            );
          }

          // setShifts(prevState => ({
          //   ...prevState,
          //   section_type: res?.data?.rows?.[0].section_type,
          //   section_id: res?.data?.rows?.[0]._id,
          //   to_time_string: res?.data?.rows?.[0].to_time,
          //   from_time_string: res?.data?.rows?.[0].from_time,
          //   check_in_time: res?.data?.rows?.[0].from_time,
          //   check_out_time: res?.data?.rows?.[0].to_time,
          // }));

          setsectionrows(sectionlist);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };

  const handleChange = event => {
    let timeDifference;
    let { name, value } = event.target;

    if (name == 'shift_start_from') {
      // let date_string = dateparse(value);

      //---check if there is a checkout mills and validate the check in mills----
      setShifts({
        ...shifts,
        // from_time_string: time_string,
        // from_date_string: date_string,
        // check_in_mills: newvalue,
        // check_in_time: time_string,
        // check_out_time: res?.data?.rows?.[0].to_time,
        // shift_start_fromtime: d,
        [name]: value,
      });
    } else if (name == 'shift_start_to') {
      let d = new Date(value);
      let newvalue = d.getTime();

      //---------check if the checkindate is less than checkout date in mills--
      if (newvalue < new Date(shifts.check_in_time).getTime()) {
        setError({ common: 'choose a valid date' });
      } else {
        //---to get time seperated from date string-----
        let time_string = timeparse(value);
        let date_string = dateparse(value);

        setShifts({
          ...shifts,
          to_time_string: time_string,
          to_date_string: date_string,
          check_out_mills: newvalue,
          [name]: value,
        });
      }
    } else if (name == 'price' || name == 'employer_to_pay') {
      const regex = /^(?!\.)^(?!.*[+e])[\d\s]{0,3}(\.\d{0,2})?$/;
      const sanitizedValue = regex.test(value) ? value : '';
      if (sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: sanitizedValue,
        }));
      } else if (value === '') {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        // If sanitizedValue is empty or null, replace letters in value before setting
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
      }
    } else if (name == 'break_time') {
      const regex = /^(?!.*[+e])[\d\s]{0,3}$/;
      const sanitizedValue = regex.test(value) ? value : '';
      if (value === '' || sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
      }
    } else if (name == 'section_type') {
      setError({ currentDateWithPastTime: '' });
      let selected_section_type = '';
      let selected_section = '';
      let check_in_time = '';
      let check_out_time = '';
      for (let s = 0; sectionData.length && s < sectionData.length; s++) {
        if (value == sectionData[s]._id) {
          selected_section = sectionData[s];
          selected_section_type = sectionData[s].section_type;
          check_in_time = sectionData[s].from_time;
          check_out_time = sectionData[s].to_time;
          setselectedSectionType(sectionData[s].section_type);
        }
      }
      // Calculate the time difference between check_in_time and check_out_time
      timeDifference = miscService.calculateTimeDifference(
        check_in_time,
        check_out_time,
      );
      if (
        shifts.check_in_date_string === formattedDate &&
        check_in_time <= formattedTime
      ) {
        setError({
          currentDateWithPastTime:
            'You cannot assign the shift with the current date with past time.',
        });
        return;
      } else {
        setError({ currentDateWithPastTime: '' });
        setShifts({
          ...shifts,
          // price: price,
          section_id: value,
          to_time_string: selected_section.to_time,
          from_time_string: selected_section.from_time,
          check_in_time: selected_section.from_time,
          check_out_time: selected_section.to_time,
          // [name]: value,
          section_type: selected_section_type,
          timeDifference: timeDifference,
        });
      }
    } else if (name == 'business_unit') {
      // var index = event.nativeEvent.target.selectedIndex;
      const select = event.target;
      const bunitname = select.options[select.selectedIndex].text;
      setShifts(prevState => ({
        ...prevState,
        [name]: value,
        bunit_name: bunitname,
      }));

      // let selected_section_type = '';
      // let selected_section = '';

      // for (let s = 0; sectionData.length && s < sectionData.length; s++) {
      //   if (value == sectionData[s]._id) {
      //     selected_section = sectionData[s]
      //     selected_section_type = sectionData[s].section_type
      //     setselectedSectionType(sectionData[s].section_type)
      //   }
      // }

      // setShifts({
      //   ...shifts,
      //   // price: price,
      //   section_id: value,
      //   to_time_string: selected_section.to_time,
      //   from_time_string: selected_section.from_time,
      //   check_in_time: selected_section.from_time,
      //   check_out_time: selected_section.to_time,
      //   business_unit:value,
      //   // [name]: value,
      //   section_type: selected_section_type
      // });
    } else {
      setShifts(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const breaktimeChange = event => {
    let { value } = event.target;
    // setbreaktime(value);
    setShifts(prevState => ({ ...prevState, break_time: value }));
  };

  //---async select multiple 0user selecet----
  const selecteduser = selectedOption => {
    if (!selectedOption || selectedOption.length === 0) {
      setShifts(prevState => ({
        ...prevState,
        user: [],
        'Candidate.name': '',
      }));
      return;
    }
    const selectedLabels = new Set(shifts.user.map(u => u.value)); // Get currently selected labels
    const filteredOptions = selectedOption.filter(
      option => !selectedLabels.has(option.label),
    ); // Filter out duplicates
    const isMatch = filteredOptions.every(
      option => option.category === shifts['Category._id'],
    );
    const SelectedValues = filteredOptions.map(option => ({
      value: option.label,
      _id: option.value,
      candidate_id: option.value,
      user_id: option.user_id,
    }));
    setShifts(prevState => ({
      ...prevState,
      user: SelectedValues,
      'Candidate.name': SelectedValues.map(c => c.value).join(', '),
    }));
    setError({ candidate: '' });
    if (isMatch) {
      setShifts(prevState => ({
        ...prevState,
        user: SelectedValues,
      }));
      setError({ categoryMismatch: '' });
    } else {
      setError({
        categoryMismatch:
          'Please select a candidate with the same job role as the job role to be allocated',
      });
      return;
    }
  };

  const handleSubmit = event => {
    //------------TO AVOUD MULTIPLE BUTTON CLICK ------------
    // if (submitted) {
    //   return false;
    // }
    setError({ common: '' });
    setSubmitted(true);
    setisDisabled(true);
    event.preventDefault();
    const { dispatch } = props;

    if (
      (shifts.check_in_date_string || shifts.dates) &&
      shifts.check_in_time &&
      shifts.check_out_time &&
      shifts.section_id &&
      shifts.category
    ) {
      if (!shifts.break_time) {
        shifts.break_time = 0;
      }
      if (shifts.dates == 0 && shifts.check_in_date_string == '') {
        setError({
          common: 'Date is requried',
        });
        setisDisabled(false);
        return;
      }
      if (shifts.price && shifts.price < 10.42) {
        setError({
          common: 'Price should be greater than minimum price of 10.42',
        });
        setisDisabled(false);
        return;
      }
      if (shifts.employer_to_pay && shifts.employer_to_pay < 10.42) {
        setError({
          common: 'Employer pay should be greater than minimum price of 10.42',
        });
        setisDisabled(false);
        return;
      }
      if (shifts.timeDifference >= 6 && shifts.break_time < 20) {
        setError({
          common: 'The minimum break time should be 20 minutes or longer.',
        });
        setisDisabled(false);
        return;
      }
      if (
        shifts.check_in_date_string === formattedDate &&
        shifts.check_in_time <= formattedTime
      ) {
        setError({
          currentDateWithPastTime:
            'You cannot assign the shift with the current date and time plus one hour.',
        });
        setisDisabled(false);
        return;
      }
      if (shifts.break_time && shifts.break_time >= 180) {
        setError({
          common: 'Break time should be less than 180 minutes ',
        });
        setisDisabled(false);
        return;
      }
      if (shifts?.user?.length < 1) {
        setError({ candidate: 'Candidate is required' });
        setisDisabled(false);
        return;
      }
      setIsLoading(true);
      dispatch(shiftsActions.shiftsEdit(shifts)).then(
        () => {
          //----close shift modal after success response -----
          //------ function showAddshiftmodal is passed from  candidate component-----
          props.showEditshiftmodal(false);
          setSubmitted(false);
          setIsLoading(false);
        },
        () => {
          setSubmitted(false);
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
      setisDisabled(false);
    }
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ACB5BC',
      zIndex: '0',
      fontWeight: 'bold',
      background: '#FFFFFF',
      borderRadius: '6px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#000000',
      padding: '2px',
      width: '20px',
      height: '30px',
    }),
  };

  const selectedemployer = (selectedOption, event) => {
    (shifts.check_in_time = ''), (shifts.check_out_time = '');
    let name = event.name;

    setShifts(prevState => ({
      ...prevState,
      'Employer.company_name': selectedOption.label,
    }));
    getbusinessunit(selectedOption.value);
    getemployer(selectedOption.value);

    // getAllSection();
  };

  //@author --fahisa
  //to get check in date from props to date fromat (yyyy-mm-dd)-----
  const getcheck_in = () => {
    let fromdate =
      props.daymonthyear.year +
      '-' +
      (props.daymonthyear.month.toString().length == 1
        ? '0' + (props.daymonthyear.month + 1)
        : props.daymonthyear.month + 1) +
      '-' +
      (props.daymonthyear.day.toString().length == 1
        ? '0' + props.daymonthyear.day
        : props.daymonthyear.day);

    // let d = new Date(fromdate);
    // let newvalue = d.getTime();

    //---to get time seperated from date string-----
    let time_string = timeparse(fromdate);

    //---check if there is a checkout mills and validate the check in mills----
    setShifts({
      ...shifts,
      from_time_string: time_string,
      from_date_string: props.daymonthyear.index,
      //  check_in_mills: newvalue,
      // shift_start_fromtime: d,
      shift_start_from: props.daymonthyear.index,
    });
  };

  //get price chart -----
  const getAllpriceChart = category_id => {
    const { dispatch } = props;
    dispatch(PriceActions.PriceList({ category: category_id })).then(
      res => {
        if (res.data && res.data.rows && res?.data?.rows?.[0]) {
          let price_data = res?.data?.rows?.[0];
          let new_price = '';
          if (selectedSectionType == 'Evening') {
            new_price = price_data.evening_price;
          } else if (selectedSectionType == 'Morning') {
            new_price = price_data.morning_price ? price_data.morning_price : 0;
          } else if (selectedSectionType == 'Weekend') {
            new_price = price_data.weekend_price ? price_data.weekend_price : 0;
          } else if (selectedSectionType == 'Night') {
            new_price = price_data.night_price ? price_data.night_price : 0;
          } else {
            new_price = price_data.day_price ? price_data.day_price : 0;
          }
          if (new_price != 0) {
            setShifts({
              ...shifts,
              price: new_price,
            });
          }
        }
      },
      () => {},
    );
  };

  ///----category select change -----------
  const selectedcategory = (selectedOption, event) => {
    let name = event.name;
    if (selectedSectionType) {
      let new_price = '';
      if (selectedSectionType == 'Evening') {
        new_price = selectedOption.evening_price;
      } else if (selectedSectionType == 'Morning') {
        new_price = selectedOption.morning_price
          ? selectedOption.morning_price
          : 0;
      } else if (selectedSectionType == 'Weekend') {
        new_price = selectedOption.weekend_price
          ? selectedOption.weekend_price
          : 0;
      } else if (selectedSectionType == 'Night') {
        new_price = selectedOption.night_price ? selectedOption.night_price : 0;
      } else {
        new_price = selectedOption.day_price ? selectedOption.day_price : 0;
      }

      if (new_price != 0) {
        setShifts({
          ...shifts,
          price: new_price,
        });
      }
      getAllpriceChart(selectedOption.value);
    }

    setShifts(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      'Category.name': selectedOption.label,
    }));
  };

  const selectedshifttype = event => {
    const selectedShiftValue = event.target.value;
    setShifts(prev => ({
      ...prev,
      shift_type: selectedShiftValue,
    }));
  };
  const closeSectionAddModal = value => {
    setSectionAddModal(value);
    getAllSection();
  };

  const checkPastDay = () => {
    let today =
      new Date().getFullYear() +
      '-' +
      ((new Date().getMonth() + 1).toString().length == 1
        ? '0' + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1) +
      '-' +
      (new Date().getDate().toString().length == 1
        ? '0' + new Date().getDate()
        : new Date().getDate());

    if (props.daymonthyear && props.daymonthyear.index < today) {
      setisPastday(true);
      return 'Selected a previous date';
    }
  };

  const dateshandleChange = value => {
    let temp_dates = [];
    for (let d = 0; d < value.length; d++) {
      temp_dates.push(value[d].format('YYYY-MM-DD'));
    }
    setShifts({
      ...shifts,
      dates: temp_dates,
      check_in_date_string: '',
    });
  };

  const toggleBenefit = benefit => {
    const updatedShifts = { ...shifts };

    if (benefit === 'paid_break') {
      updatedShifts.is_break_time_pay =
        updatedShifts.is_break_time_pay === 1 ? 0 : 1;
    } else if (benefit === 'pickup_and_dropoff') {
      updatedShifts.pickup_and_dropoff =
        updatedShifts.pickup_and_dropoff === 1 ? 0 : 1;
    } else if (benefit === 'travel_reimbursement') {
      updatedShifts.travel_reimbursement =
        updatedShifts.travel_reimbursement === 1 ? 0 : 1;
    }
    setShifts(updatedShifts);
  };

  const handleBenefitChange = event => {
    const selectedBenefitValue = event.target.value;
    setShifts(prev => ({
      ...prev,
      benefits: [...(prev.benefits || []), selectedBenefitValue],
    }));

    if (submitted) {
      setSubmitted(false);
    }
  };
  return (
    <div className="ShiftEdit d-flex justify-content-center">
      <div className="col-md-11 col-sm-6 ">
        <div className="card m-0">
          <div className=" page-header ">
            <div className="page-title-content">
              <div className="row d-flex justify-content-center">
                {isLoading && <LoadingIcon></LoadingIcon>}
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h4 className="card-title mt-0 ml-3">Edit Shift</h4>
                </div>

                {props.daymonthyear &&
                  props.daymonthyear.day &&
                  props.allocateshift && (
                    <div className="col d-flex align-items-center">
                      {' '}
                      <div className="date-div">
                        {props.daymonthyear.day.toString().length == 1
                          ? '0' + props.daymonthyear.day
                          : props.daymonthyear.day}
                        -
                        {props.daymonthyear.month.toString().length == 1
                          ? '0' + props.daymonthyear.month
                          : props.daymonthyear.month}
                        -{props.daymonthyear.year}
                      </div>
                    </div>
                  )}

                {isPastday && (
                  <div className="text-secondary">
                    Selected date is past day
                  </div>
                )}

                {props && props.date && (
                  <div className="col d-flex align-items-center">
                    {' '}
                    <div className="date-div">
                      {props.date}
                      {/* hello */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className="card-body">
            <form name="form">
              <div className="row">
                <div className="col-md-12 ml-2">
                  <div className="row">
                    <div className="col-md-12 pr-1 ">
                      <label htmlFor="type">Shift Date :</label>
                      <div
                        className={
                          'form-group  ' +
                          (submitted && !shifts.shift_start_from
                            ? ' has-error'
                            : '')
                        }
                        style={{
                          position: 'relative',
                          width: '100%',
                        }}
                      >
                        <DatePicker
                          multiple
                          style={{
                            height: '40px',
                            width: '822px',
                            border: ' 1px solid #ACB5BC',
                            borderRadius: '6px',
                            fontSize: '14px',
                            padding: '4px 10px',
                            background: '#FFFFFF',
                          }}
                          value={
                            shifts.dates
                              ? new Date(shifts.dates)
                              : new Date(shifts.check_in_date_string)
                          }
                          format="DD-MM-YYYY"
                          minDate={new Date()}
                          showOtherDays={false}
                          onChange={dateshandleChange}
                        />
                        {/* <i className="fa fa-calendar assign-shift-calender-icon" /> */}
                        <i
                          className="fa fa-calendar assign-shift-calender-icon"
                          style={{
                            position: 'absolute',
                            right: '20px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#888',
                            pointerEvents: 'none',
                            fontSize: '12px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!IsEmployer && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Employer :</label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !shifts.employer_id ? ' has-error' : '')
                        }
                      >
                        <AsyncSelect
                          styles={customStyles}
                          name="employer_id"
                          onChange={selectedemployer}
                          value={{
                            label: shifts['Employer.company_name'],
                            value: shifts.employer_id,
                          }}
                          cacheOptions
                          defaultOptions
                          noOptionsMessage={() => 'No results found'}
                          loadOptions={getEmployerList}
                        />

                        {submitted && !shifts.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                        {/*Required*/}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Business Unit :</label>
                    <div className="form-group">
                      <select
                        className="select col-md-12 select-business-unit input-alnment"
                        name="business_unit"
                        onChange={handleChange}
                        value={shifts.business_unit}
                      >
                        <option value="" hidden="true">
                          {' '}
                          Select
                        </option>
                        {shiftsoptionsData}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* //------------select category ------------>>>>>>>.. */}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Job Role :</label>
                      <AsyncSelect
                        styles={customStyles}
                        name="category"
                        onChange={selectedcategory}
                        value={{
                          label: shifts['Category.name'],
                          value: shifts.category,
                        }}
                        cacheOptions
                        defaultOptions
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getcategorylist}
                      />

                      {submitted && !shifts.category && (
                        <div className="help-block"> Job role is required</div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* //------------select type of shift ------------>>>>>>>.. */}
              {!IsEmployer && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Type Of Shift :</label>
                      <div className="form-group">
                        <select
                          style={{ background: '#E5EEEFA8' }}
                          className="  col-md-12  input-alnment "
                          id="typeOfShift"
                          value={shifts.shift_type}
                          onChange={selectedshifttype}
                          selected
                        >
                          {/* <option value="" disabled>
                            {' '}
                            select
                          </option> */}
                          <option name="regular" value={1}>
                            Regular
                          </option>
                          <option name="shadow" value={2}>
                            Shadow Shift
                          </option>
                          <option name="sleep" value={3}>
                            Sleep Shift
                          </option>
                        </select>
                        {submitted && !shifts.shift_type && (
                          <div className="help-block">
                            Shift Type is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* //------------select shift time ------------>>>>>>>.. */}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <label htmlFor="type">Shift Time :</label>
                  <div className={'form-group '}>
                    <select
                      style={{
                        background: '#E5EEEFA8',
                        border: 'none',
                      }}
                      className="select col-md-12 selectDesign  selectDesn "
                      name="section_type"
                      value={shifts.section_id}
                      onChange={handleChange}
                    >
                      <option value=""> Select Shift Time</option>
                      {/* {sectionData.map((i) => {
                        return (
                          <option value={i._id} >{i.name} ({i.from_time}-{i.to_time})/{i.section_type}</option>
                        );
                      })} */}
                      {sectionrows}
                    </select>
                    {submitted && !shifts.section_type && (
                      <div className="help-block"> Section is required</div>
                    )}
                  </div>
                </div>
              </div>
              {error.currentDateWithPastTime && (
                <div className="help-block">
                  {error.currentDateWithPastTime}
                </div>
              )}

              {/* //--------select Shift allocated to ---------- */}

              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Shift Allocated To :</label>
                    <AsyncSelect
                      styles={customStyles}
                      name="candidate_id"
                      onChange={selecteduser}
                      placeholder={'select candidate'}
                      cacheOptions
                      defaultOptions
                      isMulti
                      value={
                        shifts['Candidate.name']
                          ? shifts['Candidate.name'].split(', ').map(name => ({
                              label: name,
                              value: name,
                            }))
                          : []
                      }
                      noOptionsMessage={() => 'No results found'}
                      loadOptions={getcandidatelist}
                    />
                  </div>
                </div>
              </div>
              {(error.candidate || error.categoryMismatch) && (
                <div className="help-block">
                  {error.candidate
                    ? error.candidate
                    : error.categoryMismatch
                    ? error.categoryMismatch
                    : ''}
                </div>
              )}
              {/* //------------select other benefits ------------>>>>>>>.. */}
              {!IsEmployer && (
                <div className="row pl-2">
                  <div className=" flx-other-benefits-section  mt-0">
                    <label className="flx-job-posts-labels">
                      Other Benefits :
                    </label>
                    <ul className="flx-job-post-other-benefits">
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts?.is_break_time_pay === 1
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('paid_break')}
                        name="is_break_time_pay"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/paid-brk-colored.svg"
                          className="flx-other-benefits-icons"
                        />
                        Paid Break
                      </li>
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts?.pickup_and_dropoff === 1
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('pickup_and_dropoff')}
                        name="pickup_and_dropoff"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/flx-travel-reimb.svg"
                          className="flx-other-benefits-icons"
                        />
                        Pick-up & Drop-off
                      </li>
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts?.travel_reimbursement === 1
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('travel_reimbursement')}
                        name="travel_reimbursement"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/flx-other-benefits-cashback.svg"
                          className="flx-other-benefits-icons"
                        />
                        Travel Reimbursement
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div className="row">
                {!IsEmployer && (
                  <div className="form-group col-md-6 pr-0">
                    <label htmlFor="employerPay">
                      Employer Pay :{' '}
                      {/* <span className="assign-shift-label-star">*</span> */}
                    </label>
                    <div className="input-group">
                      <div className="input-group-addon flx-addon-currency-sign">
                        £
                      </div>
                      <input
                        type="text"
                        step="any"
                        className="form-control"
                        name="employer_to_pay"
                        id="employerPay"
                        placeholder="Price"
                        value={shifts.employer_to_pay}
                        // min={shifts.employer_to_pay}
                        onChange={handleChange}
                        onWheel={handleWheel}
                      />
                      <div className="input-group-addon flx-addon-total-pay">
                        Total{' '}
                        <span className="flx-tot-pay-in-assign-shift">
                          {shifts.employer_to_pay}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!IsEmployer && (
                  <div className="form-group col-md-6 pr-0">
                    <label htmlFor="candidatePay">
                      Candidate Pay :{' '}
                      {/* <span className="assign-shift-label-star">*</span> */}
                    </label>
                    <div className="input-group">
                      <div className="input-group-addon flx-addon-currency-sign">
                        £
                      </div>
                      <input
                        type="text"
                        step="any"
                        name="price"
                        className="form-control"
                        id="candidatePay"
                        placeholder="Price "
                        value={shifts.price}
                        // min={category.minimum_price}
                        onChange={handleChange}
                        onWheel={handleWheel}
                      />
                      <div className="input-group-addon flx-addon-total-pay">
                        Total{' '}
                        <span className="flx-tot-pay-in-assign-shift">
                          {shifts.price}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* //--------------select category before user=--------user(rgn)----------- */}
              {/* {!IsEmployer && !IsAdmin && !IsStaff && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Candidate Pay</label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="price"
                          disabled={IsEmployer}
                          placeholder="Price"
                          value={shifts.price}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!IsEmployer && !IsAdmin && !IsStaff && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Employer price</label>
                      <div className="form-group">
                        <input
                          onWheel={handleWheel}
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="employer_to_pay"
                          placeholder="Employer price "
                          disabled={IsEmployer}
                          value={shifts.employer_to_pay}
                          onChange={handleChange}
                        />
                        <small>
                          *include Employer NI,Holiday pay, Admin charge{' '}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {!IsAdmin && !IsStaff && (
                <div className="row">
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type" className="mr-2">
                        Break paid ?
                      </label>
                      <input
                        type="radio"
                        className="mr-2 ml-4"
                        name="is_break_time_pay"
                        placeholder="is_break_time_pay"
                        value={1}
                        checked={shifts.is_break_time_pay == 1}
                        onChange={handleChange}
                      />
                      <label className="mr-2 ">Yes</label>
                      <input
                        type="radio"
                        className="mr-2"
                        name="is_break_time_pay"
                        placeholder="is_break_time_pay"
                        value={0}
                        checked={shifts.is_break_time_pay == 0}
                        onChange={handleChange}
                      />
                      <label className="mr-2">No</label>
                    </div>
                  </div>
                </div>
              )}

              {false && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Break Time</label>

                      <div className="form-group">
                        <select
                          className="select col-md-12 selectDesign"
                          name="break_time"
                          value={shifts.break_time}
                          onChange={breaktimeChange}
                        >
                          <option selected={shifts.break_time == 0} value={0}>
                            {' '}
                            0 mins
                          </option>
                          <option selected={shifts.break_time == 15} value={15}>
                            {' '}
                            15 mins
                          </option>
                          <option selected={shifts.break_time == 20} value={20}>
                            20 mins
                          </option>
                          <option selected={shifts.break_time == 25} value={25}>
                            25 mins
                          </option>
                          <option selected={shifts.break_time == 30} value={30}>
                            30 mins
                          </option>
                          <option selected={shifts.break_time == 35} value={35}>
                            35 mins
                          </option>
                          <option selected={shifts.break_time == 40} value={40}>
                            40 mins
                          </option>
                          <option selected={shifts.break_time == 45} value={45}>
                            45 mins
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!IsAdmin && !IsStaff && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Break Time (mins) : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          onWheel={handleWheel}
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="break_time"
                          placeholder="Break Time "
                          value={shifts.break_time}
                          onChange={handleChange}
                        />
                        {submitted && !shifts.break_time && (
                          <div className="help-block">
                            Break Time is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="row">
                <div className="col-md-12 pr-1">
                  <small>
                    * If it is already checked in, you should go and edit
                    timesheet{' '}
                  </small>
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-12 pr-0">
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <div
                        className=" btn-primary-blue-border btn-save-assign-add-shift "
                        onClick={isDisabled ? undefined : handleSubmit}
                      >
                        <span
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: 1.25,
                          }}
                        >
                          Save
                        </span>
                        {props.requestStatus ===
                          reducerConstants.SHIFTS_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <span className="color-red">{error.common}</span>
          </div>
        </div>
      </div>

      {SectionAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setSectionAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <SectionAdd closeSectionAddModal={closeSectionAddModal} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerList = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      // body: JSON.stringify({ searchKey: inputValue,category:['Water purifier','Water treatment plan']}),
      body: JSON.stringify({ searchKey: inputValue, status: 1 }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            resolve(
              data.rows.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          }),
      )
      .catch(reject);
  });

const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            resolve(
              data.rows.map(
                ({
                  _id,
                  name,
                  morning_price,
                  night_price,
                  evening_price,
                  day_price,
                  weekend_price,
                  holiday_price,
                }) => ({
                  value: _id,
                  label: name,
                  morning_price: morning_price,
                  night_price: night_price,
                  evening_price: evening_price,
                  day_price: day_price,
                  weekend_price: weekend_price,
                  holiday_price: holiday_price,
                }),
              ),
            );
          }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return {
    requestStatus,
    shiftsData,
  };
}

const connectedShiftEdit = connect(mapStateToProps)(ShiftEdit);
export { connectedShiftEdit as ShiftEdit };
