/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {
  PriceActions,
  categoryActions,
  employerActions,
  sectionActions,
  shiftsActions,
} from '../_actions';
import { apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';
import { BusinessunitAdd } from '../BusinessUnit/BusinessunitAdd';
import AddJobRoleModal from '../EmployerSettings/EmployerSettingsContentComponents/AddJobRoleModal';
import AddNewBusinessUnitModal from '../EmployerSettings/EmployerSettingsContentComponents/AddNewBusinessUnitModal';
import { AddShiftTimeForm } from '../Job/AddShiftTimePopup';
import { SectionAdd } from '../Section';

let employer_id = '';
// eslint-disable-next-line no-unused-vars
const breaktime = '';

const ShiftsAdd = props => {
  const [shifts, setShifts] = useState({
    shift_start_from: '',
    dates: [],
    shift_start_to: '',
    check_in_time: '',
    check_out_time: '',
    shift_status: '',
    employer_id: '', //---carehome id--------
    business_unit: '',
    // check_in_mills: '',
    check_out_mills: '',
    isShift_multiple: false,
    from_time_string: '',
    to_time_string: '',
    from_date_string: '',
    to_date_string: '',
    // break_time: 0,
    section_type: '',
    employer_to_pay: '',
    price: '',
    benefits: [],
    shift_type: 1,
    user: [], //----this is candidates to is_break_paidehich shifts are allocated-----
  });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [sectionrows, setsectionrows] = useState([]);
  const [candidatepaydetails, setcandidatepaydetails] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [sectionData, setsectionData] = useState([]);
  const [IsEmployer, setIsEmployer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [IsBunit, setIsBunit] = useState(false);
  const [SectionAddModal, setSectionAddModal] = useState(false);
  const [BusinessunitAddModal, setBusinessunitAddModal] = useState(false);
  const [businessunitoptionsData, setbusinessunitoptionsData] = useState();
  const [error, setError] = useState({ common: '', categoryMismatch: '' });
  const [isDisabled, setisDisabled] = useState(false);
  const [IsParentEmployer, setIsParentEmployer] = useState(false);
  const [sectionDataInShifts, setSectionDataInShifts] = useState(false);
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showJobRoleModal, setShowJobRoleModal] = useState(false);
  const [showShiftTimeModal, setShowShiftTimeModal] = useState(false);
  const [candidates, setIsCandidates] = useState([]);
  const [isMatchingRoleCandidates, setIsMatchingroleCandidates] =
    useState(false);
  const [selectedCategory, setIsSelectedCategory] = useState();
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [userSuccessMessage, setUserSuccessMessage] = useState(false);
  const [allCandidates, setAllCandidates] = useState([]);
  const [displayedCandidates, setDisplayedCandidates] = useState([]);
  const [loadedCandidatesCount, setLoadedCandidatesCount] = useState(5);
  const [datePickerWidth, setDatePickerWidth] = useState(getInitialWidth());
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-CA');
  const formattedTime = currentDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });
  function getInitialWidth() {
    const width = window.innerWidth;
    if (width >= 1200) return '800px';
    if (width >= 768) return '500px';
    return '310px';
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 912) {
        setDatePickerWidth('849px');
      } else if (width >= 820) {
        setDatePickerWidth('686px');
      } else if (width >= 768) {
        setDatePickerWidth('644px');
      } else if (width >= 430) {
        setDatePickerWidth('326px');
      } else if (width >= 375) {
        setDatePickerWidth('252px');
      } else if (width >= 320) {
        setDatePickerWidth('236px');
      } else {
        setDatePickerWidth('310px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //---get al category for listing in table -------------
  const getAllCategory = category_id => {
    props
      .dispatch(
        categoryActions.categoryList({
          _id: category_id,
          select: ['_id', 'name', 'minimum_employer_price', 'minimum_price'],
        }),
      )
      .then(
        res => {
          if (res && res.data.rows && res.data.rows.length > 0) {
            setCategory(res.data.rows[0]);
          }
        },
        () => {
          setIsLoading(false);
          setError({ common: 'Error ' });
        },
      );
  };
  useEffect(() => {
    getAllCategory(shifts.category);
    getemployerpay(shifts.section_type);
  }, [shifts.category, candidatepaydetails]);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.role && user.role == 4) {
        setIsEmployer(true);
        if (user.is_parent == 1) {
          setIsParentEmployer(true);
        }
      }
      if (user.role == 7) {
        setIsBunit(true);
      }
      if (user.role == 1) {
        setIsAdmin(true);
      }
      if (user.role == 6) {
        setIsStaff(true);
      }
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        getemployer(user.employer_id);
        getbusinessunit(user.employer_id);
        setsectionrows([]);
        getAllSection(user.employer_id);
      }
    }
    if (
      props &&
      props.listViewShiftAddDate &&
      props.listViewShiftAddDate.shiftdata &&
      props.listViewShiftAddDate.shiftdata._id
    ) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            value:
              props.listViewShiftAddDate.shiftdata.name +
              '  ' +
              props.listViewShiftAddDate.shiftdata.last_name +
              ' (' +
              props.listViewShiftAddDate.shiftdata.Category.name +
              ') ',
            _id: props.listViewShiftAddDate.shiftdata._id,
            candidate_id: props.listViewShiftAddDate.shiftdata._id,
            user_id: props.listViewShiftAddDate.shiftdata.user_id,
          },
        ],
        from_date_string: props.listViewShiftAddDate.date,
        dates: [props.listViewShiftAddDate.date],
        shift_start_from: props.listViewShiftAddDate.date,
        // check_in_mills: new Date(props.listViewShiftAddDate.date).getTime(),
      }));
    }

    //-----------FOR SHIFT ADD THROUGH 30 DAYS CALENDAR VIEW------@harshad---date is send via "props.daymonthyear"
    if (props.daymonthyear && props.daymonthyear.day) {
      setShifts({
        ...shifts,
        shift_start_from: props.daymonthyear.index,
        dates: [props.daymonthyear.index],
      });
    }
    //-------if shift is being added by carehome set carehome id / employer_id from localstorage else select from asyn
    if (IsEmployer) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        setShifts(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
          employer_name: user.name,
        }));
      }
    }
    if (props && props.id) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            _id: props.id,
            user_id: props.user_id,
            candidate_id: props.candidate_id,
          },
        ], //if admin add shifts---
      }));
    }
    //------------------get business unit---------------------
  }, [query]);

  useEffect(() => {
    // setsectionrows([]);
    getAllSection();
    if (selectedCategory) {
      getcandidatelist();
    }
  }, [shifts.employer_id, selectedCategory]);

  const getemployer = employer_id => {
    const { dispatch } = props;
    dispatch(employerActions.employerList({ _id: employer_id })).then(
      res => {
        setIsLoading(false);
        // setbreaktime(res.data.rows[0].break_time);
        setShifts(prevState => ({
          ...prevState,
          // break_time: res?.data?.rows[0]?.break_time,
          // is_break_time_pay: res?.data?.rows[0]?.pay_break == 1 ? 1 : 0,
          employer_id: res?.data?.rows[0]?._id,
        }));
        // setEmployer(res.data.rows[0]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //------------------get candiate---------------------
  const getcandidatelist = inputvalue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcandidatelist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          active_status: 1,
          isverified: 1,
          isAssignShiftCandidatesFlag: 1,
          category: selectedCategory,
          // other_categories: JSON.stringify(selectedCategory),
          other_categories: [selectedCategory],
          employer_id: employer_id,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            if (data && data.rows && data.rows.length > 0) {
              let candidatesData = data.rows.map(item => ({
                value: item['_id'],
                label:
                  item['name'] +
                  ' ' +
                  (item['last_name'] ? item['last_name'] : ' ') +
                  ' ' +
                  '(' +
                  (item['Category.name'] ? item['Category.name'] : ' ') +
                  ' ' +
                  (item['city'] ? '-' + item['city'] : ' ') +
                  ')',
                user_id: item['user_id'],
                candidate_mail: item['email'],
                candidate_name: item['name'] + ' ' + item['last_name'],
                category: item['Category._id'],
                weekend_day_price: item['weekend_day_price'],
                weekend_night_price: item['weekend_night_price'],
                morning_price: item['morning_price'],
                day_price: item['day_price'],
                night_price: item['night_price'],
                evening_price: item['evening_price'],
                profile_image: item['profileimage'],
              }));
              resolve(candidatesData);
              setIsCandidates(candidatesData);
            } else {
              // reject([]);
            }
          }),
        )
        .catch(reject);
    });
  const handleCheckboxChange = () => {
    setIsMatchingroleCandidates(prevState => !prevState);
  };
  // Function to load initial candidates
  useEffect(() => {
    loadInitialCandidates();
  }, []);

  const loadInitialCandidates = async () => {
    const candidates = await getcandidatelist();
    setAllCandidates(candidates);
    setDisplayedCandidates(candidates.slice(0, 5));
  };

  useEffect(() => {
    let FilteredCandidates = [];

    if (selectedShift == 2 || selectedShift == 3 || IsEmployer) {
      FilteredCandidates = candidates.filter(
        candidate => candidate.category === selectedCategory,
      );
    } else if (isMatchingRoleCandidates) {
      FilteredCandidates = candidates.filter(
        candidate => candidate.category === selectedCategory,
      );
    } else {
      FilteredCandidates = candidates.filter(
        candidate =>
          candidate.morning_price < shifts.employer_to_pay &&
          candidate.category === selectedCategory,
      );
    }
    setFilteredCandidates(FilteredCandidates);
    setDisplayedCandidates(FilteredCandidates.slice(0, 5));
  }, [
    shifts.employer_to_pay,
    selectedShift,
    isMatchingRoleCandidates,
    candidates,
  ]);

  // Custom Option Component to display profile image
  const CustomOption = props => {
    const priceDifference = props?.data?.morning_price - shifts.employer_to_pay;
    return (
      <div {...props.innerProps} className="assign-shift-custom-option">
        <div>
          <img
            src={
              props?.data?.profile_image
                ? apiConstants.imgUrl + props?.data?.profile_image
                : '/assets/img/demo-img-prev-worked-candidate.png'
            }
            alt="Profile"
            className="candidate-profile-image"
          />
          <span>{props.data.label}</span>
        </div>
        {isMatchingRoleCandidates && (
          <div>
            {priceDifference > 0 && (
              <span className="candidate-price-diff">
                + £{priceDifference} /hour
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  const CustomMenuList = ({ children, onLoadMore }) => {
    return (
      <div>
        <div>{children}</div>
        {displayedCandidates.length < filteredCandidates.length && (
          <div className="shift-view-more-btn" onClick={onLoadMore}>
            View More
          </div>
        )}
      </div>
    );
  };

  const loadMoreCandidates = () => {
    const newCount = loadedCandidatesCount + 5;
    setDisplayedCandidates(filteredCandidates.slice(0, newCount));
    setLoadedCandidatesCount(newCount);
  };

  //------------------get business unit---------------------
  const getbusinessunit = employer_id => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          select: ['_id', 'name'],
        }),
      }).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            // setbusinessunitData(data.rows);
            let businessunit_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              businessunit_rows.push(
                <option value={data.rows[s]._id} key={data.rows[s]._id}>
                  {data.rows[s].name}
                </option>,
              );
            }
            setbusinessunitoptionsData(businessunit_rows);
            setShifts(prevState => ({
              ...prevState,
              bunit_name: data?.rows[0]?.name,
              bunit_address: data?.rows[0]?.address,
            }));
          }
        }),
      );
    }
  };
  //--------get date in string format--------
  const timeparse = datestr => {
    let timesplitted = datestr.split('T');
    return timesplitted[1];
  };

  //---------get date in string format =----------
  const dateparse = datestr => {
    let datesplitted = datestr.split('T');
    return datesplitted[0];
  };
  //---get all section when carehome is selected--------
  const getAllSection = () => {
    setIsLoading(true);
    const { dispatch } = props;
    if (shifts && shifts.employer_id && shifts.employer_id != 0) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employer_id) {
          shifts.employer_id = user.employer_id;
        }
      }
      dispatch(
        sectionActions.sectionList({
          employer_id: shifts.employer_id,
          status: 1,
        }),
      ).then(
        res => {
          let sectionlist = [];
          let shiftListWithoutHtml = [];
          setIsLoading(false);
          // let sectionData = res.data.rows;
          setsectionData(res.data.rows);
          for (let i = 0; i < res?.data?.rows?.length; i++) {
            sectionlist.push(
              <option
                key={res?.data?.rows?.[i].section_type}
                value={res?.data?.rows[i]?._id}
              >
                {res.data.rows[i].name}{' '}
                {'(' +
                  res.data.rows[i].from_time +
                  '-' +
                  res.data.rows[i].to_time +
                  ')'}
              </option>,
            );
            shiftListWithoutHtml.push(
              ' (' +
                res.data.rows[i].from_time +
                '-' +
                res.data.rows[i].to_time +
                ')',
            );
          }
          setSectionDataInShifts(shiftListWithoutHtml);
          setShifts(prevState => ({
            ...prevState,
            section_type: res?.data?.rows?.[0]?.section_type,
            to_time_string: res?.data?.rows?.[0]?.to_time,
            from_time_string: res?.data?.rows?.[0]?.from_time,
            check_in_time: res?.data?.rows?.[0]?.from_time,
            check_out_time: res?.data?.rows?.[0]?.to_time,
          }));
          setsectionrows(sectionlist);
          getemployerpay(res?.data?.rows?.[0]?.section_type);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };
  const getemployerpay = sectiontype => {
    const { dispatch } = props;
    if (
      shifts.employer_id &&
      (candidatepaydetails.category || shifts.category) &&
      sectiontype
    ) {
      let details = {};
      details.employer_id = shifts.employer_id;
      (details.category = shifts.category
        ? shifts.category
        : candidatepaydetails.category
        ? candidatepaydetails.category
        : ''),
        // details.section_type=sectiontype
        dispatch(PriceActions.PriceList(details)).then(
          res => {
            setIsLoading(false);
            let employerpay = 0;
            let candiadtepay = 0;
            if (res?.data?.rows.length !== 0) {
              if (sectiontype == 'Night') {
                employerpay = res.data.rows[0].night_price
                  ? res?.data?.rows[0].night_price
                  : res.data.rows[0].night_price;
                candiadtepay = candidatepaydetails.night_price
                  ? candidatepaydetails.night_price
                  : props?.listViewShiftAddDate?.shiftdata?.Category
                      ?.night_price;
              } else if (sectiontype == 'Morning') {
                employerpay = res.data.rows[0].morning_price
                  ? res.data.rows[0].morning_price
                  : res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.morning_price
                  ? candidatepaydetails.morning_price
                  : props?.listViewShiftAddDate?.shiftdata?.Category
                      ?.morning_price;
              } else if (sectiontype == 'Day') {
                employerpay = res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.day_price;
              } else if (sectiontype == 'Evening') {
                employerpay = res.data.rows[0].evening_price
                  ? res.data.rows[0].evening_price
                  : res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.evening_price
                  ? candidatepaydetails.evening_price
                  : candidatepaydetails.day_price;
              } else {
                employerpay = res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.day_price;
              }
              setShifts(prevState => ({
                ...prevState,
                employer_to_pay: employerpay,
                price: candiadtepay,
              }));
            }
          },
          () => {
            setIsLoading(false);
          },
        );
    }
  };

  const handleChange = event => {
    let timeDifference;
    let { name, value } = event.target;
    if (name == 'shift_start_from') {
      // let d = new Date(value);
      // let newvalue = d.getTime();
      // --to get time seperated from date string-----
      // let time_string = timeparse(value);
      // let date_string = dateparse(value);
      setShifts({
        ...shifts,
        // check_in_mills: newvalue,
        [name]: value,
      });
    } else if (name == 'shift_start_to') {
      let d = new Date(value);
      let newvalue = d.getTime();
      //---------check if the checkindate is less than checkout date in mills--
      if (newvalue < new Date(shifts.check_in_time).getTime()) {
        setError({ common: 'choose a valid date' });
      } else {
        //---to get time seperated from date string-----
        let time_string = timeparse(value);
        let date_string = dateparse(value);
        setShifts({
          ...shifts,
          to_time_string: time_string,
          to_date_string: date_string,
          check_out_mills: newvalue,
          [name]: value,
        });
      }
    } else if (name == 'price' || name == 'employer_to_pay') {
      const regex = /^(?!\.)^(?!.*[+e])[\d\s]{0,3}(\.\d{0,2})?$/;
      const sanitizedValue = regex.test(value) ? value : '';
      if (sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: sanitizedValue,
        }));
      } else if (value === '') {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        // If sanitizedValue is empty or null, replace letters in value before setting
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
      }
    } else if (name == 'break_time') {
      const regex = /^(?!.*[+e])[\d\s]{0,3}$/;
      const sanitizedValue = regex.test(value) ? value : '';
      if (value === '' || sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
      }
    } else if (name == 'section_type') {
      setError({ currentDateWithPastTime: '' });
      let check_in_time = '';
      let check_out_time = '';
      let selected_section = '';
      let selected_section_type = '';
      for (let s = 0; sectionData.length && s < sectionData.length; s++) {
        if (value == sectionData[s]._id) {
          selected_section = sectionData[s];
          selected_section_type = sectionData[s].section_type;
          check_in_time = sectionData[s].from_time;
          check_out_time = sectionData[s].to_time;
        }
      }
      // Calculate the time difference between check_in_time and check_out_time
      timeDifference = miscService.calculateTimeDifference(
        check_in_time,
        check_out_time,
      );
      if (shifts.dates[0] === formattedDate && check_in_time <= formattedTime) {
        setError({
          currentDateWithPastTime:
            'You cannot assign the shift with the current date with past time.',
        });
        return;
      } else {
        setError({ currentDateWithPastTime: '' });
        setShifts({
          ...shifts,
          section_id: value,
          to_time_string: selected_section.to_time,
          from_time_string: selected_section.from_time,
          check_in_time: selected_section.from_time,
          check_out_time: selected_section.to_time,
          section_type: selected_section_type,
          timeDifference: timeDifference,
        });
      }

      getemployerpay(selected_section_type);
    } else if (name == 'business_unit') {
      const select = event.target;
      const bunitname = select.options[select.selectedIndex].text;

      setShifts(prevState => ({
        ...prevState,
        [name]: value,
        bunit_name: bunitname,
      }));
    } else {
      setShifts(prevState => ({ ...prevState, [name]: value }));
    }
  };

  //--------multipel date picker handle change ---------------
  const dateshandleChange = value => {
    let temp_dates = [];
    for (let d = 0; d < value.length; d++) {
      temp_dates.push(value[d].format('YYYY-MM-DD'));
    }
    setShifts({
      ...shifts,
      dates: temp_dates,
    });
  };

  const selecteduser = selectedOption => {
    // let name = event.name;
    if (!selectedOption || selectedOption.length === 0) {
      setSelectedCandidates([]);
      // Handle the case when no option is selected
      setShifts(prevState => ({
        ...prevState,
        user: [],
      }));
      setcandidatepaydetails({
        category: '',
      });
      setError({ common: '' });
      return;
    }
    // const isMatch = selectedOption.every(option =>
    //   option.label.includes(shifts.category_name),
    // );
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      let obj = {
        value: selectedOption[i].label,
        _id: selectedOption[i].value,
        candidate_id: selectedOption[i].value,
        user_id: selectedOption[i].user_id,
        candidate_mail: selectedOption[i].candidate_mail,
        candidate_name: selectedOption[i].candidate_name,
        morning_price: selectedOption[i].morning_price,
      };
      setcandidatepaydetails({
        category: selectedOption[i].category,
        _id: selectedOption[i].value,
        weekend_day_price: selectedOption[i].weekend_day_price,
        weekend_night_price: selectedOption[i].weekend_night_price,
        morning_price: selectedOption[i].morning_price,
        day_price: selectedOption[i].day_price,
        night_price: selectedOption[i].night_price,
        evening_price: selectedOption[i].evening_price,
      });
      SelectedValues.push(obj);
    }
    setError({ categoryMismatch: '' });
    setSelectedCandidates(selectedOption);
    setShifts(prevState => ({
      ...prevState,
      user: SelectedValues,
    }));
    // if (isMatch) {
    //   setShifts(prevState => ({
    //     ...prevState,
    //     user: SelectedValues,
    //   }));
    //   setError({});
    // } else {
    //   setError({
    //     categoryMismatch:
    //       'Please select a candidate with the same role as the role to be allocated',
    //   });
    // }
  };

  const handleWheel = event => {
    event.target.blur();
  };
  const handleSubmit = event => {
    //------------TO AVOUD MULTIPLE BUTTON CLICK ------------
    setError({ common: '' });
    event.preventDefault();
    setSubmitted(true);
    setisDisabled(true);
    const { dispatch } = props;
    if (
      (shifts.shift_start_from || shifts.dates.length) &&
      shifts.check_in_time &&
      shifts.check_out_time &&
      shifts.section_id &&
      shifts.category &&
      shifts.business_unit
    ) {
      if (shifts.shift_start_from && shifts.shift_start_to) {
        if (shifts.shift_start_from >= shifts.shift_start_to) {
          setError({ common: 'Choose a valid date' });
          setisDisabled(false);
          return;
        }
      }
      if (shifts.user.length < 1) {
        setError({ candidate: 'Candidate is required' });
        setisDisabled(false);
        return;
      }
      // const isShadowOrSleepShift = ['2', '3'].includes(selectedShift);
      // if (!isShadowOrSleepShift) {
      //   if (
      //     shifts.price &&
      //     shifts.price < category.minimum_price &&
      //     selectedCandidates.length <= 1
      //   ) {
      //     setError({
      //       common:
      //         'Price should be greater than minimum price of category' +
      //         ' ' +
      //         category.minimum_price,
      //     });
      //     setisDisabled(false);
      //     return;
      //   } else {
      //     if (shifts.price && shifts.price < category.minimum_price) {
      //       // shifts.price = 11.44;
      //       alert(
      //         'if multiple candidate price is less than minimum price 11.44',
      //       );
      //     }
      //   }
      // }

      // if (shifts.timeDifference >= 6 && shifts.break_time < 20) {
      //   setError({
      //     common: 'The minimum break time should be 20 minutes or longer.',
      //   });
      //   setisDisabled(false);
      //   return;
      // }
      if (
        shifts.dates[0] === formattedDate &&
        shifts.check_in_time <= formattedTime
      ) {
        setError({
          currentDateWithPastTime:
            'You cannot assign the shift with the current date and time plus one hour.',
        });
        setisDisabled(false);
        return;
      }

      if (shifts.break_time && shifts.break_time >= 180) {
        setError({
          common: 'Break time should be less than 180 minutes ',
        });
        setisDisabled(false);
        return;
      }
      // if (!isShadowOrSleepShift) {
      //   if (shifts.employer_to_pay && shifts.employer_to_pay < 10.42) {
      //     setError({
      //       common:
      //         'Employer pay should be greater than minimum price of 10.42',
      //     });
      //     setisDisabled(false);
      //     return;
      //   }
      //   if (
      //     shifts.employer_to_pay &&
      //     shifts.employer_to_pay < category.minimum_employer_price
      //   ) {
      //     setError({
      //       common:
      //         'Employer pay should be greater than minimum price of category ' +
      //         ' ' +
      //         category.minimum_employer_price,
      //     });
      //     setisDisabled(false);
      //     return;
      //   }
      // }
      setIsLoading(true);
      dispatch(shiftsActions.shiftsAdd(shifts)).then(
        () => {
          props.showAddshiftmodal(false);
          props?.callBackBusinessunits();
          setSubmitted(false);
          setIsLoading(false);
          // if (!props.listViewShiftsAdd) {
          //   window.location.reload();
          // }
          setisDisabled(false);
        },
        err => {
          setError({ common: err });
          setSubmitted(false);
          setisDisabled(false);
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'Please complete the form' });
      setisDisabled(false);
    }
  };

  const selectedemployer = (selectedOption, event) => {
    shifts.check_in_time = '';
    shifts.check_out_time = '';
    let name = event.name;
    setShifts(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    // setbreaktime(selectedOption.break_time);
    setShifts(prevState => ({
      ...prevState,
      employer_name: selectedOption.company_name + ' ' + selectedOption.city,
      break_time: selectedOption.break_time,
      // is_break_time_pay: selectedOption.pay_break,
    }));
    // setEmployer(selectedOption);
    getbusinessunit(selectedOption.value);
    getemployer(selectedOption.value);
  };

  ///----category select change -----------
  const selectedcategory = selectedOption => {
    setIsSelectedCategory(selectedOption.value);
    // let name = event.name;
    setShifts(prevState => ({
      ...prevState,
      category: selectedOption.value,
      category_name: selectedOption.label,
    }));

    const isMatch = shifts?.user?.every(option =>
      option?.value?.includes(selectedOption?.label),
    );
    if (!isMatch) {
      setShifts(prevState => ({
        ...prevState,
        user: [],
      }));
      setSelectedCandidates([]);
      setIsMatchingroleCandidates(false);
      // setError({
      //   categoryMismatch:
      //     'Please select a candidate with the same role as the role to be allocated',
      // });
    } else {
      setError({});
    }
    if (props.listViewShiftAddDate && props.listViewShiftAddDate.shiftdata) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            value:
              props.listViewShiftAddDate.shiftdata.name +
              '  ' +
              props.listViewShiftAddDate.shiftdata.last_name +
              ' (' +
              props.listViewShiftAddDate.shiftdata.Category.name +
              ') ',
            _id: props.listViewShiftAddDate.shiftdata._id,
            candidate_id: props.listViewShiftAddDate.shiftdata._id,
            user_id: props.listViewShiftAddDate.shiftdata.user_id,
          },
        ],
      }));
    }
    if (shifts.section_type) {
      getemployerpay(shifts.section_type);
    }
  };

  const closeSectionAddModal = value => {
    setSectionAddModal(value);
    setsectionrows([]);
    getAllSection();
  };

  const closeBusinessunitAddModal = value => {
    setBusinessunitAddModal(value);
    getbusinessunit(shifts.employer_id);
  };

  const toggleBenefit = benefit => {
    const updatedBenefits = shifts.benefits.includes(benefit)
      ? shifts.benefits.filter(item => item != benefit)
      : [...shifts.benefits, benefit];
    setShifts({
      ...shifts,
      benefits: updatedBenefits,
    });
  };

  const handleShiftChange = event => {
    const selectedShiftValue = event.target.value;
    setSelectedShift(selectedShiftValue);
    setShifts(prev => ({
      ...prev,
      shift_type: selectedShiftValue,
    }));
  };

  const handleBenefitChange = event => {
    const selectedBenefitValue = event.target.value;
    setSelectedShift(selectedBenefitValue);
    setShifts(prev => ({
      ...prev,
      benefits: [selectedBenefitValue],
    }));
    if (submitted) {
      setSubmitted(false);
    }
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #acb5bc',
      fontWeight: 'bold',
      // background: '#f1f2f4',
      background: '#FFFFFF',
      borderRadius: '6px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#000000',
      padding: '2px',
      width: '20px',
      height: '30px',
    }),
  };

  const customStylesss = {
    control: provided => ({
      ...provided,
      border: '1px solid #acb5bc',
      fontWeight: 'bold',
      background: '#FFFFFF',
      borderRadius: '6px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#000000',
      padding: '2px',
      width: '20px',
      height: '30px',
    }),
    valueContainer: (provided, state) => {
      const length = state.getValue().length;
      return {
        ...provided,
        '::before': {
          content:
            selectedCandidates?.length > 0 ? `"${length} selected"` : `" "`,
          color: 'black',
        },
      };
    },
    multiValue: () => ({ display: 'none' }),
    // Add scrollable dropdown
    menu: provided => ({
      ...provided,
      maxHeight: '150px',
      overflowY: 'auto',
    }),

    menuList: provided => ({
      ...provided,
      maxHeight: '150px',
      overflowY: 'auto',
    }),
  };

  const handleUserSuccess = props => {
    setShifts(prevState => ({
      ...prevState,
      business_unit: props._id,
    }));
    setUserSuccessMessage(true);
    setTimeout(() => {
      setUserSuccessMessage(false);
      getbusinessunit(shifts.employer_id);
    }, 5000);
  };

  const handleJobRoleSuccess = async newJobRoleData => {
    // Fetch the updated category list
    const updatedCategories = await getcategorylist();

    const matchingCategory = updatedCategories.find(
      category => category.value === newJobRoleData?.job_role_id,
    );
    setShifts(prevState => ({
      ...prevState,
      category: matchingCategory.value,
      category_name: matchingCategory.label,
    }));
    setUserSuccessMessage(true);
    setTimeout(() => {
      setUserSuccessMessage(false);
      getcategorylist();
    }, 5000);
  };

  const handleShiftTimeSuccess = props => {
    let check_in_time = '';
    let check_out_time = '';
    let selected_section = '';
    let selected_section_type = '';

    for (let s = 0; sectionData.length && s < sectionData.length; s++) {
      if (props === sectionData[s]._id) {
        selected_section = sectionData[s];
        selected_section_type = sectionData[s].section_type;
        check_in_time = sectionData[s].from_time;
        check_out_time = sectionData[s].to_time;
      }
    }

    const timeDifference = miscService.calculateTimeDifference(
      check_in_time,
      check_out_time,
    );

    setShifts(prevShifts => ({
      ...prevShifts,
      section_id: props._id,
      to_time_string: selected_section.to_time,
      from_time_string: selected_section.from_time,
      check_in_time: selected_section.from_time,
      check_out_time: selected_section.to_time,
      section_type: selected_section_type,
      timeDifference: timeDifference,
    }));

    getemployerpay(selected_section_type);
    setUserSuccessMessage(true);
    setTimeout(() => {
      setUserSuccessMessage(false);
      getAllSection();
    }, 5000);
  };

  const handleCloseModal = () => {
    setShowShiftTimeModal(false);
  };
  return (
    <div className="ShiftsAdd d-flex justify-content-center">
      <div className="col-md-11 col-sm-6 p-0">
        <div className="card m-0">
          <div className=" page-header ">
            <div className="page-title-content">
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h4 className="card-title mt-0 ml-3">
                    {!props.allocateshift
                      ? 'Assign New Shift'
                      : 'Allocate Shift'}{' '}
                  </h4>
                </div>
                {props.daymonthyear &&
                  props.daymonthyear.day &&
                  props.allocateshift && (
                    <div className="col d-flex align-items-center">
                      <div className="date-div">
                        {moment(props.daymonthyear.index, 'YYYY-MM-DD').format(
                          'DD MMM YYYY',
                        )}
                      </div>
                    </div>
                  )}
                {isLoading && <span></span>}
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              {props.listViewShiftAddDate &&
                props.listViewShiftAddDate.date && (
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      {' '}
                      <div className="date-div">
                        {moment(
                          props.listViewShiftAddDate.date,
                          'YYYY-MM-DD',
                        ).format('DD MMM YYYY')}
                      </div>
                    </div>
                  </div>
                )}
              {props.listViewShiftAddDate &&
                props.listViewShiftAddDate.shiftdata &&
                props.listViewShiftAddDate.shiftdata.name && (
                  <div className="col ">
                    <div className="row d-flex align-items-center mt-2  ">
                      <div className="">
                        <img
                          src="/assets/img/recsy-fav-icon.png"
                          className=" round-emp-image"
                        ></img>
                      </div>
                      <div style={{ fontWeight: '700' }}>
                        {props.listViewShiftAddDate.shiftdata.name +
                          '  ' +
                          props.listViewShiftAddDate.shiftdata.last_name +
                          ' (' +
                          props.listViewShiftAddDate.shiftdata.Category.name +
                          ') '}
                      </div>
                    </div>
                  </div>
                )}

              {/* //-----------dates for multiple date picker range ----------------- */}
              {!props.allocateshift && !props.listViewShiftAddDate && (
                <div className="row">
                  <div className="col-md-12 ml-1">
                    <div className="row">
                      {/* Label in the first row */}
                      <div className="col-md-12">
                        <label htmlFor="type" className="mb-2 mr-2">
                          Choose Date :{' '}
                          <span className="assign-shift-label-star">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      {/* Date input in the second row */}
                      <div className="col-md-12 pr-0">
                        <div
                          className={
                            'form-group datepicker-container' +
                            (submitted && !shifts.shift_start_from
                              ? ' has-error'
                              : '')
                          }
                        >
                          <div
                            className=""
                            style={{
                              position: 'relative',
                              // display: 'inline-block',
                              marginLeft: '2px',
                            }}
                          >
                            <DatePicker
                              multiple
                              data-cy="shift-date"
                              className="date-picker-assign-shift-input"
                              style={{
                                height: '40px',
                                borderRadius: '6px',
                                fontSize: '14px',
                                padding: '4px 40px 4px 10px',
                                background: '#FFFFFF',
                                border: '1px solid #acb5bc',
                                width: datePickerWidth,
                              }}
                              placeholder="Select..."
                              format="DD-MM-YYYY"
                              minDate={new Date()}
                              onChange={dateshandleChange}
                            />
                            <i
                              className="fa fa-calendar pr-2"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                color: 'black',
                                pointerEvents: 'none',
                              }}
                            ></i>
                          </div>
                          {submitted &&
                            !shifts.shift_start_from &&
                            !shifts.dates.length && (
                              <div className="help-block">Date is required</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!IsBunit && !IsEmployer && (
                // !IsEmployer && // it hide for employer add shift to child organization
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        {IsParentEmployer
                          ? 'Organization (Select if shift is for organization)'
                          : 'Employer'}{' '}
                        : <span className="assign-shift-label-star">*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !shifts.employer_id ? ' has-error' : '')
                        }
                      >
                        <AsyncSelect
                          styles={customStyles}
                          name="employer_id"
                          onChange={selectedemployer}
                          placeholder="Select"
                          cacheOptions
                          defaultOptions
                          className="employerselect"
                          noOptionsMessage={() => 'No results found'}
                          loadOptions={getEmployerList}
                        />
                        {submitted && !shifts.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ------------ Business Unit ----------------- */}
              {!IsBunit && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Business Unit :{' '}
                        <span className="assign-shift-label-star">*</span>
                      </label>
                      {/* {shifts.employer_id && (
                        <i
                          onClick={() => {
                            setBusinessunitAddModal(true);
                          }}
                          className="fa fa-plus-circle"
                          style={{
                            fontSize: '20px',
                            color: ' #3388EB',
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                        ></i>
                      )} */}
                      {!IsEmployer && (
                        <button
                          type="button"
                          className="flx-add-location-popup-form1 d-flex align-items-center"
                          onClick={() => setShowModal(true)}
                        >
                          <span>
                            <i className="fas fa-plus flx-icon-plus flx-icon-plus1"></i>{' '}
                          </span>
                          <span>Create Business Unit</span>
                        </button>
                      )}

                      <div className="form-group">
                        <select
                          className="form-control select  col-md-12 select-business-unit selectDesn "
                          name="business_unit"
                          value={shifts.business_unit}
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            Select
                          </option>
                          {businessunitoptionsData}
                        </select>
                        {submitted && !shifts.business_unit && (
                          <div className="help-block">
                            {' '}
                            Business Unit is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* create business unit modal */}
              {showModal && (
                <div className="modal-overlay hide-scrollbar">
                  <div className="popup">
                    <AddNewBusinessUnitModal
                      employerId={shifts.employer_id}
                      setShowModal={setShowModal}
                      title="Add New Business Unit"
                      onSuccess={handleUserSuccess}
                      callBackBusinessunits={() =>
                        getbusinessunit(shifts.employer_id)
                      }
                    />
                  </div>
                </div>
              )}
              {userSuccessMessage && (
                <div className="alert alertboxCustomSuccess">
                  <span
                    className="alert-close"
                    onClick={() => setUserSuccessMessage(null)}
                  >
                    X
                  </span>
                  Successfully Created
                  <p></p>
                </div>
              )}

              {/* //-----------dates for multiple date picker range ----------------- */}
              {/* {!props.allocateshift && !props.listViewShiftAddDate && (
                <div className="row">
                  <div className="col-md-12 ml-1">
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <label htmlFor="type">
                          Choose date:{' '}
                          <span className="assign-shift-label-star">*</span>
                        </label>
                        <div
                          className={
                            'form-group datepicker-container' +
                            (submitted && !shifts.shift_start_from
                              ? ' has-error'
                              : '')
                          }
                          // style={{
                          //   position: 'relative',
                          //   width: '100%',
                          // }}
                        >
                          <DatePicker
                            multiple
                            data-cy="shift-date"
                            className="date-picker-assign-shift-input"
                            style={{
                              height: '40px',
                              borderRadius: '4px',
                              fontSize: '14px',
                              padding: '4px 10px',
                              width: datePickerWidth,
                              background: '#f1f2f4',
                              border: '1px solid #acb5bc',
                            }}
                            // value={Dates}
                            format="DD-MM-YYYY"
                            minDate={new Date()}
                            onChange={dateshandleChange}
                          />
                          <i className="fa fa-calendar assign-shift-calender-icon mr-1" />
                          {submitted &&
                            !shifts.shift_start_from &&
                            !shifts.dates.length && (
                              <div className="help-block">
                                {' '}
                                Date is required
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {/* //------------select category ------------>>>>>>>.. */}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <div className="assign-shift-add-job-role">
                        <div>
                          <label htmlFor="type">
                            Jobrole :{' '}
                            <span className="assign-shift-label-star">*</span>
                          </label>
                        </div>
                        {!IsEmployer && (
                          <div>
                            <button
                              type="button"
                              className="flx-add-location-popup-form1  d-flex align-items-center"
                              onClick={() => setShowJobRoleModal(true)}
                            >
                              <span>
                                <i className="fas fa-plus flx-icon-plus flx-icon-plus1"></i>{' '}
                              </span>
                              <span> Create Job Role</span>
                            </button>
                          </div>
                        )}
                      </div>

                      <AsyncSelect
                        styles={customStyles}
                        name="category"
                        placeholder="Select"
                        onChange={selectedcategory}
                        value={{
                          value: shifts.category,
                          label: shifts.category_name,
                        }}
                        cacheOptions
                        defaultOptions
                        className="categoryselect "
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getcategorylist}
                      />
                      {submitted && !shifts.category && (
                        <div className="help-block"> Job role is required</div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {showJobRoleModal && (
                <div className="modal-overlay hide-scrollbar">
                  <div className="popup">
                    <AddJobRoleModal
                      employerId={shifts.employer_id}
                      setShowModal={setShowJobRoleModal}
                      onSuccess={handleJobRoleSuccess}
                    />
                  </div>
                </div>
              )}
              {/* Type of shift - to be added  */}

              {!IsEmployer && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Type Of Shift :</label>

                      <div className="form-group">
                        <select
                          className="form-control select  col-md-12 select-shift-type selectDesign selectDesn "
                          id="typeOfShift"
                          value={shifts.shift_type}
                          onChange={handleShiftChange}
                          selected
                        >
                          <option name="regular" value={1}>
                            Regular
                          </option>
                          <option name="shadow" value={2}>
                            Shadow Shift
                          </option>
                          <option name="sleep" value={3}>
                            Sleep Shift
                          </option>
                        </select>
                        {submitted && !shifts.shift_type && (
                          <div className="help-block">
                            Shift Type is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedShift === '3' && (
                <div className="flx-notice-type-of-shift">
                  <i className="fa fa-info notice-flex-info-icon"></i>Note:
                  Payment is calculated for the entire shift duration, not on an
                  hourly basis. Please enter the total payment for the shift
                </div>
              )}

              {/* Type of shift - to be added ends */}

              <div className="row">
                <div className="col-md-12 pr-1 ">
                  <label htmlFor="type">
                    Shift Time :{' '}
                    <span className="assign-shift-label-star">*</span>
                  </label>
                  {/* {shifts.employer_id && (
                    <i
                      onClick={() => {
                        setSectionAddModal(true);
                      }}
                      className="fa fa-plus-circle"
                      style={{
                        fontSize: '20px',
                        color: ' #3388EB',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    ></i>
                  )} */}
                  {!IsEmployer && (
                    <button
                      type="button"
                      className="flx-add-location-popup-form1  d-flex align-items-center"
                      onClick={() => {
                        setShowShiftTimeModal(true);
                      }}
                    >
                      <span>
                        <i className="fas fa-plus flx-icon-plus flx-icon-plus1"></i>{' '}
                      </span>
                      <span>Create Shift</span>
                    </button>
                  )}

                  <div className={'form-group '}>
                    <select
                      className="select col-md-12  select-shift-time-assign-shift select-shift-time selectDesn "
                      name="section_type"
                      value={shifts.section_id}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {sectionrows}
                    </select>
                    {submitted && !shifts.section_id && (
                      <div className="help-block"> Section is required</div>
                    )}
                  </div>
                </div>
              </div>
              {error.currentDateWithPastTime && (
                <div className="help-block">
                  {error.currentDateWithPastTime}
                </div>
              )}

              {showShiftTimeModal && (
                <div className="request-modal-overlay">
                  <div className="popup">
                    <AddShiftTimeForm
                      employerId={shifts.employer_id}
                      setShowModal={setShowShiftTimeModal}
                      getAllSectionList={getAllSection}
                      handleCloseModal={handleCloseModal}
                      onSuccess={handleShiftTimeSuccess}
                      existingTimeList={sectionData}
                    />
                  </div>
                </div>
              )}

              {/* //--------select user ---------- */}
              {!IsEmployer &&
                !IsBunit &&
                !props.isHideuser &&
                props.isSelectUsershow && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Shift Allocated To :{' '}
                          <span className="assign-shift-label-star">*</span>
                        </label>
                        {selectedShift != 2 && selectedShift != 3 && (
                          <div className="mb-2 ml-4 d-flex align-items-center">
                            <input
                              className="form-check-input flx-comp-settings-form-control"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={handleCheckboxChange}
                              checked={isMatchingRoleCandidates}
                            />

                            <label
                              className="form-check-label candidate-info-labels mb-0 mt-1"
                              htmlFor="flexCheckDefault"
                            >
                              Show all candidates with matching roles
                            </label>
                          </div>
                        )}
                        <AsyncSelect
                          styles={customStylesss}
                          name="candidate_id"
                          onChange={selecteduser}
                          placeholder={'Select'}
                          cacheOptions
                          defaultOptions={displayedCandidates}
                          className="Shift_allocated_to"
                          isMulti
                          noOptionsMessage={() => 'No results found'}
                          loadOptions={getcandidatelist}
                          components={{
                            Option: CustomOption,
                            MultiValue: () => null,
                            MenuList: props => (
                              <CustomMenuList
                                {...props}
                                onLoadMore={loadMoreCandidates}
                              />
                            ),
                          }}
                          value={selectedCandidates}
                        />

                        <div className="profile-badge-container">
                          {selectedCandidates.map(candidate => {
                            const priceDifference =
                              candidate.morning_price - shifts.employer_to_pay;
                            return (
                              <div
                                className="profile-badge mt-4 ml-3"
                                key={candidate.value}
                              >
                                {priceDifference > 0 && (
                                  <div>
                                    <div className="rate-badge">
                                      + £{priceDifference} /hour
                                    </div>
                                    <div className="rate-badge1">
                                      <span>a</span>
                                    </div>
                                  </div>
                                )}

                                <img
                                  src={
                                    candidate?.profile_image
                                      ? apiConstants.imgUrl +
                                        candidate?.profile_image
                                      : '/assets/img/demo-img-prev-worked-candidate.png'
                                  }
                                  alt="Profile Picture"
                                  className="profile-picture"
                                />
                                <span className="profile-name">
                                  {candidate.candidate_name}
                                </span>
                                <span
                                  className="remove-icon"
                                  onClick={() =>
                                    setSelectedCandidates(
                                      shifts.user.filter(
                                        item => item.value !== candidate.value,
                                      ),
                                    )
                                  }
                                >
                                  &times;
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        {(submitted && !candidatepaydetails.category) ||
                        error.categoryMismatch ? (
                          <div className="help-block">
                            {submitted && !candidatepaydetails.category
                              ? 'Candidate is required'
                              : error.categoryMismatch
                              ? error.categoryMismatch
                              : ''}
                          </div>
                        ) : null}
                        {error.candidate && submitted && (
                          <div className="help-block">{error.candidate}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {/* //---user multiple---- */}
              {(IsEmployer || IsBunit) &&
                (props.isSelectUsershow ||
                  !props.listViewShiftAddDate ||
                  props.isMultipleDays) &&
                !props.isHideuser && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Shift Allocated To :{' '}
                          <span className="assign-shift-label-star">*</span>
                        </label>
                        {selectedShift != 2 && selectedShift != 3 && (
                          <div className="mb-1 ml-4">
                            <input
                              className="form-check-input flx-comp-settings-form-control"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={handleCheckboxChange}
                              checked={isMatchingRoleCandidates}
                            />

                            <label
                              className="form-check-label candidate-info-labels"
                              htmlFor="flexCheckDefault"
                            >
                              Show all candidates with matching roles
                            </label>
                          </div>
                        )}
                        <AsyncSelect
                          styles={customStylesss}
                          name="candidate_id"
                          onChange={selecteduser}
                          placeholder={'Select'}
                          cacheOptions
                          defaultOptions={displayedCandidates}
                          className="Shift_allocated_to"
                          isMulti
                          noOptionsMessage={() => 'No results found'}
                          loadOptions={getcandidatelist}
                          components={{
                            Option: CustomOption,
                            MultiValue: () => null,
                            MenuList: props => (
                              <CustomMenuList
                                {...props}
                                onLoadMore={loadMoreCandidates}
                              />
                            ),
                          }}
                          value={selectedCandidates}
                        />

                        <div className="profile-badge-container">
                          {selectedCandidates.map(candidate => {
                            const priceDifference =
                              candidate.morning_price - shifts.employer_to_pay;
                            return (
                              <div
                                className="profile-badge mt-4 ml-3"
                                key={candidate.value}
                              >
                                {priceDifference > 0 && (
                                  <div>
                                    <div className="rate-badge">
                                      + £{priceDifference} /hour
                                    </div>
                                    <div className="rate-badge1">
                                      <span>a</span>
                                    </div>
                                  </div>
                                )}

                                <img
                                  src={
                                    candidate?.profile_image
                                      ? apiConstants.imgUrl +
                                        candidate?.profile_image
                                      : '/assets/img/demo-img-prev-worked-candidate.png'
                                  }
                                  alt="Profile Picture"
                                  className="profile-picture"
                                />
                                <span className="profile-name">
                                  {candidate.candidate_name}
                                </span>
                                <span
                                  className="remove-icon"
                                  onClick={() => {
                                    // Remove candidate from selectedCandidates
                                    setSelectedCandidates(prevSelected => {
                                      const updatedSelected =
                                        prevSelected.filter(
                                          item => item._id !== candidate._id,
                                        );
                                      return updatedSelected;
                                    });

                                    // Remove candidate from shifts.user
                                    setShifts(prevShifts => {
                                      const updatedUser =
                                        prevShifts.user.filter(
                                          item => item._id !== candidate.value,
                                        );
                                      return {
                                        ...prevShifts,
                                        user: updatedUser,
                                      };
                                    });
                                  }}
                                >
                                  &times;
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        {(submitted && !candidatepaydetails.category) ||
                        error.categoryMismatch ? (
                          <div className="help-block">
                            {submitted && !candidatepaydetails.category
                              ? 'Candidate is required'
                              : error.categoryMismatch
                              ? error.categoryMismatch
                              : ''}
                          </div>
                        ) : null}
                        {error.candidate && submitted && (
                          <div className="help-block">{error.candidate}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {/* --!!!!!!!!!!!!it comment for temporary after to do  */}
              {/* {!IsEmployer ||
                (IsParentEmployer && (
                  // !IsEmployer && // it hide for employer add shift to child organization
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        {IsParentEmployer && (
                          <label htmlFor="type">
                            Select employer :(select for organization)
                          </label>
                        )}
                        {!IsEmployer && (
                          <label htmlFor="type">
                            Select employer :(select for organization)
                          </label>
                        )}
                        <div
                          className={
                            'form-group ' +
                            (submitted && !shifts.employer_id
                              ? ' has-error'
                              : '')
                          }
                        >
                          <AsyncSelect
                            name="employer_id"
                            onChange={selectedemployer}
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerList}
                          />
                          {submitted && !shifts.employer_id && (
                            <div className="help-block">
                              Employer is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}

              {/*rate card holiday shift end*/}
              {!IsEmployer && (
                <div className="row pl-2">
                  <div className=" flx-other-benefits-section mt-0">
                    <label htmlFor="type">Other Benefits :</label>
                    <ul
                      className="flx-job-post-other-benefits"
                      style={{ marginBottom: '0px' }}
                    >
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts.benefits.includes('paid_break')
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('paid_break')}
                        name="is_break_time_pay"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/paid-brk-colored.svg"
                          className="flx-other-benefits-icons flx-other-benefits-paid-break"
                        />
                        Paid Break
                      </li>
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts.benefits.includes('pickup_and_drop-off')
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('pickup_and_drop-off')}
                        name="pickup_and_dropoff"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/flx-travel-reimb.svg"
                          className="flx-other-benefits-icons"
                        />
                        Pick-up & Drop-off
                      </li>
                      <li
                        className={`flx-job-post-other-benefits-li ${
                          shifts.benefits.includes('travel_reimbursement')
                            ? 'flx-job-post-other-benefits-li-active'
                            : ''
                        }`}
                        onClick={() => toggleBenefit('travel_reimbursement')}
                        name="travel_reimbursement"
                        onChange={handleBenefitChange}
                      >
                        <img
                          src="/assets/img/flx-other-benefits-cashback.svg"
                          className="flx-other-benefits-icons"
                        />
                        Travel Reimbursement
                      </li>
                    </ul>
                    {/* {submitted && !shifts.benefits.length && (
                      <div className="help-block"> Benefit is required</div>
                    )} */}
                  </div>
                </div>
              )}

              {/*rate card holiday shift end*/}
              {/* Employer pay & Candidate pay new design start*/}

              <div className="row">
                {!IsEmployer && !IsBunit && (
                  <div className="form-group col-md-6 pr-0">
                    <label htmlFor="employerPay">
                      Employer Pay :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-addon flx-addon-currency-sign">
                        £
                      </div>
                      <input
                        type="text"
                        step="any"
                        className="form-control"
                        name="employer_to_pay"
                        id="employerPay"
                        placeholder="Price"
                        value={shifts.employer_to_pay}
                        min={category.minimum_employer_price}
                        onChange={handleChange}
                        onWheel={handleWheel}
                      />
                      <div className="input-group-addon flx-addon-total-pay">
                        Total{' '}
                        <span className="flx-tot-pay-in-assign-shift">
                          {shifts.employer_to_pay}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {!IsEmployer && !IsBunit && (
                  <div className="form-group col-md-6 pr-0">
                    <label htmlFor="candidatePay">
                      Candidate Pay :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-addon flx-addon-currency-sign">
                        £
                      </div>
                      <input
                        type="text"
                        step="any"
                        name="price"
                        className="form-control"
                        id="candidatePay"
                        placeholder="Price "
                        value={shifts.price}
                        min={category.minimum_price}
                        onChange={handleChange}
                        onWheel={handleWheel}
                      />
                      <div className="input-group-addon flx-addon-total-pay">
                        Total{' '}
                        <span className="flx-tot-pay-in-assign-shift">
                          {shifts.price}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* Employer pay & Candidate pay new design ends*/}
              {/* {!IsEmployer && !IsBunit && (
                <div className="row hidden filters">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Candidate Pay : <span>*</span>{' '}
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="price"
                          min={category.minimum_price}
                          disabled={IsEmployer}
                          placeholder="Price"
                          value={shifts.price}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                        <small>
                          * Keep price blank or zero,It will add candidate
                          default price{' '}
                        </small>
                        {submitted && !shifts.price && (
                          <div className="help-block">
                            Candidate Pay is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!IsEmployer && !IsBunit && (
                <div className="row hidden filters">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Employer price : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="employer_to_pay"
                          placeholder="Employer price "
                          min={category.minimum_employer_price}
                          value={shifts.employer_to_pay}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                        <small>
                          *include Employer NI,Holiday pay, Admin charge{' '}
                        </small>
                        {submitted && !shifts.employer_to_pay && (
                          <div className="help-block">
                            Employer Price is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              <div className="row hidden-filters">
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-4'}>
                    <label htmlFor="type" className="mr-2">
                      Break paid ?
                    </label>
                    <input
                      type="radio"
                      className="mr-2 ml-4 "
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={1}
                      checked={shifts.is_break_time_pay == 1 ? true : undefined}
                      onChange={handleChange}
                    />
                    <label className="mr-2 break-time-pay-radio-button">
                      Yes
                    </label>
                    <input
                      type="radio"
                      className="mr-2 "
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={0}
                      checked={shifts.is_break_time_pay == 0 ? true : undefined}
                      onChange={handleChange}
                    />
                    <label className="mr-2">No</label>
                    {submitted &&
                      shifts.is_break_time_pay != 1 &&
                      shifts.is_break_time_pay != 0 && (
                        <div className="help-block">Break Paid is required</div>
                      )}
                    <label className="mr-2 break-time-pay-radio-button">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {/* //----------show only break timeis pay true------- */}
              {
                <div className="row hidden-filters">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Break Time (mins) : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="break_time"
                          placeholder="Break Time "
                          value={shifts.break_time}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                        {submitted && !shifts.break_time && (
                          <div className="help-block">
                            Break Time is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <div
                        className=" btn-primary-blue-border btn-save-assign-add-shift"
                        onClick={isDisabled ? undefined : handleSubmit}
                      >
                        <span
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: 1.25,
                          }}
                        >
                          {' '}
                          Save & Assign
                        </span>
                        {props.requestStatus ===
                          reducerConstants.SHIFTS_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <span className="color-red">{error.common}</span>
          </div>
        </div>
      </div>

      {SectionAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setSectionAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <SectionAdd
                        isAdd={true}
                        employer_id={shifts.employer_id}
                        closeSectionAddModal={closeSectionAddModal}
                        shiftRow={sectionDataInShifts}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {BusinessunitAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setBusinessunitAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <BusinessunitAdd
                        employer_id={shifts.employer_id}
                        closeBusinessunitAddModal={closeBusinessunitAddModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerList = inputValue =>
  new Promise((resolve, reject) => {
    let parent_id = '';
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id && user.role == 4) {
        parent_id = user.employer_id;
      }
    }
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        searchKey: inputValue,
        status: 1,
        active_status: 1,
        parent_id: parent_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(employer => ({
              value: employer._id,
              label: employer.company_name ? employer.company_name : 'Unknown',
              ...employer,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        select: [
          '_id',
          'name',
          'minimum_employer_price',
          'minimum_price',
          'morning_price',
          'night_price',
          'evening_price',
          'day_price',

          'holiday_price',
        ],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(
              ({
                _id,
                name,
                morning_price,
                night_price,
                evening_price,
                day_price,

                holiday_price,
              }) => ({
                value: _id,
                label: name,
                morning_price: morning_price,
                night_price: night_price,
                evening_price: evening_price,
                day_price: day_price,

                holiday_price: holiday_price,
              }),
            ),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedShiftsAdd = connect(mapStateToProps)(ShiftsAdd);
export { connectedShiftsAdd as ShiftsAdd };
