import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { agencyService } from '../_services';
import { LoadingIcon } from '../_components';

const AgencyWaitingPage = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  // Use useEffect to initialize the user state from localStorage
  useEffect(() => {
    const Agency = JSON.parse(localStorage.getItem('user'));

    if (Agency && Agency._id) {
      setUser({ _id: Agency.agencyId });
    }
  }, []);

  // Call getAllAgency only when user._id is set
  useEffect(() => {
    if (user._id || user.user_id) {
      getAllAgency();
    }
  }, [user]);

  useEffect(() => {
    // Add the event listener for the popstate event
    window.addEventListener('popstate', handlePopState);

    // Push a new state to history to intercept the back button
    window.history.pushState(null, document.title, window.location.href);

    // Reset history state to prevent navigating back to this page
    window.history.replaceState(null, document.title, window.location.href);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handlePopState = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  const getAllAgency = () => {
    setLoading(true);
    agencyService
      .agencyList(user)
      .then(res => {
        setLoading(false);
        if (res.data && res.data.rows[0].active_status === 1) {
          window.location.href = '/dashboardemployer';
        }
      })
      .catch(err => {
        setLoading(false);
        setError({
          common: err.message || 'An error occurred.',
        });
      });
  };

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            // background: 'rgba(133, 187, 255, 0.5)', // Light background for the page
          }}
        >
          <div
            style={{
              width: '50%',
              height: '40%',
              padding: '20px',
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <h2 style={{ fontFamily: 'italic', fontWeight: 'bold' }}>
              Please Wait
            </h2>
            <br />
            <p style={{ padding: '20px ', fontWeight: 'bold' }}>
              We are processing your data. We will contact you via email if
              additional information is required.
            </p>
            {loading && <LoadingIcon loadingText={"Loading..."}/>}
            <p>Please stay on this page while we process your request.</p>
          </div>
          <div className="mt-4">
            <span className="color-red">{error.common}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default AgencyWaitingPage;

function mapStateToProps() {
  return {};
}

const connectedAgencyWaitingPage = connect(mapStateToProps)(AgencyWaitingPage);
export { connectedAgencyWaitingPage as AgencyWaitingPage };
