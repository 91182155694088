import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notificationActions } from '../_actions';
import { LoadingIcon } from '../_components';

// eslint-disable-next-line no-unused-vars
let notificationRows = [];

const NotificationList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationRows, setNotifcationRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  // effect to call notifications
  useEffect(() => {
    getAllNotification(1);
  }, []);
  //------------get notiifcation data ----------
  const getAllNotification = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (props && props.iscarehome) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employer_id) {
          queryNew.employer_id = user.employer_id;
        }
      }
    }
    setIsLoading(true);
    const { dispatch } = props;
    // if (props && props.isAgency) {
    //   queryNew.type = 'vacancy add';
    // }
    dispatch(notificationActions.notificationList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //----------splitting time element to 2 and get time from datestring
  //example-----2022-08-10T 10:11 => 10:11 (output)----------
  const timeparse = datestr => {
    let timesplitted = datestr.split('T');
    return timesplitted[1].substr(0, 5);
  };
  //---------get date in string format =----------
  const dateparse = datestr => {
    let datesplitted = datestr.split('T');
    return datesplitted[0];
  };
  // set notification list and pagination
  const setListAndPagination = notificationData => {
    let notif_datesrow = [];
    let notificationList = [];

    for (
      let j = 0;
      notificationData &&
      notificationData.rows &&
      j < notificationData.rows.length;
      j++
    ) {
      let found = false;

      for (let k = 0; k < notif_datesrow.length; k++) {
        if (notif_datesrow[k] === notificationData.rows[j].date_string) {
          found = true;
          notificationList.push({
            message: notificationData.rows[j].notification_text,
            time: `${dateparse(notificationData.rows[j].updatedAt)} ${timeparse(
              notificationData.rows[j].updatedAt,
            )}`,
          });
        }
      }

      if (!found) {
        notificationList.push({
          message: notificationData.rows[j].notification_text,
          time: `${dateparse(notificationData.rows[j].updatedAt)} ${timeparse(
            notificationData.rows[j].updatedAt,
          )}`,
        });
      }
    }

    setNotifcationRows(notificationList); // Now storing an array of objects, not JSX elements
  };

  return (
    <div className="NotificationList fh100">
      <div className="col-md-12 fh100" style={{ background: '#f9f9f9' }}>
        {isLoading && <LoadingIcon></LoadingIcon>}
        <div className="notif-card">
          <div className="row dflex align-items-center ml-0 mr-0">
            {/* <div className=" bell_icon">
              <i className="fas fa-bell icon-22"></i>
            </div> */}
            <div className=" mt-1 notif-title">Notifications</div>
          </div>
          {/* <div className="dummy-view"></div> */}
          {/* <div className="notification-card">
            <div className="notifications-row">
              <div>
                <table className="table">
                  <thead></thead>
                  <tbody> {notificationRows} </tbody>
                </table>
              </div>
              {notificationRows.length == 0 && (
                <div className="d-flex justify-content-center text-secondary">
                  {' '}
                  No notifications
                </div>
              )}
            </div>
          </div> */}
          <div className="notifications-container ml-2 mr-2 ">
            {notificationRows.length > 0 ? (
              notificationRows.map((notification, index) => (
                <div key={index} className="notification-card ">
                  <div className="notification-content my-2">
                    <p className="notification-message mx-2 ">
                      {notification.message}
                    </p>
                    <div className="d-flex justify-content-end mx-2 mt-3 mb-1">
                      <span className="notification-time ">
                        {notification.time}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center text-secondary">
                No notifications
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedNotificationList = connect(mapStateToProps)(NotificationList);
export { connectedNotificationList as NotificationList };
