import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { messageConstant, roleConstant } from '../_constants';
import { agencyService, employerService } from '../_services';

const PhoneNumber = () => {
  const [error, setError] = useState({ common: '' });

  const [user, setUser] = useState({
    contact_number: '',
    company_name: '',
  });
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  // Use useEffect to initialize the user state from localStorage
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [submit]);

  const handleBeforeUnload = () => {
    if (submit === false) {
      localStorage.clear();
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to execute when component unmounts or navigates away
      setLoading(false);
    };
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;
    let numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    const errorCopy = { ...error }; // Create a copy of the error object to avoid direct mutation

    if (name === 'contact_number') {
      if (numericValue.charAt(0) === '0') {
        // Handle error for numbers starting with 0
        if (numericValue.length > 11) {
          return;
        }
        if (numericValue.length !== 11) {
          errorCopy.contact_number = messageConstant.VALID_PHONE_WITHOUT_ZERO;
          errorCopy.isError = true;
        } else {
          errorCopy.contact_number = ''; // Reset error if length is correct
        }
      } else {
        if (numericValue.length > 11) {
          return;
        }
      }
    }

    setUser(prevUser => ({
      ...prevUser,
      [name]: name === 'contact_number' ? numericValue : value,
    }));

    // Reset the specific error for the current field
    errorCopy[name] = '';
    setError(errorCopy);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (loading) return;

    error.isError = false;

    // Validate phone number
    const { contact_number } = user;
    if (contact_number) {
      const phoneNumberLength = contact_number.length;
      const firstChar = contact_number.charAt(0);

      if (firstChar === '0' && phoneNumberLength !== 11) {
        error.contact_number = messageConstant.VALID_PHONE_WITHOUT_ZERO;
        error.isError = true;
      } else if (firstChar !== '0' && phoneNumberLength !== 11) {
        error.contact_number = messageConstant.VALID_PHONE;
        error.isError = true;
      }
    }

    // Validate company name
    if (!user.company_name) {
      error.company_name = messageConstant.COMPANY_REQUIRED;
      error.isError = true;
    }

    if (error.isError) {
      setError({ ...error });
      return;
    } else {
      error.isError = { common: '' };
      setError({ ...error });
    }

    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem('user'));
    let res;
    try {
      setLoading(true);
      setSubmit(true);

      // Check if user is agency or employer
      if (
        userData.is_signup_completed === 0 ||
        userData.role === roleConstant.AGENCY
      ) {
        res = await agencyService.agencyAdd({
          contact_number: user.contact_number,
          _id: userData._id,
          company_name: user.company_name,
          role: userData.role,
          status: 1,
          is_signup_completed: userData.is_signup_completed,
        });
        if (res.error_code == 0) {
          window.location.href = '/agencywaitingpage';
        } else {
          window.location.href = '/login';
        }
      } else {
        res = await employerService.employerAdd({
          contact_number: user.contact_number,
          _id: userData.employer_id,
          company_name: user.company_name,
        });

        userData.company_name = res.data.company_name;
        localStorage.setItem('user', JSON.stringify(userData));

        if (res.error_code == 0) {
          window.location.href = '/dashboardemployer';
        } else {
          error.contact_number =
            res.message || messageConstant.PHONE_NOT_UPDATED;
          setError(error);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError({
        common: error.message || messageConstant.ERROR,
      });
    }
  };

  // // checking the contact number is valid
  // const valid_contact_number = phone_number => {
  //   phone_number = phone_number.toString().replace(/\s/g, '');
  //   // eslint-disable-next-line no-useless-escape
  //   let  regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  //   return regex.test(phone_number);
  // };

  return (
    <>
      <div className="container ">
        <div className="d-flex flex-row justify-content-center mt-5 mainphonenumber">
          <div className="col-md-5">
            <h2
              className="fw-bold"
              style={{
                fontWeight: '700',
              }}
            >
              Enter Your Phone Number & Company Name
            </h2>
            <h2
              className="fw-bold"
              style={{
                fontWeight: '700',
              }}
            >
              make it more faster
            </h2>
            <div
              className={
                'form-group ' + (error.company_name ? ' has-error' : '')
              }
            >
              <label htmlFor="company_name  " className="mt-4 ">
                Company Name <span className="help-block"> * </span>
              </label>
              <input
                type="text"
                // className="input-textarea"
                className="form-control  input-alnment phone-input"
                name="company_name"
                placeholder="Your company name"
                value={user.company_name}
                onChange={handleChange}
              />
              {error.company_name && (
                <div className="help-block">{error.company_name}</div>
              )}
            </div>

            <div
              className={
                'form-group' + (error.contact_number ? ' has-error' : '')
              }
            >
              <label htmlFor="contact_number">
                Phone Number
                {/* <span className="help-block"> * </span> */}
              </label>
              <div
                className="d-flex flex-row input-group input-wrap-with-btn "
                style={{ background: 'rgb(241, 242, 244)', border: 'none' }}
              >
                <span className="fw-bold m-auto border-right px-2">+44</span>
                <input
                  type="text"
                  step="any"
                  className="form-control border border-0 pl-1 "
                  placeholder="0999999999"
                  name="contact_number"
                  value={user.contact_number}
                  onChange={handleChange}
                />
              </div>
              {/* {user.contact_number && user.contact_number.length < 11 && (
              <div className="help-block">{messageConstant.VALID_PHONE}</div>
            )} */}
              {error.contact_number && (
                <div className="help-block">{error.contact_number}</div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-right">
              {/* <Link to="/dashboardemployer" className="send-btn ">
              Skip
            </Link> */}
              {/* ml-2 in the button classname */}
              <button onClick={handleSubmit} className={'send-btn  blue '}>
                {!loading ? 'Next' : 'Saving...'}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {showMessage && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgba(133, 187, 255, 0.5)' }}
        >
          <div className="modal-dialog" style={{ maxWidth: '50%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {' '}
                  Please stay on this page while we process your request.
                </h4>
              </div>
              <div className="modal-body">
                <p>{message}</p>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

function mapStateToProps() {
  return {};
}

const connectedPhoneNumber = connect(mapStateToProps)(PhoneNumber);
export { connectedPhoneNumber as PhoneNumber };
