import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { notificationService } from '../_services';
export const notificationActions = {
  // notificationAdd,
  notificationList,
  // notificationDelete,
  sendEmailNotificationSettings
};

// function notificationAdd(notificationObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.NOTIFICATION_REQUEST,
//         notificationObj,
//       });

//       notificationService.notificationAdd(notificationObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.NOTIFICATION_SUCCESS,
//             data,
//           });

//           if (data.error_code === 0) {
//             resolve(data.data);
//             dispatch(alertActions.success(data.message.toString()));
//           } else {
//             reject(data.data);

//             dispatch(alertActions.error(data.message.toString()));
//           }
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.NOTIFICATION_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function notificationList(notificationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.NOTIFICATION_REQUEST,
        notificationObj,
      });

      notificationService.notificationList(notificationObj).then(
        data => {
          dispatch({
            type: reducerConstants.NOTIFICATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.NOTIFICATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function notificationDelete(notificationObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.NOTIFICATION_REQUEST,
//         notificationObj,
//       });

//       notificationService.notificationDelete(notificationObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.NOTIFICATION_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.NOTIFICATION_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function sendEmailNotificationSettings(notificationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.NOTIFICATION_REQUEST,
        notificationObj,
      });

      notificationService.sendEmailNotificationSettings(notificationObj).then(
        data => {
          dispatch({
            type: reducerConstants.NOTIFICATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.NOTIFICATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
