import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let userRows = [];
const UserList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({ rows: [] });
  const [userRows, setUserRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllUser(1);
  }, []);

  /**
   * Get all users
   **/
  const getAllUser = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(userActions.userList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = userData => {
    let userList = [];
    for (
      let j = 0;
      userData && userData.rows && j < userData.rows.length;
      j = j + 1
    ) {
      userList.push(
        <tr key={j}>
          <td> {(userData.page - 1) * userData.limit + j + 1}</td>
          <td>{userData.rows[j].name} </td>
          <td className="eventCell  eventView">
            <a href={'/user/' + userData.rows[j]._id}>view </a>
          </td>
          <td className="eventCell  event Edit">
            <a href={'/useradd/' + userData.rows[j]._id}>Edit</a>
          </td>
        </tr>,
      );
    }
    setUserRows(userList);
    setUserData(userData);
  };
  return (
    <div className="UserList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <h4> User List</h4>
              </div>
              <div className="col-md-4">
                <a className="btn btn-primary float-right" href="/useradd/0">
                  <i className="fa fa-envelope" />
                  <span> Add User</span>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className="eventCell  eventView" />
                    <th className="eventCell  eventEdit" />
                  </tr>
                </thead>
                <tbody>{userRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            {userData &&
              userData.rows &&
              userData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllUser(data);
              }}
              paginationData={userData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {
    // requestStatus,
    // userData
  };
}
const connectedUserList = connect(mapStateToProps)(UserList);
export { connectedUserList as UserList };
