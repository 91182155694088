import React, { useState } from 'react';
export const AllShift = () => {
  const [active, setActive] = useState('allShift');

  return (
    <>
      <div className="container-fluid flx-inv-container mt-4">
        <div
          className="flx-admin-shift-sign-off-btn-container btn-group"
          role="group"
        >
          <button
            className={`btn ${
              active === 'allShift'
                ? 'flx-admin-shift-sign-off-active'
                : 'flx-admin-shift-sign-off'
            }`}
            onClick={() => setActive('allShift')}
          >
            All Shifts
          </button>
          <button
            className={`btn ${
              active === 'assign'
                ? 'flx-admin-shift-sign-off-active'
                : 'flx-admin-shift-sign-off'
            }`}
            onClick={() => setActive('assign')}
          >
            Assign
          </button>
          <button
            className={`btn ${
              active === 'pending'
                ? 'flx-admin-shift-sign-off-active'
                : 'flx-admin-shift-sign-off'
            }`}
            onClick={() => setActive('pending')}
          >
            Pending Sign Off
          </button>
        </div>
        <h2 className="title-all-shifts">All Shifts</h2>
        {/* Dropdown Selectors */}
        <div className="row mt-4 d-none d-lg-flex">
          <div className="col-auto">
            <input
              type="date"
              className="form-control flx-date-filter"
              placeholder="From"
            />
          </div>
          <div className="col-auto">
            <input
              type="date"
              className="form-control flx-date-filter"
              placeholder="To"
            />
          </div>
          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Select Employer</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Job Role</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Agency</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Verified</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="col-auto float-right">
            <button type="button" className="flx-assign-shift-button">
              <i className="flx-assign-user-icon fa fa-user-plus ml-2"></i>
              Assign Shift
            </button>
            <button type="button" className="flx-add-timesheet">
              <i className="flx-assign-user-icon fa fa-user-plus ml-2"></i>
              Add Timesheet
            </button>
          </div>
        </div>
        <div className="row mt-4 d-none d-lg-flex">
          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Attended</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Time Sheet</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className="col-auto">
            <select className="form-control flx-ag-price-table-bus-unit-filter">
              <option value="">Business Unit</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div className="col-auto float-right">
            <button type="button" className="flx-assign-shift-download-button">
              <i className="fa fa-download"></i>Download
            </button>
            <button type="button" className="flx-shift-sign-off-cancel-button">
              Trash
            </button>
            <button type="button" className="flx-shift-sign-off-cancel-button">
              Cancelled
            </button>
          </div>
        </div>

        <table
          className="table flx-user-settings-table mt-3"
          style={{ borderRadius: '10px' }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Name</th>

              <th>From</th>
              <th>To</th>

              <th>Shift</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Break</th>
              <th>Hours</th>
              <th>Price</th>
              <th>Employer Pay</th>
              <th>Timesheet</th>
              <th>Invoice Generated</th>
              <th>Employer</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="flx-selected-div-invoice">
              <td>
                <input type="checkbox" aria-label="select" checked />
              </td>
              <td>
                <i className="fa fa-user-circle"></i>Abraham
              </td>
              <td>31-Mar-2024</td>
              <td>31-Mar-2024</td>
              <td>
                Morning
                <div>9:00 -16:00</div>
              </td>
              <td>9.00</td>
              <td>16.05</td>
              <td>30m</td>
              <td>8</td>
              <td>£17</td>
              <td>£21</td>
              <td>
                <i className="fa fa-check"></i>
              </td>
              <td>
                <i className="fa fa-times"></i>
              </td>
              <td>Rehab</td>
              <td>12-Jun-24</td>
              <td>Cancel</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
