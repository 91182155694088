import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { VerifyShift } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

// eslint-disable-next-line no-unused-vars
let shiftRows = [];
let employer_id = [];

const CarehometimeSheet = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftData, setshiftData] = useState({ rows: [] });
  const [shiftRows, setshiftRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [viewdetails, setviewdetails] = useState(false);
  const [candidateData, setcandidateData] = useState({});
  const [ShowVerifyModal, setShowVerifyModal] = useState(false);
  const [viewshiftdetails, setviewshiftdetails] = useState([]);
  const [shiftdatapagination, setshiftdatapagination] = useState([]);
  const [VerifyShiftCallData, setVerifyShiftCallData] = useState({});
  const [timesheet, settimesheet] = useState({
    from_date: new Date().toISOString().slice(0, 10),
    is_attended: false,
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  const [showverifyconfirmation, setshowverifyconfirmation] = useState({
    value: false,
    candidate_id: '',
  });
  const [showsingleverifyconfirmation, setshowsingleverifyconfirmation] =
    useState(false);
  const [singleverify, setsingleverify] = useState({});
  const [showsinglecancelconfirmation, setshowsinglecancelconfirmation] =
    useState(false);
  const [cancelshiftid, setcancelshiftid] = useState({});
  // effect to load get all shifts
  useEffect(() => {
    getAllShifts(1);
  }, [timesheet, query.pageVo]);
  // handler for change
  const handleChange = event => {
    const { name, value, checked } = event.target;
    if (name == 'is_attended') {
      settimesheet({ ...timesheet, [name]: checked });
    } else {
      settimesheet({ ...timesheet, [name]: value });
    }
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  //---view candiate based shifts ----on button "VIEW" click-----
  const candidateshifts = (candidate_id, pageNo, queryTemp) => {
    setviewdetails(true);
    let queryNew = queryTemp ? queryTemp : query;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    if (timesheet.from_date) {
      queryNew.from_date = timesheet.from_date;
      let nextMonthDate = moment(timesheet.from_date, 'YYYY-MM-DD')
        .add(1, 'months')
        .format('YYYY-MM-DD');
      queryNew.end_date = nextMonthDate;
    }
    if (!timesheet.is_attended) {
      queryNew.is_attended = '';
    }
    if (timesheet.is_attended) {
      queryNew.is_attended = 1;
    }
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(
      shiftsActions.shiftsList({
        employer_id: queryNew.employer_id,
        candidate_id: candidate_id,
        from_date: queryNew.from_date,
        is_attended: queryNew.is_attended,
        end_date: queryNew.end_date,
        status: 1,
      }),
    ).then(
      res => {
        shiftdetails(res.data);
        setIsLoading(false);
        setshowsingleverifyconfirmation(false);
        setshowsinglecancelconfirmation(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   * Get all employers
   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setQuery(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
        queryNew.employer_id = user.employer_id;
        employer_id = user.employer_id;
      }
    }
    if (timesheet.from_date) {
      queryNew.from_date = timesheet.from_date;
    }
    if (timesheet.searchKey) {
      queryNew.searchKey = timesheet.searchKey;
    }
    queryNew.is_attended = timesheet.is_attended;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.timesheetShiftListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //---verify single shift ----on click verify button
  const shiftverify = (shiftid, candidate_id) => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.updateShift({ _id: shiftid, is_verified: 1 })).then(
      () => {
        setIsLoading(false);
        candidateshifts(candidate_id);
        getAllShifts(1);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // close verify shift modal
  const closeVerifyShiftModal = () => {
    setShowVerifyModal(false);
  };
  // shift cancel
  const shiftcancel = (shiftid, candidate_id) => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.updateShift({ _id: shiftid, status: -1 })).then(
      () => {
        setIsLoading(false);
        candidateshifts(candidate_id);
        getAllShifts(1);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // all shift verify
  const allshiftsverify = candidate_id => {
    setIsLoading(true);
    let from_date = '';
    if (timesheet.from_date) {
      from_date = timesheet.from_date;
    }
    const { dispatch } = props;
    dispatch(
      shiftsActions.updateShift({
        candidate_id: candidate_id,
        is_verified: 1,
        allshiftsverify: true,
        from_date: from_date,
        employer_id: employer_id,
      }),
    ).then(
      () => {
        setIsLoading(false);
        setshowverifyconfirmation({ value: false, candidate_id: '' });
        getAllShifts();
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = shiftData => {
    let shiftList = [];
    for (
      let j = 0;
      shiftData &&
      shiftData.rows &&
      shiftData.rows.length &&
      j < shiftData.rows.length;
      j = j + 1
    ) {
      shiftList.push(
        <tr key={j}>
          {/* --------id starts from 2000 --------- */}
          <td className="username">
            {shiftData.rows[j].profileimage &&
            shiftData.rows[j].profileimage &&
            shiftData.rows[j].profileimage != null ? (
              <img
                className=" round-emp-image"
                src={apiConstants.imgUrl + shiftData.rows[j].profileimage}
              />
            ) : (
              <img
                className=" round-emp-image"
                src="/assets/img/recsy-fav-icon.png"
              />
            )}
            {shiftData.rows[j].name}+{' '}
            {shiftData.rows[j].last_name ? shiftData.rows[j].last_name : ''}
          </td>
          <td>{shiftData.rows[j].total_shift}</td>
          <td>{shiftData.rows[j].to_verify}</td>
          <td>{shiftData.rows[j].attended} </td>
          <td>{shiftData.rows[j].approved} </td>
          <td>{shiftData.rows[j].canceled} </td>
          <td
            className="eventCell  eventView blue"
            onClick={() => {
              setcandidateData({
                candidate_name: shiftData.rows[j].name,
                profileimage: shiftData.rows[j].profileimage,
              });
              candidateshifts(shiftData.rows[j].candidate_id);
            }}
          >
            <a>View Shifts</a>
          </td>
          <td className="eventCell  eventView confirm">
            <a
              onClick={() => {
                setshowverifyconfirmation({
                  value: false,
                  candidate_id: shiftData.rows[j].candidate_id,
                });
                setVerifyShiftCallData({
                  candidate_id: shiftData.rows[j].candidate_id,
                });
                setShowVerifyModal(true);
              }}
            >
              Verify all
            </a>
          </td>
        </tr>,
      );
    }
    setshiftRows(shiftList);
    setshiftData(shiftData);
  };
  // set list shift details
  const shiftdetails = shiftdata => {
    let shiftList = [];
    for (let j = 0; shiftdata && j < shiftdata.rows.length; j = j + 1) {
      setcandidateData({
        candidate_name: shiftdata.rows[j]['Candidate.name'],
        profileimage: shiftdata.rows[j]['Candidate.profileimage'],
      });
      shiftList.push(
        <tr key={j}>
          <td>{shiftdata.rows[j].check_in_date_string} </td>
          <td>{shiftdata.rows[j].section_type} </td>
          <td>{shiftdata.rows[j].check_in_time_string}</td>
          <td>
            {shiftdata.rows[j].break_time
              ? shiftdata.rows[j].break_time + ' ' + 'min'
              : 0 + ' ' + 'min'}{' '}
          </td>
          <td>{shiftdata.rows[j].check_out_time_string}</td>
          <td>{shiftdata.rows[j].price}</td>
          <td>
            {shiftdata.rows[j].actual_hours_after_break_time + ' ' + 'hrs'}
          </td>
          {shiftdata.rows[j].is_attended == 1 ? (
            <td style={{ color: '#2DA463' }}>Yes</td>
          ) : (
            <td style={{ color: '#C34444' }}>No</td>
          )}
          <td>
            {shiftdata.rows[j].is_verified == 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                {shiftdata.rows[j].is_attended == 1 ? (
                  <button
                    onClick={() => {
                      setshowsingleverifyconfirmation(true),
                        setsingleverify({
                          _id: shiftdata.rows[j]._id,
                          candidate_id: shiftdata.rows[j].candidate_id,
                        });
                    }}
                    className="verifybtn"
                    style={{ cursor: 'pointer' }}
                  >
                    verify
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setshowsinglecancelconfirmation(true),
                        setcancelshiftid({
                          _id: shiftdata.rows[j]._id,
                          candidate_id: shiftdata.rows[j].candidate_id,
                        });
                    }}
                    className="cancelbtn"
                    style={{ cursor: 'pointer' }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{ marginTop: '8px' }}
                  src="/assets/img/Subtract.png"
                />
              </div>
            )}
          </td>
        </tr>,
      );
    }
    setviewshiftdetails(shiftList);
    setshiftdatapagination(shiftdata);
  };
  return (
    <div className="EmployerList">
      <div className="col-md-12">
        <div className="card regular-table-with-color ">
          {!props.isHomepage && (
            <div className="card-header page-header mt-20 ">
              <div className="col-md-3">
                <div className="page-title">Time Sheet</div>
              </div>
              <div className="row ">
                <div className="col-md-8 d-flex  flex-wrap ">
                  <div className=" col-md-4 col-sm-6 input-outer-div">
                    <a className="search_bar">
                      <i className="fa fa-search in_bar" aria-hidden="true" />
                    </a>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="date"
                      className="form-control  height-33"
                      name="from_date"
                      placeholder=""
                      value={timesheet.from_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className=" col-md-4 col-sm-6 d-flex align-items-center height-33">
                    <div>
                      {' '}
                      <label htmlFor="type">Only attended</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className=" checkboxheight "
                        name="is_attended"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              !props.isHomepage
                ? 'card-body  table-responsive main-card-body '
                : '  table-responsive main-card-body '
            }
          >
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSort('name')}
                      className="sortHead bgcolorth"
                    >
                      Name{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th className="bgcolorth ">Total shifts</th>
                    <th className="bgcolorth " style={{ color: '#237EC0' }}>
                      To verify
                    </th>
                    <th className="bgcolorth " style={{ color: '#2DA463' }}>
                      Attended{' '}
                    </th>
                    <th className="bgcolorth " style={{ color: '#2DA463' }}>
                      Approved
                    </th>
                    <th className="bgcolorth " style={{ color: '#C34444' }}>
                      Cancelled{' '}
                    </th>
                    <th className="bgcolorth " style={{ color: '#2DA463' }} />
                    <th className="bgcolorth " style={{ color: '#2DA463' }} />
                  </tr>
                </thead>
                <tbody>{shiftRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {shiftRows && shiftRows.length === 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftData}
            />
          </div>
          {viewdetails && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setviewdetails(false)}>
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    {candidateData && candidateData.candidate_name && (
                      <div className="row mb-30 cname">
                        {candidateData && candidateData.profileimage ? (
                          <img
                            className=" round-emp-image"
                            src={
                              apiConstants.imgUrl + candidateData.profileimage
                            }
                          />
                        ) : (
                          <img
                            className=" round-emp-image"
                            src="/assets/img/recsy-fav-icon.png"
                          />
                        )}
                        {candidateData.candidate_name}
                      </div>
                    )}
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="bgcolorth ">Date</th>
                          <th className="bgcolorth ">Shift</th>
                          <th className="bgcolorth ">Clock in</th>
                          <th className="bgcolorth ">Break time</th>
                          <th className="bgcolorth ">Clock out</th>
                          <th className="bgcolorth ">Price</th>
                          <th className="bgcolorth ">Total Hour</th>
                          <th className="bgcolorth ">Is Attended</th>
                          <th className="bgcolorth " />
                        </tr>
                      </thead>
                      <tbody>{viewshiftdetails}</tbody>
                    </table>
                    {viewshiftdetails.length == 0 && (
                      <div className="d-flex justify-content-center">
                        no result found
                      </div>
                    )}
                  </div>
                  <div className="card-footer">
                    <Pagination
                      pageOnClick={data => {
                        candidateshifts(data);
                      }}
                      paginationData={shiftdatapagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <p>
            <div className="modal-dialog ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <a onClick={() => setshowmodal(false)}>
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body d-flex align-items-center align-items-center justify-content-center ">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="row"
                          style={{ marginBottom: '10px', marginTop: '15px' }}
                        >
                          <div className="col-md-6 pr-1">
                            <div>
                              <input
                                type="datetime-local"
                                className="form-control form-group-50px-nobg"
                                name="check_in"
                                placeholder="From"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 pr-1">
                            <div className={'form-group '}>
                              <input
                                type="datetime-local"
                                className="form-control form-group-50px-nobg"
                                name="check_out"
                                placeholder="To"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <div className={'form-group mt-3'}>
                          <textarea
                            name="note"
                            placeholder="Note"
                            onChange={handleChange}
                            id="note"
                            className="form-control"
                            rows="4"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pr-1">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">Select Job role</label>
                          <select
                            className="select col-md-12 selectDesign"
                            name="category"
                            onChange={handleChange}
                          >
                            <option value=""> select</option>
                            <option value={'cat1'}>cat1</option>
                            <option value={'cat2'}>cat2</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6  ">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">Select shift</label>
                          <select
                            className="select col-md-12 selectDesign"
                            name="priority"
                            onChange={handleChange}
                          >
                            <option value=""> select</option>
                            <option value={'night'}>Night</option>
                            <option value={'morning'}>Morning</option>
                            <option value={'custome'}>custome</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 pr-1">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">Select Status</label>
                          <select
                            className="select col-md-12 selectDesign"
                            name="category"
                            onChange={handleChange}
                          >
                            <option value=""> select</option>
                            <option value={'rejected'}>Rejected</option>
                            <option value={'confirmed'}>Confirmed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <button className="btn btn-primary float-right">
                          Save
                          {props.requestStatus ===
                            reducerConstants.STAFF_REQUEST && (
                            <div className="lds-ring ml-2">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p>
        </div>
      )}
      {showverifyconfirmation.value && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowverifyconfirmation({
                        value: false,
                        candidate_id: '',
                      });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowverifyconfirmation({
                              value: false,
                              candidate_id: '',
                            });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            allshiftsverify(showverifyconfirmation.candidate_id)
                          }
                        >
                          {' '}
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showsingleverifyconfirmation && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowsingleverifyconfirmation(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowsingleverifyconfirmation(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            shiftverify(
                              singleverify._id,
                              singleverify.candidate_id,
                            )
                          }
                        >
                          {' '}
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showsinglecancelconfirmation && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowsinglecancelconfirmation(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowsinglecancelconfirmation(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            shiftcancel(
                              cancelshiftid._id,
                              cancelshiftid.candidate_id,
                            )
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowVerifyModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setShowVerifyModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <VerifyShift
                    VerifyShiftCallData={VerifyShiftCallData}
                    closeVerifyShiftModal={closeVerifyShiftModal}
                    from_date={timesheet.from_date}
                  ></VerifyShift>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps() {
  return {};
}
const connectedCarehometimeSheet = connect(mapStateToProps)(CarehometimeSheet);
export { connectedCarehometimeSheet as CarehometimeSheet };
