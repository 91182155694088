import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { logService } from '../_services';
export const logActions = {
  // logAdd,
  logList,
  // logDelete,
};

// function logAdd(logObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({ type: reducerConstants.LOG_REQUEST, logObj });

//       logService.logAdd(logObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.LOG_SUCCESS,
//             data,
//           });

//           if (data.error_code === 0) {
//             resolve(data.data);
//             dispatch(alertActions.success(data.message.toString()));
//           } else {
//             reject(data.data);

//             dispatch(alertActions.error(data.message.toString()));
//           }
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.LOFG_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function logList(logObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.LOG_REQUEST,
        logObj,
      });

      logService.logList(logObj).then(
        data => {
          dispatch({
            type: reducerConstants.LOG_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.LOG_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function logDelete(logObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.LOG_REQUEST,
//         logObj,
//       });

//       logService.logDelete(logObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.LOG_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.LOG_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
