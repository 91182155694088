/* eslint-disable no-self-assign */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { PayoutAdd2 } from '../Payout/PayoutAdd2';
import { candidateActions, payoutActions, shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { NewPayoutList } from './NewPayoutList';

// eslint-disable-next-line no-unused-vars
let staffRows = [];

const PayoutList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [payoutData, setPayoutData] = useState({ rows: [] });
  const [payoutRows, setPayoutRows] = useState([]);
  const [showblock1, setshowblock1] = useState(false);
  const [showblock2, setshowblock2] = useState(false);
  const [shiftidsCount, setshiftidsCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [printpdfLoading, setprintpdfLoading] = useState(false);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [selected_id, setselected_id] = useState('');
  const [selected_payout_data, setselected_payout_data] = useState([]);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const [payoutNewToggle, setPayoutNewToggle] = useState(false);
  const [payoutToggle, setPayoutToggle] = useState(false);
  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query?.from_date_string, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch) {
        getAllPayout(1);
      }
    }
  }, [debouncedSearch]);
  //--- debouncing end ---
  useEffect(() => {
    getAllPayout(1);
  }, []);
  //-----to show edit invoice modal ----------
  const setEditPayoutModal = (value, _id) => {
    if (value == false) {
      getAllPayout(query.pageVo.pageNo);
    }
    setselected_id(_id);
    setPayoutToggle(true);
    showPayoutModal(value);
  };
  //---------payout modal view show-----
  const showPayoutModal = value => {
    if (value == false) {
      window.location.reload();
    }
    setshowblock1(value);
    getAllPayout(query.pageVo.pageNo);
  };
  /**
   * Get all payout data
   **/
  const getAllPayout = (pageNo, queryTemp) => {
    let updatedQuery = { ...query, ...queryTemp }; // Merge new query with the existing one
    updatedQuery.pageVo = { ...updatedQuery.pageVo, pageNo }; // Ensure `pageNo` is updated
    setQuery(updatedQuery); // Update the query state
    setIsLoading(true);
    if (updatedQuery.from_date_string == '') {
      delete updatedQuery.from_date_string;
    }
    const { dispatch } = props;
    dispatch(payoutActions.payoutList(updatedQuery)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data); // Update list and pagination
      },
      () => {
        setIsLoading(false); // Handle errors
      },
    );
  };

  //-------get all candidate data ----------
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination({ rows: res.data.rows });
        // setCandidateData({ rows: res.data.rows });
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
      getAllCandidate();
    } else {
      isfirsttime.current = false;
      setQuery(prevstate => ({ ...prevstate, [name]: value }));
    }
  };
  //-------function to check if shift is selected ---------
  const check_ifSelected = _id => {
    for (let s = 0; s < selected_shiftIds.length; s++) {
      if (selected_shiftIds[s] == _id) {
        return true;
      }
    }
  };
  // shift check mark handler
  const handleChange_checked = (event, i, shift_id, payoutdata) => {
    // event.stopPropagation()
    const { name, checked } = event.target;
    if (name == 'shift_checked') {
      const { checked } = event.target;
      let new_selectedShiftIds = selected_shiftIds;
      let new_selected_payout_data = selected_payout_data;
      let csv_file_data = {
        'Employee first name': payoutdata['Candidate.name'],
        'Employee surname': '',
        'Employee NINO': payoutdata['Candidate.ni_number'],
        'Employee works number': payoutdata._id,
        'Employee ni_hour': payoutdata['Candidate.ni_hour'],
        // "Number of hours at an hourly rate set up within the Employer utility": payoutdata["Candidate.day_price"],
        'Total hours':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? payoutdata['Candidate.ni_hour']
            : payoutdata.total_hours,
        // "Total hours":  payoutdata.total_hours,
        'Payment 1 - Rate per hour (if different from standard rate)': (
          payoutdata.total_amount / payoutdata.total_hours
        ).toFixed(2),
        'UnPaid hours':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? payoutdata.unpaid_hours
            : 0,
        'UnPaid Amount':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? (
                payoutdata.unpaid_hours *
                (payoutdata.total_amount / payoutdata.total_hours)
              ).toFixed(2)
            : 0,
      };
      if (checked) {
        new_selectedShiftIds.push(shift_id);
        new_selected_payout_data.push(csv_file_data);
      } else {
        for (let k = 0; k < new_selectedShiftIds.length; k++) {
          if (new_selectedShiftIds[k] == shift_id) {
            new_selectedShiftIds.splice(k, 1);
            new_selected_payout_data.splice(k, 1);
          }
        }
      }
      setshiftidsCount(new_selectedShiftIds.length);
      setselected_shiftIds(new_selectedShiftIds);
      setselected_payout_data(new_selected_payout_data);
    } else if (name == 'trash') {
      if (checked) {
        setQuery({ ...query, status: 0 });
      } else {
        setQuery({ ...query, status: 1 });
      }
    }
  };
  // list payout data and pagination items
  const setListAndPagination = payoutData => {
    let payoutList = [];
    for (
      let j = 0;
      payoutData && payoutData.rows && j < payoutData.rows.length;
      j = j + 1
    ) {
      let d = new Date(parseInt(payoutData.rows[j].createdtime, 10));

      let a = d.toLocaleString('en-US', { timeZone: 'America/New_York' });

      payoutData.rows[j].total_amount = payoutData.rows[j].total_amount;
      const candidateFirstName = payoutData.rows[j]['Candidate.name'];
      const candidateLastName = payoutData.rows[j]['Candidate.last_name'];
      const fullName =
        candidateFirstName && candidateLastName
          ? `${candidateFirstName} ${candidateLastName}`
          : candidateFirstName || candidateLastName || '';

      const displayName =
        fullName.length > 8 ? `${fullName.substring(0, 12)}...` : fullName;
      payoutList.push(
        <tr key={j}>
          <td>
            <div>
              <input
                data-cy="selectfordownloadcsv"
                type="checkbox"
                style={{ cursor: 'pointer' }}
                className=" checkboxheight "
                name="shift_checked"
                placeholder=""
                checked={check_ifSelected(payoutData.rows[j]._id)}
                onChange={e => {
                  handleChange_checked(
                    e,
                    j,
                    payoutData.rows[j]._id,
                    payoutData.rows[j],
                  );
                }}
              />
            </div>
          </td>
          <td>PY0{payoutData.rows[j]._id}</td>
          <td className="username">
            <div
              onClick={() => {
                setSelectedCandidateId(payoutData.rows[j].candidate_id);
                setshowCandidateprofileModal(true);
              }}
              style={{
                color: '#000',
                cursor: 'pointer',
                display: 'flex', // Flexbox for horizontal alignment
                alignItems: 'center', // Vertically align image and text
                gap: '10px', // Space between image and text
                flexWrap: 'wrap', // Allow wrapping on smaller screens
                padding: '5px', // Optional padding for better spacing
              }}
            >
              {payoutData.rows[j]['Candidate.profileimage'] != null ? (
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%', // Makes the image circular
                    objectFit: 'cover', // Ensures proper scaling of the image
                  }}
                  src={
                    apiConstants.imgUrl +
                    payoutData.rows[j]['Candidate.profileimage']
                  }
                  alt="Profile"
                />
              ) : (
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%', // Makes the image circular
                    objectFit: 'cover', // Ensures proper scaling of the image
                  }}
                  src="/assets/img/recsy-fav-icon.png"
                  alt="Default"
                />
              )}
              <div
                style={{
                  wordBreak: 'break-word', // Prevent text overflow
                  flex: '1', // Allows text to take up remaining space
                }}
                title={fullName}
              >
                {displayName}
              </div>
            </div>
          </td>
          <td>{moment(a)?.format('DD-MMM-YYYY hh:mm A')} </td>
          <td>
            {moment(payoutData?.rows[j]?.from_date_string).format(
              'DD-MMM-YYYY',
            )}
          </td>
          <td>
            {moment(payoutData?.rows[j]?.to_date_string).format('DD-MMM-YYYY')}
          </td>
          <td>{payoutData?.rows[j]?.total_hours?.toFixed(2)}&nbsp;hrs </td>
          <td style={{ color: '#2DA463' }}>
            {payoutData.rows[j].paid_status == 1
              ? 'paid'
              : '£' +
                ' ' +
                (payoutData.rows[j].total_amount % 1 == 0
                  ? payoutData.rows[j].total_amount
                  : payoutData.rows[j].total_amount.toFixed(2))}
          </td>
          <td className="eventCell  eventView" data-cy="viewpayout">
            <a href={'/payout/' + payoutData.rows[j]._id}> View </a>
          </td>
          <td
            className="eventCell event Edit"
            data-cy="editpayout"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditPayoutModal(true, payoutData.rows[j]._id);
            }}
          >
            <img
              src="/assets/img/edit-icon-pending-sign-off.svg"
              alt=""
              className=""
            />
          </td>
        </tr>,
      );
    }
    setPayoutRows(payoutList);
    setPayoutData(payoutData);
  };
  // to show blocks
  const showblocks = () => {
    setshowblock1(false), setshowblock2(true);
  };
  // eslint-disable-next-line no-unused-vars
  const printShiftpdf = () => {
    setprintpdfLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.printShiftPdf({ Ids: selected_shiftIds })).then(
      res => {
        setprintpdfLoading(false);
        if (res && res.data && res.data.output_file_name) {
          window.open(apiConstants.shiftpdfUrl + res.data.output_file_name, '');
        }
      },
      () => {
        setprintpdfLoading(false);
      },
    );
  };
  // handler for sort payout list
  const handleSort = sortField => {
    const sortOrder =
      query.pageVo.sort === sortField && query.pageVo.sort_order === 'asc'
        ? 'desc'
        : 'asc';

    const updatedQuery = {
      ...query,
      pageVo: {
        ...query.pageVo,
        sort: sortField,
        sort_order: sortOrder,
        pageNo: 1, // Reset to the first page on sorting
      },
    };

    getAllPayout(1, updatedQuery); // Reload with updated query
  };

  const handlePayoutNewClick = () => {
    setshowblock1(true); // Toggle the modal for generating payout
    setPayoutNewToggle(true); // Toggle the visibility of the Payout New section
  };

  const handleGeneratePayoutClick = () => {
    setselected_id('');
    setshowblock1(true); // Toggle the modal for generating payout
    setPayoutToggle(true);
  };
  return (
    <div className="StaffList">
      <div className="col-md-12 p-0">
        <div className="card regular-table-with-color mx-4 px-2">
          <div className="card-header page-header py-2">
            <div className="pb-2">
              <div className="col-md-8 p-0">
                <div className="page-title">Payout</div>
              </div>
            </div>
            <div className=" d-flex justify-content-between align-items-center">
              {/* ======FROM DATE PICKER======== */}
              <div className="col-md-2 p-0">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2 input-design1"
                    name="from_date_string"
                    placeholder="from"
                    value={query.from_date_string} // Assuming query is defined in your code
                    onChange={handleChange} // Assuming handleChange is defined in your code
                  />
                </div>
              </div>
              <div className="col-md-4 p-0">
                <div
                  data-cy="payoutadd"
                  className="btn btn-primary-blue float-right ml-2 mb-2 flx-invoice-payment"
                  onClick={handleGeneratePayoutClick}
                >
                  {/* <i className="fas fa-file-invoice pr-1"></i> */}
                  <i className="fas fa-file-invoice-dollar pr-1"></i>

                  <span> Generate Payout</span>
                </div>
                <div
                  data-cy="payoutadd"
                  className="btn btn-primary-blue float-right mb-2 "
                  onClick={handlePayoutNewClick}
                >
                  {/* <i className="fas fa-file-invoice pr-2 "></i> */}
                  <i className="fas fa-hand-holding-usd pr-1"></i>

                  <span>Payout New</span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body table-responsive main-card-body ">
            <div className="card-header page-header pt-2 pl-4 ">
              <div className="row d-flex justify-content-between">
                {selected_shiftIds.length > 0 && (
                  <div className="pt-2"> {shiftidsCount} items selected</div>
                )}
                <div className="pr-3 ">
                  {selected_shiftIds.length > 0 && (
                    <div>
                      <div className="row mr-1 flx-payout-download">
                        <CsvDownload
                          style={{
                            border: 'none',
                            padding: '6px 4px',
                            backgroundColor: '',
                            marginLeft: '8px',
                            color: '#FFFFFF',
                          }}
                          data={selected_payout_data}
                        >
                          Download CSV
                        </CsvDownload>
                        <div>
                          <img
                            style={{
                              height: 15,
                              width: 15,
                              marginTop: '6px',
                              marginRight: '8px',
                            }}
                            src="/assets/img/csv1.png"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              // className="  table-responsive main-card-body scrollable-table-container"
              className="  table-responsive main-card-body flx-table-border"
              style={{ paddingTop: 0 }}
            >
              {!isLoading && (
                <table className="table flx-custom-table ">
                  <thead>
                    <tr className="flx-table-header-clr">
                      <th className="border-top1 table-header-ht">#</th>
                      <th>Id</th>
                      <th
                        style={{ width: '200px' }}
                        onClick={() => handleSort('candidate_name')}
                        className="sortHead"
                      >
                        <div className="d-flex align-items-center ">
                          <span>Candidate</span>

                          <span
                            style={{ width: '20px' }}
                            className="d-flex flex-column align-items-center"
                          >
                            {query.pageVo.sort === 'candidate_name' &&
                            query.pageVo.sort_order !== 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up-black.png"
                                onClick={() => handleSort('candidate_name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up.png"
                                onClick={() => handleSort('candidate_name')}
                              />
                            )}
                            {query.pageVo.sort === 'candidate_name' &&
                            query.pageVo.sort_order === 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down-black.png"
                                onClick={() => handleSort('candidate_name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down.png"
                                onClick={() => handleSort('candidate_name')}
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        style={{ width: '200px' }}
                        onClick={() => handleSort('createdtime')}
                        className="sortHead"
                      >
                        <div className="d-flex align-items-center ">
                          <span>Created Date & Time</span>

                          <span
                            style={{ width: '20px' }}
                            className="d-flex flex-column align-items-center"
                          >
                            {query.pageVo.sort === 'createdtime' &&
                            query.pageVo.sort_order !== 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up-black.png"
                                onClick={() => handleSort('createdtime')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up.png"
                                onClick={() => handleSort('createdtime')}
                              />
                            )}
                            {query.pageVo.sort === 'createdtime' &&
                            query.pageVo.sort_order === 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down-black.png"
                                onClick={() => handleSort('createdtime')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down.png"
                                onClick={() => handleSort('createdtime')}
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th>From</th>
                      <th>To</th>
                      <th>Total hrs</th>
                      <th>Amount Paid</th>
                      <th></th>
                      <th className="border-top2"></th>
                    </tr>
                  </thead>
                  <tbody>{payoutRows}</tbody>
                </table>
              )}
            </div>
            {isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {payoutData &&
              payoutData.rows &&
              payoutData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>

          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllPayout(data);
              }}
              paginationData={payoutData}
            />
          </div>

          {/* Modal for showblock1 */}
          {showblock1 && (
            <div
              className="modal "
              style={{
                display: 'block',
                background: 'rgb(10 11 11 / 50%)',
              }}
            >
              <div className="modal-dialog modal-dialog-large">
                <div className="modal-content ">
                  <div className="modal-header p-0">
                    <div className="close"></div>
                  </div>
                  <div className="modal-body p-0">
                    <div className="bag">
                      {payoutToggle && (
                        <PayoutAdd2
                          showPayoutModal={showPayoutModal}
                          editId={selected_id}
                        ></PayoutAdd2>
                      )}
                    </div>
                    <div className="bag">
                      {payoutNewToggle && (
                        <NewPayoutList
                          setPayoutNewToggle={setPayoutNewToggle}
                          setshowblock1={setshowblock1}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Modal for showblock2 */}
          {showblock2 && (
            <div
              className="modal "
              style={{
                display: 'block',
                background: 'rgb(10 11 11 / 50%)',
              }}
            >
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setshowblock1(false)}>
                        <i className="fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="card regular-table-with-color">
                          <div className="card-header page-header ">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="page-title">
                                  Generate timesheet
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-7">
                                <p>
                                  <div className="input-outer-div">
                                    <input
                                      className="search-input-div"
                                      value={query.searchKey}
                                      onChange={handleChange}
                                      type="text"
                                      name="searchKey"
                                      aria-label="Search"
                                      placeholder="Search here..."
                                    />
                                    <a className="search_bar">
                                      <i
                                        className="fa fa-search in_bar"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </div>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mt-20">
                                <a
                                  className="btn btn-primary float-right"
                                  href="/payoutadd/0"
                                >
                                  <div onClick={() => showblocks()}> Next</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Modal for showCandidateprofileModal */}
          {showCandidateprofileModal && (
            <div
              className="modal "
              style={{
                display: 'block',
                background: 'rgb(10 11 11 / 50%)',
              }}
            >
              <div className="modal-dialog modal-dialog-90 ">
                <div className="modal-content ">
                  <div className="modal-header">
                    <div className="close">
                      <a
                        onClick={() => {
                          setshowCandidateprofileModal(false);
                        }}
                      >
                        <i className="fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    <CandidateProfile candidate_id={SelectedCandidateId} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, staffData } = state.staff;
  return {
    requestStatus,
    staffData,
  };
}

const connectedPayoutList = connect(mapStateToProps)(PayoutList);
export { connectedPayoutList as PayoutList };
