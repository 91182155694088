import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BlockBookinglist } from '../Block_booking';
import { CandidateList } from '../Candidate';
import { NotificationList } from '../Notification';
import { AgencyUpcomingShift } from '../Shifts';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import DashBoardCard from './DashBoardCard';

const HomePage = props => {
  let navigate = useNavigate();
  const [isAdmin, setisAdmin] = useState(false);
  const [isAgency, setisAgency] = useState(false);
  const [isEmployer, setisEmployer] = useState(false);
  const [usercounts, setusercounts] = useState({});
  const [isStaff, setStaff] = useState(false);
  const [franchise_staff, setFranchise_staff] = useState(false);
  let id = '';
  // effect load to get dashboard data and ui
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        const { dispatch } = props;
        dispatch({
          type: 'isSidebarOpen',
          data: true,
        });
      }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        navigate('/dashboardemployer');
      }
      if (user.role == 4 || user.role == 7) {
        setisEmployer(true);
        id = user.employer_id;
      }
      if (user.role == 5) {
        setisAgency(true);
      }
      if (user.role == 6) {
        if (user.franchise_staff === true) {
          setFranchise_staff(true);
        }
        setStaff(true);
      }
      dashboarddata({ id: id });
    }
  }, []);
  // get dashboard counts data
  const dashboarddata = data => {
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/dashboardadmin', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(({ data }) => {
          setusercounts(data.data);
          resolve();
        })
        .catch(reject);
    });
  };

  return (
    <div className="col-md-12 pl-0 pr-0">
      <div></div>
      <div className=" homebg ">
        <div className="row">
          <div className="col-md-8">
            <div className="ml-4">
              <div className="overview-welcome d-flex margin-bottom-20 mt-3">
                Welcome to Recsy Temp
              </div>
              <div className="overview-text d-flex margin-bottom-20">
                Overview
              </div>
            </div>

            <div className="p-0 mt-2 mb-5">
              <div
                className="row "
                style={{ marginLeft: '10px', marginRight: '2px' }}
              >
                {(isAdmin || isStaff) && (
                  <div className="col  mb-3 pll-0 ml-2">
                    <DashBoardCard
                      title="Candidates"
                      count={usercounts?.totalcandidatecount}
                      linkTo="/candidateslist"
                      icon="/assets/img/overview-candidates.png"
                      backgroundColor="#e4f8ff"
                      style="  overview-candidates d-flex justify-content-center align-items-center "
                    />
                  </div>
                )}
                {isEmployer && (
                  <div className="col  mb-3 pll-0 ml-2">
                    <DashBoardCard
                      className="bg-gradient-custom"
                      title="Total Shifts"
                      count={usercounts?.carehome_total_shift}
                      linkTo="/shiftlist"
                      icon="/assets/img/overview-candidates.png"
                      backgroundColor="#e4f8ff"
                      style="  overview-candidates d-flex justify-content-center align-items-center "
                    />
                  </div>
                )}
                {isAgency && (
                  <div className="col  mb-3 pll-0 ml-2">
                    <DashBoardCard
                      title="Number of Shifts"
                      count={usercounts?.count_agency_shifts}
                      linkTo="/agencyShifts/0"
                      icon="/assets/img/overview-candidates.png"
                      backgroundColor="#e4f8ff"
                      style="  overview-candidates d-flex justify-content-center align-items-center "
                    />
                  </div>
                )}
                {isAgency && (
                  <div className="col  mb-3  ">
                    <DashBoardCard
                      title="Upcoming Shifts"
                      count={usercounts?.count_agencyupcomming_shift}
                      linkTo="/agencyShifts/upcomingshifts"
                      icon="/assets/img/overview-employers.png"
                      backgroundColor="#d1f7dd"
                      style="overview-employers d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {isAgency && (
                  <div className="col  mb-3  ">
                    <DashBoardCard
                      title="Total Bookings"
                      count={usercounts?.agency_booking}
                      linkTo="/agencybookinglist"
                      icon="/assets/img/overview-invoices.png"
                      backgroundColor="#fcf3db"
                      style=" overview-invoices d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {isAgency && (
                  <div className="col  mb-3  ">
                    <DashBoardCard
                      title="Total Vacancies"
                      count={usercounts?.vacancycount}
                      linkTo="/AgencyvacancyList"
                      icon="/assets/img/overview-vacancies.png"
                      backgroundColor="#d6ffff"
                      style="overview-vacancies d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {(isAdmin || isStaff) && (
                  <div className="col  mb-3 pll-0 ">
                    <DashBoardCard
                      title="Employers"
                      count={usercounts?.totalemployercount}
                      linkTo="/employerlist"
                      icon="/assets/img/overview-employers.png"
                      backgroundColor="#d1f7dd"
                      style="overview-employers d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {(isAdmin || isStaff) && !franchise_staff && (
                  // <div className="col-xl-6 col-md-6 mb-4 custom-dashboard-div">
                  <div className="col  mb-3  ">
                    <DashBoardCard
                      title="Invoices"
                      count={usercounts?.totalinvoicecount}
                      linkTo="/invoicelist"
                      icon="/assets/img/overview-invoices.png"
                      backgroundColor="#fcf3db"
                      style=" overview-invoices d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {(isAdmin || isStaff) && (
                  <div className="col  mb-3  ">
                    <DashBoardCard
                      title="Vacancies"
                      count={usercounts?.vacancycount}
                      linkTo="/vacancyshiftlist"
                      icon="/assets/img/overview-vacancies.png"
                      backgroundColor="#d6ffff"
                      style="overview-vacancies d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
                {isEmployer && (
                  <div className="col  mb-3 pll-0 ">
                    <DashBoardCard
                      title="Unverified Shifts"
                      count={usercounts?.carehome_unverified_shift}
                      linkTo="/allshiftlist/unverified"
                      icon="/assets/img/overview-employers.png"
                      backgroundColor="#d1f7dd"
                      style="overview-employers d-flex justify-content-center align-items-center"
                    />
                  </div>
                )}
              </div>
              {/* /=====SECOND CARD  ROW HERE ============= */}

              {isEmployer && (
                <div className="col  mb-3  ">
                  <DashBoardCard
                    title="Bookings"
                    count={usercounts?.booking_count}
                    linkTo="/bookinglist/0"
                    icon="/assets/img/overview-invoices.png"
                    backgroundColor="#fcf3db"
                    style=" overview-invoices d-flex justify-content-center align-items-center"
                  />
                </div>
              )}

              {isEmployer && (
                <div className="col  mb-3  ">
                  <DashBoardCard
                    title="New Bookings"
                    count={usercounts?.new_bookings}
                    linkTo="/bookinglist/newbookings"
                    icon="/assets/img/overview-vacancies.png"
                    backgroundColor="#d6ffff"
                    style="overview-vacancies d-flex justify-content-center align-items-center"
                  />
                </div>
              )}
            </div>
            {(isAdmin || isStaff) && (
              <div>
                <div className="">
                  <CandidateList isHomepage={true}></CandidateList>
                </div>
              </div>
            )}
            {isEmployer && (
              <div>
                <div className="">
                  <BlockBookinglist isHomepage={true}></BlockBookinglist>
                </div>
              </div>
            )}
            {isAgency && (
              <div>
                <div className="pl-3">
                  <h4
                    className=" agency-shift"
                    style={{ fontWeight: 500, fontSize: '20px' }}
                  >
                    Upcoming Shifts
                  </h4>
                  <AgencyUpcomingShift></AgencyUpcomingShift>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 vh100 ">
            <div className=" fh100">
              {(isAdmin || isStaff) && (
                <div className="notification-list fh100">
                  <NotificationList isAdmin={true} />
                </div>
              )}
              {isEmployer && (
                <div className="notification-list fh100">
                  <NotificationList iscarehome={true} />
                </div>
              )}
              {isAgency && (
                <div className="notification-list fh100">
                  <NotificationList isAgency={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
