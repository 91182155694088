import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const businessunitService = {
  businessunitAdd,
  businessunitList,
  // businessunitDelete,
  dashbordBunitReport,
  changeBusinessUnitStatus,
};

function businessunitAdd(data) {
  console.log(data, 'Data for business unit add==>18976');
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addbusinessunit', requestOptions).then(
    miscService.handleResponse,
  );
}
function businessunitList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    apiConstants.apiUrl + '/getbusinessunitlistforshift',
    requestOptions,
  ).then(miscService.handleResponse);
}

function dashbordBunitReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordbunitreport',
    requestOptions,
  ).then(miscService.handleResponse);
}
function changeBusinessUnitStatus(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/changebusinessunitstatus',
    requestOptions,
  ).then(miscService.handleResponse);
}
// function businessunitDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/deletebusinessunit',
//     requestOptions,
//   ).then(handleResponse);
// }
