import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../_actions';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isEmployer: false,
      isAgency: false,
      isStaff: false,
      isBusinessUnit: false,
      isParentEmployer: false,
    };
    this.openHeadbar = this.openHeadbar.bind(this);

    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      this.state.userid = user._id;
      if (user.role == 1) {
        this.state.isAdmin = true;
      }
      if (user.role == 4) {
        this.state.isEmployer = true;
        if (user.is_parent) {
          this.state.isParentEmployer = true;
        }
      }
      if (user.role == 5) {
        this.state.isAgency = true;
      }
      if (user.role == 6) {
        this.state.isStaff = true;
        if (user.franchise_staff) {
          this.state.franchise_staff = true;
        }
      }
      if (user.role == 7) {
        this.state.isBusinessUnit = true;
      }
    }

    // this.closeMenu = this.closeMenu.bind(this);
  }
  //-------------close side bar function while responsive -----------
  closeMenu() {
    const { dispatch, isSidebarOpen } = this.props;
    dispatch({
      type: 'isSidebarOpen',
      data: !isSidebarOpen,
    });
  }
  // to reset all filter options
  resetallfilters() {
    const { dispatch } = this.props;
    dispatch({
      type: 'isrouteKey',
      data: new Date().toString(),
    });
  }
  //----------open header while responsive --------------
  openHeadbar() {
    const { dispatch, isSidebarOpen } = this.props;
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
    dispatch({
      type: 'isSidebarOpen',
      data: !isSidebarOpen,
    });
  }

  // logout user
  logout() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  render() {
    let path = window.location.pathname;
    const {
      isAdmin,
      isEmployer,
      isAgency,
      isStaff,
      franchise_staff,
      isBusinessUnit,
      isParentEmployer,
    } = this.state;
    const { isSidebarOpen } = this.props;
    return (
      <div>
        <nav
          className={'navbar navbar-expand-lg navbar-light sm-dblock'}
          style={{ background: '#fafafa' }}
        >
          <button
            className="navbar-toggler m-2"
            type="button"
            data-toggle="collapse "
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => this.openHeadbar()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              'collapse navbar-collapse flex1 ' +
              (isSidebarOpen ? ' menuopen' : '')
            }
            id="navbarText"
          >
            <ul className="navbar-nav mr-auto  flex1">
              {(isAdmin || isAgency || isStaff) && (
                <li
                  style={{ position: 'relative' }}
                  className={
                    path === '/' ? 'nav-item active1  flex1 ' : 'nav-item flex1'
                  }
                >
                  {/* <div className="blue-card"></div> */}

                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-dashboard-menu-icon"
                        src="/assets/img/dashboard-icon.svg"
                      ></img>
                      <p className="navbar-head">Dashboard</p>
                    </div>
                    {/* <p className="navbar-sub">Dashboard data </p> */}
                  </Link>
                </li>
              )}
              {(isEmployer || isBusinessUnit) && (
                <li
                  className={
                    path === '/dashboardemployer'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/dashboardemployer"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-dashboard-menu-icon"
                        src="/assets/img/dashboard-icon.svg"
                      ></img>
                      <p className="navbar-head">Dashboard</p>
                    </div>
                  </Link>
                </li>
              )}
              {isEmployer && false && (
                <li
                  className={
                    path === '/blockbookinglist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/blockbookinglist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <p className="navbar-head">Assign</p>
                  </Link>
                </li>
              )}
              {(isEmployer || isAdmin || isStaff || isBusinessUnit) && (
                <li
                  className={
                    path === '/vacancyshiftlist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/vacancyshiftlist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/vacancy-icon.svg"
                      ></img>
                      <p className="navbar-head">Vacancies </p>
                    </div>
                  </Link>
                </li>
              )}
              {(isAdmin || isEmployer || isStaff || isBusinessUnit) && (
                <li
                  className={
                    path === '/bookinglist/0'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/bookinglist/0"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/booking-icon.svg"
                      ></img>
                      <p className="navbar-head">Bookings </p>
                    </div>
                  </Link>
                </li>
              )}
              {(isEmployer || isAdmin || isStaff || isBusinessUnit) && (
                <li
                  className={
                    path === '/shiftlist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/shiftlist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-shift-menu-icon"
                        src="/assets/img/shift-icon.svg"
                      ></img>
                      <p className="navbar-head">Shifts</p>
                    </div>
                  </Link>
                </li>
              )}
              {(isAdmin || isStaff) && (
                <li
                  className={
                    path === '/candidateslist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/candidateslist"
                    onClick={() => {
                      this.resetallfilters();
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-candidate-menu-icon"
                        src="/assets/img/candidate-icon.svg"
                      ></img>
                      <p className="navbar-head">Candidates</p>
                    </div>
                  </Link>
                </li>
              )}
              {/* //-----------employer list tab----------- */}
              {(isAdmin || isStaff || isParentEmployer) && (
                <li
                  className={
                    path === '/employerlist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/employerlist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-employer-menu-icon"
                        src="/assets/img/employer-icon.svg"
                      ></img>
                      <p className="navbar-head">
                        {!isParentEmployer ? 'Employers' : 'Organisations'}
                      </p>
                    </div>
                  </Link>
                </li>
              )}
              {/* //--------Allocated candidates list tab------------- */}
              {(isAdmin || isStaff) && (
                <li
                  className={
                    path === '/allocatecandidatelist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/allocatecandidatelist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/allocate-icon.svg"
                      ></img>
                      <p className="navbar-head">Allocate</p>
                    </div>
                  </Link>
                </li>
              )}
              {/* //------rejected candidate for employer list tab--------------- */}

              {(isEmployer || isBusinessUnit || isAgency) && (
                <li
                  className={
                    path === '/timesheetlist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/timesheetlist"
                    onClick={() => {
                      this.openHeadbar(), this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/booking-icon.svg"
                      ></img>
                      <p className="navbar-head">Timesheet </p>
                    </div>
                  </Link>
                </li>
              )}
              {isAdmin && (
                <li
                  className={
                    path === '/stafflist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/stafflist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/staff-icon.svg"
                      ></img>
                      <p className="navbar-head">Staff</p>
                    </div>
                  </Link>
                </li>
              )}
              {isAdmin && true && (
                <li
                  className={
                    path === '/agencylist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/agencylist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/agency-icon.svg"
                      ></img>
                      <p className="navbar-head">Agency</p>
                    </div>
                  </Link>
                </li>
              )}
              {isEmployer && true && (
                <li
                  className={
                    path === '/subscribedagencylist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/subscribedagencylist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/agency-icon.svg"
                      ></img>
                      <p className="navbar-head">Agency</p>
                    </div>
                  </Link>
                </li>
              )}
              {(isEmployer || isBusinessUnit) && (
                <li
                  className={
                    path === '/invoicelist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/invoicelist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/payment-icon.svg"
                      ></img>
                      <p className="navbar-head">Invoices</p>
                    </div>
                  </Link>
                </li>
              )}
              {isAgency && (
                <li
                  className={
                    path === '/agencycandidateslist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/agencycandidateslist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    {' '}
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-candidate-menu-icon"
                        src="/assets/img/candidate-icon.svg"
                      ></img>
                      <p className="navbar-head">Agency Candidates</p>
                    </div>
                  </Link>
                </li>
              )}
              {isAgency && (
                <li
                  className={
                    path === '/agencyShifts/0'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/agencyShifts/0"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    {' '}
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons flx-header-shift-menu-icon"
                        src="/assets/img/shift-icon.svg"
                      ></img>
                      <p className="navbar-head">Shifts</p>
                    </div>
                  </Link>
                </li>
              )}
              {isAgency && (
                <li
                  className={
                    path === '/AgencyvacancyList'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/AgencyvacancyList"
                    onClick={this.openHeadbar}
                  >
                    {' '}
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/vacancy-icon.svg"
                      ></img>
                      <p className="navbar-head">Vacancies </p>{' '}
                    </div>
                  </Link>
                </li>
              )}
              {isAgency && (
                <li
                  className={
                    path === '/agencybookinglist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/agencybookinglist"
                    onClick={this.openHeadbar}
                  >
                    {' '}
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/booking-icon.svg"
                      ></img>
                      <p className="navbar-head">My Bookings </p>{' '}
                    </div>
                  </Link>
                </li>
              )}
              {isAgency && (
                <li
                  className={
                    path === '/agencyinvoicelist'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/agencyinvoicelist"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    {' '}
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/payment-icon.svg"
                      ></img>
                      <p className="navbar-head"> Invoice</p>{' '}
                    </div>
                  </Link>
                </li>
              )}
              {(isAdmin || isStaff) && franchise_staff !== true && (
                <li
                  className={
                    path === '/payments'
                      ? 'nav-item active1 flex1'
                      : 'nav-item flex1'
                  }
                >
                  <Link
                    className="nav-link pt-3 pb-3"
                    to="/payments"
                    onClick={() => {
                      this.closeMenu();
                      this.resetallfilters();
                    }}
                  >
                    <div className="flx-menu-icon-name-container">
                      <img
                        className="flx-menu-icons"
                        src="/assets/img/payment-icon.svg"
                      ></img>
                      <p className="navbar-head">Payments</p>
                    </div>
                  </Link>
                </li>
              )}
              {(isAdmin || isEmployer || isBusinessUnit || isStaff) &&
                franchise_staff !== true && (
                  <li
                    className={
                      path === '/reportlist'
                        ? 'nav-item active1 flex1'
                        : 'nav-item flex1'
                    }
                  >
                    <Link
                      className="nav-link pt-3 pb-3"
                      to="/reportlist"
                      onClick={() => {
                        this.closeMenu();
                        this.resetallfilters();
                      }}
                    >
                      <div className="flx-menu-icon-name-container">
                        <img
                          className="flx-menu-icons"
                          src="/assets/img/report-icon.svg"
                        ></img>
                        <p className="navbar-head">Reports</p>
                      </div>
                    </Link>
                  </li>
                )}
              {/* //--------logatable data ------------ */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users, authentication, misc } = state;
  const { user } = authentication;
  return {
    user,
    users,
    isMenuOpen: misc.isMenuOpen,
    isSidebarOpen: misc.isSidebarOpen,
  };
}

const connectedSidebar = connect(mapStateToProps)(Sidebar);
export { connectedSidebar as Sidebar };
