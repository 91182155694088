import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { agencyActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants } from '../_constants';

const Agency = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [agency, setAgency] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  // to if user == admin
  let isadmin = false;
  let user = JSON.parse(localStorage.getItem('user'));
  if (user.role == 1 || user.role == '1') {
    isadmin = true;
  }
  // effect for call get all agency
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllAgency(1, query);
    }
  }, []);
  /**
   * Get all agencies
   * */
  const getAllAgency = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.select = [
      '_id',
      'key_contact',
      'profileimage',
      'contact_number',
      'email',
      'company_name',
      'address',
      'location',
      'postcode',
      'website',
      'priority',
      'nationality',
      'city',
      'dob',
    ];
    dispatch(agencyActions.agencyList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setAgency(res?.data?.rows?.[0]);
          setAgency(prevState => ({
            ...prevState,
            key_contact: JSON.parse(res?.data?.rows?.[0].key_contact),
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    // <div
    //   style={{
    //     display: 'flex',
    //     alignItems: 'start',
    //     justifyContent: 'center',
    //     height: '100vh',
    //     marginTop: '50px',
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       height: '60%',
    //       marginTop: '30px',
    //       marginRight: '40px',
    //       marginLeft: '40px',
    //       // padding: '20px',
    //       backgroundColor: '#fff',
    //       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow on all sides
    //       borderRadius: '8px',
    //       textAlign: 'center',
    //     }}
    //   >
    <div className="Agency">
      {isLoading && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color ">
          <div className="card-body">
            <div className="">
              <div className="row d-flex justify-content-between top-border-bot m-3 pb-3">
                <div className="col-md-2">
                  <div className="agency-image">
                    {agency && agency.profileimage ? (
                      <img
                        className=" round-emp-image-single"
                        src={apiConstants.imgUrl + agency.profileimage}
                      />
                    ) : (
                      <img
                        className="round-emp-image"
                        src="/assets/img/recsy-fav-icon.png"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-4  ">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-phone green_border"></i>
                        <p className="emp-phone">
                          {agency &&
                            agency.contact_number &&
                            agency.contact_number}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-envelope  red_border"></i>
                        <p className="emp-phone">
                          {agency && agency.email && agency.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isadmin && (
              <div className=" col text_align">
                <Link
                  data-cy="editagency"
                  className="link"
                  to={'/agencyadd/' + agency._id}
                >
                  <i className="fas fa-pen pointer f18"></i>
                </Link>
              </div>
            )}
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Name</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col-4">
                <p className="agency-view-details">
                  {agency.company_name ? agency.company_name : 'Unknown'}{' '}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Address</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.address ? agency.address : 'Not Provided'}
                </p>
                <p
                  className="agency-view-details"
                  style={{ lineHeight: '5px' }}
                >
                  {agency.location && agency.location}
                </p>
                <p className="agency-view-details">
                  &nbsp;{agency.postcode && agency.postcode}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Website</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.website ? agency.website : 'Not Provided'}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Priority</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.priority ? agency.priority : 'Not Provided'}{' '}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Jobrole</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency['Category.name']
                    ? agency['Category.name']
                    : 'Not Provided'}{' '}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>Country</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.nationality ? agency.nationality : 'Not Provided'}{' '}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>City</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.city ? agency.city : 'Not Provided'}{' '}
                </p>
              </div>
            </div>
            <div className="row ml-4">
              <div className="col-md-2 col-sm-6">
                <label>DOB</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p className="agency-view-details">
                  {agency.dob ? agency.dob : 'Not Provided'}{' '}
                </p>
              </div>
            </div>
            {agency &&
              agency.key_contact &&
              agency.key_contact.length &&
              agency.key_contact[0] &&
              agency.key_contact[0].key_name && (
                <div className="col-md-8">
                  <div className="card-dark mt-4 pl-4">
                    <div className="mt-3">
                      <h4 className="">Key Contact 1</h4>
                    </div>
                    <div className="">
                      <div className="row ml-4">
                        <div className="col-md-3 col-sm-6">
                          <label>Name</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {agency &&
                              agency.key_contact &&
                              agency.key_contact[0] &&
                              agency.key_contact[0].key_name &&
                              agency.key_contact[0].key_name}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row ml-4">
                        <div className="col-md-3 col-sm-6">
                          <label>email</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {agency &&
                              agency.key_contact &&
                              agency.key_contact[0] &&
                              agency.key_contact[0].key_email &&
                              agency.key_contact[0].key_email}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row ml-4">
                        <div className="col-md-3 col-sm-6">
                          <label>Phone</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {agency &&
                              agency.key_contact &&
                              agency.key_contact[0] &&
                              agency.key_contact[0].key_phone &&
                              agency.key_contact[0].key_phone}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row ml-4">
                        <div className="col-md-3 col-sm-6">
                          <label>Position</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {agency &&
                              agency.key_contact &&
                              agency.key_contact[0] &&
                              agency.key_contact[0].key_position &&
                              agency.key_contact[0].key_position}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, agencyData } = state.agency;
  return {
    requestStatus,
    agencyData,
  };
}

const connectedAgency = connect(mapStateToProps)(Agency);
export { connectedAgency as Agency };
