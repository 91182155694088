import { format, getDay, parseISO } from 'date-fns';
import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import { LoadingIcon } from '../_components';
import { messageConstant } from '../_constants';
import {
  categoryService,
  miscService,
  sectionService,
  vacancyshiftService,
} from '../_services';
import { AddShiftTimeForm } from './AddShiftTimePopup';
import { RateCardDetails } from './RateCardDetailsPopup';

const AddNewShift = props => {
  const [postAgencyData, setPostAgencyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({
    selectedJobTitle: null,
    dates: [],
    selectedBenefits: [],
  });
  const [emergency, setEmergency] = useState(false);

  const [employerId, setEmployerId] = useState(null);
  const [jobTitleList, setJobTitleList] = useState(null);
  const [candidate, setCandidate] = useState([]);
  const [numberOfVacancies, setNumberOfVacancies] = useState(1);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [showCandidate, setShowCandidate] = useState(false);
  const [shiftTimeList, setShiftTimeList] = useState(null);
  const [shiftTime, setShiftTime] = useState(null);
  const [rateCardList, setRateCardList] = useState([]);
  const [isSLAUser, setIsSLAUser] = useState(false);
  const [selectedShiftTime, setSelectedShiftTime] = useState(null);
  const [selectedrateCardDetails, setSelectedrateCardDetails] = useState(null);
  const [continueAddingShift, setContinueAddingShift] = useState([]);
  const [error, setError] = useState({ common: '' });
  const [activeAnotherShiftButton, setActiveAnotherShiftButton] =
    useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [ratecardShowModal, setRateCardModal] = useState('');
  const [addshifttimeShowModal, setAddShiftTimeModal] = useState('');
  const [defaultBenefits, setDefaultBenefits] = useState([]);
  const [activeBenefits, setActiveBenefits] = useState([]);
  const [activeSaveButton, setActiveSaveButton] = useState(false);

  const [weekdays, setWeekdays] = useState([]);
  const [weekends, setWeekends] = useState([]);
  const [bankHoliday, setBankHoliday] = useState([]);

  const [weekendAndHolidays, setWeekendAndHolidays] = useState(false);
  useEffect(() => {
    if (props.dateAndPositionData !== null) {
      setSelectedData({
        dates: [...props.dateAndPositionData.dates],
        selectedJobTitle: { ...props.dateAndPositionData.selectedJobTitle },
      });
    }
  }, [props.dateAndPositionData]); // Dependency array
  useEffect(() => {
    if (
      props.selectedShiftData !== null &&
      props.selectedDateAndPosition !== null &&
      props.selectedShiftData !== selectedShiftTime
    ) {
      setShiftTime(props.selectedShiftData.shift_time);
      setSelectedShiftTime(props.selectedShiftData.shift_time?._id);
      setNumberOfVacancies(props.selectedShiftData.vacancies);
      setSelectedrateCardDetails(props.selectedShiftData.price);
      setSelectedCard(props.selectedShiftData.price?.rate_card_type);
      setSelectedData(pre => ({
        ...pre,
        dates: [props.selectedDateAndPosition.date],
        selectedJobTitle: props.selectedDateAndPosition.position,
      }));
      setEmergency(props.selectedShiftData?.emergency);
      setContinueAddingShift(null);

      setActiveAnotherShiftButton(false);
    }
  }, [props.selectedShiftData, props.selectedDateAndPosition]);
  // Rest of your component code
  const handleIncrement = e => {
    e.preventDefault();
    setNumberOfVacancies(numberOfVacancies + 1);
  };

  const handleDecrement = e => {
    e.preventDefault();
    if (numberOfVacancies > 1) {
      setNumberOfVacancies(numberOfVacancies - 1);
    }
  };

  const getRateCardDetails = async dates => {
    try {
      if (shiftTime) {
        setIsLoading(true);
        const rateCardResponse = await categoryService.rateCardList({
          business_unit_id: postAgencyData.selectedBusinessUnit._id,
          job_role_id: postAgencyData.selectedJobTitle._id,
          section_id: shiftTime._id,
          dates,
        });
        setRateCardList(rateCardResponse.data.data);
        setIsLoading(false);

        if (rateCardResponse.data?.data[0]?.rate_card_type) {
          setIsSLAUser(false);
        } else {
          setIsSLAUser(true);
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    emergencyShiftCalculation(shiftTime?.from_time);
  }, [shiftTime, props.selectedShiftData]);

  const handleSelectshiftName = event => {
    event.preventDefault();

    const selectedShiftResponse = shiftTimeList?.find(
      unit => unit._id == event.target.value,
    );
    setShiftTime(selectedShiftResponse);
    setSelectedShiftTime(event.target.value);
    if (weekendAndHolidays) {
      setActiveSaveButton(true);
      return;
    } else getRateCardDetails(selectedData.dates);
  };

  const handleSelectedRateCard = event => {
    // setting the selected card
    setSelectedrateCardDetails(event);
    setActiveSaveButton(true);
  };

  const hasDuplicateShift = (shiftTime, continueAddingShift) => {
    if (continueAddingShift === null) {
      return false; // Return false if continueAddingShift is not an array
    }
    return continueAddingShift.some(
      shift => shift.shift_time._id === shiftTime._id,
    );
  };

  const emergencyShiftCalculation = from_time => {
    setEmergency(false);
    let ermergency;
    setError({
      ...error,
      shiftTime: '',
    });
    const currDate = new Date(); // Current date
    const currentDate = currDate.toISOString().slice(0, 10);
    const nextDate = new Date(currDate.getTime() + 24 * 60 * 60 * 1000); // Adding 24 hours (in milliseconds)
    const nextdateFormat = nextDate.toISOString().slice(0, 10);

    const todayDate = selectedData.dates.filter(
      date =>
        format(miscService.dateConvertion(date), 'yyyy-MM-dd') === currentDate,
    );
    const findNextDay = selectedData.dates.filter(
      date =>
        format(miscService.dateConvertion(date), 'yyyy-MM-dd') ===
        nextdateFormat,
    );
    if (todayDate.length > 0) {
      ermergency = miscService.currentTimeCalculation(from_time);
      if (ermergency) {
        setError({
          ...error,
          shiftTime: messageConstant.SHIFT_TIME_EXP,
          isError: true,
        });
        setIsLoading(false);
        return;
      } else {
        setEmergency(true);
      }
    }

    if (findNextDay.length > 0) {
      ermergency = miscService.emergencyShift(from_time);
      if (ermergency) {
        setEmergency(true);
      }
    }

    if (weekendAndHolidays) {
      setActiveSaveButton(true);
    } else {
      getRateCardDetails(selectedData.dates);
    }
  };
  const handleAddAnotherShift = (shift_time, vacancies, price) => {
    // Check for duplicates before adding
    if (!hasDuplicateShift(shift_time, continueAddingShift)) {
      setError({
        ...error,
        shiftTime: '',
        isError: false,
      });
      if (weekendAndHolidays) {
        setContinueAddingShift(prevArray => [
          ...(prevArray || []),
          { shift_time, vacancies, emergency },
        ]);
      } else {
        setContinueAddingShift(prevArray => [
          ...(prevArray || []),
          { shift_time, vacancies, price, emergency },
        ]);
      }

      setShiftTimeList(null);
      setShiftTime(null);
      setSelectedShiftTime(null);
      getShiftTimeList();
      setNumberOfVacancies(1);
      setSelectedrateCardDetails(null);
      setRateCardList(null);
      setActiveAnotherShiftButton(false);
      setSelectedrateCardDetails(null);
      setSelectedCard(null);
      setEmergency(false);
    } else {
      // Handle duplicate case
      setError({
        ...error,
        shiftTime: messageConstant.DUPLICATE_SHIFT,
        isError: true,
      });
    }
  };
  // selecting the card onclick
  const handleCardClick = cardType => {
    setSelectedCard(cardType);
    if (shiftTime) {
      setActiveAnotherShiftButton(true);
    }
  };

  const handleDeleteShift = index => {
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });
    // Implement your delete logic here
    const updatedShifts = [...continueAddingShift];
    updatedShifts.splice(index, 1);
    setContinueAddingShift(updatedShifts);
  };

  const handleEditShift = (index, event) => {
    event.preventDefault();
    setError({
      ...error,
      shiftTime: '',
      isError: false,
    });

    const updatedShifts = [...continueAddingShift];
    setShiftTime(index.shift_time);
    setSelectedShiftTime(index.shift_time._id);
    setNumberOfVacancies(index.vacancies);

    setContinueAddingShift(updatedShifts);
    handleCardClick(index.price.rate_card_type);
    handleSelectedRateCard(index.price);
    updatedShifts.splice(index, 1);
  };

  const handleSaveVacanciesWithMultipleShift = (
    date,
    position,
    selectedBusinessUnit,
    continueAddingShift,
    activeBenefits,
    weekendAndHoliday,
  ) => {
    props.vacancyData({
      date,
      position,
      selectedBusinessUnit,
      continueAddingShift,
      activeBenefits,
      weekendAndHoliday,
    });
  };

  const handleCloseVacancy = e => {
    e.preventDefault();
    props.closeAddNewVacancy();
  };

  useEffect(() => {
    if (props.postAgencyData) {
      setPostAgencyData(props.postAgencyData);
    }
  }, [props.postAgencyData]);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4) {
        if (user && user.employer_id) {
          setEmployerId(user.employer_id);
        }
      }
    }
  }, []);

  const handleDateChange = dates => {
    const formatedDate = dates.map(date =>
      format(miscService.dateConvertion(date), 'dd/MM/yyyy'),
    );
    setSelectedData(pre => ({ ...pre, dates: formatedDate }));
  };
  const today = new Date();
  const minSelectableDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );

  const getJobTitle = async () => {
    try {
      const res = await categoryService.jobTitleList();
      if (res.error_code === 0) {
        if (res?.data?.length > 0) {
          setJobTitleList(res.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedJobTitle = event => {
    const selectjob = jobTitleList.find(
      category => category._id == event.target.value,
    );
    setSelectedData(pre => ({ ...pre, selectedJobTitle: selectjob }));
  };

  const getDefaultBenefits = async () => {
    try {
      if (employerId !== null) {
        const res = await vacancyshiftService.benefitMasterList();
        setDefaultBenefits(res.data.rows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBenefits = async () => {
    try {
      if (employerId !== null) {
        const res = await vacancyshiftService.benefitEmployerList({
          business_unit_id: postAgencyData.selectedBusinessUnit._id,
          job_role_id: postAgencyData.selectedJobTitle._id,
        });
        setActiveBenefits(res.data.rows.map(d => d.benefit_master_id));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const toggleBenefits = id => {
    const pd = defaultBenefits.find(a => a.name === 'PICKUP_DROP')?._id;
    const re = defaultBenefits.find(a => a.name === 'REIMBURSEMENT')?._id;

    if (id == pd || id == re) {
      if (activeBenefits.find(a => a === (id === pd ? re : pd))) {
        return;
      }
    }

    const isExists = activeBenefits.includes(id);
    if (isExists) {
      setActiveBenefits(activeBenefits.filter(b => b !== id));
    } else {
      setActiveBenefits([...activeBenefits, id]);
    }
  };

  const getCandidate = async () => {
    try {
      if (employerId !== null) {
        const Candidates = await vacancyshiftService.VacancyShiftCandidateList({
          employer_id: employerId,
          business_unit: postAgencyData.selectedBusinessUnit._id,
        });
        setCandidate(Candidates.data);
      }
    } catch (error) {
      console.error(error, 'Error fetching candidates:');
    }
  };
  const handleShowCandidates = () => {
    if (candidate.length > 0) {
      setShowCandidate(prevShowCandidate => !prevShowCandidate);
      setSelectedCandidates([]);
    }
  };

  const handleSelectedCandidates = id => {
    const isSelected = selectedCandidates.includes(id);
    if (isSelected) {
      setSelectedCandidates(
        selectedCandidates.filter(candidateId => candidateId !== id),
      );
    } else {
      setSelectedCandidates([...selectedCandidates, id]);
    }
  };

  const getShiftTimeList = async () => {
    try {
      if (employerId !== null) {
        const response = await sectionService.sectionList({
          employer_id: employerId,
        });
        if (response.error_code === 0) {
          if (response?.data?.rows?.length > 0) {
            setShiftTimeList(response.data.rows);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getJobTitle();
    getBenefits();
    getCandidate();
    getShiftTimeList();
    getDefaultBenefits();
  }, [employerId]);

  useEffect(() => {
    const processDates = async () => {
      const newWeekdays = [];
      const newWeekends = [];
      const newHolidays = [];

      try {
        const holidayList = await vacancyshiftService.bankHolidayListUK();
        const events2024 = holidayList['england-and-wales'].events.filter(
          data => new Date(data.date).getFullYear() > 2023,
        );

        for (const date of selectedData.dates) {
          const convertedDate = miscService.dateConvertion(date);
          const dayOfWeek = getDay(convertedDate);

          if (dayOfWeek === 6 || dayOfWeek === 0) {
            newWeekends.push(date);
          } else {
            const formattedInputDate = format(convertedDate, 'dd-MM-yyyy');
            const isHoliday = events2024.some(event => {
              const eventDate = parseISO(event.date);
              const formattedEventDate = format(eventDate, 'dd-MM-yyyy');
              return formattedEventDate === formattedInputDate;
            });

            if (isHoliday) {
              newHolidays.push(date);
            } else {
              newWeekdays.push(date);
            }
          }
        }
        if (
          (newWeekdays.length > 0 &&
            (weekends.length > 0 || newHolidays.length > 0)) ||
          (newWeekends.length > 0 && newHolidays.length > 0)
        ) {
          setWeekendAndHolidays(true);
        } else setWeekendAndHolidays(false);

        setBankHoliday(newHolidays);
        setWeekdays(newWeekdays);
        setWeekends(newWeekends);
      } catch (error) {
        console.error(error);
      }
    };

    processDates();
  }, [selectedData.dates]);

  const handleSaveVacancies = e => {
    e.preventDefault();

    if (!hasDuplicateShift(shiftTime, continueAddingShift)) {
      setError({
        ...error,
        shiftTime: '',
        isError: false,
      });
      if (bankHoliday.length > 0) {
        let tempBankHoliday = [];
        if (weekendAndHolidays) {
          tempBankHoliday = [
            {
              shift_time: shiftTime,
              vacancies: numberOfVacancies,
              emergency,
            },
          ];
        } else {
          tempBankHoliday = [
            {
              shift_time: shiftTime,
              vacancies: numberOfVacancies,
              price: selectedrateCardDetails,
              emergency,
            },
          ];
        }
        handleSaveVacanciesWithMultipleShift(
          bankHoliday,
          selectedData.selectedJobTitle,
          postAgencyData.selectedBusinessUnit,
          (Array.isArray(continueAddingShift) &&
            continueAddingShift.length > 0) ||
            selectedShiftTime
            ? [
                ...(Array.isArray(continueAddingShift)
                  ? continueAddingShift
                  : []),
                ...tempBankHoliday,
              ]
            : continueAddingShift,
          activeBenefits,
          'holiday',
        );

        if (
          Array.isArray(continueAddingShift) &&
          continueAddingShift.length > 0
        ) {
          setContinueAddingShift(prevArray =>
            Array.isArray(prevArray)
              ? [...prevArray, ...tempBankHoliday]
              : [...tempBankHoliday],
          );
        }
        if (weekdays.length > 0) {
          let tempWeekdays = [];
          if (weekendAndHolidays) {
            tempWeekdays = [
              {
                shift_time: shiftTime,
                vacancies: numberOfVacancies,
                emergency,
              },
            ];
          } else {
            tempWeekdays = [
              {
                shift_time: shiftTime,
                vacancies: numberOfVacancies,
                price: selectedrateCardDetails,
                emergency,
              },
            ];
          }
          handleSaveVacanciesWithMultipleShift(
            weekdays,
            selectedData.selectedJobTitle,
            postAgencyData.selectedBusinessUnit,
            (Array.isArray(continueAddingShift) &&
              continueAddingShift.length > 0) ||
              selectedShiftTime
              ? [
                  ...(Array.isArray(continueAddingShift)
                    ? continueAddingShift
                    : []),
                  ...tempWeekdays,
                ]
              : continueAddingShift,
            activeBenefits,
            'weekdays',
          );

          if (
            Array.isArray(continueAddingShift) &&
            continueAddingShift.length > 0
          ) {
            setContinueAddingShift(prevArray =>
              Array.isArray(prevArray)
                ? [...prevArray, ...tempWeekdays]
                : [...tempWeekdays],
            );
          } else {
            setContinueAddingShift(tempWeekdays);
          }
        }
        if (weekends.length > 0) {
          let tempWeekends = [];
          if (weekendAndHolidays) {
            tempWeekends = [
              {
                shift_time: shiftTime,
                vacancies: numberOfVacancies,
                emergency,
              },
            ];
          } else {
            tempWeekends = [
              {
                shift_time: shiftTime,
                vacancies: numberOfVacancies,
                price: selectedrateCardDetails,
                emergency,
              },
            ];
          }
          handleSaveVacanciesWithMultipleShift(
            weekends,
            selectedData.selectedJobTitle,
            postAgencyData.selectedBusinessUnit,
            (Array.isArray(continueAddingShift) &&
              continueAddingShift.length > 0) ||
              selectedShiftTime
              ? [
                  ...(Array.isArray(continueAddingShift)
                    ? continueAddingShift
                    : []),
                  ...tempWeekends,
                ]
              : continueAddingShift,
            activeBenefits,
            'weekends',
          );

          if (
            Array.isArray(continueAddingShift) &&
            continueAddingShift.length > 0
          ) {
            setContinueAddingShift(prevArray =>
              Array.isArray(prevArray)
                ? [...prevArray, ...tempWeekends]
                : [...tempWeekends],
            );
          } else {
            setContinueAddingShift(tempWeekends);
          }
        }
        props.closeAddNewVacancy();
      } else {
        setError({
          ...error,
          shiftTime: messageConstant.DUPLICATE_SHIFT,
          isError: true,
        });
      }
      if (error.isError) return;
    }
  };
  return (
    <>
      <form>
        <div className="flx-clone-shift-container">
          <div className="flx-clone-container-icons">
            <img
              className="flx-rate-card-title-date-edit"
              src="/assets/img/job-post-edit.svg"
            />
            <button onClick={e => handleCloseVacancy(e)}>
              <img
                className="flx-rate-card-title-date-delete"
                src="/assets/img/job-post-delete.svg"
              />
            </button>
          </div>
          <div className="flx-final-shift-date-title-container">
            <div className="form-group">
              <label
                htmlFor="date"
                className="flx-job-posts-labels flx-recs-required"
              >
                Choose date{' '}
              </label>

              <div>
                <DatePicker
                  value={selectedData.dates}
                  onChange={date => handleDateChange(date)}
                  minDate={minSelectableDate}
                  id="flx-date"
                  multiple
                  sort
                  style={{ width: '620px' }}
                  arrow={false}
                  format={'DD/MM/YYYY'}
                  placeholder="Select date from the calendar "
                  className="custom-calendar"
                  calendarPosition="bottom-center"
                />
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="jobTitle"
                className="flx-job-posts-labels flx-recs-required"
              >
                Job Title{' '}
              </label>
              {(jobTitleList == null || jobTitleList?.length <= 1) && (
                <input
                  type="text"
                  className="form-control"
                  id="flx-location"
                  value={jobTitleList ? jobTitleList.name : ''}
                  placeholder={`You have't added any job title`}
                />
              )}
              {jobTitleList?.length > 0 && (
                <select
                  className="form-control select-custom-style"
                  id="flx-location"
                  onChange={e => handleSelectedJobTitle(e)}
                >
                  <option
                    value=""
                    selected={!selectedData.selectedJobTitle}
                    disabled
                  >
                    Add your job title
                  </option>
                  {jobTitleList.map((list, key) => (
                    <option
                      value={list._id}
                      key={key}
                      selected={list._id == selectedData.selectedJobTitle?._id}
                    >
                      {list.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div className="container my-3">
            {/*If another shift button is clicked, the current shift will be saved and appear on the top of the form */}
            {continueAddingShift?.map((shift, index) => (
              <table
                key={index}
                className="table flx-current-shift-saved-table"
              >
                <thead>
                  <tr>
                    <th scope="col">Shift time</th>
                    <th scope="col">Vacancies</th>
                    <th scope="col">Price</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody key={index}>
                  <tr>
                    <td>
                      {shift?.shift_time.name}
                      <br />
                      {shift?.shift_time.from_time}-{shift?.shift_time.to_time}
                    </td>
                    <td className="">{shift.vacancies}</td>
                    <td>£{shift?.price?.price}</td>
                    <td>
                      <div>
                        <button
                          onClick={event => handleEditShift(shift, event)}
                        >
                          <img
                            className="flx-shift-date-edit"
                            src="/assets/img/job-post-edit.svg"
                          />
                        </button>
                        <button onClick={() => handleDeleteShift(index)}>
                          <img
                            className="flx-shift-date-delete"
                            src="/assets/img/job-post-delete.svg"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
            <span className="color-red">{error.shiftTime}</span>
            {/*If another shift button is clicked, the current shift will be saved block ends */}
            <div className="row flx-rate-card-query-main-container">
              <div className="col-md-6 mb-3">
                <label
                  htmlFor="shiftTime"
                  className="form-label flx-job-posts-labels flx-recs-required "
                >
                  Shift time
                </label>
                <button type="button" className="flx-rate-card-add-shift">
                  <i
                    className="fas fa-plus flx-icon-plus"
                    onClick={e => {
                      e.preventDefault();
                      setAddShiftTimeModal('addshifttime');
                    }}
                  ></i>{' '}
                  <span className="flx-add-new-shift-text">
                    Create new shift
                  </span>
                </button>
                {(shiftTimeList == null || shiftTimeList?.length <= 1) && (
                  <input
                    type="text"
                    className="form-control"
                    id="flx-location"
                    value={shiftTimeList ? shiftTimeList.name : ''}
                    placeholder={
                      shiftTimeList?.length > 0
                        ? ''
                        : "You haven't added any shift list"
                    }
                  />
                )}
                {shiftTimeList?.length > 1 && (
                  <select
                    className="form-control"
                    id="flx-location"
                    value={selectedShiftTime}
                    onChange={handleSelectshiftName}
                  >
                    <option value="" selected disabled>
                      Select shift
                    </option>
                    {shiftTimeList.map((list, key) => (
                      <option value={list._id} key={key}>
                        {list.name} - {list.from_time} to {list.to_time}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-md-6 mb-3 d-flex">
                <div className="flx-num-vacancies-container">
                  <label
                    htmlFor="noOfVacancies"
                    className="form-label flx-job-posts-labels flx-recs-required"
                  >
                    No of vacancies
                  </label>

                  <div className="flx-num-vacancies-section">
                    <button
                      className="flx-button-grp-addition-minus"
                      onClick={e => handleDecrement(e)}
                      disabled={numberOfVacancies === 1}
                    >
                      <i className="fa fa-minus flx-icon-rate-card-minus"></i>
                    </button>

                    <input
                      type="number"
                      className="text-center flx-no-vacancy-field"
                      value={numberOfVacancies}
                      aria-label="Number of vacancies"
                      id="noOfVacancies"
                    />
                    <button
                      className="flx-button-grp-addition-plus"
                      onClick={e => handleIncrement(e)}
                      disabled={numberOfVacancies === 10}
                    >
                      <i className="fa fa-plus flx-icon-rate-card-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="flx-rate-card-add-another-shift">
                  <button
                    type="button"
                    className="flx-add-shift"
                    disabled={!activeAnotherShiftButton}
                    onClick={() => {
                      handleAddAnotherShift(
                        shiftTime,
                        numberOfVacancies,
                        selectedrateCardDetails,
                      );
                    }}
                  >
                    <i
                      className={`fas fa-plus flx-icon-plus-another-shift ${
                        activeAnotherShiftButton
                          ? ''
                          : 'flx-icon-plus-another-shift-diabled'
                      }`}
                    ></i>{' '}
                    <br />
                    <span className="flx-add-another-shift-text ">
                      Add another shift
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* rate cards regular*/}
          {isLoading && <LoadingIcon loadingText={'Loading...'} />}

          {!weekendAndHolidays && (
            <div className="container mt-5 flx-agency-job-post-regular-ratecards">
              {!isSLAUser && (
                <div className="flx-rate-card-container text-center">
                  {rateCardList?.map((rateCard, index) => (
                    <div
                      key={index}
                      className={`flx-rate-card ${
                        selectedCard === rateCard.rate_card_type
                          ? 'flx-card-container-active'
                          : ''
                      }`}
                      onClick={() => {
                        handleCardClick(rateCard.rate_card_type);
                        handleSelectedRateCard(rateCard);
                      }}
                    >
                      {rateCard.most_used === 1 && (
                        <span
                          className={`flx-rate-card-most-used-label ${
                            selectedCard === rateCard.rate_card_type
                              ? 'bg-white text-primary'
                              : ''
                          }`}
                        >
                          {rateCard.most_used ? 'Most Used' : ''}
                        </span>
                      )}
                      <h6 className="flx-rate-card-title">
                        {rateCard.rate_card_type}
                      </h6>
                      <p className="flx-card-text">
                        {`£${rateCard.price} `}
                        {rateCard.originalPrice && (
                          <span className="flx-original-price">
                            <s>{`£${rateCard.originalPrice}`}</s>
                          </span>
                        )}
                        <p className="flx-quantity-ratecard">per hour</p>
                      </p>
                      <hr></hr>
                      <p className="flx-admin-charge-title">Admin charge</p>
                      <p className="flx-admin-charge">
                        {rateCard.admin_fee_is_percentage
                          ? `${rateCard.admin_fee}%`
                          : `${rateCard.admin_fee}p`}
                        <span className="flx-per-hour-text">/ hour</span>
                      </p>
                      <button
                        className="btn btn-details btn-sm ratecard-buttons"
                        onClick={e => {
                          e.preventDefault();
                          setRateCardModal('ratecarddetails');
                        }}
                      >
                        Details
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="form-group flx-other-benefits-section ">
            <label className="flx-job-posts-labels">Other Benefits</label>
            <ul className="flx-job-post-other-benefits">
              {defaultBenefits?.map((benefit, index) => (
                <li
                  className={`flx-job-post-other-benefits-li ${
                    activeBenefits?.find(b => b === benefit._id)
                      ? 'flx-job-post-other-benefits-li-active'
                      : ''
                  }`}
                  key={index}
                  onClick={() => toggleBenefits(benefit._id)}
                >
                  {benefit._id === 8 && (
                    <img
                      src="/assets/img/flx-paid-break-other-benefits.svg"
                      className="flx-other-benefits-icons"
                      alt="Paid Break"
                    />
                  )}
                  {benefit._id === 9 && (
                    <img
                      src="/assets/img/flx-travel-reimb.svg"
                      className="flx-other-benefits-icons"
                      alt="Pick-up & Drop-off"
                    />
                  )}
                  {benefit._id === 10 && (
                    <img
                      src="/assets/img/flx-other-benefits-cashback.svg"
                      className="flx-other-benefits-icons"
                      alt="travel teimbursement"
                    />
                  )}
                  {benefit.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="flx-prev-candidates">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              disabled={!candidate || candidate.length === 0}
              onChange={handleShowCandidates}
            />

            <label
              className="form-check-label prefer-prevoius-candidate"
              htmlFor="flexCheckDefault"
            >
              Give priority to previously worked candidates
            </label>
          </div>
          {/* prev worked candidates card */}
          {showCandidate && (
            <>
              <h5 className="flx-prev-worked-candidate-selection-text mt-2">
                Select from candidate who successfully completed the previous
                shift
              </h5>
              <div className="d-flex flx-prev-candidate-container-main">
                {candidate.slice(0, 10).map((list, index) => (
                  <div
                    className={`flx-prev-candidate-card-container ${
                      selectedCandidates.includes(list._id)
                        ? 'flx-prev-candidate-active'
                        : ''
                    }`}
                    key={index}
                    onClick={() => handleSelectedCandidates(list._id)}
                  >
                    <img
                      // src="/assets/img/demo-img-prev-worked-candidate.png"
                      src={
                        list.profileimage ||
                        '/assets/img/demo-img-prev-worked-candidate.png'
                      }
                      className="prev-candidate-img-top"
                    />
                    <div className="flx-prev-candidate-card-body">
                      <h6 className="flx-prev-candidate-card-title">
                        {list.full_name}
                      </h6>

                      {/*  the star rating */}
                      <span className="flx-prev-candidate-card-stars">
                        {Array.from({ length: list.rating }, (_, index) => (
                          <i
                            key={index}
                            className="fa fa-star flx-prev-candidate-rating flx-rated"
                          ></i>
                        ))}
                        {Array.from({ length: 5 - list.rating }, (_, index) => (
                          <i
                            key={list.rating + index}
                            className="fa fa-star flx-prev-candidate-rating"
                          ></i>
                        ))}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <button
            type="button"
            className={`btn border mt-2 ${
              activeSaveButton ? 'verify-btn' : ''
            }`}
            disabled={!activeSaveButton}
            onClick={e => handleSaveVacancies(e)}
          >
            Save
          </button>

          {/* rate card form ends*/}
        </div>
      </form>
      <div
        id="AddShiftTime"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          addshifttimeShowModal == 'addshifttime' ? 'show' : ''
        }`}
        style={{
          display: addshifttimeShowModal == 'addshifttime' ? 'block' : 'none',
        }}
      >
        <AddShiftTimeForm
          handleCloseModal={setAddShiftTimeModal}
          existingTimeList={shiftTimeList}
        />
      </div>

      <div
        id="RateCardModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          ratecardShowModal == 'ratecarddetails' ? 'show' : ''
        }`}
        style={{
          display: ratecardShowModal == 'ratecarddetails' ? 'block' : 'none',
        }}
      >
        <RateCardDetails
          handleCloseModal={setRateCardModal}
          detailsOfRateCard={selectedrateCardDetails}
        />
      </div>
    </>
  );
};
function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedShiftFinal = memo(connect(mapStateToProps)(AddNewShift));
export { connectedShiftFinal as AddNewShift };
