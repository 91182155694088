import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const allocatecandidateService = {
  allocatecandidateAdd,
  allocatecandidateList,
  allocatecandidateDelete,
  // blockBookingList,
};

function allocatecandidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/addcandidateemployerallocation',
    requestOptions,
  ).then(miscService.handleResponse);
}
function allocatecandidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateemployerallocationlist',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function blockBookingList(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/blockbookinglist', requestOptions).then(
//     handleResponse,
//   );
// }
function allocatecandidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deletecandidateemployerallocation',
    requestOptions,
  ).then(miscService.handleResponse);
}
