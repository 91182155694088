import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { payoutActions, shiftsActions } from '../_actions';
import { LoadingIcon } from '../_components';

const Payout = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [Payout, setPayout] = useState({
    name: '',
  });
  const [Shiftrows, setShiftrows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });

  // effect to load payouts and shifts , state query
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllPayout(1, query);
      getAllShifts(id);
    }
  }, []);
  //------set list and pageindation eith shift rows ----------
  const setListAndPagination = shiftDataList => {
    let shiftList = [];
    for (let s = 0; s < shiftDataList.length; s++) {
      shiftList.push(
        <div className="row">
          <div className="col-md-2 border payout-table-body payout-tbl-bdy2">
            <label className="">
              {' '}
              <b>
                {shiftDataList[s]['Candidate.name']}{' '}
                {shiftDataList[s]['Candidate.last_name'] &&
                  shiftDataList[s]['Candidate.last_name']}
              </b>{' '}
            </label>
          </div>
          <div className="col-md-1 border payout-tbl-bdy2">
            {' '}
            <b>{shiftDataList[s].actual_hours_after_break_time} hrs</b>{' '}
          </div>
          <div className="col-md-2 border payout-tbl-bdy2">
            {' '}
            <b>{shiftDataList[s]['Employer.company_name']} </b>{' '}
          </div>
          <div className="col-md-2 border payout-tbl-bdy2">
            {' '}
            <b>
              {moment(shiftDataList[s].check_in_date_string).format(
                'DD-MMM-YYYY',
              )}
            </b>
          </div>
          <div className="col-md-1 border payout-tbl-bdy2">
            {' '}
            <b> {shiftDataList[s].attendance_check_in}</b>{' '}
          </div>
          <div className="col-md-1 border payout-tbl-bdy2">
            {' '}
            <b> {shiftDataList[s].attendance_check_out}</b>{' '}
          </div>
          <div className="col-md-1 border payout-tbl-bdy2">
            {' '}
            <b>£ {shiftDataList[s].price}</b>{' '}
          </div>
          <div className="col-md-2 border payout-tbl-bdy2">
            {' '}
            <b>
              £{' '}
              {(
                shiftDataList[s].actual_hours_after_break_time *
                shiftDataList[s].price
              ).toFixed(2)}
            </b>{' '}
          </div>
        </div>,
      );
    }
    setShiftrows(shiftList);
  };
  //------get all shift data for shift list under invioice ---------
  const getAllShifts = async payout_id => {
    const { dispatch } = props;
    //----------status:777 gets both deleted and active shifts-----------
    await dispatch(
      shiftsActions.shiftsList({ status: '', payout_id: payout_id }),
    ).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data.rows);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //-------------get Payout data----------
  const getAllPayout = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(payoutActions.payoutList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setPayout(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Payout">
      {isLoading && <LoadingIcon loadingText={"Loading..."}/>}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header p-0"></div>
          <div className="col-md-12">
            <div className="card-body">
              <div className="row">
                <div className=" col-md-12 d-flex justify-content-end m-2 p-0">
                  <div className="row invoicePrintTag m-1">
                    <a
                      data-cy="printshifts"
                      href={`/payoutprint/${Payout._id}`}
                      target="blank"
                    >
                      {' '}
                      <p className="f-12 fontwghtbold">Print </p>
                    </a>
                    <div>
                      <img
                        style={{ height: 15, width: 15, marginLeft: 5 }}
                        src="/assets/img/pdf.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="row d-flex justify-content-between pl-3 py-3">
                    <div className="page-titile-content">
                      <h4 className="card-title">PAYOUT</h4>
                    </div>
                    <div className="">
                      <img
                        src="/assets/img/recsy-temp-logo.svg"
                        className="d-inline-block align-top logo-img"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pl-0">
                  <div className=" border-invoice">
                    <div className="col-md-3">
                      <label className="">To</label>
                    </div>
                    <div className="ml-4">
                      <p className="invoice_element_main">
                        {' '}
                        {Payout['Candidate.name'] + ' '}
                        {Payout['Candidate.last_name'] &&
                          Payout['Candidate.last_name']}{' '}
                      </p>
                      <p className="invoice_element">
                        {' '}
                        {Payout['Candidate.address']},{' '}
                      </p>
                      <p className="invoice_element">
                        {' '}
                        {Payout['Candidate.location']}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 pl-2">
                    <div className="col-md-3 ">
                      {' '}
                      <label className="">Payout No </label>{' '}
                    </div>
                    <div className="col-md-1">:</div>
                    <div className="col-md-6">
                      {' '}
                      <b>PY0{Payout._id}</b>{' '}
                    </div>
                  </div>
                  <div className="row pl-2">
                    <div className="col-md-3">
                      <label className="">Issued :</label>
                    </div>
                    <div className="col-md-1">:</div>
                    <div className="col-md-6">
                      {' '}
                      <b>
                        {moment(Payout.createdAt).format('DD-MMM-YYYY')}
                      </b>{' '}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pr-0">
                  <div className="custom_card">
                    <div className="row ml-1">
                      <div className="col-md-8">
                        {' '}
                        <label className="">From</label>{' '}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 ml-5">
                        <label className="invoice_desc ">Recsy Ltd.</label>
                        <label className="invoice_desc">
                          {' '}
                          10-12 Southernhay Basildon Essex SS14 1EL United
                          kingdom www.recsy.com
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 ">
                <div className="col-md-2 bg-invoice-tab payout-header table-header-ht">
                  <label className="">Candidate</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">Total Hours</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Employer</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">check in date</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">check in</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">Check out</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">Amount/hr</label>
                </div>
                <div className="col-md-2 bg-invoice-tab payout-header1">
                  <label className="">Total Amount</label>
                </div>
              </div>
              {/* //-----------new fieds added ---------- */}
              {/* //------------Payout single details ends herer ----------- */}
              {Shiftrows}
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-end border">
                  <label className="f18 mr-4">SUB TOTAL :</label>
                  <label className="f18">
                    £ {parseFloat(Payout.total_amount).toFixed(2)}{' '}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedPayout = connect(mapStateToProps)(Payout);
export { connectedPayout as Payout };
