import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const sectionService = {
  sectionAdd,
  sectionList,
  // sectionDelete,
  getgraphData,
  changeStatusShiftTime,
};

function sectionAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addsection', requestOptions).then(
    miscService.handleResponse,
  );
}
function sectionList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getsectionList', requestOptions).then(
    miscService.handleResponse,
  );
}
// function sectionDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deletesection', requestOptions).then(
//     handleResponse,
//   );
// }
function getgraphData(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordsectionreport',
    requestOptions,
  ).then(miscService.handleResponse);
}
function changeStatusShiftTime(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/changestatusshifttime',
    requestOptions,
  ).then(miscService.handleResponse);
}
