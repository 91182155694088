import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { invoiceService } from '../_services';
export const invoiceActions = {
  invoiceAdd,
  agencyinvoiceAdd,
  invoiceUpdate,
  invoiceList,
  invoiceListAgency,
  // invoiceDelete,
  // invoiceGenerate,
  invoiceBulkGenerate,
};

function invoiceAdd(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.INVOICE_REQUEST, invoiceObj });

      invoiceService.invoiceAdd(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function agencyinvoiceAdd(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.INVOICE_REQUEST, invoiceObj });

      invoiceService.agencyinvoiceAdd(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function invoiceUpdate(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.INVOICE_REQUEST, invoiceObj });

      invoiceService.invoiceUpdate(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function invoiceBulkGenerate(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.INVOICE_REQUEST, invoiceObj });

      invoiceService.invoiceBulkGenerate(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function invoiceGenerate(invoiceObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({ type: reducerConstants.INVOICE_REQUEST, invoiceObj });

//       invoiceService.generateInvoice(invoiceObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.INVOICE_SUCCESS,
//             data,
//           });

//           if (data.error_code === 0) {
//             resolve(data.data);
//             dispatch(alertActions.success(data.message.toString()));
//           } else {
//             reject(data.data);

//             dispatch(alertActions.error(data.message.toString()));
//           }
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.INVOICE_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function invoiceList(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.INVOICE_REQUEST,
        invoiceObj,
      });

      invoiceService.invoiceList(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function invoiceListAgency(invoiceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.INVOICE_REQUEST,
        invoiceObj,
      });

      invoiceService.invoiceListAgency(invoiceObj).then(
        data => {
          dispatch({
            type: reducerConstants.INVOICE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.INVOICE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function invoiceDelete(invoiceObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.INVOICE_REQUEST,
//         invoiceObj,
//       });

//       invoiceService.invoiceDelete(invoiceObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.INVOICE_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.INVOICE_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
