import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { agencycandidateActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { AgencyCandidateProfile } from './AgencyCandidateProfile';

const AgencyCandidateList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [candidateRows, setCandidateRows] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  //---csv states -----
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
    status: 1,
  });
  // effect to load candidates
  useEffect(() => {
    getAllCandidate(query.pageVo.pageNo);
  }, [query]);
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // loader for category list in select options
  const getCategorylist = () =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcategorylist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({ status: 1, select: ['_id', 'name'] }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data.rows);
            resolve(
              newArray.map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
            );
          }),
        )
        .catch(reject);
    });

  const deletecandidate = () => {
    const { dispatch } = props;
    if (isDeleteLoading) {
      return;
    }
    setIsDeleteLoading(true);
    dispatch(
      agencycandidateActions.agencycandidateDelete({
        status: 0,
      }),
    ).then(
      () => {
        setdeleteconfirmationmodal(false);
        setIsDeleteLoading(false);
        getAllCandidate(1);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  //---------get all candidates list -------------
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (props.isHomepage) {
      queryNew.isverified = 0;
    }
    dispatch(agencycandidateActions.agencycandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
        setCandidateData(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters
  const handleChange = event => {
    const { name, value } = event.target;
    setQuery(prevstate => ({
      ...prevstate,
      [name]: value,
    }));
  };
  // handler category select
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
  };
  //-----------list and pagination data for candidate data --------------
  const setListAndPagination = candidateData => {
    let candidateList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      candidateList.push(
        <tr key={j}>
          <td>
            {2000 +
              (candidateData &&
                candidateData.rows &&
                candidateData.rows[j]._id &&
                candidateData.rows[j]._id)}{' '}
          </td>
          <td className="username">
            {' '}
            <div className="d-flex">
              {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].profileimage ? (
                <img
                  className=" round-emp-image"
                  src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
                />
              ) : (
                <img
                  className=" round-emp-image"
                  src="/assets/img/recsy-fav-icon.png"
                />
              )}
              <div
                onClick={() => {
                  setSelectedCandidateId(candidateData.rows[j]._id),
                    setshowCandidateprofileModal(true);
                }}
                style={{ color: '#404144', cursor: 'pointer' }}
              >
                {' '}
                {candidateData.rows[j].name}{' '}
              </div>
            </div>
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].Category &&
              candidateData.rows[j].Category.name}
          </td>
          <td>
            <a
              style={{ color: '#1ac8ff' }}
              href={
                'https://www.google.com/maps/search/?api=1&query=' +
                candidateData.rows[j].lat +
                ',' +
                candidateData.rows[j].long
              }
            >
              {candidateData &&
                candidateData.rows &&
                candidateData.rows[j].city &&
                candidateData.rows[j].city}
            </a>
            <br />
            {candidateData.rows[j].postcode
              ? candidateData.rows[j].postcode
              : ''}
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].Category &&
              candidateData.rows[j].service_type}
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].createdAt &&
              moment(candidateData.rows[j].createdAt).format('DD-MMM-YYYY')}
          </td>
          <td className="eventCell  eventView">
            <a href={'/agencycandidate/' + candidateData.rows[j]._id}>View</a>
          </td>
        </tr>,
      );
    }
    setCandidateRows(candidateList);
  };
  // custom styles for select box
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      paddingBottom: '6px',
      height: '26px',
      width: '200px',
      lineHeight: '25px',
      background: '#F4F4F4',
      borderRadius: '12px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000',
    }),
  };
  return (
    <div className="CandidateList ">
      <div className="row mr-1 ml-1">
        <div className="col-md-12 col-sm-12  ">
          <div className="card regular-table-with-color">
            {!props.isHomepage && (
              <div className="card-header page-header mr-3 ">
                <div className="row mb-3 agency-title">
                  <div className="col-md-8 ">
                    <div className="page-title ">Agency Candidate</div>
                  </div>
                </div>
                <div className="row pr-0 pb-2">
                  <div className="col-md-3 agency-candidate-row2 ">
                    <p>
                      <div className="input-outer-div input-design1">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-2 mr-0">
                    <select
                      className="select col-md-12 selectDesign39 input-design1"
                      name="service_type"
                      value={query.service_type}
                      onChange={handleChange}
                      placeholder={'select service sector'}
                    >
                      <option value=""> ALL</option>
                      <option value="Hospitality"> Hospitality</option>
                      <option value="Health Care"> Health Care</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <div className="">
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="category"
                        onChange={category_change}
                        placeholder="select category"
                        cacheOptions
                        defaultOptions
                        loadOptions={getCategorylist}
                      />
                    </div>
                  </div>
                  {/* //==============filter end ---------------- */}
                  <div className="col-md-2 d-flex justify-content-end mr-0 ml-auto">
                    <a
                      className="btn btn-primary-blue btn-primary-blue1"
                      href="/agencycandidateadd/0"
                    >
                      <i className="flx-assign-user-icon fa fa-user-plus ml-2"></i>
                      <span className="pr-3"> Add Candidate</span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body  table-responsive main-card-body flx-table-border ">
              {!isLoading && (
                <table className="table  flx-custom-table">
                  <thead>
                    <tr className="flx-table-header-clr">
                      <th
                        className="table-header-ht"
                        style={{ width: '160px' }}
                      >
                        Id
                      </th>
                      <th
                        style={{ width: '300px' }}
                        onClick={() => handleSort('name')}
                        className="sortHead flx-agency"
                      >
                        Name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th className="flx-agency">Jobrole</th>
                      <th className="flx-agency">Location</th>
                      <th className="flx-agency">Service Sector</th>
                      <th
                        onClick={() => handleSort('createdAt')}
                        className="sortHead flx-agency"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{candidateRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
              {candidateRows && candidateRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllCandidate(data);
                }}
                paginationData={candidateData}
              />
            </div>
          </div>
        </div>
        {deleteconfirmationmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setdeleteconfirmationmodal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <p className="delete_string" style={{ color: 'black' }}>
                          Are you sure ?{' '}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setdeleteconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            onClick={() => deletecandidate()}
                          >
                            {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCandidateprofileModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setshowCandidateprofileModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <AgencyCandidateProfile candidate_id={SelectedCandidateId} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedAgencyCandidateList =
  connect(mapStateToProps)(AgencyCandidateList);
export { connectedAgencyCandidateList as AgencyCandidateList };
