import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SwitchModal = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [switchText, setSwitchText] = useState('');
  const handleRecsyHireClick = () => {
    setSwitchText('Switching to Recsy Blaze');
    setIsLoading(true);
    window.location.href = 'https://blazehr.co.uk/';
    setIsLoading(false);
  };

  const handleRecsyTempClick = () => {
    setSwitchText('Switching to Recsy Perm');
    setIsLoading(true);
    window.location.href = 'https://employer.recsy.co.uk/';
    setIsLoading(false);
  };

  const handleRecsyInternationalClick = () => {
    setSwitchText('Switching to Recsy International');
    setIsLoading(true);
    window.location.href = 'https://recsy.co.uk/';
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex flex-wrap flex-column justify-content-center align-items-center"
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            backgroundColor: '#fff',
          }}
        >
          <img
            src="/assets/img/loader.gif"
            alt="Loading..."
            style={{ width: '50px', height: '50px' }}
          />
          <div>{switchText}</div>
        </div>
      ) : (
        <div
          className={`switch_modal-overlay ${show ? 'show' : ''}`}
          onClick={onClose}
        >
          <div
            className={`switch_modal ${show ? 'show' : ''}`}
            onClick={e => e.stopPropagation()}
          >
            <div
              className="d-flex flex-column flex-wrap"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              <div className="d-flex flex-column flex-wrap mb-4 ">
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: 'rgba(255, 255, 255, 1)',
                  }}
                >
                  Our Apps
                </div>
                <div className="header-line"></div>
              </div>
              <div className="d-flex flex-wrap" style={{ gap: '35px' }}>
                <div
                  onClick={handleRecsyHireClick}
                  className="d-flex flex-column flex-wrap pointer"
                  style={{ alignItems: 'center', maxWidth: '80px' }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      height: '60px',
                      width: '60px',
                      alignContent: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      style={{ width: '31px', height: '29px', flexShrink: 0 }}
                      src="/assets/img/blaze-icon.png"
                      className="pointer align-self-center"
                    />
                  </div>
                  <div
                    style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 400 }}
                  >
                    <span style={{ fontWeight: 700 }}>Blaze</span> HR
                  </div>
                </div>
                <div
                  onClick={handleRecsyInternationalClick}
                  className="d-flex flex-column flex-wrap pointer"
                  style={{ alignItems: 'center', maxWidth: '80px' }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 1)',
                      height: '60px',
                      width: '60px',
                      alignContent: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      style={{ width: '31px', height: '29px', flexShrink: 0 }}
                      src="/assets/img/recsyhire.png"
                      className="pointer align-self-center"
                    />
                  </div>
                  <div
                    style={{
                      color: 'rgba(255, 255, 255, 1)',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>Recsy</span> International
                  </div>
                </div>
                <div
                  onClick={handleRecsyTempClick}
                  className="d-flex flex-column flex-wrap pointer"
                  style={{ alignItems: 'center', maxWidth: '80px' }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      height: '60px',
                      width: '60px',
                      alignContent: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      style={{ width: '31px', height: '29px', flexShrink: 0 }}
                      src="/assets/img/recsytemp.png"
                      className="pointer align-self-center"
                    />
                  </div>
                  <div
                    style={{ color: 'rgba(255, 255, 255, 1)', fontWeight: 400 }}
                  >
                    <span style={{ fontWeight: 700 }}>Recsy</span> Perm
                  </div>
                </div>
              </div>
              <div className="header-line2 mt-2"></div>
              {/* <div className='mt-4' style={{ width: '100%', height: '0.5px', backgroundColor: 'rgba(255, 255, 255, 1)' }}></div> */}
              <div
                className="mt-4"
                style={{
                  border: '1px solid rgba(255, 255, 255, 1)',
                  borderRadius: '5px',
                  padding: '10px 10px 10px 10px',
                  backgroundColor: 'rgba(76, 76, 76, 0.55)',
                  maxWidth: '348px',
                }}
              >
                <div className="d-flex flex-column flex-wrap">
                  <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                    <div
                      style={{
                        backgroundColor: 'rgba(26, 200, 255, 1)',
                        height: '60px',
                        width: '60px',
                        alignContent: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        borderRadius: '6px',
                      }}
                    >
                      <img
                        style={{ width: '25px', height: '25px', flexShrink: 0 }}
                        src="/assets/img/recsylms.png"
                        className="pointer align-self-center"
                      />
                    </div>
                    <div
                      className="d-flex flex-column flex-wrap"
                      style={{
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        gap: '5px',
                      }}
                    >
                      <div
                        style={{
                          color: 'rgba(255, 255, 255, 1)',
                          fontWeight: 400,
                          textAlign: 'center',
                        }}
                      >
                        <span style={{ fontWeight: 600 }}>Recsy</span> LMS
                      </div>
                      <div style={{ gap: '5px' }} className="d-flex flex-wrap">
                        <img
                          style={{
                            width: '14px',
                            height: '14px',
                            flexShrink: 0,
                          }}
                          src="/assets/img/freetrial.png"
                          className="pointer"
                        />
                        <div
                          style={{
                            color: 'rgba(255, 255, 255, 1)',
                            fontStyle: '14px',
                            fontWeight: 400,
                            textAlign: 'center',
                          }}
                        >
                          3O day free trial
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 text-align-left"
                    style={{
                      color: 'rgba(255, 255, 255, 1)',
                      fontStyle: '14px',
                      fontWeight: 400,
                      textAlign: 'left',
                      maxWidth: '295px',
                    }}
                  >
                    Manage all aspects of your sales cycle and customer data
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      border: '1px solid rgba(255, 255, 255, 1)',
                      borderRadius: '5px',
                      padding: '10px 10px 10px 10px',
                      maxWidth: '115px',
                      alignSelf: 'flex-end',
                      backgroundColor: 'rgba(103, 105, 120, 1)',
                    }}
                  >
                    <div
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        fontStyle: '14px',
                        fontWeight: 400,
                        textAlign: 'center',
                      }}
                    >
                      Coming soon
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SwitchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SwitchModal;
