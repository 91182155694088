import React from 'react';
import { Link } from 'react-router-dom';

function DashBoardCard({ title, count, linkTo, icon, style, backgroundColor }) {
  return (
    <Link to={linkTo} className={style}>
      <div className="inner-card d-flex justify-content-center align-items-center">
        <div
          className="inner-card-child"
          style={{ background: backgroundColor }}
        >
          <div className="inner-icon" style={{ margin: '13px 0px 0px 15px' }}>
            <img src={icon} alt={title} />
          </div>
        </div>

        <div className="d-flex flex-column text-center">
          <div className="counts">{count ? count : 0}</div>
          <div className="title">{title}</div>
        </div>
      </div>
    </Link>
  );
}

export default DashBoardCard;
