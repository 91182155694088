import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BlockBookinglist } from '../Block_booking';
import { AllShiftsList } from '../Shifts';
// import { PendingSignOff } from './PendingSignOff';
const Shiftmenu = props => {
  let routeKey = props.isrouteKey;
  //const [Allshiftstoggle, setAllshiftstoggle] = useState(true);
  const [TabSelected, setTabSelected] = useState(1);

  useEffect(() => {
    const { isrouteKey } = props;
    routeKey = isrouteKey;
  }, [props.isrouteKey]);

  return (
    <>
      <div className="CandidateList ">
        <div className="row">
          <div className="col-md-12 col-sm-12 px-0">
            <div className="card regular-table-with-color mx-4 px-2">
              <div className="card-header page-header p-1">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title"></div>
                  </div>
                </div>
              </div>
              {/* {false && (
              <div className="card-header page-header p-0">
                <div className="d-flex justify-content-center">
                  <div className="col-md-8 d-flex justify-content-center">
                    <button
                      className={
                        'col-md-3 col-sm-6 ' &&
                        (Allshiftstoggle
                          ? 'activestate col-md-3 col-sm-6'
                          : 'col-md-3 col-sm-6 nonactive ')
                      }
                      onClick={() => {
                        setTabSelected(2), setTabSelected(1);
                      }}
                    >
                      {' '}
                      All Shifts
                    </button>
                    <button
                      className={
                        'col-md-3 col-sm-6 ' &&
                        (TabSelected
                          ? 'activestate col-md-3 col-sm-6'
                          : 'col-md-3 col-sm-6 nonactive ')
                      }
                      onClick={() => {
                       setTabSelected(2);
                      }}
                    >
                      {' '}
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            )} */}
              <div className="tabsContainer">
                <div className="tabs">
                  {/* //-------------first tab---------- */}
                  <input
                    type="radio"
                    data-cy="list"
                    style={{ display: 'none' }}
                    id="radio-1"
                    name="tabs1"
                    onChange={() => setTabSelected(1)}
                    checked={TabSelected == 1 ? true : false}
                  />
                  <label className="tab" htmlFor="radio-1">
                    All Shifts
                  </label>
                  {/* //----------second tab----------- */}
                  <input
                    data-cy="Assign"
                    type="radio"
                    style={{ display: 'none' }}
                    id="radio-2"
                    name="tabs2"
                    onChange={() => setTabSelected(2)}
                    checked={TabSelected == 2 ? true : false}
                  />
                  <label className="tab" htmlFor="radio-2">
                    Assign
                  </label>

                  {/* //-------------Third tab---------- */}
                  {/* <input
                    type="radio"
                    data-cy="list"
                    style={{ display: 'none' }}
                    id="radio-3"
                    name="tabs1"
                    checked={TabSelected == 3 ? true : false}
                    onChange={() => setTabSelected(3)}
                  />
                  <label className="tab" htmlFor="radio-3">
                    Pending sign off
                  </label> */}

                  <span className="glider"></span>
                </div>
              </div>
              {TabSelected == 1 && (
                <AllShiftsList key={routeKey}></AllShiftsList>
              )}
              {TabSelected == 2 && (
                <BlockBookinglist key={routeKey}></BlockBookinglist>
              )}
              {/* {TabSelected === 3 && (
                <PendingSignOff key={routeKey}></PendingSignOff>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  const { misc } = state;
  return {
    isrouteKey: misc.isrouteKey,
  };
}
const connectedShiftmenu = connect(mapStateToProps)(Shiftmenu);
export { connectedShiftmenu as Shiftmenu };
