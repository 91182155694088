import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { employerActions } from '../_actions';
import { LoadingIcon,Pagination } from '../_components';
import { apiConstants } from '../_constants';

const DashbordCarehomeReport = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [carehomeData, setCarehomeData] = useState({ rows: [] });
  const [carehomeRows, setCarehomeRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  // effect for get employers
  useEffect(() => {
    if (props && props.ishomepage) {
      query.pageVo.noOfItems = 3;
    }
    getAllEmployer(1);
  }, [query]);

  /**
   * Get all Employer
   **/
  const getAllEmployer = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(employerActions.dashbordemployerreport(queryNew)).then(
      res => {
        setIsLoading(false);

        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = employerData => {
    let employerList = [];
    for (
      let j = 0;
      employerData &&
      employerData.rows &&
      employerData.rows.length &&
      j < employerData.rows.length;
      j = j + 1
    ) {
      employerList.push(
        <tr key={j}>
          <td>
            <div className="row d-flex align-items-center ml-2">
              <div className=" border-rad-55 ml-2 ">
                {employerData &&
                employerData.rows[j] &&
                employerData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={
                      apiConstants.imgUrl + employerData.rows[j].profileimage
                    }
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {employerData.rows[j].company_name}
                </p>
                <div className="row d-flex align-items-center">
                  <div className="col pr-3">
                    <span>{employerData.rows[j].email}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {employerData.rows[j].no_of_shifts
              ? employerData.rows[j].no_of_shifts
              : 0}
          </td>
          <td style={{ fontWeight: 'bold' }}>
            {' '}
            {employerData.rows[j].avg_candidate_price
              ? employerData.rows[j].avg_candidate_price
              : 0}
          </td>
          <td style={{ fontWeight: 'bold' }}>
            {' '}
            {employerData.rows[j].total_employer_pay
              ? employerData.rows[j].total_employer_pay
              : 0}
          </td>
          <td style={{ color: '#ec5832' }}>
            {' '}
            {employerData.rows[j].spend ? employerData.rows[j].spend : 0}
          </td>
        </tr>,
      );
    }
    setCarehomeRows(employerList);
    setCarehomeData(employerData);
  };
  return (
    <div className="EmployerList">
      <div className="row">
        <div className="col-md-12">
          <div className=" regular-table-with-color ">
            {!props.isHomepage && (
              <div className="card-header page-header "></div>
            )}
            <div
              className={
                !props.isHomepage
                  ? '  table-responsive main-card-body  '
                  : '  table-responsive main-card-body '
              }
            >
              <div className="d-flex justify-content-between">
                <div>
                  {' '}
                  <label>CAREHOME REPORT</label>
                </div>
                {props && props.ishomepage && (
                  <div>
                    <Link to="/dashbordemployerreport">
                      <button className="blackbutton">
                        Viewall<i className="fa fa-arrow-right ml-10"></i>
                      </button>
                    </Link>
                  </div>
                )}{' '}
              </div>
              {!isLoading && (
                <table className="table table-hover mt-4">
                  <thead>
                    <tr>
                      <th className="">Employer name</th>
                      <th>No of Shift</th>
                      <th>Average Price </th>
                      <th>Flexi Price</th>
                      <th>Spend </th>
                    </tr>
                  </thead>
                  <tbody>{carehomeRows}</tbody>
                </table>
              )}
              {!!isLoading && !props.ishomepage && <LoadingIcon loadingText={"Loading..."}/>}
              {carehomeRows && carehomeRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.ishomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllEmployer(data);
                  }}
                  paginationData={carehomeData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedDashbordCarehomeReport = connect(mapStateToProps)(
  DashbordCarehomeReport,
);
export { connectedDashbordCarehomeReport as DashbordCarehomeReport };
