import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { agencyActions, employerActions } from '../_actions';
import { Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';

const SubscribedAgencyList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [agencyData, setAgencyData] = useState({ rows: [] });
  const [agencyRows, setAgencyRows] = useState([]);
  const [agencySubscriptionModal, setAgencySubscriptionModal] = useState(false);
  const [allocatedagency_ids, setAllocatedagency_ids] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  const { dispatch } = props;
  let employerData = {};
  // to if user == admin
  let isadmin = false;
  let user = JSON.parse(localStorage.getItem('user'));
  if (user.role == 1 || user.role == '1') {
    isadmin = true;
  }
  //--- debouncing feature start ----
  const isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query.searchKey, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch) getAllAgency(1);
    }
  }, [debouncedSearch]);
  //--- debouncing feature end----
  // effect to load all agency
  useEffect(() => {
    getAllAgency(1);
  }, []);
  // handler for state query
  const handleChange = event => {
    const { name, value } = event.target;
    //-----------search query--------
    isfirsttime.current = false;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
    }
  };
  /**
   * Get all agencys
   **/
  const getAllAgency = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    dispatch(agencyActions.subscribedagencylist(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = agencyData => {
    let agencyList = [];
    for (
      let j = 0;
      agencyData &&
      agencyData.rows &&
      agencyData.rows.length &&
      j < agencyData.rows.length;
      j = j + 1
    ) {
      agencyList.push(
        <tr key={j}>
          {/* --------id starts from 2000 --------- */}
          <td> {2000 + agencyData.rows[j]._id} </td>
          <td>
            <div className="row d-flex align-items-center ml-2">
              <div className=" border-rad-55 ml-2 ">
                {agencyData &&
                agencyData.rows[j] &&
                agencyData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={apiConstants.imgUrl + agencyData.rows[j].profileimage}
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {agencyData.rows[j].company_name}
                </p>
                <div className="row d-flex align-items-center">
                  <div className="col pr-3">
                    <img className="ml-1 pin-image" src="/assets/img/pin.png" />
                    <span>{agencyData.rows[j].city}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].shift_completed
              : 0}
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].available_shift
              : 0}
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].upcoming_shifts
              : 0}
          </td>
          {isadmin && (
            <td>
              {' '}
              {agencyData.rows[j].total_amount
                ? '£' + ' ' + agencyData.rows[j].total_amount.toFixed(2)
                : '£' + ' ' + 0}{' '}
            </td>
          )}
          <td className="eventCell eventView">
            {' '}
            <a
              onClick={() => {
                changeSubscription(0, agencyData.rows[j].user_id);
              }}
            >
              Unsubscribe
            </a>
          </td>
          <td className="eventCell  eventView">
            <a data-cy="viewagency" href={'/agency/' + agencyData.rows[j]._id}>
              View
            </a>
          </td>
        </tr>,
      );
    }
    setAgencyRows(agencyList);
    setAgencyData(agencyData);
  };
  // to hide error
  //   const hideError = () => {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 3000);
  //   };

  const changeSubscription = (value, agency_id) => {
    let employer = employerData;
    // to check the agency id already exist
    // if exist remove id and save / if not add id and save

    if (value == 0) {
      employer.allocated_agency_user_ids = agency_id;
      employer.isSubscribe = 0;
    }
    if (value == 1) {
      employer.allocated_agency_user_ids = allocatedagency_ids.map(item => {
        return item.value;
      });
      employer.isSubscribe = 1;
    }
    setIsLoading(true);
    dispatch(employerActions.addSubscription(employer)).then(() => {
      setIsLoading(false);
      window.location.reload();
    });
  };
  // hander for agency filter selection
  const agency_change = selectedOption => {
    setAllocatedagency_ids(selectedOption);
  };
  // custom styles for select
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #A8A8A8',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      marginBottom: '30px',
      // background: '#f4f4f4',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
  };
  return (
    <div className="EmployerList">
      <div className="row">
        <div className="col-md-12 p-0">
          <div className="card regular-table-with-color mx-4 px-2">
            {!props.isHomepage && (
              <div className="card-header page-header py-2">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title mb-3 ml-2">
                      My Agency Subscription
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pl-2">
                  <div className="col-md-4  ">
                    <p>
                      <div className="input-outer-div input-design1">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search Here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>

                  {/* FROM DATE PICKER======== */}
                  <div className="col-md-8 pr-4 ">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-md-3 ">
                        <div
                          data-cy="addagency"
                          className="btn btn-primary-blue float-right flx-subscribe-agency "
                          onClick={() => {
                            setAgencySubscriptionModal(true);
                          }}
                        >
                          <i className="fa fa-user-plus pr-1"></i>
                          Subscribe Agency
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* card-body */}
            <div
              className={
                !props.isHomepage
                  ? 'card-body  table-responsive main-card-body  flx-table-border'
                  : '  table-responsive main-card-body '
              }
            >
              {!isLoading && (
                <table className="table flx-custom-table">
                  <thead>
                    <tr className="flx-table-header-clr">
                      <th className="border-top1 table-header-ht">ID</th>
                      <th className="">Employer Name</th>
                      <th>Shift Completed</th>
                      <th>Total Shift </th>
                      <th>Upcoming Shift </th>
                      {isadmin && <th>Payment</th>}
                      <th></th>
                      <th className="eventCell  eventView" />
                    </tr>
                  </thead>
                  <tbody>{agencyRows}</tbody>
                </table>
              )}
              {/* {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>} */}
              {agencyRows && agencyRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.isHomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllAgency(data);
                  }}
                  paginationData={agencyData}
                />
              </div>
            )}
          </div>
        </div>
        {agencySubscriptionModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setAgencySubscriptionModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center ">
                      <div className="col-md-8 ">
                        <label htmlFor="">Select Agency</label>
                        <AsyncSelect
                          styles={customStyles}
                          name="agency_id"
                          onChange={agency_change}
                          placeholder="Select Agency"
                          cacheOptions
                          defaultOptions
                          // className="agencyselectt1"
                          isMulti
                          loadOptions={getAgencylist}
                        />
                        <div className="mt-3 d-flex justify-content-center">
                          <button
                            style={{
                              borderRadius: '12px',
                            }}
                            className="button_delete width_100"
                            onClick={() => {
                              setAgencySubscriptionModal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            style={{
                              borderRadius: '12px',
                              background: '#1ac8ff',
                            }}
                            data-cy="deletebutton"
                            className="button_delete_close width_100"
                            onClick={() =>
                              changeSubscription(1, allocatedagency_ids)
                            }
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// loader for agency list in async select
const getAgencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/unsubscribedagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        select: ['user_id', 'company_name'],
        searchKey: inputvalue,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add an all select option
          //   let AllElement = { _id: 0, company_name: 'ALL' };
          //   let newArray = [AllElement].concat(data.rows);
          resolve(
            data.rows.map(({ user_id, company_name }) => ({
              value: user_id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, agencyData } = state.agency;
  return {
    requestStatus,
    agencyData,
  };
}

const connectedSubscribedAgencyList =
  connect(mapStateToProps)(SubscribedAgencyList);
export { connectedSubscribedAgencyList as SubscribedAgencyList };
