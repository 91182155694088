import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { vacancyshiftActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { bookingActions } from '../_actions';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
// eslint-disable-next-line no-unused-vars
let vacancyshiftRows = [];
let agency_id = '';

const AgencyvacancyList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [vacancyshiftData, setVacancyshiftData] = useState({ rows: [] });
  const [vacancyshiftRows, setVacancyshiftRows] = useState([]);
  const [showbookingModal, setshowbookingModal] = useState(false);
  const [bookedvacancydetails, setbookedvacancydetails] = useState([]);
  const [bookingdetails, setrbookingdetails] = useState({
    applied_date: new Date().toISOString().slice(0, 10),
  });
  const [viewdetails, setviewdetails] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const [carehome, setIsCarehome] = useState({});
  const { dispatch, vacancyshiftFilterDate } = props;
  const [IsAdmin, setisAdmin] = useState(false);
  // effect to load get vacancies
  useEffect(() => {
    getAllVacancyshift(query.pageVo.pageNo);
  }, [vacancyshiftFilterDate, query, carehome, bookingdetails.user]);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));

      agency_id = user.agency_id;
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        // setisEmployer(true);
      }
    }
    dispatch({
      type: 'vacancyshiftFilterDate',
      data: '',
    });
  }, []);
  // handler for select candidate
  const selectedcandidate = selectedOption => {
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      let obj = {
        value: selectedOption[i].label,
        _id: selectedOption[i].value,
      };
      SelectedValues.push(obj);
      setrbookingdetails(prevState => ({
        ...prevState,
        user: SelectedValues,
      }));
    }
    setrbookingdetails(prevState => ({
      ...prevState,
      no_of_vacancy: selectedOption.length,
    }));
  };
  // to book agency bidding
  const agencybook = vacancydata => {
    setshowbookingModal(true);
    if (vacancydata.maximum_candidate_bidding_amount) {
      setrbookingdetails({
        ...bookingdetails,
        amount: vacancydata.maximum_candidate_bidding_amount,
      });
    }
  };
  // handler for select carehome
  const selectedcarehome = (selectedOption, event) => {
    let name = event.name;
    setIsCarehome(() => ({
      [name]: selectedOption.value,
    }));
  };
  // call for get all vacancy shifts
  const getAllVacancyshift = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.employer_id = carehome.employer_id;
    queryNew.agencyvacancyshiftlist = true;
    dispatch(vacancyshiftActions.vacancyshiftListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for state bidding amount
  const handleChange = event => {
    const { name, value } = event.target;
    setError({ common: '' });
    setrbookingdetails({
      ...bookingdetails,
      [name]: value,
    });
    if (name == 'amount') {
      bookedvacancydetails.maximum_bidding_amount == 0;
    }
  };
  // booking add and validation checking
  const addbooking = () => {
    setSubmitted(true);
    const { dispatch } = props;
    setError({ common: '' });
    if (bookingdetails.no_of_vacancy <= 0) {
      setError({ common: 'no of vacancy required' });
      return;
    }
    if (bookingdetails.no_of_vacancy) {
      if (bookedvacancydetails.is_bidding_option_needed == 1) {
        if (!bookingdetails.amount) {
          setError({ common: 'Bidding amount required' });
          return;
        }
      }
      if (
        bookingdetails.no_of_vacancy > bookedvacancydetails.no_of_openings ||
        bookingdetails.no_of_vacancy < 0
      ) {
        setError({ common: 'max vacancy exceed' });
        bookingdetails.amount = '';
        return;
      }
      if (
        bookingdetails.amount <= 0 &&
        bookedvacancydetails.is_bidding_option_needed == 1
      ) {
        setError({ common: 'Bidding amount should be greater than zero' });
        bookingdetails.amount = '';
        return;
      }
      if (
        parseInt(bookingdetails.amount) >
          parseInt(bookedvacancydetails.maximum_candidate_bidding_amount) &&
        bookingdetails.no_of_vacancy &&
        bookedvacancydetails.is_bidding_option_needed == 1
      ) {
        setError({
          common:
            'Bidding amount greater than than maximum bidding amount' +
            ' ' +
            bookedvacancydetails.maximum_candidate_bidding_amount,
        });
        bookingdetails.amount = '';
        return;
      }
      setIsLoading(true);
      (bookingdetails.vacancy_id = bookedvacancydetails._id),
        (bookingdetails.employer_id = bookedvacancydetails.employer_id),
        (bookingdetails.booking_status = 0);
      bookingdetails.agency_id = agency_id;
      (bookingdetails.applied_date = new Date().toISOString().slice(0, 10)),
        dispatch(bookingActions.bookingAdd(bookingdetails)).then(
          () => {
            setIsLoading(false);
            setshowbookingModal(false);
            setrbookingdetails({});
            getAllVacancyshift(query.pageVo.pageNo);
          },
          err => {
            setError({
              common: err && err.message ? err.message : err,
            });
            setrbookingdetails({});
            setIsLoading(false);
          },
        );
      setIsLoading(false);
      bookingdetails.no_of_vacancy = '';
    } else {
      setError({ common: '' });
    }
  };
  // list and paginate vacancy data
  const setListAndPagination = vacancyshiftData => {
    let vacancyshiftList = [];
    for (
      let j = 0;
      vacancyshiftData &&
      vacancyshiftData.rows &&
      j < vacancyshiftData.rows.length;
      j = j + 1
    ) {
      let dt = new Date(vacancyshiftData.rows[j].from_date_string);
      const from_date =
        ('0' + dt.getDate()).slice(-2) +
        '-' +
        ('0' + (dt.getMonth() + 1)).slice(-2) +
        '-' +
        dt.getFullYear();
      let color = 0;
      if (vacancyshiftData.rows[j].name == 'Night') color = 1;
      else if (vacancyshiftData.rows[j].name == 'Morning') color = 2;
      else color = 0;
      vacancyshiftList.push(
        <tr key={j}>
          <td className="font-11">{from_date}</td>
          <td>{'FLX' + vacancyshiftData.rows[j]._id}</td>
          <td
            className={
              'font-11' &&
              (color == 1 ? 'night' : color == 2 ? 'morning' : 'custome')
            }
          >
            <p style={{ fontWeight: 'bold' }}>
              {vacancyshiftData.rows[j]['Section.section_type']}{' '}
            </p>
            <p style={{ color: '#183245' }}>
              {' '}
              {vacancyshiftData.rows[j].company_name}{' '}
            </p>
          </td>
          <td>{vacancyshiftData.rows[j].postcode}</td>
          <td>
            {' '}
            {vacancyshiftData.rows[j].from_time}-
            {vacancyshiftData.rows[j].to_time}{' '}
          </td>
          <td className="font-11">
            <div>
              {vacancyshiftData.rows[j].agencybookedcount
                ? vacancyshiftData.rows[j].agencybookedcount + ' of '
                : 0 + ' of '}
              {vacancyshiftData.rows[j].no_of_openings
                ? vacancyshiftData.rows[j].no_of_openings
                : 0}
            </div>
          </td>
          <td>£ {vacancyshiftData.rows[j].price} /hr</td>
          <td>
            {vacancyshiftData.rows[j].agencybookedcount > 0 ? (
              <p style={{ fontWeight: 'bold', color: '#0F723C' }}>yes</p>
            ) : (
              <p style={{ fontWeight: 'bold', color: '#DD3A3A' }}>No</p>
            )}
          </td>
          <td>
            {
              <div
                className="bookinganglearrow pl-4"
                // className="agency-viewdt"
                // style={{ background: 'rgba(94, 213, 249, 0.8)' }}
                style={{ color: '#1ac8ff', cursor: 'pointer' }}
                onClick={() => {
                  setviewdetails(vacancyshiftData.rows[j]),
                    setviewdetailsmodal(true);
                }}
              >
                View Details
              </div>
            }
          </td>
          <td>
            {vacancyshiftData.rows[j].confirmedbookingcount >=
            vacancyshiftData.rows[j].no_of_openings ? (
              <div className=" agency-book-filled ">Filled</div>
            ) : (
              <div
                className="agency-book ml-5"
                onClick={() => {
                  setbookedvacancydetails(vacancyshiftData.rows[j]),
                    agencybook(vacancyshiftData.rows[j]),
                    setrbookingdetails({
                      no_of_vacancy: 0,
                      amount:
                        vacancyshiftData.rows[j]
                          .maximum_candidate_bidding_amount,
                    }),
                    setError({ common: '' });
                }}
              >
                Book
              </div>
            )}
          </td>
          <td>
            <Link
              style={{ color: '#1ac8ff', textDecoration: 'none' }}
              to={'/agencybookinglist/' + vacancyshiftData.rows[j]._id}
            >
              <div className="d-flex  ">
                <div className="bookinganglearrow pl-3">Bookings</div>
                <div>
                  <i
                    className="fas fa-angle-right"
                    style={{ color: '#1ac8ff' }}
                  ></i>
                </div>
              </div>
            </Link>
          </td>
        </tr>,
      );
    }
    setVacancyshiftRows(vacancyshiftList);
    setVacancyshiftData(vacancyshiftData);
  };
  // custom select box styles
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '200px',
      lineHeight: '25px',
      background: '#f4f4f4',
      borderRadius: '12px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000',
      width: '32px',
    }),
  };
  const customSelectStyles1 = {
    control: provided => ({
      ...provided,
      border: '1px solid #A8A8A8',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      // width: '200px',
      lineHeight: '25px',
      // background: '#E5EEEFA8',
      borderRadius: '6px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000',
      width: '32px',
    }),
  };
  return (
    <div className="VacancyshiftList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8 mb-2 ml-2 ">
                <div className="page-title">Vacancy Shift</div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-3 " style={{ marginLeft: '14px' }}>
                <div className="row d-flex  mt-1">
                  <div className="col">
                    <div className={'form-group '}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="employer_id"
                        onChange={selectedcarehome}
                        placeholder={'select carehome'}
                        // isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={getcarehomelist}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body flx-table-border">
            {!isLoading && (
              <table className="table  flx-custom-table">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th className="table-header-ht">Date</th>
                    <th>Vacancy ID</th>
                    <th>Shift</th>
                    <th>Postcode</th>
                    <th>Time</th>
                    <th>No of Vacancy</th>
                    <th>pay</th>
                    <th>Is booked</th>
                    <th className="" />
                    <th className="eventCell  eventEdit" />
                    <th></th>
                  </tr>
                </thead>
                <tbody>{vacancyshiftRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {vacancyshiftRows &&
              vacancyshiftRows &&
              vacancyshiftRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllVacancyshift(data);
              }}
              paginationData={vacancyshiftData}
            />
          </div>
        </div>
      </div>
      {showbookingModal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm">
            <div className="modal-content ">
              <div className="modal-header border-bot-0 d-flex justify-content-end">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setshowbookingModal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body mx-2 pt-0">
                <div className="bag">
                  <div className="">
                    <div className="bookingvacancy ">Book a vacancy</div>
                    <div className="bookingdate">
                      {moment(bookedvacancydetails.from_date_string).format(
                        'DD-MMM-YYYY',
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className=" text-secondary-size-13 pl-0 ">
                      {' '}
                      Total number of vacancy{' '}
                    </div>
                    <div className=" vacancyno d-flex align-items-center">
                      {bookedvacancydetails.no_of_openings}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-5 ml-2 mt-2">
                      <label htmlFor="type">Select allocate candidate</label>
                      <AsyncSelect
                        styles={customSelectStyles1}
                        name="agency_candidate_id"
                        onChange={selectedcandidate}
                        placeholder="select candidate"
                        isMulti
                        cacheOptions
                        defaultOptions
                        loadOptions={getagencycandidatelist}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-5 ml-2 mt-3">
                      <div className={'form-group '}>
                        <label htmlFor="type">No of vacancy you need</label>
                        <div className="form-group ">
                          <input
                            type="number"
                            className="form-control selectDesign"
                            name="no_of_vacancy"
                            placeholder=""
                            value={bookingdetails.no_of_vacancy}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {bookedvacancydetails.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div className="col-md-12 pr-5">
                        <div className={'form-group '}>
                          <label htmlFor="type">
                            Enter your bidding amount
                          </label>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              name="amount"
                              placeholder=""
                              value={bookingdetails.amount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
                    <div className=" col-md-9 p-2 bd-highlight d-flex flex-row bd-highlight mb-3">
                      <div className="p-2 mb-3"></div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="row d-flex justify-content-center">
                        <div
                          className="booknow pointer p-1"
                          onClick={() => {
                            addbooking();
                          }}
                        >
                          Book Now
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {submitted && (
                      <div className="col-md-12 pr-1">
                        <span className="color-red">{error.common}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0 py-0 d-flex  justify-content-end">
                <div className="  ">
                  <div className=" close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body mx-5">
                <div className="bag">
                  <div className="d-flex flex-column ">
                    <div
                      className=" text-secondary mb-2"
                      style={{ lineHeight: '0px' }}
                    >
                      VACANCY ID
                    </div>
                    <div className="">
                      <label>{'FLX00' + viewdetails._id}</label>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-item-center ">
                    <div
                      className={'icon-div mt-1'}
                      style={{ color: '#20B9B7' }}
                    >
                      <img
                        src="/assets/img/calendar1.png"
                        style={{
                          height: '20px',
                          width: '20px',
                          filter: 'invert(1)',
                        }}
                      ></img>
                    </div>
                    <div className="d-flex align-items-center justify-content-center ml-10 font-weight-bold">
                      {moment(viewdetails.from_date_string).format(
                        'DD-MMM-YYYY',
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
                    <div className=" col-md-9 pt-2 pl-0 bd-highlight d-flex flex-row bd-highlight">
                      <div className="pt-2 bd-highlight d-flex flex-column bd-highlight">
                        <div className="p-2 bd-highlight bluetag ">
                          {viewdetails.name}{' '}
                        </div>
                      </div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight compny_name_agency">
                          {viewdetails.company_name}{' '}
                        </div>
                      </div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight vacancy_type">
                          {viewdetails.is_bidding_option_needed == 1
                            ? 'Bidding'
                            : viewdetails.is_auto_booking_confirm == 1
                            ? 'Auto booking confirm'
                            : 'Manual confirm'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dummy-view"></div>
                  <div className="row mt-3">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      {' '}
                      <label>Vacancy ID</label>
                    </div>
                    <div className="">
                      <label>:</label>{' '}
                    </div>
                    <div className="col-6">
                      <p>{'FLX00' + viewdetails._id} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>Shift</label>{' '}
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.name} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      {' '}
                      <label>Time</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails.from_time + '-' + viewdetails.to_time}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      {' '}
                      <label>No of Vacancy</label>
                    </div>
                    <div className="">
                      {' '}
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.no_of_openings} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>Pay</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>£ {viewdetails.price}/hr </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      {' '}
                      <label>City</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.city} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>County</label>
                    </div>
                    <div className="">
                      {' '}
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.county} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>Allocated Staff</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails['Staff.name']} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>Employer pay</label>
                    </div>
                    <div className="">
                      <label>:</label>{' '}
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.employer_to_pay} </p>
                    </div>
                  </div>
                  {IsAdmin && (
                    <div className="row">
                      <div
                        className="col-md-3 col-sm-6"
                        style={{ marginLeft: '7px' }}
                      >
                        <label>Candidate price</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails.price} </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className="col-md-3 col-sm-6"
                      style={{ marginLeft: '7px' }}
                    >
                      <label>Publish Status</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>
                        {viewdetails.publish_status == 1 ? 'public' : 'draft'}{' '}
                      </p>
                    </div>
                  </div>
                  {viewdetails.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div
                        className="col-md-6 col-sm-6"
                        style={{ marginLeft: '7px' }}
                      >
                        <label>Maximum candidate bidding amount</label>
                      </div>
                      <div className="">
                        {' '}
                        <label>:</label>
                      </div>
                      <div className="col-3">
                        <p>{viewdetails.maximum_candidate_bidding_amount} </p>
                      </div>
                    </div>
                  )}
                  {viewdetails.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div
                        className="col-md-6 col-sm-6"
                        style={{ marginLeft: '7px' }}
                      >
                        <label>Bidding expire date</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-3">
                        <p>{viewdetails.bidding_expire_date} </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for get carehome list for select options
const getcarehomelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for get agency candidate list for select options
const getagencycandidatelist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencycandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let newArray = data.rows;
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { misc } = state;
  return {
    vacancyshiftFilterDate: misc.vacancyshiftFilterDate,
    vacancyshiftFilterbusinessunit: misc.vacancyshiftFilterbusinessunit,
  };
}
const connectedAgencyvacancyList = connect(mapStateToProps)(AgencyvacancyList);
export { connectedAgencyvacancyList as AgencyvacancyList };
