import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const vacancyshiftService = {
  vacancyshiftAdd,
  vacancyshiftList,
  vacancyshiftListv2,
  vacancyshiftDelete,
  Vacancycalender,
  // VacancyShiftCandidateList,
  // benefitList,
  benefitMasterList,
  benefitEmployerList,
  bankHolidayListUK,
};

function vacancyshiftAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addvacancyshift', requestOptions).then(
    miscService.handleResponse,
  );
}
function vacancyshiftList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getvacancyshiftList',
    requestOptions,
  ).then(miscService.handleResponse);
}

function vacancyshiftListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getvacancyshiftListv2',
    requestOptions,
  ).then(miscService.handleResponse);
}

function Vacancycalender(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getvacancycalender ',
    requestOptions,
  ).then(miscService.handleResponse);
}
function vacancyshiftDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deletevacancyshift',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function VacancyShiftCandidateList(data) {
//   const queryParams = new URLSearchParams(data);

//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   const apiUrlWithParams =
//     apiConstants.apiUrl + '/vacancy/candidates/preworked?' + queryParams;

//   return fetch(apiUrlWithParams, requestOptions)
//     .then(response => response.json())
//     .then(parsedData => {
//       console.log(parsedData);
//       return parsedData;
//     })
//     .catch(error => {
//       console.error(error);
//       throw error;
//     });
// }

// function benefitList(data) {
//   const queryParams = new URLSearchParams(data);

//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   const apiUrlWithParams =
//     apiConstants.apiUrl + '/vacancy/benefit?' + queryParams;

//   return fetch(apiUrlWithParams, requestOptions)
//     .then(response => response.json())
//     .then(parsedData => {
//       console.log(parsedData);
//       return parsedData;
//     })
//     .catch(error => {
//       console.error(error);
//       throw error;
//     });
// }

function benefitMasterList(data) {
  const queryParams = new URLSearchParams(data);

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const apiUrlWithParams =
    apiConstants.apiUrl + '/benefitmaster?' + queryParams;

  return fetch(apiUrlWithParams, requestOptions)
    .then(response => response.json())
    .then(parsedData => {
      console.log(parsedData);
      return parsedData;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

function benefitEmployerList(data) {
  const queryParams = new URLSearchParams(data);

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const apiUrlWithParams =
    apiConstants.apiUrl + '/benefitemployer?' + queryParams;

  return fetch(apiUrlWithParams, requestOptions)
    .then(response => response.json())
    .then(parsedData => {
      console.log(parsedData);
      return parsedData;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

function bankHolidayListUK() {
  const requestOptions = {
    method: 'GET',
  };
  return fetch('https://www.gov.uk/bank-holidays.json', requestOptions).then(
    miscService.handleResponse,
  );
}
