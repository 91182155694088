import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { businessunitService } from '../_services';
export const businessunitActions = {
  businessunitAdd,
  businessunitList,
  // businessunitDelete,
  dashbordBunitReport,
};

function businessunitAdd(businessunitObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BUSINESSUNIT_REQUEST,
        businessunitObj,
      });

      businessunitService.businessunitAdd(businessunitObj).then(
        data => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function businessunitList(businessunitObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BUSINESSUNIT_REQUEST,
        businessunitObj,
      });

      businessunitService.businessunitList(businessunitObj).then(
        data => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function dashbordBunitReport(businessunitObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BUSINESSUNIT_REQUEST,
        businessunitObj,
      });

      businessunitService.dashbordBunitReport(businessunitObj).then(
        data => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BUSINESSUNIT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function businessunitDelete(businessunitObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.BUSINESSUNIT_REQUEST,
//         businessunitObj,
//       });

//       businessunitService.businessunitDelete(businessunitObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.BUSINESSUNIT_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.BUSINESSUNIT_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
