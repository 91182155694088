import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { agencyActions, categoryActions, staffActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { FileUpload } from '../fileupload/FileUpload';
const AgencyAdd = props => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [agency, setAgency] = useState({
    name: '',
    pay_break: 0,
    description: '',
  });
  const currentdate = new Date().toISOString().slice(0, 10);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  let startdate = moment().subtract(15, 'years').format('YYYY-MM-DD');
  const [staffrows, setstaffrows] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [countries, setCountries] = useState();
  const [countriesData, setCountriesData] = useState([]);
  const [error, setError] = useState({ common: '' });
  const [categoriesrows, setcategoriesrows] = useState();
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  // effect to call categories ,agency details,staff, fetch country.json
  useEffect(() => {
    getAllcategories();
    /**
     * Edit data using url query
     */
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllAgency(1, query);
      loadaddress();
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllAgency(1, query);
    }
    fetch('/assets/countries.json')
      .then(response => response.json())
      .then(data => {
        setCountriesData(data);
      });
    getAllstaff();
  }, []);
  // effect for setting countries options and get all staff
  // having dependencies countriesData
  useEffect(() => {
    let countrylist = [];
    let data = countriesData;
    let isFound = false;
    for (let i = 0; i < data.length; i++) {
      if (agency.nationality == data[i].name) {
        isFound = true;
      }
      countrylist.push(
        <option
          value={data[i].value}
          // eslint-disable-next-line react/no-unknown-property
          test={isFound ? 'true' : 'false'}
          selected={agency.nationality == data[i].name}
        >
          {data[i].name}
        </option>,
      );
    }
    if (!isFound) {
      countrylist.unshift(
        <option selected={'true'}>Select Nationality</option>,
      );
    }
    setCountries(countrylist);
    getAllstaff();
  }, [countriesData]);
  useEffect(() => {
    if (id !== '0') {
      loadaddress();
    }
  }, [agency._id]);

  const loadaddress = async () => {
    // let res = '';
    if (agency.postcode) {
      let res = agency?.postcode?.replace(/ /g, '');

      if (valid_postcode(res)) {
        let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
        let response = await fetch(posstcodeurl);
        response = await response.json();
        if (response && response.latitude && response.longitude) {
          let addresses_rows = [];
          let regex = /\s+,/g;
          let newarray = response.addresses[0].split(',');
          for (let s = 0; s < response.addresses.length; s++) {
            addresses_rows.push(
              <option>{response.addresses[s].replace(regex, ' ')}</option>,
            );
          }
          setPostcodeObject({
            isValied: true,
            addresses: addresses_rows,
          });
          setAgency({
            ...agency,
            lat: response.latitude,
            long: response.longitude,
            county: newarray[6],
            city: newarray[5],
            postcode: agency.postcode,
          });
        }
      }
    }
  };

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // show file upload modal
  const showfileupload = () => {
    setshowmodalfileupload(true);
  };
  // hide file upload modal
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };
  // call for get all staffs
  const getAllstaff = () => {
    const { dispatch } = props;
    dispatch(staffActions.staffList()).then(res => {
      let staffData = res.data.rows;
      let stafflist = [];
      for (let i = 0; i < staffData.length; i++) {
        stafflist.push(
          <option value={staffData[i]._id}>{staffData[i].name}</option>,
        );
      }
      setstaffrows(stafflist);
    });
  };
  //get all categories for select drop down----
  const getAllcategories = () => {
    const { dispatch } = props;
    dispatch(categoryActions.categoryList(query)).then(res => {
      let categoryData = res.data.rows;
      let categorylist = [];
      for (let i = 0; i < categoryData.length; i++) {
        categorylist.push(
          <option value={categoryData[i]._id}>{categoryData[i].name}</option>,
        );
      }
      setcategoriesrows(categorylist);
    });
  };
  /**
   * Get all agencys
   * */
  const getAllAgency = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;

    dispatch(agencyActions.agencyList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setAgency(res?.data?.rows?.[0]);
          setAgency(prevState => ({
            ...prevState,
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler -for state agency-----
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'contact_number' && value.length > 11) {
      return;
    }
    if (
      name == 'key_name' ||
      name == 'key_position' ||
      name == 'key_email' ||
      name == 'key_phone'
    ) {
      //--------set to key_contact object -------
      setAgency({
        ...agency,
        key_contact: { ...agency.key_contact, [name]: value },
      });
    } else if (name == 'contact_number') {
      if (value.length >= 12) {
        return;
      } else {
        setAgency({
          ...agency,
          [name]: value,
        });
      }
    } else {
      //--------set to employer state variable-----------
      setAgency({
        ...agency,
        [name]: value,
      });
    }
  };
  // validate postcode
  const valid_postcode = str_postcode => {
    let postcode = str_postcode?.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    let regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return regex.test(postcode);
  };
  // validate phone number
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // file upload response handler
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setAgency(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
      setshowmodalfileupload(false);
    }
  };
  // post change handler
  const handleChangepostcode = async event => {
    event.preventDefault();
    const { name, value } = event.target;
    setAgency({
      ...agency,
      [name]: value,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        let regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setAgency({
          ...agency,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: value,
        });
      }
    }
  };
  // submit action for add agency
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    delete agency.key_contact;
    const { dispatch } = props;
    if (
      agency.company_name &&
      agency.email &&
      agency.contact_number &&
      agency.priority &&
      agency.category &&
      agency.cdm_staff &&
      agency.address &&
      agency.postcode &&
      agency.dob &&
      agency.nationality &&
      agency.password
    ) {
      let postcode = valid_postcode(agency.postcode);
      if (postcode == false) {
        setError({ common: 'Enter a valid postcode' });
        return;
      }
      if (agency.dob) {
        if (agency.dob > currentdate) {
          setError({ common: 'Choose a valid date of birth' });
          return;
        }
      }
      if (agency.break_time && agency.break_time >= 180) {
        setError({
          common: 'Break time should be less than 180 minutes ',
        });
        return;
      }
      if (!agency.password) {
        setError({
          common: 'Password is required',
        });
        return;
      }
      let contact_number_validate = valid_contact_number(agency.contact_number);
      if (contact_number_validate == false) {
        setError({ common: 'Enter a valid phone number' });
        return;
      }
      setIsLoading(true);

      agency.active_status = 1;
      dispatch(agencyActions.agencyAdd(agency)).then(
        () => {
          navigate('/agencylist');
          setIsLoading(false);
        },
        err => {
          setError({
            common: err ? err : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
    }
  };
  return (
    <div className="EmployerAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2 p-0">
        {isLoading && <LoadingModal />}
        <div className="card mt-2 mx-0">
          <div className="  page-header ">
            <div className="page-title-content ml-2 pl-1">
              <p className="card-title">
                {agency && agency._id ? 'Update' : 'Add'} Agency{' '}
              </p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1 ">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Company Name :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="company_name  "
                      className="form-control input-alnment"
                      name="company_name"
                      placeholder="Company Name"
                      value={agency.company_name}
                      onChange={handleChange}
                    />
                    {submitted && !agency.company_name && (
                      <div className="help-block">Company Name is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Email : <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control input-alnment"
                      name="email"
                      placeholder="Email"
                      value={agency.email}
                      onChange={handleChange}
                    />
                    {submitted && !agency.email && (
                      <div className="help-block">Email is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Contact Number :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="number"
                      step="any"
                      className="form-control input-alnment"
                      name="contact_number"
                      placeholder="Contact Number"
                      value={agency.contact_number}
                      onChange={handleChange}
                    />
                    {submitted && !agency.contact_number && (
                      <div className="help-block">
                        Contact Number is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      DOB : <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      data-cy="agency_dob"
                      type="date"
                      className="form-control input-alnment"
                      name="dob"
                      max={startdate}
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      placeholder="DOB"
                      value={agency.dob}
                      onChange={handleChange}
                    />
                    {submitted && !agency.dob && (
                      <div className="help-block">DOB is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Nationality :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <select
                      className="select col-md-12 selectdesign  input-alnment"
                      name="nationality"
                      onChange={handleChange}
                    >
                      {countries}
                    </select>
                    {submitted && !agency.nationality && (
                      <div className="help-block">Nationality is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6  ">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Select Priority :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <select
                      className="select col-md-12 selectdesign input-alnment"
                      name="priority"
                      value={agency.priority}
                      onChange={handleChange}
                    >
                      <option value=""> Select</option>
                      <option value={'1'}>1</option>
                      <option value={'2'}>2</option>
                    </select>
                    {submitted && !agency.priority && (
                      <div className="help-block">Priority is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Select Categroy :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <select
                      className="select col-md-12 selectdesign input-alnment"
                      name="category"
                      value={agency.category}
                      onChange={handleChange}
                    >
                      <option value=""> Select</option>
                      {categoriesrows}
                    </select>
                    {submitted && !agency.category && (
                      <div className="help-block">Category is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Postcode :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="postcode"
                      className="form-control input-alnment"
                      name="postcode"
                      placeholder="postcode "
                      value={agency.postcode}
                      onChange={handleChangepostcode}
                    />
                    {submitted && !agency.postcode && (
                      <div className="help-block">Postcode is required</div>
                    )}
                  </div>
                </div>
              </div>
              <label htmlFor="type">
                Address : <span className="assign-shift-label-star">*</span>
              </label>
              <div className="row">
                <div className="col-md-6 pr-3">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !agency.address ? ' has-error' : '')
                    }
                  >
                    <select
                      className="form-control input-alnment"
                      name="address"
                      placeholder="Select Address"
                      value={agency.address}
                      onChange={handleChange}
                    >
                      <option value={''}>Select Address</option>
                      {PostcodeObject.addresses}
                    </select>
                    {submitted && !agency.address && (
                      <div className="help-block">Address is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <input
                      className="form-control input-alnment"
                      name="address_line2"
                      placeholder="Address line 2"
                      value={agency.address_line2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type">
                      Select CDM Staff :{' '}
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <select
                      className="select col-md-12 selectdesign input-alnment"
                      name="cdm_staff"
                      value={agency.cdm_staff}
                      onChange={handleChange}
                    >
                      <option value=""> Select Staff</option>
                      {staffrows}
                    </select>
                    {submitted && !agency.cdm_staff && (
                      <div className="help-block">CDM Staff is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Scope : <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="scope"
                      className="form-control input-alnment"
                      name="scope"
                      placeholder="Scope"
                      value={agency.scope}
                      onChange={handleChange}
                    />
                    {submitted && !agency.scope && (
                      <div className="help-block">Scope is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group'}>
                    <label htmlFor="type">Website :</label>
                    <input
                      type="Website"
                      className="form-control input-alnment"
                      name="website"
                      placeholder="Website"
                      value={agency.website}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group'}>
                    <label htmlFor="type">Other Info :</label>
                    <input
                      type="other_info"
                      className="form-control input-alnment"
                      name="other_info"
                      placeholder="Other Info"
                      value={agency.other_info}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-3'}>
                    <label htmlFor="type" className="mr-2">
                      Break Paid ?
                    </label>
                    <input
                      type="radio"
                      className="mr-2 ml-4"
                      name="pay_break"
                      placeholder="pay_break"
                      value={1}
                      checked={agency.pay_break == 1}
                      onChange={handleChange}
                    />
                    <label className="mr-2 flx-agency-radio-btn">Yes</label>
                    <input
                      type="radio"
                      className="mr-2 "
                      name="pay_break"
                      placeholder="pay_break"
                      value={0}
                      checked={agency.pay_break == 0}
                      onChange={handleChange}
                    />
                    <label className="mr-2 flx-agency-radio-btn">No</label>
                  </div>
                </div>
              </div>
              {agency.pay_break == 1 && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Break Time (mins) :
                        <span className="assign-shift-label-star">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control input-alnment"
                        name="break_time"
                        placeholder="Break Time"
                        value={agency.break_time}
                        onChange={handleChange}
                      />
                      {submitted && !agency.break_time && (
                        <div className="help-block">Break Time is required</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group m-0'}>
                    <label htmlFor="type">Preference :</label>
                    <textarea
                      name="preference"
                      placeholder="preference"
                      value={agency.preference}
                      onChange={handleChange}
                      id="preference"
                      className="form-control input-alnment"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* //---password update ----- */}
              {/* {agency._id && ( */}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group mt-3'}>
                    <label htmlFor="type">Password : </label>{' '}
                    <span className="assign-shift-label-star">*</span>
                    <input
                      type="password"
                      className="form-control input-alnment"
                      name="password"
                      placeholder="Password"
                      value={agency.password}
                      onChange={handleChange}
                    />
                    {submitted && !agency.password && (
                      <div className="help-block">Password is required</div>
                    )}
                  </div>
                </div>
              </div>
              {/* )} */}
              <div className="row ">
                <div className="col-md-12 ">
                  <label htmlFor="type">Upload Profile Image</label>
                  <div className="row m-0">
                    <div
                      className="col-md-6 ml-2 "
                      style={{ maxWidth: 'none' }}
                    >
                      <div className="row filerow form-control p-4 ">
                        <label
                          htmlFor="type"
                          className=""
                          style={{ lineHeight: 2 }}
                        >
                          {' '}
                          {agency && agency.profileimage
                            ? agency.profileimage
                            : 'No Image Uploaded'}{' '}
                        </label>
                        <div className="col-md-3 float-right">
                          {' '}
                          <div
                            className="changebutton m-2 p-3"
                            onClick={showfileupload}
                          >
                            <span>
                              {agency && agency.profileimage ? (
                                <img
                                  className=" round-emp-image"
                                  src={
                                    apiConstants.imgUrl + agency.profileimage
                                  }
                                />
                              ) : (
                                <img
                                  className=" round-emp-image"
                                  src="/assets/img/recsy-fav-icon.png"
                                />
                              )}
                            </span>{' '}
                            <span>Change</span>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group d-flex justify-content-end">
                    <button className="btn btn-primary" data-cy="addagency">
                      <span
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: 1.25,
                        }}
                      >
                        Save
                      </span>
                      {props.requestStatus ===
                        reducerConstants.AGENCY_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'Profile image'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, agencyData } = state.agency;
  return {
    requestStatus,
    agencyData,
  };
}

const connectedAgencyAdd = connect(mapStateToProps)(AgencyAdd);
export { connectedAgencyAdd as AgencyAdd };
