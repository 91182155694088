import React from 'react';

const EmailChangeConfirmPopup = props => {
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="flx-email-change-req-modal-title" id="modalLabel">
            Please confirm new email
          </h5>
          <a
            data-cy="close-modal"
            className="mb-3 mr-2"
            onClick={() => props?.handleClosePopup()}
          >
            <i className="fa fa-times-circle close-button-modal"></i>
          </a>
        </div>
        <div className="modal-body">
          <p className="flx-popup-body flx-popup-body-email-change-req">
            After confirmation, an OTP will be sent to the new email for
            updating your user account.
          </p>
        </div>
        <div className="flx-popup-bottom flx-change-email-popup-footer d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-sm flx-popup-button"
            onClick={e => {
              e?.preventDefault(),
                props?.handleSubmit(),
                props?.handleClosePopup();
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => props?.handleClosePopup()}
            className="ml-2 btn bg-secondary flx-popup-button float-end pointer"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailChangeConfirmPopup;
