import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { AttendaceBloackbooking } from '../Block_booking/AttendanceBloackbooking';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { Shifts } from '../Shifts/Shifts';
import { shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';

let employer_id = '';
// let agency_id = '';
// eslint-disable-next-line no-unused-vars
let user_role = '';

const TimesheetList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [timesheetmodal, settimesheetmodal] = useState(false);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  // const [shiftidsCount, setshiftidsCount] = useState(0);
  const [sectionData, setsectionData] = useState([]);
  // const [agencysectionData, setAgencysectionData] = useState([]);
  // const [businessunitData, setbusinessunitData] = useState([]);
  const [businessunitoptionsData, setbusinessunitoptionsData] = useState([]);

  const [view_shift_id, Setview_shift_id] = useState();
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);

  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [showAgencyCandidateprofileModal, setshowAgencyCandidateprofileModal] =
    useState(false);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  // effect to load all shift
  useEffect(() => {
    getAllShifts(1);
  }, [query, selected_shiftIds.length, isAdmin, isStaff]);
  // effect to load section and bunit
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
      }
      // if (user && user.agency_id) {
      //   agency_id = user.agency_id;
      // }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 6) {
        setisStaff(true);
      }
      if (user.role == 7) {
        // setisBunit(true);
      }
    }
    getAllSection();
    getAgencySection();
    getbusinessunit();
    getagencyshiftsbusinessunit();
  }, []);
  // show or close time sheet modal
  const showTimesheetModalfunction = value => {
    settimesheetmodal(value);
    getAllShifts(1);
    setselected_shiftIds([]);
  };

  // call for get all section
  const getAllSection = () => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getsectionlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          attributes: ['_id', 'name'],
        }),
      }).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            let shift_option_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              shift_option_rows.push(
                <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
              );
            }
            setsectionData(shift_option_rows);
          }
        }),
      );
    }
  };

  // call for get all agency section
  // const getAgencySection = () => {
  //   fetch(apiConstants.apiUrl + '/getagencysectionlist', {
  //     method: 'post',
  //     headers: authHeader(),
  //     body: JSON.stringify({
  //       status: 1,
  //       agency_id: agency_id,
  //       attributes: ['_id', 'name'],
  //     }),
  //   }).then(response =>
  //     response.json().then(({ data }) => {
  //       if (data && data.rows) {
  //         let shift_option_rows = [];
  //         for (let s = 0; s < data.rows.length; s++) {
  //           shift_option_rows.push(
  //             <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
  //           );
  //         }
  //         setAgencysectionData(shift_option_rows);
  //       }
  //     }),
  //   );
  // };
  const getAgencySection = () =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getagencysection', {
        method: 'get',
        headers: authHeader(),
        // body: JSON.stringify({
        //   // status: 1,
        //   // agency_id: agency_id,
        //   // select: ['_id', 'name'],
        // }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data);

            resolve(
              newArray.map(item => ({
                value: item._id,
                label: item.name,
              })),
            );
          }),
        )
        .catch(reject);
    });
  // --get all businessunit--------------
  const getbusinessunit = () => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          select: ['_id', 'name'],
        }),
      }).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            let shift_option_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              shift_option_rows.push(
                <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
              );
            }
            setbusinessunitoptionsData(shift_option_rows);
          }
        }),
      );
    }
  };

  // --get all agency candidates shifts businessunit--------------
  const getagencyshiftsbusinessunit = () =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getagencybusinessunit', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({ status: 1, select: ['_id', 'name'] }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data);

            resolve(
              newArray.map(item => ({
                value: item._id,
                label: item.name,
              })),
            );
          }),
        )
        .catch(reject);
    });
  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    queryNew.is_verified = 1;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        for (
          let s = 0;
          res.data && res.data.rows && s < res?.data?.rows?.length;
          s++
        ) {
          res.data.rows[s].x = 1;
        }
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for category change
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
  };
  // handler for agency change
  const agency_change = selectedOption => {
    setQuery(prevState => ({
      ...prevState,
      agency_id: selectedOption.value,
      candidate_id: 0,
    }));
  };
  // handler for candidate allocation
  const selectedallocatecandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };
  let shiftsList = [];
  // set list and pagination shiftdata
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      const candidateFirstName = shiftsData.rows[j]['Candidate.name'];
      const candidateLastName = shiftsData.rows[j]['Candidate.last_name'];
      const fullName =
        candidateFirstName && candidateLastName
          ? `${candidateFirstName} ${candidateLastName}`
          : candidateFirstName || candidateLastName || '';

      const displayName =
        fullName.length > 8 ? `${fullName.substring(0, 12)}...` : fullName;
      shiftsList.push(
        <tr key={j} className="row-body2">
          <td>
            {shiftsData.rows[j]['Candidate.name'] && (
              <div className="">
                <div
                  onClick={() => {
                    setSelectedCandidateId(shiftsData.rows[j].candidate_id),
                      setshowCandidateprofileModal(true);
                  }}
                  style={{
                    color: 'black',
                    fontSize: '12px',
                    flexDirection: 'row',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  {shiftsData.rows[j]['Candidate.profileimage'] ? (
                    <img
                      className="round-emp-image"
                      src={
                        apiConstants.imgUrl +
                        shiftsData.rows[j]['Candidate.profileimage']
                      }
                    />
                  ) : (
                    <img
                      className=" round-emp-image"
                      src="/assets/img/recsy-fav-icon.png"
                    />
                  )}
                  <p className="username" title={displayName}>
                    {displayName}
                  </p>
                </div>
              </div>
            )}
            {shiftsData.rows[j].agency_candidate_id &&
              shiftsData.rows[j]['Agencycandidate.name'] && (
                <div className="">
                  <div
                    onClick={() => {
                      setSelectedCandidateId(
                        shiftsData.rows[j].agency_candidate_id,
                      ),
                        setshowAgencyCandidateprofileModal(true);
                    }}
                    style={{
                      color: 'black',
                      fontSize: '12px',
                      flexDirection: 'row',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    {shiftsData.rows[j]['Agencycandidate.profileimage'] ? (
                      <img
                        className="round-emp-image"
                        src={
                          apiConstants.imgUrl +
                          shiftsData.rows[j]['Agencycandidate.profileimage']
                        }
                      />
                    ) : (
                      <img
                        className=" round-emp-image"
                        src="/assets/img/recsy-fav-icon.png"
                      />
                    )}
                    {shiftsData.rows[j]['Agency.company_name'] &&
                    shiftsData.rows[j]['Agencycandidate.name']
                      ? shiftsData.rows[j]['Agencycandidate.name'].length > 8
                        ? shiftsData.rows[j]['Agencycandidate.name'].substring(
                            0,
                            8,
                          ) + '...'
                        : shiftsData.rows[j]['Agencycandidate.name']
                      : ''}
                    <br />
                    {shiftsData.rows[j]['Agency.company_name'] &&
                    shiftsData.rows[j]['Agency.company_name'].length > 5
                      ? ' ( ' +
                        shiftsData.rows[j]['Agency.company_name'].substring(
                          0,
                          5,
                        ) +
                        '...)'
                      : ' ( ' + shiftsData.rows[j]['Agency.company_name'] + ')'}
                  </div>
                </div>
              )}
          </td>
          <td style={{ width: '100px' }}>
            {moment(shiftsData.rows[j].check_in_date_string).format(
              'DD-MMM-YYYY',
            )}
          </td>
          <td style={{ width: '110px' }}>
            {moment(shiftsData.rows[j].check_out_date_string).format(
              'DD-MMM-YYYY',
            )}
          </td>
          <td>
            <div>
              {shiftsData.rows[j].section_type == 'Morning'
                ? 'Early'
                : shiftsData.rows[j].section_type == 'Evening'
                ? 'Late'
                : shiftsData.rows[j].section_type}
            </div>
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(shiftsData.rows[j].check_in_time_string)}{' '}
              -{' '}
              {miscService.time24to12(shiftsData.rows[j].check_out_time_string)}
            </div>
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_in)}
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_out)}
          </td>
          <td>{shiftsData.rows[j].total_hours}</td>
          <td>
            <p>
              {shiftsData.rows[j].employer_signature_url ? (
                <img
                  className="sign-image"
                  src={
                    apiConstants.imgUrl +
                    shiftsData.rows[j].employer_signature_url
                  }
                />
              ) : (
                '-'
              )}
            </p>
          </td>
          <td>
            {shiftsData.rows[j].signature_employer_name
              ? shiftsData.rows[j].signature_employer_name
              : '-'}
          </td>
          <td>
            <p>
              {shiftsData.rows[j].candidate_signature_url ? (
                <img
                  className="sign-image"
                  src={
                    apiConstants.imgUrl +
                    shiftsData.rows[j].candidate_signature_url
                  }
                />
              ) : (
                '-'
              )}
            </p>
          </td>
          <td>
            {shiftsData.rows[j]['User.role'] == 1 ? (
              <p>Admin</p>
            ) : shiftsData.rows[j]['User.role'] == 6 ? (
              <p>Staff</p>
            ) : shiftsData.rows[j]['User.role'] == 4 ? (
              <p>Carehome</p>
            ) : shiftsData.rows[j]['User.role'] == 3 ? (
              <p>Candidate</p>
            ) : shiftsData.rows[j]['User.role'] == 7 ? (
              <p>Businessunit</p>
            ) : shiftsData.rows[j]['User.role'] == 5 ? (
              <p>Agency</p>
            ) : (
              ''
            )}
          </td>
          <td>
            {shiftsData &&
              shiftsData.rows &&
              shiftsData.rows[j].createdAt &&
              moment(shiftsData.rows[j].createdAt).format('DD-MMM-YYYY')}
          </td>
          <td>
            <div
              className="allshift-viewdt"
              onClick={() => {
                Setview_shift_id(shiftsData.rows[j]._id),
                  setviewdetailsmodal(true);
              }}
            >
              {' '}
              View
            </div>
          </td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // handler for filters
  const handleChange = event => {
    const { name, value, checked } = event.target;
    setQuery({ ...query, [name]: value });
    if (name == 'shift_checked') {
      let new_selectedShiftIds = selected_shiftIds;
      if (checked) {
        new_selectedShiftIds.push(value);
      }
    }
  };
  // custom style for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#f4f4f4',
      borderRadius: '12px',
      color: '#000',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000',
      height: '40px',
      width: '32px',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };
  return (
    <div className="ShiftList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-9 mb-3 ml-2">
                <div className="page-title"> Timesheet list</div>
              </div>
            </div>
            <div className="space-row mb-3">
              <div className="filter-row">
                <div className="filter-item">
                  <input
                    type="date"
                    className="input-design1"
                    style={{
                      border: 'none',
                      display: 'block',
                      width: '100%',
                      height: '40px',
                      padding: '0.375rem 0.75rem',
                      flexShrink: '0',
                      fontSize: '14px',
                      fontWeight: '500',
                      borderRadius: '6px',
                    }}
                    name="from_date"
                    placeholder=""
                    value={query.from_date}
                    onChange={handleChange}
                  />
                </div>
                <div className="filter-item">
                  <input
                    type="date"
                    className="input-design1"
                    style={{
                      border: 'none',
                      display: 'block',
                      width: '100%',
                      height: '40px',
                      padding: '0.375rem 0.75rem',
                      flexShrink: '0',
                      fontSize: '14px',
                      fontWeight: '500',
                      borderRadius: '6px',
                    }}
                    name="to_date"
                    placeholder=""
                    value={query.to_date}
                    onChange={handleChange}
                  />
                </div>
                {
                  <div className="filter-item">
                    <AsyncSelect
                      styles={customStyles}
                      name="candidate_id"
                      onChange={selectedallocatecandidate}
                      placeholder={'Select Candidate'}
                      className="allocatedcandiadte"
                      cacheOptions
                      defaultOptions
                      noOptionsMessage={() => 'No results found'}
                      loadOptions={getshiftscandidatelist}
                    />
                  </div>
                }
                {
                  <div className="filter-item">
                    <AsyncSelect
                      styles={customStyles}
                      name="category"
                      onChange={category_change}
                      placeholder="Select job role"
                      cacheOptions
                      defaultOptions
                      // className="categoryselectt "
                      // isMulti
                      loadOptions={getCategorylist}
                    />
                  </div>
                }
                {user_role != 5 && (
                  <div className="filter-item">
                    <AsyncSelect
                      styles={customStyles}
                      name="agency_id"
                      onChange={agency_change}
                      placeholder="Select Agency"
                      className="agencyselect"
                      cacheOptions
                      defaultOptions
                      // isMulti
                      loadOptions={getAgencylist}
                    />
                  </div>
                )}
                {user_role != 5 && (
                  <div className="filter-item">
                    <select
                      className="select col-md-12 selectDesign-report input-design "
                      name="business_unit"
                      value={query.business_unit}
                      onChange={handleChange}
                    >
                      <option value="" hidden="true">
                        {' '}
                        Select Business Unit
                      </option>
                      <option value={''}> All</option>
                      {businessunitoptionsData}
                    </select>
                  </div>
                )}
                {user_role == 5 && (
                  <div className="filter-item">
                    <AsyncSelect
                      styles={customStyles}
                      name="business_unit"
                      onChange={handleChange}
                      placeholder="Select BusinessUnit"
                      className="agencyselect"
                      value={query.business_unit}
                      cacheOptions
                      defaultOptions
                      loadOptions={getagencyshiftsbusinessunit}
                    />
                  </div>
                )}

                {user_role != 5 && (
                  <div className="filter-item">
                    <select
                      className="select col-md-12 selectDesign-report input-design"
                      name="section_id"
                      value={query.section_id}
                      onChange={handleChange}
                    >
                      <option hidden="true"> Select Shift</option>
                      <option value={''}> All</option>
                      {sectionData}
                    </select>
                  </div>
                )}
                {/* {user_role == 5 && (
                  <div className="filter-item">
                    <select
                      className="select col-md-12 selectDesign-report input-design"
                      name="section_id"
                      value={query.section_id}
                      onChange={handleChange}
                    >
                      <option hidden="true"> Select Shift</option>
                      <option value={''}> All</option>
                      {agencysectionData}
                    </select>
                  </div>
                )} */}
                {user_role == 5 && (
                  <div className="filter-item">
                    <AsyncSelect
                      styles={customStyles}
                      name="section_id"
                      onChange={handleChange}
                      placeholder="Select Shift"
                      className="agencyselect"
                      value={query.section_id}
                      cacheOptions
                      defaultOptions
                      loadOptions={getAgencySection}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex ">
                {
                  <div className="ml-10 float-right">
                    <div
                      data-cy="add_timesheet"
                      className="c btn btn-primary-blue  mt-1 "
                      onClick={() => {
                        settimesheetmodal(true);
                      }}
                    >
                      <p className="f-10 pr-3">
                        {' '}
                        <i className="flx-assign-user-icon fa fa-user-plus ml-2"></i>{' '}
                        Add Timesheet{' '}
                      </p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div
            className="card-body  table-responsive main-card-body flx-table-border"
            style={{ paddingTop: 0 }}
          >
            {!isLoading && (
              <table className="table  flx-custom-table">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead table-header-ht"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Name</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'candidate_name' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('candidate_name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('candidate_name')}
                            />
                          )}
                          {query.pageVo.sort === 'candidate_name' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('candidate_name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('candidate_name')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th
                      onClick={() => handleSort('check_in_date_string')}
                      className="sortHead"
                      style={{ width: '160px' }}
                    >
                      <div className="d-flex align-items-center ">
                        <span>From</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'check_in_date_string' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('check_in_date_string')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('check_in_date_string')}
                            />
                          )}
                          {query.pageVo.sort === 'check_in_date_string' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('check_in_date_string')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('check_in_date_string')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th
                      onClick={() => handleSort('check_out_date_string')}
                      className="sortHead"
                      style={{ width: '160px' }}
                    >
                      <div className="d-flex align-items-center ">
                        <span>To</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'check_out_date_string' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() =>
                                handleSort('check_out_date_string')
                              }
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() =>
                                handleSort('check_out_date_string')
                              }
                            />
                          )}
                          {query.pageVo.sort === 'check_out_date_string' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() =>
                                handleSort('check_out_date_string')
                              }
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() =>
                                handleSort('check_out_date_string')
                              }
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th>Shift</th>
                    <th style={{ width: '140px' }}>Check In</th>
                    <th style={{ width: '140px' }}>Check Out</th>
                    <th>Total Hours</th>
                    <th>Sign</th>
                    <th>Authorised</th>
                    <th>Self Sign</th>
                    <th>Generated By</th>
                    <th
                      onClick={() => handleSort('createdtime')}
                      className="sortHead"
                      style={{ width: '160px' }}
                    >
                      <div className="d-flex align-items-center ">
                        <span>Created</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'createdtime' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('createdtime')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('createdtime')}
                            />
                          )}
                          {query.pageVo.sort === 'createdtime' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('createdtime')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('createdtime')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {shiftsRows &&
              shiftsRows &&
              shiftsRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0 d-flex justify-content-end ">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body py-1">
                <Shifts id={view_shift_id} />
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgencyCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowAgencyCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <AgencyCandidateProfile
                  agency_candidate_id={SelectedCandidateId}
                ></AgencyCandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {timesheetmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      settimesheetmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body py-0">
                <div className="bag">
                  <AttendaceBloackbooking
                    hideemployerfilter={1}
                    showTimesheetModal={showTimesheetModalfunction}
                  ></AttendaceBloackbooking>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for get shift candidate list in select options
const getshiftscandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    let employer_id;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(i => ({
                value: i['_id'],
                label:
                  i['name'] +
                  (i['last_name'] ? i['last_name'] : '') +
                  (i['Category'] || i['city'] ? '(' : '') +
                  (i['Category'] ? i['Category']['name'] : '') +
                  (i['Category'] && i['city'] ? '-' : '') +
                  (i['city'] ? i['city'] : '') +
                  (i['Category'] || i['city'] ? ')' : ''),
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });
// loader for get category list in select options
const getCategorylist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, select: ['_id', 'name'] }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for get agency list in select options
const getAgencylist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, select: ['_id', 'company_name'] }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return {
    requestStatus,
    shiftsData,
  };
}

const connectedTimesheetList = connect(mapStateToProps)(TimesheetList);
export { connectedTimesheetList as TimesheetList };
