import { useEffect, useState } from 'react';
import { LoadingIcon } from '../_components';

export const SelectPrice = ({
  handleClosePricePopUp,
  rateCardInfo,
  updatePrice,
}) => {
  const [rateCardDetails, setRateCardDetails] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedrateCardDetails, setSelectedrateCardDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rateCardInfo && rateCardInfo.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [rateCardInfo]);

  const handleCardClick = cardType => {
    setSelectedCard(cardType);
  };

  const handleDetails = () => {
    setRateCardDetails(true);
  };

  const handleSelectedRateCard = event => {
    // setting the selected card
    setSelectedrateCardDetails(event);
    updatePrice(event);
  };
  const handleBackButton = e => {
    e.preventDefault();
    setRateCardDetails(false);
  };

  return (
    <div
      className="modal-dialog flx-modal-popup flx-select-price-popup"
      role="document"
    >
      <div className="modal-content">
        {!rateCardDetails && (
          <>
            <div className="modal-header flx-add-shift-popup-header">
              <h5 className="modal-title" id="modalLabel">
                Choose your price
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{
                  background: '#e63946',
                  border: 'none',
                  marginTop: '-40px',
                }}
                onClick={handleClosePricePopUp}
              >
                <span aria-hidden="true" className="flx-closeicon m-auto">
                  &times;
                </span>
              </button>
              <p className="flx-add-shift-text">
                You can create your own shift
              </p>
            </div>

            {isLoading && <LoadingIcon loadingText={'Loading...'} />}

            <div className="container mt-2 flx-agency-job-post-regular-ratecards">
              <div className="flx-rate-card-container text-center">
                {rateCardInfo?.map((rateCard, index) => (
                  <div
                    key={index}
                    className={`flx-rate-card ${
                      selectedCard === rateCard.rate_card_type
                        ? 'flx-card-container-active'
                        : ''
                    }`}
                    onClick={() => {
                      handleCardClick(rateCard.rate_card_type);
                      handleSelectedRateCard(rateCard);
                    }}
                  >
                    {rateCard.most_used === 1 && (
                      <span
                        className={`flx-rate-card-most-used-label ${
                          selectedCard === rateCard.rate_card_type
                            ? 'bg-white text-primary'
                            : ''
                        }`}
                      >
                        {rateCard.most_used ? 'Most Used' : ''}
                      </span>
                    )}
                    <h6 className="flx-rate-card-title">
                      {rateCard.rate_card_type}
                    </h6>
                    <p className="flx-card-text">
                      {`£${rateCard.price} `}
                      {rateCard.originalPrice && (
                        <span className="flx-original-price">
                          <s>{`£${rateCard.originalPrice}`}</s>
                        </span>
                      )}
                      <p className="flx-quantity-ratecard">per hour</p>
                    </p>
                    <hr></hr>
                    <p className="flx-admin-charge-title">Admin charge</p>
                    <p className="flx-admin-charge">
                      {rateCard.admin_fee_is_percentage
                        ? `${rateCard.admin_fee}%`
                        : `${rateCard.admin_fee}p`}
                      <span className="flx-per-hour-text">/ hour</span>
                    </p>
                    <button
                      className="btn btn-details btn-sm ratecard-buttons"
                      onClick={e => handleDetails(e)}
                    >
                      Details
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {/* When details button is clicked in popup, replace with the bottom block */}
        {rateCardDetails && (
          <div className="recs-ratecard-details-popup-main-container">
            <button onClick={e => handleBackButton(e)}>
              <i
                className="fa fa-arrow-left"
                style={{ marginBottom: '5px' }}
              ></i>
            </button>
            <div className="flx-ratecard-popup-title">
              {selectedrateCardDetails.rate_card_type}
            </div>
            <p className="flx-ratecard-popup-text">
              Providing a base selection of candidates tailored to the
              organizations needs.
            </p>
            <hr />
            <div className="payment-breakdown">
              <h5 className="flx-ratecard-details-popup-table-heading">
                Payment breakdown
              </h5>
              <div className="flx-table-res-container">
                <table className="table flx-ratecard-details-table">
                  <tbody>
                    <tr className="flx-ratecard-details-heading">
                      <th>Hourly rate</th>
                      <th>Employer NI</th>
                      <th>Holiday pay</th>
                      <th>Pension</th>
                      <th>Admin fee</th>
                      <th>Your fee</th>
                    </tr>
                    <tr>
                      <td data-title="Hourly rate">
                        £
                        {selectedrateCardDetails.hourly_rate
                          ? selectedrateCardDetails?.hourly_rate
                          : 'Nill'}
                      </td>
                      <td data-title="Employer NI">
                        £
                        {selectedrateCardDetails.employer_ni
                          ? selectedrateCardDetails.employer_ni
                          : 'Nill'}
                      </td>
                      <td data-title="Holiday pay">
                        £
                        {selectedrateCardDetails.holiday_price
                          ? selectedrateCardDetails.holiday_price
                          : 'Nill'}
                      </td>
                      <td data-title="Pension">
                        {selectedrateCardDetails.hourly_rate
                          ? selectedrateCardDetails.hourly_rate
                          : 'Nill'}
                      </td>
                      <td data-title="Admin fee">
                        £
                        {selectedrateCardDetails?.admin_fee_is_percentage
                          ? `${selectedrateCardDetails?.admin_fee} %`
                          : `${selectedrateCardDetails?.admin_fee} p`}
                      </td>
                      <td data-title="Your fee">
                        £
                        {selectedrateCardDetails?.price
                          ? selectedrateCardDetails.price
                          : 'Nill'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
