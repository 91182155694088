import React from 'react';
import { LoadingIcon } from '../../_components';

const RequestModal = () => {
    return (
        <div className="request-modal-overlay">
            <div className="request-modal-content">
                <LoadingIcon loadingText={"Loading..."}/>
            </div>
        </div>
    );
};

export default RequestModal;
