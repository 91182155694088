import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const userService = {
  userAdd,
  userList,
  // userDelete,
  login,
  logout,
  register,
  registerCandidate,
  changePassword,
  sendOtp,
  sendResetUserCredentials,
  verifyOtp,
  GoogleLoginRegistration,
  updatePhoneNumber,
  sendOtpRegister,
  verifyOtpRegister,
  registrationWithToken,
  microsoftlogin,
  sendOtpLogin,
  verifyLogin,
  // resendOtpRegister,
  changEmailRequest,
  verifyEmail,
};
function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(apiConstants.apiUrl + '/login', requestOptions)
    .then(miscService.handleResponse)
    .then(user => {
      console.log(user.data, 'User data for login case==>25656');
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }

      return user;
    });
}

function logout() {
  localStorage.removeItem('user');
  localStorage.clear();
}
function userAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/adduser', requestOptions).then(
    miscService.handleResponse,
  );
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/employerregister', requestOptions).then(
    miscService.handleResponse,
  );
}

//-----register candidate thru referal-------------
function registerCandidate(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(
    apiConstants.apiUrl + '/candidateregisterreferal',
    requestOptions,
  ).then(miscService.handleResponse);
}
function sendOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/setotpmail', requestOptions).then(
    miscService.handleResponse,
  );
}

function verifyOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/verifyOtpweb', requestOptions).then(
    miscService.handleResponse,
  );
}

function userList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getuserList', requestOptions).then(
    miscService.handleResponse,
  );
}

// function userDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deleteuser', requestOptions).then(
//     handleResponse,
//   );
// }

function changePassword(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/changepassword', requestOptions).then(
    miscService.handleResponse,
  );
}

function sendResetUserCredentials(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/sendResetUserCredentials',
    requestOptions,
  ).then(miscService.handleResponse);
}

function microsoftlogin(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      access_token: data.accessToken,
      email: data.account.username,
      isLogin: data.isLogin,
      isRegister: data.isRegister,
      userType: data.userType,
      id: data.id,
      is_saved_microsoft_id: !!data.is_saved_microsoft_id,
    }),
  };
  return fetch(apiConstants.apiUrl + '/microsoft-auth', requestOptions).then(
    miscService.handleResponse,
  );
}

function GoogleLoginRegistration(user) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  };
  return fetch(
    apiConstants.apiUrl + '/verify-google-token',
    requestOptions,
  ).then(miscService.handleResponse);
}

function updatePhoneNumber(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/updateprofile', requestOptions).then(
    miscService.handleResponse,
  );
}

function sendOtpRegister(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/sentOtpRegister', requestOptions).then(
    miscService.handleResponse,
  );
}

// function resendOtpRegister(user) {
//   console.log(user, 'wwwwwwwwwwwwwwwwwwwww');
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(user),
//   };
//   return fetch(apiConstants.apiUrl + '/resendotpRegister', requestOptions).then(
//     handleResponse,
//   );
// }

function sendOtpLogin(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/sendLoginOtp', requestOptions).then(
    miscService.handleResponse,
  );
}

function verifyOtpRegister(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/verifyRegister', requestOptions).then(
    miscService.handleResponse,
  );
}

function registrationWithToken(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(
    apiConstants.apiUrl + '/employerRegistration',
    requestOptions,
  ).then(miscService.handleResponse);
}

function verifyLogin(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/verifyLogin', requestOptions)
    .then(miscService.handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }
      return user;
    });
}

function changEmailRequest(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return fetch(
    apiConstants.apiUrl + '/changeemailrequest',
    requestOptions,
  ).then(miscService.handleResponse);
}

function verifyEmail(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/verifynewemail', requestOptions)
    .then(miscService.handleResponse)
    .then(user => {
      let User = JSON.parse(localStorage.getItem('user'));
      if (user.data.email) {
        User.email = user.data.email;
      }
      localStorage.setItem('user', JSON.stringify(User));
      return user;
    });
}
