import React, { useEffect, useState } from 'react';
import { apiConstants } from '../../_constants';
import { userService } from '../../_services';
import RequestModal from './RequestModal';

const BillingInfo = props => {
  let billing_Address = props?.profile['Employers.billingAddress'];
  const [billingInfo, setBillingInfo] = useState({
    billingAddress: {
      firstName: billing_Address?.['firstName'] || '',
      lastName: billing_Address?.['lastName'] || '',
      postcode: billing_Address?.['postcode'],
      address: billing_Address?.['address'],
    },
  });
  const [error, setError] = useState(null);
  const [billingError, setBillingError] = useState({
    firstName: '',
    lastName: '',
    postcode: '',
    address: '',
  });
  const [loading, setLoading] = useState(false);
  // const [saved, setSaved] = useState(false);
  const [requested, setRequested] = useState(false);
  const [requestAgain, setRequestAgain] = useState(false);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    // getCustomerCard()
    if (
      billingInfo?.billingAddress?.postcode ||
      props?.profile?.billingAddress?.postcode
    ) {
      loadaddress();
    }
  }, [
    billingInfo?.billingAddress?.postcode ||
      props?.profile?.billingAddress?.postcode,
  ]);

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'postcode') {
      if (value.trim() === '') {
        setBillingInfo({
          ...billingInfo,
          billingAddress: {
            ...billingInfo.billingAddress,
            postcode: '',
            address: '',
          },
        });
        setPostcodeObject({});
        return;
      } else if (value.length <= 10) {
        if (!valid_postcode(value)) {
          setPostcodeObject({});
          setBillingError({ ...billingError, postcode: 'Invalid Postcode' });
        } else {
          setBillingError({ ...billingError, postcode: '' });
        }
        setBillingInfo({
          ...billingInfo,
          billingAddress: {
            ...billingInfo.billingAddress,
            address: '',
            [name]: value,
          },
        });
      }
    } else {
      setBillingInfo(prevBillingInfo => ({
        ...prevBillingInfo,
        billingAddress: {
          ...prevBillingInfo.billingAddress,
          [name]: value,
        },
      }));
    }
  };

  //getCustomerCard
  // const getCustomerCard = () => {
  //     setPaymentLoading(true)
  //     query.user_id = props.id;
  //     setQuery(query);

  //     paymentService.getCustomerCard(query).then(
  //         res => {
  //             console.log(res, 'Payment response==>7756')
  //             setPaymentLoading(false);
  //             setShowShowCard(true)

  //         },
  //         error => {
  //             if (error?.error_code == 1) {
  //                 setShowSetUp(true);
  //                 setPaymentLoading(false);
  //             } else if (error?.error_code == 2) {
  //                 setError(error.message)
  //                 setPaymentLoading(false);
  //             } else if (error?.error_code == 3) {
  //                 console.log('Logged in no payment');
  //                 setError(error.message);
  //                 setPaymentLoading(false);
  //                 setShowSetUp(true);
  //             }
  //             setPaymentLoading(false);

  //         },
  //     );
  // };

  // ---check valid postcode or not---------
  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    var regex =
      /^[A-Za-z]{1,2}[0-9RCHNQrchnq][0-9A-Za-z]?\s?[0-9][ABD-HJLNP-UW-Zabc-hjlnp-z]{2}$/;
    return regex.test(postcode);
  };

  // load address by postcode
  const loadaddress = async () => {
    try {
      const res = (
        billingInfo?.billingAddress?.postcode ||
        props?.profile?.billingAddress?.postcode
      )?.replace(/ /g, '');
      if (valid_postcode(res)) {
        let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
        let response = await fetch(posstcodeurl);
        response = await response.json();
        if (response && response.latitude && response.longitude) {
          // results geometry location
          let addresses_rows = [];
          let regex = /\s+,/g;
          for (let s = 0; s < response.addresses.length; s++) {
            addresses_rows.push(
              <option>{response.addresses[s].replace(regex, ' ')}</option>,
            );
          }
          setPostcodeObject({
            isValied: true,
            addresses: addresses_rows,
          });
        }
      }
    } catch (error) {
      console.error('Error loading address:', error);
      setError('Error loading address. Please try again.');
    }
  };

  const requestSubmit = event => {
    setRequested(true);
    const slaStatusObject = {};
    slaStatusObject.role = 4;
    slaStatusObject._id = props.id;
    event.preventDefault();
    slaStatusObject.SLA_status = -1;
    if (slaStatusObject.SLA_status) {
      // If SLA_status is set, send only slaStatusObject to the backend
      userService.userAdd(slaStatusObject).then(
        res => {
          console.log(res, 'res of request');
          setRequested(false);
          setEdit(false);
          props.userlist();
          props.profile.SLA_status = -1;
        },
        err => {
          console.error('Error submitting request:', err);
          setRequested(false);
          setError('Error submitting request. Please try again.');
          props.profile.SLA_status = 0;
        },
      );
    }
  };

  const requestAgainSubmit = event => {
    setRequestAgain(true);
    const slaStatusObject = {};
    slaStatusObject.role = 4;
    slaStatusObject._id = props.id;
    event.preventDefault();
    slaStatusObject.SLA_status = -2;
    if (slaStatusObject.SLA_status) {
      // If SLA_status is set, send only slaStatusObject to the backend
      userService.userAdd(slaStatusObject).then(
        res => {
          console.log(res, 'res of request again');
          setRequestAgain(false);
          setEdit(false);
          props.userlist();
          props.profile.SLA_status = -2;
        },
        err => {
          console.error('Error submitting request again:', err);
          setRequestAgain(false);
          setError('Error submitting request. Please try again.');
          props.profile.SLA_status = -1;
        },
      );
    }
  };

  const handleSubmit = event => {
    // setSaved(true)
    setError('');
    setBillingError('');
    billingInfo.role = 4;
    const slaStatusObject = {};
    slaStatusObject.role = 4;
    slaStatusObject._id = props?.id;
    event.preventDefault();
    billingInfo._id = props.id;
    if (billingError) {
      return;
    }
    setLoading(true);
    if (
      billingInfo.billingAddress.firstName &&
      billingInfo.billingAddress.lastName &&
      billingInfo.billingAddress.postcode &&
      billingInfo.billingAddress.address
    ) {
      // Check if there is valid billing information
      userService.userAdd(billingInfo).then(
        res => {
          console.log(res, 'ressssssssssssssss amdin');
          setLoading(false);
          setEdit(false);
          props.billingAddressChange();
        },
        err => {
          console.error('Error submitting billing info:', err);
          setLoading(false);
          setError('Error submitting billing info. Please try again.');
        },
      );
    } else {
      setLoading(false);
      setError('Please fill the details');
    }
  };
  return (
    <div className="col-md-10 flx-emp-profile-main-form">
      <h2 className="flx-company-info-form-heading">Billing Info</h2>
      {/* Pricing Section */}
      {(props?.profile['Employers.SLA_status'] === 0 ||
        props?.profile['Employers.SLA_status'] === null) && (
        <>
          <p className="flx-billing-info-pricing-text">Pricing</p>
          <div className="flx-billing-info-pricing-plan-name">
            Standard{' '}
            <i
              className="fa fa-info-circle company-info-icon-tooltip pointer"
              style={{ position: 'relative' }}
            >
              <div className="tooltip-content">Standard Pricing</div>
            </i>
          </div>
        </>
      )}

      {/* Invoice Block */}
      {props?.profile['Employers.SLA_status'] === -2 && (
        <>
          <p className="flx-billing-info-pricing-text">Pricing</p>
          <div className="flx-billing-info-pricing-plan-name">
            Invoicing{' '}
            <i
              className="fa fa-info-circle company-info-icon-tooltip pointer"
              style={{ position: 'relative' }}
            >
              <div className="tooltip-content">Invoicing</div>
            </i>
          </div>
        </>
      )}
      {/* {props?.profile['Employers.SLA_status'] == -2 && (
        <>
          <p className="flx-billing-info-pricing-text">Pricing</p>
          <div className="flx-billing-info-pricing-plan-name mb-3">
            <div>Requested for invoicing</div>
          </div>
        </>
      )} */}
      {/* Main Content for Editing Profile Information */}
      {props?.profile['Employers.SLA_status'] === 0 && (
        <div className="flx-container my-4">
          <div className="flx-invoicing-container d-flex justify-content-center align-items-center">
            <div className="flx-switch-invoice-text">
              <h6>Want to switch to Invoicing?</h6>
              <p>
                Switch to invoicing and get benefits of guaranteed staffing,
                fixed price and up to 45 days to pay your agency shift charges.
              </p>
            </div>
            <div
              onClick={event => requestSubmit(event)}
              className="btn-billing-info pointer"
            >
              Request
            </div>
          </div>
        </div>
      )}

      {/* Request Submitted for Invoice Block */}
      {props?.profile['Employers.SLA_status'] === -1 && (
        <div className="flx-container my-4">
          <div className="flx-invoicing-container-agreed d-flex justify-content-center align-items-center">
            <img
              className="flx-billin-info-edit"
              src="/assets/img/inv-req-correct.svg"
              alt="Request Correct"
            />
            <div>
              <h6 className="flx-billing-alert-heading">
                Thank you for your request to switch to monthly invoicing. Our
                team will get in touch with you shortly.
              </h6>
              <p className="mb-0">
                It may take up to 3 business days to process your request. You
                can also send an email to{' '}
                <a href="mailto:info@flexirecs.co.uk" className="alert-link">
                  info@flexirecs.co.uk
                </a>{' '}
                and request for this change.
              </p>
            </div>
            <div
              onClick={event => requestAgainSubmit(event)}
              className="flx-req-again-inv pointer"
            >
              Request again
            </div>
          </div>
        </div>
      )}
      {/* Request Submitted for Invoice Block Ends */}

      {/* Billing Address Section */}
      <h6 className="flx-billing-address-title flx-recs-required">
        Billing Address
      </h6>
      <form>
        {!billing_Address?.['firstName'] &&
          !billingInfo?.billingAddress?.firstName &&
          !edit && (
            <span
              htmlFor="Add"
              className="add-billing-style"
              onClick={() => setEdit(true)}
            >
              Add
            </span>
          )}
        {/* Section When Billing Details are Saved */}
        {(billing_Address?.['firstName'] ||
          billing_Address?.['postcode'] ||
          billing_Address?.['address']) &&
          !edit && (
            <div className="card flx-billing-saved-container my-3">
              <div
                className="flx-billin-info-edit-icon-container pointer"
                onClick={() => setEdit(true)}
              >
                <img
                  className="flx-billin-info-edit-icon"
                  src="/assets/img/job-post-edit.svg"
                  alt="Edit Billing Info"
                />
              </div>
              <div className="col-md-7 card-body flx-billing-saved-body pt-0">
                {/* Rows */}
                <div className="row flx-billing-saved-row">
                  <div className="col flx-billing-saved-col ">
                    <h6 className="flx-billing-saved-card-title">First Name</h6>
                    <p className="flx-billing-saved-card-text">
                      {billing_Address?.['firstName']}
                    </p>
                  </div>
                  <div className="col flx-billing-saved-col">
                    <h6 className="flx-billing-saved-card-title">Last Name</h6>
                    <p className="flx-billing-saved-card-text">
                      {billing_Address?.['lastName']}
                    </p>
                  </div>
                </div>

                <div className="row flx-billing-saved-row">
                  <div className="col flx-billing-saved-col">
                    <h6 className="flx-billing-saved-card-title">Postcode</h6>
                    <p className="flx-billing-saved-card-text">
                      {billing_Address?.['postcode']}
                    </p>
                  </div>
                  <div className="col flx-billing-saved-col">
                    <h6 className="flx-billing-saved-card-title">Address</h6>
                    <p className="flx-billing-saved-card-text">
                      {billing_Address?.['address']}
                    </p>
                  </div>
                </div>
                {/* Rows End */}
              </div>
            </div>
          )}
        {/* Section When Billing Details are Saved Ends */}

        {edit && (
          <>
            <div className="mb-3">
              <label
                htmlFor="First Name"
                className="form-label company-info-labels flx-recs-required"
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control selectDesign"
                id="companyName"
                name="firstName"
                value={billingInfo?.billingAddress?.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="Last Name"
                className="form-label company-info-labels flx-recs-required"
              >
                Last Name
              </label>
              <input
                type="text"
                className="form-control selectDesign"
                id="companyName"
                name="lastName"
                value={billingInfo?.billingAddress?.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="postcode"
                className="form-label company-info-labels flx-recs-required"
              >
                Postcode
              </label>
              <input
                type="text"
                className="form-control selectDesign"
                id="postcode"
                name="postcode"
                value={billingInfo?.billingAddress?.postcode}
                onChange={handleChange}
                placeholder="Enter Postcode"
              />
              {billingError.postcode && (
                <div className="text-danger">{billingError.postcode}</div>
              )}
            </div>
            <div className="mb-3">
              <div className="col-md-12 px-0">
                <div className="form-group">
                  <label
                    htmlFor="postcode"
                    className="form-label company-info-labels flx-recs-required"
                  >
                    Address
                  </label>
                  <select
                    className="form-control selectDesign"
                    name="address"
                    placeholder="select Address"
                    value={billingInfo?.billingAddress?.address}
                    onChange={handleChange}
                  >
                    <option value={''} hidden="true">
                      Select Address
                    </option>
                    {PostcodeObject.addresses}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
      {edit && (
        <button
          style={{ borderRadius: '6px', background: '#1AC8FF' }}
          onClick={handleSubmit}
          type="submit"
          className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
          disabled={loading}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: 1.25,
            }}
          >
            {' '}
            {loading ? 'Saving...' : 'Save'}
          </span>
        </button>
      )}
      {error && (
        <p style={{ color: 'red' }}>
          {error.message
            ? error.message
            : typeof error === 'string'
            ? error
            : 'Please try again after some time'}
        </p>
      )}
      {/* Payment Details Section */}

      {/* The below portion of the code is under maintenance. It will be uncommented in */}

      {/* <div className="flx-payment-details-container mt-5">

                <h5 className="flx-payment-details-heading flx-recs-required">
                    Payment Details
                </h5>
                {showSetUp && <button
                    type="submit"
                    onClick={() => setPaymentModal(true)}
                    className="btn btn-sm flx-recs-primary-btn billing-payment-button"
                >
                    Set up payment
                </button>
                } */}

      {/* Card Details Saved Block */}
      {/* {!showSetUp && !paymentLoading && showCard && <>
                    <div className="flx-billing-info-card-details-saved-container d-flex">
                        <p className="flx-billing-info-card">
                            Card ending at <span className="flx-card-numbers">8990</span>{' '}
                        </p>
                        <a className="flx-billing-remove-card">
                            Remove card
                        </a>
                    </div>
                    <a
                        onClick={() => setPaymentModal(true)}
                        className="flx-billing-remove-card float-right mb-10 mt-1"
                    >
                        {' '}
                        Change card/ payment method
                    </a>
                </>}
                {!showSetUp && paymentLoading && <LoadingImg />} */}
      {/* Card Details Saved Block Ends */}
      {/* </div> */}

      {/* {error && <p style={{ color: 'red' }}>{error.message ? error.message : (typeof error === "string" ? error : "Please try again after some time")}</p>} */}
      {/* {paymentModal && (
                <div className="modals">
                    <div className="d-flex column modals-content">
                        <span className="closed" onClick={() => setPaymentModal(false)}>
                            &times;
                        </span>
                        <AddPayment setShowSetUp={setShowSetUp} />

                    </div>
                </div>
            )} */}
      {requested && <RequestModal />}
      {requestAgain && <RequestModal />}
    </div>
  );
};

export default BillingInfo;
