import React, { useEffect, useState } from 'react';
import { LoadingIcon } from '../_components';
import { userService } from '../_services';
import { EmployerProfileContent } from './EmployerProfileContent';
import EmployerProfileMenu from './EmployerProfileMenu';

const EmployerProfilePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [isAgency, setIsAgency] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  let id = null;
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setprofile] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });

  const billingAddressChange = () => {
    getAllEmployerDetails(1, query);
  };

  useEffect(() => {
    if (userData.role == 1) {
      setIsAdmin(true);
    }
    if (userData.role == 5) {
      setIsAgency(true);
    }
    if (userData.role == 6) {
      setIsStaff(true);
    }
    if (userData.role == 4) {
      setIsEmployer(true);
    }
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllEmployerDetails(1, query);
    }
  }, []);
  const userData = JSON.parse(localStorage.getItem('user'));
  id = userData._id;
  const getAllEmployerDetails = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    // employerService.employerDetails(queryNew).then(
    userService.userList(queryNew).then(
      res => {
        setIsLoading(false);
        if (res?.data?.rows) {
          setprofile(res?.data?.rows[0]);
        }
      },
      err => {
        setIsLoading(false);
        setError('Error fetching employer details. Please try again.');
        console.error('Error fetching employer details:', err);
      },
    );
  };

  return (
    <div className="flx-company-info container mt-3">
      {!isLoading ? (
        <div className="flx-company-info-row row d-block d-md-flex">
          <div className="col-md-4">
            <EmployerProfileMenu
              currentPage={currentPage}
              id={id}
              profile={profile}
              setCurrentPage={setCurrentPage}
              isAgency={isAgency}
              isStaff={isStaff}
              isEmployer={isEmployer}
              isAdmin={isAdmin}
            />
          </div>
          <div className="col-md-8">
            <EmployerProfileContent
              billingAddressChange={billingAddressChange}
              currentPage={currentPage}
              id={id}
              profile={profile}
              setprofile={setprofile}
              setCurrentPage={setCurrentPage}
              userlist={getAllEmployerDetails}
              isAgency={isAgency}
              isStaff={isStaff}
              isEmployer={isEmployer}
              isAdmin={isAdmin}
            />
          </div>
          {error && (
            <p style={{ color: 'red' }}>
              {error.message
                ? error.message
                : typeof error === 'string'
                ? error
                : 'Please try again after some time'}
            </p>
          )}
        </div>
      ) : (
        <LoadingIcon loadingText={'Loading...'} />
      )}
    </div>
  );
};

export default EmployerProfilePage;
