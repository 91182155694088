import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

// eslint-disable-next-line no-unused-vars
let reportRows = [];
let isAdmin = null;
let isEmployer = false;
let isBunit = false;
let isStaff = null;
let employer_id = '';
let bunit_id = '';

const OrganizationReport = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [booking, setbooking] = useState({});
  const [reportRows, setReportRows] = useState([]);

  const [filter, setfilter] = useState({ employer_id: '', candidate_id: '' });
  const [viewshiftsEmployer_id, SetviewshiftsEmployer_id] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [selecteddate, setselecteddate] = useState({
    from_date: '',
    to_date: '',
  });
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4) {
        isEmployer = true;

        isAdmin = false;
        isBunit = false;
        if (user && user.employer_id) {
          employer_id = user.employer_id;
        }
      }
      if (user.role == 7) {
        isBunit = true;
        isEmployer = true;
        employer_id = user.employer_id;
        bunit_id = user.business_unit_id;
      }
    }
    getAllShifts(1);
  }, [
    selecteddate,
    query.employer_id,
    query.from_date,
    query.to_date,
    query.candidate_id,
    query,
    query.branch,
  ]);

  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    setfilter({ ...filter, candidate_id: selectedOption.value });
    let newquery = { ...query, candidate_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    setfilter({ ...filter, employer_id: selectedOption.value });
    let newquery = { ...query, employer_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (selecteddate.from_date) {
      queryNew.from_date = selecteddate.from_date;
    }
    if (selecteddate.to_date) {
      queryNew.to_date = selecteddate.to_date;
    }
    setQuery(queryNew);
    setIsLoading(true);
    if (isAdmin == true || isStaff == true) {
      queryNew.adminreport = true;
    }
    if (isEmployer == true) {
      //   queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
      }
    }
    if (isBunit == true) {
      //   queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
        queryNew.business_unit = bunit_id;
      }
    }
    if (queryNew.from_date > queryNew.to_date) {
      setError({ common: 'Choose a valid date' });
      return;
    }
    const { dispatch } = props;
    dispatch(shiftsActions.getorganizationreportShiftList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setselecteddate({
      ...selecteddate,
      [name]: value,
    });
    setError({ common: '' });
    setbooking({
      ...booking,
      [name]: value,
    });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      padding: '2px',
      paddingBottom: '6px',
      height: '6px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      border: 'none',
      background: '#f4f4f4',
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    // placeholder: provided => ({
    //   ...provided,
    //   color: '#000000',
    // }),
  };

  const setListAndPagination = reportData => {
    let reportList = [];
    for (
      let j = 0;
      reportData && reportData.rows && j < reportData.rows.length;
      j = j + 1
    ) {
      reportList.push(
        <tr key={j} className="username">
          {isEmployer && <td>{reportData.rows[j].company_name} </td>}
          <td>
            {reportData.rows[j].total_shifts
              ? reportData.rows[j].total_shifts
              : 0}
          </td>
          {/* <td>
            {reportData.rows[j].total_signed_hrs
              ? reportData.rows[j].total_signed_hrs + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td> */}
          <td>
            {reportData.rows[j].total_hours
              ? reportData.rows[j].total_hours + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td>
          <td>
            {reportData.rows[j].invoice_generated
              ? reportData.rows[j].invoice_generated
              : 0}
          </td>{' '}
          <td>
            {reportData.rows[j].avg_price ? reportData.rows[j].avg_price : 0}
          </td>
          <td>
            {reportData.rows[j].total_amount
              ? '£' + ' ' + reportData.rows[j].total_amount
              : '£' + ' ' + 0}
          </td>
          {/* <td>
            Night &nbsp; - &nbsp;{' '}
            {reportData.rows[j].total_night && reportData.rows[j].total_night
              ? reportData.rows[j].total_night
              : 0}
            &nbsp; hrs
            <p>
              Morning&nbsp; - &nbsp;
              {reportData.rows[j].total_morning &&
              reportData.rows[j].total_morning
                ? reportData.rows[j].total_morning
                : 0}
              &nbsp; hrs
            </p>
            Custome&nbsp; - &nbsp;
            {reportData.rows[j].total_custome &&
            reportData.rows[j].total_custome
              ? reportData.rows[j].total_custome
              : 0}
            &nbsp; hrs
          </td> */}
          <td>
            <div className="d-flex justify-content-center">
              <div
                className="  allshift-viewdt"
                onClick={() => {
                  SetviewshiftsEmployer_id(reportData.rows[j].employer_id),
                    setviewdetailsmodal(true);
                }}
              >
                View
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setReportRows(reportList);
    setCandidateData(reportData);
  };

  return (
    <div className="CandidateList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8">
                  {/* <div className="page-title">Report</div> */}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="ml-3 filter-row-full ">
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-1 pl-1">
                      {' '}
                      <label className="" htmlFor="type">
                        From
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="input-design1"
                        style={{
                          border: 'none',
                          display: 'block',
                          width: '100%',
                          height: '40px',
                          padding: '0.375rem 0.75rem',
                          flexShrink: '0',
                          fontSize: '14px',
                          fontWeight: '500',
                          borderRadius: '6px',
                        }}
                        name="from_date"
                        placeholder=""
                        value={selecteddate.from_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-1 pl-1">
                      {' '}
                      <label className="" htmlFor="type">
                        To
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="input-design1"
                        style={{
                          border: 'none',
                          display: 'block',
                          width: '100%',
                          height: '40px',
                          padding: '0.375rem 0.75rem',
                          flexShrink: '0',
                          fontSize: '14px',
                          fontWeight: '500',
                          borderRadius: '6px',
                        }}
                        name="to_date"
                        placeholder=""
                        value={selecteddate.to_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {!isEmployer && (
                  <div className="filter-item">
                    <div className="" style={{}}>
                      <div className="mb-1 pl-1">
                        {' '}
                        <label className="" htmlFor="type">
                          Employer
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="employer_id"
                          onChange={setEmployer}
                          placeholder={'Select Employer'}
                          cacheOptions
                          defaultOptions
                          // isMulti
                          loadOptions={getEmployerlist}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-1 pl-1">
                      <label className="" htmlFor="type">
                        Candidate
                      </label>
                    </div>
                    <div>
                      <AsyncSelect
                        styles={customStyles}
                        name="candidate_id"
                        onChange={selectedcandidate}
                        placeholder={'Select Candidate '}
                        cacheOptions
                        defaultOptions
                        className="candidateselect"
                        // isMulti
                        loadOptions={getcandidatelist}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-10">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        Branch
                      </label>
                    </div>
                    <div>
                      <select
                        className="select col-md-12 selectDesign"
                        name="branch"
                        value={query.branch}
                        // onChange={e => {
                        //   query.branch = e.target.value;
                        // }}
                        onChange={e => {
                          setQuery(prevquery => ({
                            ...prevquery,
                            branch: e.target.value,
                          }));
                        }}
                      >
                        <option value="" hidden={true}>
                          {' '}
                          select
                        </option>
                        <option value="">All</option>
                        <option value={'Basildon'}>Basildon</option>
                        <option value={'Wales'}>Wales</option>
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <>
              <div className="card-body  table-responsive main-card-body ">
                {!isLoading && (
                  <table className="table  flx-custom-table ">
                    <thead>
                      <tr className="flx-table-header-clr">
                        <th
                          style={{ width: '200px' }}
                          onClick={() => handleSort('company_name')}
                          className="sortHead border-top1 table-header-ht"
                        >
                          <div className="d-flex align-items-center ">
                            <span>Name</span>

                            <span
                              style={{ width: '20px' }}
                              className="d-flex flex-column align-items-center"
                            >
                              {query.pageVo.sort === 'company_name' &&
                              query.pageVo.sort_order !== 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up-black.png"
                                  onClick={() => handleSort('company_name')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up.png"
                                  onClick={() => handleSort('company_name')}
                                />
                              )}
                              {query.pageVo.sort === 'company_name' &&
                              query.pageVo.sort_order === 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down-black.png"
                                  onClick={() => handleSort('company_name')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down.png"
                                  onClick={() => handleSort('company_name')}
                                />
                              )}
                            </span>
                          </div>
                        </th>
                        {(isAdmin || isStaff) && <th>Total People Worked</th>}
                        <th>Total Shifts Worked</th>
                        {/* <th
                          // style={{ width: '200px' }}
                          onClick={() => handleSort('total_signed_hrs')}
                          className="sortHead border-top1"
                        >
                          Total signed hours
                          <span className={'sortBtn '}>
                            {' '}
                            <i
                              className={
                                'fa fa-sort-up ' +
                                (query.pageVo.sort == 'total_signed_hrs' &&
                                query.pageVo.sort_order != 'desc'
                                  ? ' active'
                                  : '')
                              }
                            ></i>{' '}
                            <i
                              className={
                                'fa fa-sort-down ' +
                                (query.pageVo.sort == 'total_signed_hrs' &&
                                query.pageVo.sort_order == 'desc'
                                  ? ' active'
                                  : '')
                              }
                            ></i>
                          </span>
                        </th> */}
                        <th
                          // style={{ width: '200px' }}
                          onClick={() => handleSort('total_hours')}
                          className="sortHead border-top1"
                        >
                          <div className="d-flex align-items-center ">
                            <span>Total Hour Worked</span>

                            <span
                              style={{ width: '20px' }}
                              className="d-flex flex-column align-items-center"
                            >
                              {query.pageVo.sort === 'total_hours' &&
                              query.pageVo.sort_order !== 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up-black.png"
                                  onClick={() => handleSort('total_hours')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up.png"
                                  onClick={() => handleSort('total_hours')}
                                />
                              )}
                              {query.pageVo.sort === 'total_hours' &&
                              query.pageVo.sort_order === 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down-black.png"
                                  onClick={() => handleSort('total_hours')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down.png"
                                  onClick={() => handleSort('total_hours')}
                                />
                              )}
                            </span>
                          </div>
                        </th>
                        <th>Invoice Generated</th>
                        <th>Avg Price</th>
                        <th
                          // style={{ width: '200px' }}
                          onClick={() => handleSort('total_amount')}
                          className="sortHead border-top1"
                        >
                          <div className="d-flex align-items-center ">
                            <span>Total Amount</span>

                            <span
                              style={{ width: '20px' }}
                              className="d-flex flex-column align-items-center"
                            >
                              {query.pageVo.sort === 'total_amount' &&
                              query.pageVo.sort_order !== 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up-black.png"
                                  onClick={() => handleSort('total_amount')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-up.png"
                                  onClick={() => handleSort('total_amount')}
                                />
                              )}
                              {query.pageVo.sort === 'total_amount' &&
                              query.pageVo.sort_order === 'desc' ? (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down-black.png"
                                  onClick={() => handleSort('total_amount')}
                                />
                              ) : (
                                <img
                                  style={{
                                    height: 10,
                                    width: 10,
                                    cursor: 'pointer',
                                  }}
                                  src="/assets/img/sort-down.png"
                                  onClick={() => handleSort('total_amount')}
                                />
                              )}
                            </span>
                          </div>
                        </th>
                        {/* <th>Worked shifts</th> */}
                        <th className="border-top2"></th>
                      </tr>
                    </thead>
                    <tbody>{reportRows}</tbody>
                  </table>
                )}
                {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
                {reportRows && reportRows.length == 0 && !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
              </div>
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllShifts(data);
                  }}
                  paginationData={candidateData}
                />
              </div>
            </>

            <div className="row">
              <div className="col-md-12 pr-1" style={{ marginLeft: '10px' }}>
                <span className="color-red">{error.common}</span>
              </div>
            </div>
          </div>
        </div>

        {viewdetailsmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content ">
                <div className="modal-header border-bot-0 justify-content-end">
                  <div className="   ">
                    <div className="close">
                      <a onClick={() => setviewdetailsmodal(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <AllShiftsList
                    is_report={true}
                    parent_id={viewshiftsEmployer_id}
                    from_date={selecteddate.from_date}
                    to_date={selecteddate.to_date}
                    candidate_id={filter.candidate_id}
                    is_verified="1"
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
        // is_organization_load_options: true,
        select: ['_id', 'company_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name }) => ({
              value: _id,
              label: name + ' ' + (last_name ? last_name : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedOrganizationReport =
  connect(mapStateToProps)(OrganizationReport);
export { connectedOrganizationReport as OrganizationReport };
