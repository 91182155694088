import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { allocatecandidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

const CandidateemployerallocationAdd = props => {
  const { id } = useParams();
  const [allocatecandidate, setAllocatecandidate] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (id != undefined) {
      query._id = id;
      setQuery(query);
      getAllAllocatecandidate(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllAllocatecandidate(1, query);
    }
  }, []);
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  /**
   * Get all allocatecandidates
   * */
  const getAllAllocatecandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(allocatecandidateActions.allocatecandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setAllocatecandidate(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // submit action for allocate candidate
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (allocatecandidate.candidate_id && allocatecandidate.employer_id) {
      setIsLoading(true);
      dispatch(
        allocatecandidateActions.allocatecandidateAdd(allocatecandidate),
      ).then(
        () => {
          setIsLoading(false);
          props.showsavemodal(false);
        },
        err => {
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          hideError();
          setIsLoading(false);
        },
      );
    }
  };
  // to select user from select box
  const selecteduser = selectedOption => {
    setAllocatecandidate(prevState => ({
      ...prevState,
      candidate_id: selectedOption.value,
    }));
  };
  // to select carehome from select box
  const selectedcarehome = selectedOption => {
    setAllocatecandidate(prevState => ({
      ...prevState,
      employer_id: selectedOption.value,
    }));
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #acb5bc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      background: '#FFFFFF',
      borderRadius: '6px',
      lineHeight: '25px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };
  return (
    <div className="AllocatecandidateAdd">
      <div className="col-md-12 d-flex justify-content-center">
        {isLoading && <LoadingModal />}
        <div className="card col-md-12 ">
          <div className=" page-header ">
            <div className="page-title-content ml-2 pl-1">
              <p className="card-title">Allocate user for block booking</p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !allocatecandidate.candidate_id
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">
                      user : <span>*</span>
                    </label>
                    <AsyncSelect
                      styles={customStyles}
                      name="candidate_id"
                      onChange={selecteduser}
                      cacheOptions
                      defaultOptions
                      className="candidateselect"
                      loadOptions={getcandidatelist}
                    />
                    {submitted && !allocatecandidate.candidate_id && (
                      <div className="help-block">user is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !allocatecandidate.employer_id
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">
                      carehome : <span>*</span>
                    </label>
                    <AsyncSelect
                      styles={customStyles}
                      name="employer_id"
                      onChange={selectedcarehome}
                      cacheOptions
                      defaultOptions
                      loadOptions={getcarehomelist}
                      className="employerselect"
                    />
                    {submitted && !allocatecandidate.employer_id && (
                      <div className="help-block">carehome is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="float-right">
                      <button
                        className="btn btn-primary"
                        data-cy="allocate"
                        style={{ width: '120px' }}
                      >
                        <span
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: 1.25,
                          }}
                        >
                          Save
                        </span>
                        {props.requestStatus ===
                          reducerConstants.ALLOCATECANDIDATE_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
// loader for candidate select box
const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        isverified: 1,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.candidate.data.rows.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for carehome select box
const getcarehomelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, allocatecandidateData } =
    state.candidateemployerallocation;
  return {
    requestStatus,
    allocatecandidateData,
  };
}

const connectedCandidateemployerallocationAdd = connect(mapStateToProps)(
  CandidateemployerallocationAdd,
);
export { connectedCandidateemployerallocationAdd as CandidateemployerallocationAdd };
