import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { AccountIDPrompt, RegPopup } from '../_components';
import { loginRequest, userService } from '../_services';

const MicrosoftAuth = ({
  isLogin,
  showError,
  showLoading,
  isRegister,
  userType,
}) => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [microsoftLoginId, setMicrosoftLoginId] = useState(null);
  const [promptModel, setpromptModel] = useState(false);
  const [promptModelRegistration, setPromptModelRegistration] = useState(false);

  const handleLogin = async () => {
    await instance.loginPopup(loginRequest);
  };

  const updateLoadingStatus = status => {
    showLoading(status);
  };

  useEffect(() => {
    return () => {
      // Cleanup function to execute when component unmounts or navigates away
      updateLoadingStatus(false);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      // updateLoadingStatus(true);
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: loginRequest.scopes,
        })
        .then(response => {
          microsoftLoginService(response);
        });
    }
  }, [inProgress, isAuthenticated, accounts, instance]);

  const microsoftLoginService = async data => {
    setMicrosoftLoginId(data);
    data.isLogin = isLogin;
    data.isRegister = isRegister;
    data.userType = userType;
    updateLoadingStatus(true);

    try {
      const res = await userService.microsoftlogin(data);

      if (res.error_code !== 0) {
        updateLoadingStatus(false);
        showError(res.message);
        return;
      }

      const { data: userData, message } = res;

      // Handle different scenarios based on response data
      if (userData.microsoftLogin) {
        handleLogin();
        return;
      }

      if (userData.show_microsoftIntegrationModel) {
        setPromptModelRegistration(true);
      } else if (userData.toLinkWithGoogle) {
        setpromptModel(true);
      } else {
        // Store user data in local storage
        localStorage.setItem('user', JSON.stringify(userData));

        if (message === 'NOT ACTIVATED') {
          window.location.href = '/agencywaitingpage';
          return;
        }

        // Handle redirection based on user role and status
        handleRedirect(userData);
      }

      updateLoadingStatus(false);
    } catch (error) {
      updateLoadingStatus(false);
      showError(typeof error === 'string' ? error : 'Microsoft Login Failed');
      window.localStorage.clear();
    }
  };

  // Function to handle redirection based on user role and status
  const handleRedirect = userData => {
    const { role, active_status, phone, name, is_signup_completed } = userData;

    switch (role) {
      case 5: // Employer
        if (active_status === 1) {
          window.location.href = '/dashboardemployer';
        } else if (!phone) {
          window.location.href = '/phonenumber';
        } else if (name && active_status === 0) {
          window.location.href = '/agencywaitingpage';
        } else {
          window.location.href = '/login';
        }
        break;

      case 4: // Agency
        if (is_signup_completed) {
          window.location.href = '/dashboardemployer';
        } else {
          window.location.href = '/phonenumber';
        }
        break;

      case 6: // Staff
      case 7: // Business Unit
        window.location.href = '/dashboardemployer';
        break;

      default:
        window.location.href = '/login';
        break;
    }
  };

  // For close the modal
  const closeModal = () => {
    setMicrosoftLoginId(null);
    setpromptModel(false);
    setPromptModelRegistration(false);
  };

  // Popup for AccountIDPrompt modal in Sign-up
  const cancelPopModal = async () => {
    setMicrosoftLoginId(null);
    window.localStorage.clear();
    window.location.href = '/login';
    return;
  };

  // AccountIDPrompt modal to link with microsoft in Sign-in
  const handleMicrosoftLoginClick = () => {
    microsoftLoginService({
      ...microsoftLoginId,
      is_saved_microsoft_id: 'true',
    });
  };

  return (
    <div>
      <>
        {promptModel && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <AccountIDPrompt
              onCancel={closeModal}
              onMicrosoftLogin={handleMicrosoftLoginClick}
            />
          </div>
        )}
      </>

      <>
        {promptModelRegistration && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <RegPopup onCancel={cancelPopModal} onAnotherAction={closeModal} />
          </div>
        )}
      </>

      <button className="microsoft-auth-btn" onClick={() => handleLogin()}>
        <img src="/assets/img/microsoft-login-icon.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Microsoft</span>
      </button>
    </div>
  );
};

export default MicrosoftAuth;
