import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
  businessunitActions,
  invoiceActions,
  shiftsActions,
} from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';
// eslint-disable-next-line no-unused-vars
let employer_id = '';
const InvoiceAdd = props => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [invoice, setInvoice] = useState({
    name: '',
    description: '',
    employer_id: '',
    employer: '',
    business_unit: 0,
    from: '',
    to: '',
    from_date_string: '',
    to_date_string: '',
    total_hours: 0,
    shifts_Ids: [],
    all_shifts_ids: [],
    payment_status: 'not paid',
    additional_hour_payment: 0,
  });
  const [agencyInvoice, setAgencyInvoice] = useState(false);
  const [EmployerRows, setEmployerRows] = useState([]);
  const [EmployerData, setEmployerData] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
    searchKey: '',
    is_verified: 1,

    invoice_generated: 0,
  });
  const [submitted, setSubmitted] = useState(false);
  const [difCandidatefound, setdifCandidatefound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showblock1, setshowblock1] = useState(true);
  const [invoiceRows, setInvoiceRows] = useState([]);
  const [dates, setdates] = useState([]);
  const [BusinessUnitrows, setBusinessUnitrows] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [showblock2, setshowblock2] = useState(false);
  const [error, setError] = useState({ common: '' });
  // const dt=new Date()
  // let  startDate=moment(dt).format('DD-MM-YYYY')/
  useEffect(() => {
    getAllEmployerWithShiftExist();
    if (id !== '0' && id != undefined && id != 0) {
      query._id = id;
      setQuery(query);
      getAllInvoice(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllInvoice(1, query);
    }
    //--------<<<for invoice edit >>>>>>------------
    // <<<<<<props.editId is propsed from invoicelist>>>>---------
    if (props.editId && props.editId !== 0 && props.editId !== '0') {
      let newquery = {};
      newquery._id = props.editId;
      newquery.pageVo = {
        pageNo: 1,
        noOfItems: 15,
      };
      setshowblock1(false);
      setshowblock2(true);
      getAllInvoice(1, newquery);
      getAllShiftsInvoiceEdit(1);
    }
  }, [query, invoice.business_unit]);
  //-------------all shift generate invoice -----------
  useEffect(() => {
    if (props.shifts_Ids) {
      setshowblock1(false);
      setshowblock2(true);
      // getAllShifts();
    }
  }, [props.shifts_Ids]);

  useEffect(() => {
    let newquery = {};
    newquery._id = props.editId;
    newquery.pageVo = {
      pageNo: 1,
      noOfItems: 15,
    };
    // getAllShiftsInvoiceEdit(1);

    getAllShifts(1);
  }, [agencyInvoice]);
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  //----------for consolidated payout details candidate shifts list -----
  const getAllEmployerWithShiftExist = async (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    const { dispatch } = props;
    if (invoice.business_unit) {
      queryNew.business_unit = invoice.business_unit;
    }
    setIsLoading(true);
    await dispatch(shiftsActions.getAllEmployerWithShiftExist(queryNew)).then(
      res => {
        setIsLoading(false);
        setConsolidateListAndPagination(res.data);
        setEmployerData(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // list and pagination
  const setConsolidateListAndPagination = EmployerData => {
    let ShiftExistList = [];
    for (
      let j = 0;
      EmployerData && EmployerData.rows && j < EmployerData.rows.length;
      j = j + 1
    ) {
      ShiftExistList.push(
        <tr key={j}>
          <td
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap', // Enables wrapping on smaller screens
              gap: '10px', // Adds spacing between the image and text
            }}
          >
            {EmployerData &&
            EmployerData.rows &&
            EmployerData.rows[j].profileimage ? (
              <img
                className="round-emp-image"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%', // Makes the image round
                  objectFit: 'cover', // Ensures proper image scaling
                }}
                src={apiConstants.imgUrl + EmployerData.rows[j].profileimage}
                alt="Employer"
              />
            ) : (
              <img
                className="round-emp-image"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%', // Makes the image round
                  objectFit: 'cover', // Ensures proper image scaling
                }}
                src="/assets/img/recsy-fav-icon.png"
                alt="Default"
              />
            )}
            <span
              style={{
                flex: '1', // Ensures the text adjusts to available space
                wordBreak: 'break-word', // Prevents long words from overflowing
              }}
            >
              {EmployerData.rows[j].employer[0]}
            </span>
          </td>

          <td className="topay_text">
            {moment(EmployerData?.rows[j]?.min_date).format('DD-MMM-YYYY')}
          </td>
          <td className="topay_text">
            {moment(EmployerData?.rows[j]?.max_date).format('DD-MMM-YYYY')}
          </td>
          <td className="topay_text">
            {EmployerData.rows[j].to_generate_invoice}
          </td>
          <td className="topay_text ">
            <div
              className="allshift-viewdt"
              style={{ color: '#1ac8ff' }}
              onClick={() => {
                showblocks2(EmployerData.rows[j].employer_id);
              }}
            >
              Next
            </div>
          </td>
        </tr>,
      );
    }
    setEmployerRows(ShiftExistList);
  };

  //---------getall shifts for invoice edit ----------
  const getAllShiftsInvoiceEdit = async () => {
    const { dispatch } = props;
    let query = {
      pageVo: {
        pageNo: 1,
        noOfItems: 150,
      },
    };
    //----for invoice edit ------------
    if (props.editId) {
      query.invoice_id = props.editId;
    }
    setIsLoading(true);
    await dispatch(shiftsActions.shiftsList(query)).then(
      res => {
        setIsLoading(false);
        res.data.rows.sort(function (a, b) {
          return a.check_in_date_string.localeCompare(b.check_in_date_string);
        });
        setShiftList(res.data.rows);
        setListAndPagination(res.data.rows, false);
        if (res.data.rows && res?.data?.rows?.[0]) {
          //--------to find the min and max date in the res data and set to filter dates -------------
          let max_date = '';
          let min_date = '';
          let All_shifts_ids = [];
          for (let l = 0; l < res?.data?.rows?.length; l++) {
            All_shifts_ids.push(res?.data?.rows?.[l]._id);
            if (l == 0) {
              max_date = res?.data?.rows?.[0].check_in_date_string;
              min_date = res?.data?.rows?.[0].check_in_date_string;
            } else {
              if (
                Date.parse(max_date) <
                Date.parse(res?.data?.rows?.[l].check_in_date_string)
              ) {
                max_date = res?.data?.rows?.[l].check_in_date_string;
              }
              if (
                Date.parse(min_date) >
                Date.parse(res?.data?.rows?.[l].check_in_date_string)
              ) {
                min_date = res?.data?.rows?.[l].check_in_date_string;
              }
            }
          }
          //----<<<<to set old shift ids while editing>>>----------
          setInvoice(prevState => ({
            ...prevState,
            all_shifts_ids: All_shifts_ids,
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  ///-----<<<<<<<<<<<,,,get all shifts for invoice generate >>>>>>>>---------------
  const getAllShifts = async employer_id => {
    const { dispatch } = props;
    let query = {
      is_verified: 1,
      invoice_generated: 0,
      employer_id: employer_id,

      pageVo: {
        pageNo: 1,
        noOfItems: 150,
      },
    };
    if (dates.from_date_string) {
      query.from_date = dates.from_date_string;
    }
    if (dates.to_date_string) {
      query.end_date = dates.to_date_string;
    }
    if (invoice.employer_id) {
      query.employer_id = invoice.employer_id;
    }
    if (invoice.business_unit) {
      query.business_unit = invoice.business_unit;
    }
    if (props.shifts_Ids && props.shifts_Ids.length) {
      query.pageVo._ids = props.shifts_Ids;
    }
    //----for invoice edit ------------
    if (props.editId) {
      query.invoice_id = props.editId;
    }
    setIsLoading(true);
    if (agencyInvoice) {
      query.notAgency_invoice = true;
    }
    await dispatch(shiftsActions.shiftsList(query)).then(
      res => {
        setIsLoading(false);
        res?.data?.rows?.sort(function (a, b) {
          return a.check_in_date_string.localeCompare(b.check_in_date_string);
        });
        setShiftList(res.data.rows);
        setListAndPagination(res.data.rows, true);
        if (res.data.rows && res?.data?.rows?.[0]) {
          //--------to find the min and max date in the res data and set to filter dates -------------
          let max_date = '';
          let min_date = '';
          for (let l = 0; l < res?.data?.rows?.length; l++) {
            if (l == 0) {
              max_date = res?.data?.rows?.[0].check_in_date_string;
              min_date = res?.data?.rows?.[0].check_in_date_string;
            } else {
              if (
                Date.parse(max_date) <
                Date.parse(res?.data?.rows?.[l].check_in_date_string)
              ) {
                max_date = res?.data?.rows?.[l].check_in_date_string;
              }
              if (
                Date.parse(min_date) >
                Date.parse(res?.data?.rows?.[l].check_in_date_string)
              ) {
                min_date = res?.data?.rows?.[l].check_in_date_string;
              }
            }
          }

          setInvoice(prevState => ({
            ...prevState,
            from_date_string: min_date,
            to_date_string: max_date,
            to: res?.data?.rows?.[0].check_in_date_string,
            from: res?.data?.rows?.[res.data.rows.length - 1]
              .check_in_date_string,
            employer: res?.data?.rows?.[0].employer_id,
            employer_id: res?.data?.rows?.[0].employer_id,
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //-----generate invoice bulk-------
  const generateInvoiceBulk = async () => {
    if (!invoice.from_date_string || !invoice.to_date_string) {
      setError({ common: 'please choose date' });
      return;
    }
    let bulk_query = {
      from_date_string: invoice.from_date_string,
      to_date_string: invoice.to_date_string,
    };
    if (invoice.employer_id) {
      bulk_query.employer_id = invoice.employer_id;
    }
    if (invoice.business_unit) {
      bulk_query.business_unit = invoice.business_unit;
    }
    const { dispatch } = props;
    await dispatch(invoiceActions.invoiceBulkGenerate(bulk_query)).then(res => {
      if (res) {
        props.showInvoiceModal(false);
      }
    });
  };
  //------show block 2 on press next ------and hide block 1
  const showblocks2 = employer_id => {
    setInvoice(prevState => ({
      ...prevState,
      employer_id: employer_id,
      user: employer_id,
    }));
    setInvoiceRows([]); //----to set invoice rows empty so going back won't show previous data-----
    getAllShifts(employer_id);
    setshowblock1(false);
    setshowblock2(true);
  };
  //------show block 1 on press back icon in modal ------and hide block 2
  const showblocks1 = () => {
    setInvoice(prevState => ({
      ...prevState,
      from: '',
      from_date_string: '',
      to: '',
      to_date_string: '',
    }));
    // setQuery((...prevState)=>({...prevState,from_date_string:"",to_date_string:""}))
    setshowblock1(true);
    setshowblock2(false);
  };
  /**
   * Get all invoices
   * */
  const getAllInvoice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(invoiceActions.invoiceList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setInvoice(prevState => ({
            ...prevState,
            employer_id: res?.data?.rows?.[0].employer_id,
            from_date_string: res?.data?.rows?.[0].from_date_string,
            to_date_string: res?.data?.rows?.[0].to_date_string,
            from: res?.data?.rows?.[0].from,
            to: res?.data?.rows?.[0].to,
            total_hours: res?.data?.rows?.[0].total_hours,
            total_amount: !isNaN(res?.data?.rows?.[0].total_amount)
              ? parseFloat(res?.data?.rows?.[0].total_amount).toFixed(2)
              : '0.00',

            invoice_note: res?.data?.rows?.[0].invoice_note,
            additional_hours: res?.data?.rows?.[0].additional_hours,
            additional_hour_payment:
              res?.data?.rows?.[0].additional_hour_payment,
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //----remove candidates from list shown between dates------
  const removeInvoiceItem = (data, j) => {
    let newshiftData = data;
    newshiftData.splice(j, 1);
    setListAndPagination(newshiftData, true);
  };
  //----async select business unit select -------
  const selectedEmployer = (selectedOption, event) => {
    setError({ common: '' });
    employer_id = selectedOption.value;
    getAllBusinessUnit(selectedOption.value);
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      employer_id: selectedOption.value,
    }));
    setInvoice(prevState => ({
      ...prevState,
      [name]: selectedOption,
      employer_id: selectedOption.value,
    }));
  };
  // call for get all business units
  const getAllBusinessUnit = employer_id => {
    const { dispatch } = props;
    dispatch(
      businessunitActions.businessunitList({
        status: 1,
        employer_id: employer_id,
      }),
    ).then(res => {
      let businessUnitData = res.data.rows;
      let businessUnitlist = [];
      for (let i = 0; i < businessUnitData.length; i++) {
        businessUnitlist.push(
          <option className="custom_option" value={businessUnitData[i]._id}>
            {businessUnitData[i].name}
          </option>,
        );
      }
      setBusinessUnitrows(businessUnitlist);
    });
  };
  // list and pagination
  const setListAndPagination = (shiftDataList, isedit) => {
    let shiftList = [];
    let total_amount = 0;
    let total_hours_sum = 0;
    let shifts_ids = [];
    let difCandidatefound = false;
    for (
      let j = 0;
      shiftDataList && shiftDataList.length && j < shiftDataList.length;
      j = j + 1
    ) {
      shiftList.push(
        <tr key={j}>
          <td>
            <div className="row">
              <div className="col-md-4 e ">
                {' '}
                <div>
                  {shiftDataList[j]['Candidate.profileimage'] ? (
                    <img
                      className="round-emp-image"
                      src={
                        apiConstants.imgUrl +
                        shiftDataList[j]['Candidate.profileimage']
                      }
                    />
                  ) : (
                    <img
                      className=" round-emp-image"
                      src="/assets/img/recsy-fav-icon.png"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className=" align-items-center">
                  {shiftDataList[j]['Candidate.name']
                    ? shiftDataList[j]['Candidate.name']
                    : shiftDataList[j]['Agency.company_name']}
                </div>
              </div>
            </div>
          </td>
          <td>
            {moment(shiftDataList[j].check_in_date_string).format(
              'DD-MMM-YYYY',
            )}{' '}
          </td>
          <td> {shiftDataList[j].employer_to_pay}</td>
          <td>
            {miscService.time24to12(shiftDataList[j].attendance_check_in)}
          </td>
          <td>
            {miscService.time24to12(shiftDataList[j].attendance_check_out)}
          </td>
          <td>{shiftDataList[j].break_time} min</td>
          <td>{shiftDataList[j].total_hours} hr</td>
          <td>{shiftDataList[j].actual_hours_after_break_time} hr</td>
          <td>
            {(
              shiftDataList[j].employer_to_pay *
              shiftDataList[j].actual_hours_after_break_time
            ).toFixed(2)}{' '}
          </td>
          <td className="eventCell  event Edit">
            <div
              className="ml-2"
              onClick={() => {
                removeInvoiceItem(shiftDataList, j);
              }}
            >
              <i className=" fa fa-times-circle red fs-18"></i>
            </div>
          </td>
        </tr>,
      );
      shifts_ids.push(shiftDataList[j]._id);
      //---------check all the employer are same
      if (shiftDataList[j].employer_id !== shiftDataList[0].employer_id) {
        difCandidatefound = true;
      }
      total_amount =
        parseFloat(total_amount) +
        parseFloat(shiftDataList[j].actual_hours_after_break_time) *
          parseFloat(shiftDataList[j].employer_to_pay);
      total_hours_sum =
        parseFloat(total_hours_sum) +
        parseFloat(shiftDataList[j].actual_hours_after_break_time);
    }
    //-----------set different candidate different found as true --------
    if (difCandidatefound) {
      setdifCandidatefound(true);
    } else {
      setdifCandidatefound(false);
    }
    total_hours_sum = total_hours_sum.toFixed(2);
    total_amount = total_amount + invoice.additional_hour_payment;
    //------------for edit and add invoice -------------------
    if (isedit) {
      setInvoice(prevState => ({
        ...prevState,
        total_hours: total_hours_sum,
        invoicedHours: total_hours_sum,
        invoiceAmount: total_amount.toFixed(2),
        total_amount: total_amount.toFixed(2),
        shifts_ids: shifts_ids,
        _id:
          shiftDataList[0] &&
          shiftDataList[0]?.invoice_id &&
          shiftDataList[0]?.invoice_id !== 0 &&
          shiftDataList[0]?.invoice_id !== '0'
            ? shiftDataList[0]?.invoice_id
            : null,
      }));
    } else {
      setInvoice(prevState => ({
        ...prevState,
        shifts_ids: shifts_ids,
        _id:
          shiftDataList[0] &&
          shiftDataList[0].invoice_id &&
          shiftDataList[0].invoice_id !== 0 &&
          shiftDataList[0].invoice_id !== '0'
            ? shiftDataList[0].invoice_id
            : null,
      }));
    }
    setInvoiceRows(shiftList);
  };
  const handleChange = event => {
    setError({ common: '' });
    let { name, value } = event.target;
    if (name === 'business_unit') {
      value = parseInt(value, 10);
    }
    if (name == 'additional_hour_payment') {
      let total_amount = 0;
      for (
        let j = 0;
        shiftList && shiftList.length && j < shiftList.length;
        j = j + 1
      ) {
        total_amount =
          parseFloat(total_amount) +
          parseFloat(shiftList[j].actual_hours_after_break_time) *
            parseFloat(shiftList[j].employer_to_pay);
      }
      total_amount = parseFloat(total_amount) + parseFloat(value ? value : 0);
      setInvoice(invoice => ({
        ...invoice,
        [name]: value,
        total_amount: total_amount,
      }));
      return;
    }
    if (name == 'from_date_string' || name == 'to_date_string') {
      let d = new Date(value).getTime();
      setQuery(prevState => ({ ...prevState, [name]: value }));
      if (name == 'from_date_string') {
        setInvoice(prevState => ({
          ...prevState,
          from: d,
          [name]: value,
        }));
      }
      if (name == 'to_date_string') {
        setInvoice(prevState => ({
          ...prevState,
          to: d,
          [name]: value,
        }));
      }
    } else {
      setInvoice(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  //--------multipel date picker handle change ---------------
  const dateshandleChange = value => {
    if (value && !value[0]) {
      return false;
    }
    setError({ common: '' });
    let from_date_string = '';
    let to_date_string = '';
    from_date_string = value[0]?.format('DD-MMM-YYYY');
    to_date_string = value[1]?.format('DD-MMM-YYYY');
    let df = new Date(from_date_string).getTime();
    let dt = new Date(to_date_string).getTime();
    setInvoice(prevState => ({
      ...prevState,
      from: df,
      from_date_string: from_date_string,
      to: dt,
      to_date_string: to_date_string,
    }));
    (query.from_date_string = from_date_string),
      (query.to_date_string = to_date_string);
    setQuery(prevState => ({
      ...prevState,
      from: df,
      from_date_string: from_date_string,
      to: dt,
      to_date_string: to_date_string,
    }));
    setdates(prevState => ({
      ...prevState,
      from: df,
      from_date_string: from_date_string,
      to: dt,
      to_date_string: to_date_string,
    }));
  };
  // submit action for invoice add
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    if (difCandidatefound) {
      setError({ common: 'this shifts belongs to different Employers' });
      setTimeout(() => {
        setError({ common: '' });
      }, 10000);
      return false;
    }
    const { dispatch } = props;
    if (
      invoice.total_amount &&
      invoice.total_hours &&
      invoice.employer_id &&
      invoiceRows.length
    ) {
      setIsLoading(true);
      dispatch(invoiceActions.invoiceAdd(invoice)).then(
        res => {
          if (res) {
            navigate('/invoicelist');
            setIsLoading(false);
            props.showInvoiceModal(true);
          }
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
    }
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      background: '#F4F4F4',
      borderRadius: '12px',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
  };
  return (
    <div className="col-md-12 mt-2">
      <div className="card m-0">
        <div className="page-title-content d-flex justify-content-between align-items-center w-100">
          {/* Page Title */}
          <div>
            {' '}
            <h4
              className={`card-title ${
                showblock2 && !props.editId
                  ? 'pl-3  ml-5 mb-2 pb-1'
                  : 'ml-4 pl-1'
              }`}
            >
              Add Invoice
            </h4>
          </div>

          {/* Buttons Container (Back and Close Buttons) */}
          {/* <div className="d-flex align-items-center"> */}
          {/* Back Button */}
          {showblock2 && !props.editId && (
            <div className="back-btn ml-3 pl-2 mt-1">
              <i
                className="fa fa-chevron-circle-left fs-22"
                onClick={() => {
                  showblocks1();
                }}
              />
            </div>
          )}

          {/* Close Button */}
          <div className="p-3">
            <i
              data-cy="close-add-invoice-modal"
              className="fa fa-times-circle red fs-18"
              onClick={() => {
                props.showInvoiceModal(false);
              }}
            />
          </div>
          {/* </div> */}
        </div>

        <div className="card-body py-0">
          <form name="form">
            {showblock1 && (
              <div>
                {/* Date ,Employer and Business Unit Section */}
                <div className="row mb-1">
                  {/* Date Picker Section */}
                  <div className="col-md-4 pl-3">
                    <label htmlFor="type">
                      Date : <span className="assign-shift-label-star">*</span>
                    </label>
                    <div
                      className={
                        'form-group mb-0' +
                        (submitted &&
                        !invoice.from_date_string &&
                        !invoice.to_date_string
                          ? ' has-error'
                          : '')
                      }
                    >
                      <DatePicker
                        range
                        className="datepicker"
                        onChange={dateshandleChange}
                        placeholder="Select..."
                        style={{
                          height: '40px',
                          borderRadius: '12px',
                          fontSize: '14px',
                          padding: '4px 10px',
                          width: '100%',
                          background: '#F4F4F4',
                          border: 'none',
                        }}
                        format="DD-MM-YYYY"
                      />
                    </div>
                  </div>
                  {/* Employer Select */}
                  <div className="col-md-4 pb-3">
                    <div className=" pl-2">
                      <label htmlFor="type">Select Employer :</label>
                      <AsyncSelect
                        styles={customStyles}
                        name="employer"
                        onChange={selectedEmployer}
                        value={invoice.employer}
                        cacheOptions
                        defaultOptions
                        loadOptions={getemployerlist}
                        className="employerselect"
                        style={{ width: '100%' }}
                      />
                      {submitted && !invoice.employer && (
                        <div className="help-block">Employer is required</div>
                      )}
                    </div>
                  </div>
                  {/* Business Unit Select */}
                  <div className="col-md-4 pb-3">
                    <div className="">
                      <label htmlFor="type">Business unit :</label>
                      <select
                        name="business_unit"
                        value={invoice.business_unit}
                        onChange={handleChange}
                        className="p-2 input-design1"
                        style={{
                          width: '100%',
                          fontSize: '12px',
                        }}
                      >
                        <option className="custom_option" value={''}>
                          Select ...
                        </option>
                        {BusinessUnitrows}
                      </select>
                      {submitted && !invoice.business_unit && (
                        <div className="help-block">
                          Business Unit is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Shift Exist List Section */}
                <div className="ShiftExistList ">
                  <div className="">
                    <div className="invoicee ">
                      <div className="">
                        {!isLoading && (
                          <table className="table  flx-custom-table flx-table-border ">
                            <thead>
                              <tr className="flx-table-header-clr">
                                <th className="border-top1 table-header-ht">
                                  Employer
                                </th>
                                <th>From</th>
                                <th>To</th>
                                <th>To generate invoice</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>{EmployerRows}</tbody>
                          </table>
                        )}
                        {!!isLoading && (
                          <LoadingIcon loadingText={'Loading...'} />
                        )}
                        {EmployerRows &&
                          EmployerRows.length === 0 &&
                          !isLoading && (
                            <p className="margin-top-20 text-center">
                              {' '}
                              No available shifts to generate invoice
                            </p>
                          )}
                      </div>
                      <span className="color-red">{error.common}</span>
                      {EmployerRows &&
                        EmployerRows.length > 0 &&
                        !isLoading && (
                          <div className="add_invoice">
                            <div
                              data-cy="generateInvoiceBulk"
                              className="btn btn-primary-blue float-right"
                              onClick={() => {
                                generateInvoiceBulk();
                              }}
                              style={{
                                padding: '10px 20px',
                                color: '#fff',
                                borderRadius: '12px',
                                cursor: 'pointer',
                              }}
                            >
                              <span>Generate Invoice</span>
                            </div>
                          </div>
                        )}
                      <div className="card-footer">
                        <Pagination
                          pageOnClick={data => {
                            getAllEmployerWithShiftExist(data);
                          }}
                          paginationData={EmployerData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ===on click next show next block============= */}
            {showblock2 && (
              <div className="col-md-12">
                <div className="row d-flex align-items-center">
                  <div className="col-md-6 ">
                    <div className="p-2  flx-agency-add ">
                      <input
                        type="checkbox"
                        id="agencyInvoice"
                        name="agencyInvoice"
                        checked={agencyInvoice}
                        onChange={e => {
                          setAgencyInvoice(e.target.checked);
                          handleChange({
                            target: {
                              name: 'agencyInvoice',
                              value: e.target.checked,
                            },
                          });
                        }}
                      />

                      <label htmlFor="agencyInvoice" className="ml-2">
                        Avoid invoice candidate
                      </label>
                    </div>
                    <h4 className="cl-green">
                      {invoice.employer &&
                        invoice.employer &&
                        invoice.employer.label &&
                        invoice.employer.label}
                    </h4>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="type">From</label>
                    <input
                      style={{
                        borderRadius: '12px',
                        background: '#F4F4F4',
                      }}
                      type="date"
                      className="form-control add-invoice-input"
                      name="from_date_string"
                      placeholder="from"
                      value={invoice.from_date_string}
                      disabled
                    />
                    {submitted && !invoice.from_date_string && (
                      <div className="help-block">from date is required</div>
                    )}{' '}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="type" className="bold">
                      to
                    </label>
                    <input
                      style={{ borderRadius: '12px', background: '#f4f4f4' }}
                      type="date"
                      className="form-control add-invoice-input"
                      name="to_date_string"
                      placeholder="to"
                      value={invoice.to_date_string}
                      disabled
                    />
                    {submitted && !invoice.to_date_string && (
                      <div className="help-block">to date is required</div>
                    )}{' '}
                  </div>
                </div>
                <div className=" mt-2 ">
                  <div className="  table-responsive main-card-body flx-table-border">
                    <table className="table ">
                      <thead>
                        <tr className="flx-table-header-clr">
                          <th className="border-top1">name</th>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Check in</th>
                          <th>Check out</th>
                          <th>Break time</th>
                          <th>Total hours</th>
                          <th>Payable hours</th>
                          <th>Total price</th>
                          <th className="border-top2"></th>
                        </tr>
                      </thead>
                      <tbody>{invoiceRows}</tbody>
                    </table>
                    {invoiceRows.length == 0 && !isLoading && (
                      <div className="d-flex justify-content-center">
                        {' '}
                        no signed shifts available
                      </div>
                    )}
                    {isLoading && <LoadingIcon loadingText={'Loading...'} />}
                  </div>
                  <div className="p-2">
                    {/* ===totoal hour===== */}
                    <div className="row d-flex justify-content-end ml-0 mr-0 p-1">
                      <div className="mr-3 p-2">
                        <label htmlFor="type" className="bold">
                          Total hr
                        </label>
                      </div>
                      <div>
                        <input
                          type="number"
                          step="any"
                          className="form-control "
                          name="total_hours"
                          placeholder="Total hours"
                          value={invoice.total_hours}
                          onChange={handleChange}
                          disabled
                        />
                        {submitted && !invoice.total_hours && (
                          <div className="help-block">
                            total hours is required
                          </div>
                        )}{' '}
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end ml-0 mr-0 p-1">
                      <div className="mr-3 p-2">
                        <label htmlFor="type" className="bold">
                          Additional payment
                        </label>
                      </div>
                      <div>
                        <input
                          type="to"
                          className="form-control"
                          name="additional_hour_payment"
                          placeholder="Additional hour payment"
                          value={invoice.additional_hour_payment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* ===total price====== */}
                    <div className="row d-flex justify-content-end ml-0 mr-0 p-1">
                      <div className="mr-3 p-2">
                        <label htmlFor="type" className="bold">
                          Total amount
                        </label>
                      </div>
                      <div>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          name="total_amount"
                          placeholder=" Total amount"
                          value={parseFloat(invoice.total_amount).toFixed(2)}
                          onChange={handleChange}
                          disabled
                        />
                        {submitted && !invoice.total_amount && (
                          <div className="help-block">
                            Total amount is required
                          </div>
                        )}{' '}
                      </div>
                    </div>
                    {/* =======invoice_note==== */}
                    <div className=" d-flex justify-content-end ml-0 mr-0 p-1">
                      <div className="mr-3 p-1">
                        <label htmlFor="type" className="bold">
                          Invoice note
                        </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control2"
                          style={{
                            borderRadius: '4px',
                            height: '35px',
                            width: '186px',
                          }}
                          name="invoice_note"
                          placeholder="Invoice note"
                          value={invoice.invoice_note}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="form-group d-flex justify-content-end">
                        {isLoading ? (
                          <div className=" btn-primary-blue-border btn-save-assign-add-shift">
                            <span
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: 1.25,
                              }}
                            >
                              Submit...
                            </span>
                          </div>
                        ) : (
                          <div
                            className=" btn-primary-blue-border btn-save-assign-add-shift"
                            onClick={handleSubmit}
                            data-cy="add-invoice"
                          >
                            <span
                              style={{
                                fontFamily: 'Roboto',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: 1.25,
                              }}
                            >
                              Submit
                            </span>
                            {props.requestStatus ===
                              reducerConstants.INVOICE_REQUEST && (
                              <div className="lds-ring ml-2">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {difCandidatefound && (
                  <div>found different Employer in the list </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, invoiceData } = state.invoice;
  return {
    requestStatus,
    invoiceData,
  };
}

const connectedInvoiceAdd = connect(mapStateToProps)(InvoiceAdd);
export { connectedInvoiceAdd as InvoiceAdd };

const getemployerlist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name || 'Unknown',
            })),
          );
        }),
      )
      .catch(reject);
  });
