import React from 'react';

import { LoadingIcon } from './index';
export class LoadingModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="LoadingModal">
        <div className="loadingModalInner">
          <LoadingIcon loadingText={"Loading..."}/>
        </div>
      </div>
    );
  }
}
