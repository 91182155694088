import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { bookingActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

let employer_id = '';

const AgencyBookingList = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setIsFilter] = useState({ employer_id: 0 });
  const [bookingData, setBookingData] = useState({ rows: [] });
  const [bookingRows, setbookingRows] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [viewdetails, setviewdetails] = useState([]);
  let vacancy_id = '';
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 18,
    },
  });

  // effect to call section,booking and bunit where dependencies change
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    if (id !== undefined) {
      vacancy_id = id;
    }
    getAllBooking(1);
    // getAllSection();
    getbusinessunit();
  }, [query, filter]);
  /**
   * Get all booking
   **/
  const getAllBooking = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    queryNew.employer_id = filter.employer_id;
    queryNew.agency_candidate_id = filter.agency_candidate_id;
    if (vacancy_id) {
      queryNew.vacancy_id = vacancy_id;
    }
    queryNew.agencybookinglist = true;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(bookingActions.bookingList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for state date
  const handleChange = event => {
    const { name, value } = event.target;
    setQuery({
      ...query,
      [name]: value,
    });
  };
  // --get all section -----------
  // const getAllSection = () => {
  //   setIsLoading(true);
  //   const { dispatch } = props;
  //   if (employer_id !== 0) {
  //     dispatch(sectionActions.sectionList({ employer_id: employer_id })).then(
  //       res => {
  //         setIsLoading(false);
  //         let shift_option_rows = [];
  //         for (let s = 0; s < res?.data?.rows?.length; s++) {
  //           shift_option_rows.push(
  //             <option value={res?.data?.rows?.[s]._id}>
  //               {res?.data?.rows?.[s].name}
  //             </option>,
  //           );
  //         }
  //       },
  //       () => {
  //         setIsLoading(false);
  //       },
  //     );
  //   }
  // };
  // -----get carehome-------------------
  const selectedfilter = (selectedOption, event) => {
    let name = event.name;
    setIsFilter(
      // eslint-disable-next-line no-unused-vars
      prevState => ({
        [name]: selectedOption.value,
      }),
    );
  };
  // --get all businessunit---------------
  const getbusinessunit = () => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({ status: 1, employer_id: employer_id }),
      }).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            let shift_option_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              shift_option_rows.push(
                <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
              );
            }
          }
        }),
      );
    }
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = bookingData => {
    let bookingList = [];
    for (
      let j = 0;
      bookingData && bookingData.rows.length && j < bookingData.rows.length;
      j = j + 1
    ) {
      let color = 0;
      if (bookingData.rows[j]['Vacancy.Section.name'] == 'Night') color = 1;
      else if (bookingData.rows[j]['Vacancy.Section.name'] == 'Morning')
        color = 2;
      else color = 0;
      bookingList.push(
        <tr key={j}>
          <td>
            {moment(bookingData.rows[j].applied_date_string).format(
              'DD-MMM-YYYY',
            )}{' '}
          </td>
          <td>{'FLXB' + bookingData.rows[j]._id}</td>
          <td
            className={
              'font-11' &&
              (color == 1 ? 'night' : color == 2 ? 'morning' : 'custome')
            }
          >
            {bookingData.rows[j]['Vacancy.Section.name']}
          </td>
          <td>{bookingData.rows[j]['Agencycandidate.name']}</td>
          <td>
            {bookingData.rows[j]['Candidate.postcode']
              ? bookingData.rows[j]['Candidate.postcode']
              : bookingData.rows[j]['Agency.postcode']
              ? bookingData.rows[j]['Agency.postcode']
              : ''}
          </td>
          <td>
            {bookingData.rows[j]['Vacancy.Section.from_time'] +
              '-' +
              bookingData.rows[j]['Vacancy.Section.to_time']}
          </td>
          <td>
            {bookingData.rows[j].booking_status == 1 ? (
              <div style={{ color: '#2DA463', fontWeight: 'bold' }}>
                Confirmed
              </div>
            ) : bookingData.rows[j].booking_status == 2 ? (
              <div style={{ color: '#B80000', fontWeight: 'bold' }}>
                Rejected
              </div>
            ) : (
              <div style={{ color: '#138988', fontWeight: 'bold' }}>
                Pending
              </div>
            )}
          </td>
          <td>{bookingData.rows[j].amount}</td>
          <td>
            {
              <div
                style={{ background: 'rgba(94, 213, 249, 0.8)' }}
                className="agency-viewdt"
                onClick={() => {
                  setviewdetails(bookingData.rows[j]),
                    setviewdetailsmodal(true);
                }}
              >
                View details
              </div>
            }
          </td>
        </tr>,
      );
    }
    setbookingRows(bookingList);
    setBookingData(bookingData);
  };
  // custome styles for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#f4f4f4',
      borderRadius: '12px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000',
      width: '32px',
    }),
  };
  return (
    <div className="CandidateList ">
      <div className="col-md-12 col-sm-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8 " style={{ marginLeft: '6px' }}>
                <div className="page-title mb-3">Booking</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 pr-1" style={{ marginLeft: '6px' }}>
                <div className={'form-group '}>
                  <input
                    type="date"
                    className="input-design1"
                    style={{
                      border: 'none',
                      display: 'block',
                      width: '100%',
                      height: '40px',
                      padding: '0.375rem 0.75rem',
                      flexShrink: '0',
                      fontSize: '14px',
                      fontWeight: '500',
                      borderRadius: '6px',
                    }}
                    name="applied_date_string"
                    placeholder=""
                    value={query.applied_date_string}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className={'form-group '}>
                  <AsyncSelect
                    styles={customStyles}
                    name="employer_id"
                    onChange={selectedfilter}
                    placeholder={'select carehome'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getEmployerList}
                  />
                </div>
              </div>
              <div className="col-md-2 pl-0">
                <div className={'form-group '}>
                  <AsyncSelect
                    styles={customStyles}
                    name="agency_candidate_id"
                    onChange={selectedfilter}
                    placeholder={'select candidate'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getagencycandidatelist}
                  />
                </div>
              </div>
              <div className="col-md-2 pr-1"></div>
            </div>
          </div>
          {id !== undefined && (
            <div className="bookingid">Bookings of vacancy ID :{id} </div>
          )}
          <div className="card-body  table-responsive main-card-body flx-table-border">
            {!isLoading && (
              <table className="table  flx-custom-table">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th className="table-header-ht">Date</th>
                    <th>Booking ID</th>
                    <th>Shift</th>
                    <th>Agency candidate</th>
                    <th>Postcode</th>
                    <th>Time</th>
                    <th>Booking Status</th>
                    <th>Pay</th>
                    <th className="eventCell  eventEdit" />
                  </tr>
                </thead>
                <tbody>{bookingRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {bookingRows && bookingRows.length == 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllBooking(data);
              }}
              paginationData={bookingData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0 flaot-lright">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <div className="bag">
                  <div className="d-flex flex-column ">
                    <div
                      className=" text-secondary mb-2"
                      style={{ lineHeight: '0px' }}
                    >
                      BOOKING ID
                    </div>
                    <div className="">
                      <label>{'FLXB' + viewdetails._id}</label>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-item-center ">
                    <div className={'icon-div mt-1'}>
                      <img
                        src="/assets/img/calendar1.png"
                        style={{ height: '20px', width: '20px' }}
                      ></img>
                    </div>
                    <div className="d-flex align-items-center justify-content-center ml-10 font-weight-bold">
                      {new Date(
                        viewdetails.applied_date_string,
                      ).toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'long',
                        weekday: 'long',
                      })}
                    </div>
                  </div>
                  <div className="d-flex flex-row bd-highlight align-items-center">
                    <div className=" pt-2 bd-highlight d-flex flex-row bd-highlight">
                      <div className="pt-2 bd-highlight d-flex flex-column bd-highlight">
                        <span className="p-1 bd-highlight bluetag ">
                          {viewdetails['Vacancy.Section.name']}
                        </span>
                      </div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight">
                        <div className="p-2 bd-highlight compny_name_agency">
                          {viewdetails['Employer.company_name']}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dummy-view"></div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>BOOKING ID</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{'FLX00' + viewdetails._id} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Shift</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails['Vacancy.Section.name']} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Time</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails['Vacancy.Section.from_time'] +
                          '-' +
                          viewdetails['Vacancy.Section.to_time']}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Booking Status</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails.booking_status == 1 ? (
                          <div style={{ color: '#2DA463', fontWeight: 'bold' }}>
                            Confirmed
                          </div>
                        ) : viewdetails.booking_status == 2 ? (
                          <div style={{ color: '#B80000', fontWeight: 'bold' }}>
                            Rejected
                          </div>
                        ) : (
                          <div style={{ color: '#138988', fontWeight: 'bold' }}>
                            Pending
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Pay</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>£ {viewdetails.amount}/hr</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for get carehomes in select options
const getEmployerList = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for get agency candidate in select options
const getagencycandidatelist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencycandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedAgencyBookingList = connect(mapStateToProps)(AgencyBookingList);
export { connectedAgencyBookingList as AgencyBookingList };
