import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const PriceService = {
  PriceAdd,
  PriceList,
  // PriceDelete,
};

function PriceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addPrice', requestOptions).then(
    miscService.handleResponse,
  );
}
function PriceList(data) {
  console.log(data, 'Data for price list ==>1439');
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getpricelist', requestOptions).then(
    miscService.handleResponse,
  );
}
// function PriceDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deletePrice', requestOptions).then(
//     handleResponse,
//   );
// }
