import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { bookingActions } from '../_actions';
import { LoadingIcon } from '../_components';
import { miscConstants } from '../_constants';
import InterestedCandidate from './InterestedCandidate';

const MapView = props => {
  const [allbookings, setAllBookings] = useState([]);
  const [confirmLoadingid, setconfirmLoadingid] = useState();
  const [confirmloading, setconfirmloading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isViewCandidate, setIsViewCandidate] = useState({
    modal: false,
    type: '',
    candidate_id: null,
    booking_id: null,
  });
  const [selectedCandidateLocation, setSelectedCandidateLocation] = useState({
    lat: '',
    long: '',
    name: '',
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const { dispatch } = props;
  const mapRef = useRef(null);
  const currentDate = new Date();
  useEffect(() => {
    loadMapScript();
  }, []);
  useEffect(() => {
    if (selectedCandidateLocation.lat && selectedCandidateLocation.long) {
      loadMap(allbookings, 'bookings');
    }
  }, [selectedCandidateLocation]);

  useEffect(() => {
    if (props?.vacancyData) {
      setQuery({
        ...query,
        vacancy_id: props.vacancyData._id,
      });
      getAllBooking();
    }
  }, [props.vacancyData]);

  const loadMapScript = () => {
    if (window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${miscConstants.REACT_APP_API_KEY}&libraries=places&v=3`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  };
  let infoWindow;
  const loadMap = async (data, from) => {
    const position = {
      lat: props?.vacancyData?.lat,
      lng: props?.vacancyData?.long,
    };
    if (!window.google || !window.google.maps) {
      // If not loaded, wait for a short interval and try again
      setTimeout(() => loadMap(data, from), 100);
      return;
    }
    const map = new window.google.maps.Map(mapRef.current, {
      center: position,
      zoom: 10,
      mapId: '4504f8b37365c3d0',
      mapTypeControl: false,
    });
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      'marker',
    );
    //--- for changing the center of the marker ---

    if (selectedCandidateLocation?.lat && selectedCandidateLocation?.long) {
      const position2 = {
        lat: selectedCandidateLocation.lat,
        lng: selectedCandidateLocation.long,
      };
      map.panTo(position2);
      map.setZoom(14);
      const customIconElementOne = document.createElement('div');
      customIconElementOne.className = 'custom-marker-container custom-label';
      customIconElementOne.textContent = selectedCandidateLocation.name;
      // Create an icon image element
      const iconImageOne = document.createElement('img');
      iconImageOne.src = '/assets/img/manmarker.png'; // Replace with the path to your icon image
      iconImageOne.alt = 'Icon'; // Provide alt text for accessibility
      iconImageOne.width = 32; // Adjust the width of the icon as needed
      iconImageOne.height = 32; // Adjust the height of the icon as needed
      customIconElementOne.appendChild(iconImageOne);
      new AdvancedMarkerElement({
        map: map, // Replace with your Google Map instance
        position: position2,
        content: customIconElementOne,
      });
      new window.google.maps.Circle({
        map: map,
        center: position2,
        radius: 1005.8375, // 10 miles in meters (1 mile = 1609.34 meters)
        strokeColor: '#4cb4b3', // Circle border color
        strokeOpacity: 0.8, // Border opacity (0-1)
        strokeWeight: 2, // Border thickness
        fillColor: '#4cb4b239', // Circle fill color
        fillOpacity: 0.35, // Fill opacity (0-1)
      });
      const circleCandidate2 = new window.google.maps.Circle({
        map: map,
        center: position2,
        // radius: 804.67, // 10 miles in meters (1 mile = 1609.34 meters)
        radius: 0, // 10 miles in meters (1 mile = 1609.34 meters)
        strokeColor: '#4cb4b245', // Circle border color
        strokeOpacity: 0.8, // Border opacity (0-1)
        strokeWeight: 2, // Border thickness
        fillColor: 'rgba(32, 185, 182, 0.356)', // Circle fill color
        fillOpacity: 0.35, // Fill opacity (0-1)
      });
      animateCircle(804.67, 1005.8375, circleCandidate2);
      setInterval(() => {
        // animateCircle(16093.4, 5000, circle);
        // animateCircle(15093.4, 5000, circle2);
        // animateCircle(8046.72, 5000, circle3);
        // animateCircle(4609.34, 10000, circle4);
        animateCircle(804.67, 1005.8375, circleCandidate2);
      }, 3000);
    }
    const customIconElementOne = document.createElement('div');
    customIconElementOne.className = 'custom-marker-container custom-label';
    // Create an icon image element
    const iconImageOne = document.createElement('img');
    iconImageOne.src = '/assets/img/homepointer.png'; // Replace with the path to your icon image
    iconImageOne.alt = 'Icon'; // Provide alt text for accessibility
    iconImageOne.width = 32; // Adjust the width of the icon as needed
    iconImageOne.height = 32; // Adjust the height of the icon as needed
    customIconElementOne.appendChild(iconImageOne);
    //---main vacancy location (home location)
    const markerOne = new AdvancedMarkerElement({
      map: map, // Replace with your Google Map instance
      position: {
        lat: props?.vacancyData?.lat,
        lng: props?.vacancyData?.long,
      },
      content: customIconElementOne,
    });
    // Create an InfoWindow to display when the marker is clicked
    infoWindow = new window.google.maps.InfoWindow({
      content: createInfoWindowContent(
        props?.vacancyData?.Employer?.company_name,
        props?.vacancyData?.postcode,
      ), // City name and postcode
    });
    //--- marker event listener ---
    markerOne.addListener('click', function () {
      infoWindow.open(map, markerOne);
    });
    //--- customization for map
    const customMapStyle = [
      {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ];
    // Apply the custom map style to the map
    map.set('styles', customMapStyle);

    // --- circle instance from home location ---
    const circle = new window.google.maps.Circle({
      map: map,
      center: position,
      // radius: 16093.4, // 10 miles in meters (1 mile = 1609.34 meters)
      strokeColor: '#4cb4b3', // Circle border color
      strokeOpacity: 0.8, // Border opacity (0-1)
      strokeWeight: 2, // Border thickness
      fillColor: '#4cb4b239', // Circle fill color
      fillOpacity: 0.35, // Fill opacity (0-1)
      radius: 16093.4,
    });
    // --- circle second instance from home location ---
    const circle2 = new window.google.maps.Circle({
      map: map,
      center: position,
      radius: 8046.72, // 10 miles in meters (1 mile = 1609.34 meters)
      // radius: 15093.4, // 10 miles in meters (1 mile = 1609.34 meters)
      strokeColor: '#4cb4b245', // Circle border color
      strokeOpacity: 0.8, // Border opacity (0-1)
      strokeWeight: 2, // Border thickness
      fillColor: 'rgba(32, 185, 182, 0.356)', // Circle fill color
      fillOpacity: 0.35, // Fill opacity (0-1)
    });
    //--- to animate circle --
    animateCircle(15093.4, 5000, circle2);
    // --- circle third instance from home location ---
    const circle3 = new window.google.maps.Circle({
      map: map,
      center: position,
      radius: 4609.34, // 10 miles in meters (1 mile = 1609.34 meters)
      // radius: 8046.72, // 10 miles in meters (1 mile = 1609.34 meters)
      strokeColor: '#4cb4b23c', // Circle border color
      strokeOpacity: 0.8, // Border opacity (0-1)
      strokeWeight: 2, // Border thickness
      fillColor: 'rgba(32, 185, 182, 0.395)', // Circle fill color
      fillOpacity: 0.35, // Fill opacity (0-1)
    });
    animateCircle(8046.72, 5000, circle3);
    const circle4 = new window.google.maps.Circle({
      map: map,
      center: position,
      // radius: 4609.34, // 10 miles in meters (1 mile = 1609.34 meters)
      radius: 0, // 10 miles in meters (1 mile = 1609.34 meters)
      strokeColor: '#4cb4b24d', // Circle border color
      strokeOpacity: 0.8, // Border opacity (0-1)
      strokeWeight: 2, // Border thickness
      fillColor: '#4cb4b2ec', // Circle fill color
      fillOpacity: 0.35, // Fill opacity (0-1)
    });
    animateCircle(4609.34, 10000, circle4);
    //--- to make animated circle for 10 sec --
    setInterval(() => {
      animateCircle(16093.4, 5000, circle);
      animateCircle(15093.4, 5000, circle2);
      animateCircle(8046.72, 5000, circle3);
      animateCircle(4609.34, 10000, circle4);
    }, 10000);

    //---displaying booked candidate on map by a marker ---
    if (from === 'bookings') {
      const markers = [];
      data.forEach(host => {
        const customIconElement = document.createElement('div');
        customIconElement.className = 'custom-marker-container custom-label';
        customIconElement.textContent = host['Candidate.name']
          ? host['Candidate.name']
          : host['Agencycandidate.name']; // You can replace this with your icon content
        // Create an icon image element
        const iconImage = document.createElement('img');
        iconImage.src = '/assets/img/manmarker.png'; // Replace with the path to your icon image
        iconImage.alt = 'Icon'; // Provide alt text for accessibility
        iconImage.width = 32; // Adjust the width of the icon as needed
        iconImage.height = 32; // Adjust the height of the icon as needed
        // Append the icon image to the custom icon element
        customIconElement.appendChild(iconImage);
        // Create an instance of AdvancedMarkerElement
        const marker = new AdvancedMarkerElement({
          map: map, // Replace with your Google Map instance
          position: {
            lat: host['Candidate.lat']
              ? parseInt(host['Candidate.lat'])
              : host['Agencycandidate.lat'],
            lng: host['Candidate.long']
              ? parseInt(host['Candidate.long'])
              : host['Agencycandidate.long'],
          },
          content: customIconElement,
        });
        marker.addListener('mouseover', () => {});
        marker.addListener('mouseout', () => {});
        // Add the marker to the map
        marker.setMap(map);
        // Add the marker to the markers array
        markers.push(marker);
      });
    }
  };
  //--- for animate circle
  function animateCircle(maxRadius, animationDuration, circle) {
    let start = Date.now();
    const animate = () => {
      const elapsed = Date.now() - start;
      const progress = elapsed / animationDuration;
      const currentRadius = progress * maxRadius;
      circle.setRadius(currentRadius);
      if (currentRadius < maxRadius) {
        requestAnimationFrame(animate);
      }
    };
    animate();
  }
  //--- home location info box
  function createInfoWindowContent(cityName, postcode) {
    return `
      <div style="background-color: #0A162E; color: white; padding: 10px; margin-right:10px; text-align:center;">
        <div>${cityName}</div>
        <div>${postcode}</div>
      </div>
    `;
  }
  /**
   * Get all booking
   **/
  const getAllBooking = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    //   delete queryNew.date
    setIsLoading(true);
    if (props?.vacancyData?._id && typeof props.vacancyData._id != String) {
      queryNew.vacancy_id = props?.vacancyData?._id;
    }
    dispatch(bookingActions.bookingList(queryNew)).then(
      res => {
        setIsLoading(false);
        setAllBookings(res.data.rows);
        loadMap(res.data.rows, 'bookings');
      },
      err => {
        console.error('errr----', err);
        setIsLoading(false);
      },
    );
  };
  //--- to update booking
  const updatebooking = (
    booking_status,
    booking_id,
    candidate_user_id,
    vacancy_id,
    candidate_id,
    agency_id,
    agency_user_id,
    agency_candidate_id,
    type,
  ) => {
    if (type === 'confirm') {
      setconfirmloading(true);
      setconfirmLoadingid(booking_id);
    }

    let querynew = {};
    if (booking_status) {
      querynew.booking_status = booking_status;
    }
    if (booking_id) {
      querynew.booking_id = booking_id;
    }
    if (vacancy_id) {
      querynew.vacancy_id = vacancy_id;
    }
    if (agency_id && agency_user_id) {
      querynew.agency_id = agency_id;
      querynew.agency_user_id = agency_user_id;
    }
    if (candidate_id) {
      querynew.candidate_id = candidate_id;
    }
    if (candidate_user_id) {
      querynew.candidate_user_id = candidate_user_id;
    }
    if (agency_candidate_id) {
      querynew.agency_candidate_id = agency_candidate_id;
    }
    dispatch(bookingActions.confirmBooking(querynew)).then(
      () => {
        setIsLoading(false);
        setconfirmLoadingid(0);
        setconfirmloading(false);
        getAllBooking();
      },
      () => {
        setIsLoading(false);
        setconfirmLoadingid(0);
        setconfirmloading(false);
      },
    );
  };
  const handleSuccess = () => {
    getAllBooking();
  };
  return (
    <div>
      <div className="row m-0 mb-2" style={{ gap: '1rem' }}>
        <div className="content-Pills">
          <img
            className=""
            src="/assets/img/building.png"
            style={{ height: 10 }}
          />
          {props?.vacancyData?.Employer?.company_name}
        </div>
        <div className="content-Pills">
          <img className="" src="/assets/img/time.png" style={{ height: 10 }} />
          {props?.vacancyData?.Section?.section_type} shift (
          {moment(props?.vacancyData?.Section?.from_time, 'HH:mm').format(
            'hh:mm A',
          )}{' '}
          -{' '}
          {moment(props?.vacancyData?.Section?.to_time, 'HH:mm').format(
            'hh:mm A',
          )}
          )
        </div>
        <div className="content-Pills">
          <img
            className=""
            src="/assets/img/calendarbooked.png"
            style={{ height: 10 }}
          />
          {moment(props?.vacancyData?.from_date_string, 'YYYY-MM-DD').format(
            'DD/MM/YYYY',
          )}
        </div>
        <div className="content-Pills">
          <img
            className=""
            src="/assets/img/bookinglocation.png"
            style={{ height: 10 }}
          />
          {props?.vacancyData?.city}
        </div>
      </div>
      <div className="row m-0 mapView">
        <div className={!isFullScreen ? 'mapContainer' : 'mapContainerFull'}>
          <div ref={mapRef} style={{ height: '80vh', width: '100%' }}></div>
          <InterestedCandidate
            postcode={props.vacancyData.postcode}
            isFullScreen={isFullScreen}
            vacancy_details={props.vacancyData}
            dispatch={dispatch}
            setSelectedCandidateLocation={setSelectedCandidateLocation}
            onSuccess={handleSuccess}
          />
        </div>
        {!isFullScreen ? (
          <div className={'map-BookingView'}>
            <div className="map-bookinglistHeading">
              <div className="bookings-heading">
                <img
                  className="map-bookinglistHeadingimg"
                  src="/assets/img/bgroup.png"
                />
                Booking List
              </div>
              <img
                onClick={() => {
                  setIsFullScreen(true);
                }}
                className="map-bookinglistHeadingimg"
                src="/assets/img/cross.png"
              />
            </div>
            <div
              style={{
                height: '73.5vh',
                overflow: 'auto',
              }}
            >
              {!isLoading &&
                allbookings.map(item => {
                  return (
                    <div key={item._id} className="booking-content">
                      <div className="row m-0">
                        <div className="map-bookingChildContent mt-2">
                          <img
                            src="/assets/img/recsy-fav-icon.png"
                            style={{ height: 50, width: 50 }}
                          />
                          <div
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setIsViewCandidate({
                                modal: true,
                                type: item['agency_candidate_id']
                                  ? 'agency'
                                  : 'candidate',
                                candidate_id: item?.agency_candidate_id
                                  ? item.agency_candidate_id
                                  : item?.candidate_id,
                                booking_id: item._id,
                              });
                            }}
                            className="map-bookingChildName"
                            style={{ cursor: 'pointer' }}
                          >
                            {item['agency_candidate_id']
                              ? item['Agency.company_name']
                                ? item['Agencycandidate.name'] +
                                  '(' +
                                  item['Agency.company_name'] +
                                  ')'
                                : ''
                              : item['Candidate.name']
                              ? item['Candidate.name'] +
                                ' ' +
                                (item['Candidate.last_name']
                                  ? item['Candidate.last_name']
                                  : '')
                              : ''}
                          </div>
                          <div className="map-bookingChildCategory">
                            {item['Category.name']}
                          </div>
                          <div className="row m-0 mt-2" style={{ gap: '1rem' }}>
                            {item.booking_status == 0 &&
                            new Date(item['Vacancy.from_date_string']) >=
                              currentDate ? (
                              <div className="row m-0 justify-content-end p-2">
                                <div
                                  className="confirmm"
                                  onClick={() => {
                                    updatebooking(
                                      1,
                                      item._id,
                                      item['Candidate.user_id'],
                                      item.vacancy_id,
                                      item.candidate_id,
                                      item.agency_id,

                                      item['Agency.user_id'],
                                      item.agency_candidate_id,
                                      'confirm',
                                    );
                                  }}
                                >
                                  Confirm
                                  {confirmLoadingid == item._id &&
                                    confirmloading && (
                                      <div
                                        id="loading-bar-spinner"
                                        className=" ml-2 spinner"
                                      >
                                        <div className="spinner-icon"></div>
                                      </div>
                                    )}
                                </div>
                                <div
                                  className="reject"
                                  onClick={() => {
                                    updatebooking(
                                      2,
                                      item._id,
                                      item['Candidate.user_id'],
                                    );
                                  }}
                                >
                                  Reject
                                </div>
                              </div>
                            ) : item.booking_status == 0 &&
                              new Date(item['Vacancy.from_date_string']) <
                                currentDate ? (
                              <div className="row m-0 justify-content-end p-2">
                                <div className="rejected">Expired</div>
                              </div>
                            ) : item.booking_status == 1 ? (
                              <div className="row m-0 justify-content-end p-2">
                                <div className="confirmed ">
                                  <img
                                    className="mr-1"
                                    src="/assets/img/Subtract.png"
                                  ></img>
                                  Confirmed
                                </div>
                              </div>
                            ) : item.booking_status == 2 ? (
                              <div className="row m-0 justify-content-end p-2">
                                <div className="rejected">
                                  <i
                                    className=" fa fa-times-circle fs-18"
                                    style={{
                                      color: '#D13A3A',
                                      cursor: 'auto',
                                      paddingRight: '3px',
                                    }}
                                  ></i>
                                  Rejected
                                </div>
                              </div>
                            ) : item.booking_status == -1 ? (
                              <div className="row m-0 justify-content-end p-2">
                                <div className="rejected">
                                  <i
                                    className=" fa fa-times-circle fs-18"
                                    style={{
                                      color: '#D13A3A',
                                      cursor: 'auto',
                                      paddingRight: '3px',
                                    }}
                                  ></i>
                                  Cancelled
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>}
              {allbookings && allbookings.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
          </div>
        ) : (
          <div className="map-BookingViewFull pl-4">
            <img
              onClick={() => {
                setIsFullScreen(false);
              }}
              src="/assets/img/angleleft.png"
              style={{ height: 15, marginRight: 5, cursor: 'pointer' }}
            />
            <img
              onClick={() => {
                setIsFullScreen(false);
              }}
              className="map-bookinglistHeadingimg"
              src="/assets/img/bgroup.png"
            />
          </div>
        )}
      </div>
      {isViewCandidate.modal &&
        (isViewCandidate.type == 'agency' ? (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setIsViewCandidate({
                          modal: false,
                          candidate_id: null,
                          type: '',
                          booking_id: null,
                        });
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <AgencyCandidateProfile
                    agency_candidate_id={isViewCandidate.candidate_id}
                    booking_id={isViewCandidate.booking_id}
                  ></AgencyCandidateProfile>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setIsViewCandidate({
                          modal: false,
                          candidate_id: null,
                          type: '',
                          booking_id: null,
                        });
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <CandidateProfile
                    candidate_id={isViewCandidate.candidate_id}
                    booking_id={isViewCandidate.booking_id}
                  ></CandidateProfile>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedMapView = connect(mapStateToProps)(MapView);
export { connectedMapView as MapView };
