import React, { useEffect, useState } from 'react';
import { userService } from '../../_services';

const ExistingEmailOTP = props => {
  const [query, setQuery] = useState({
    otp: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      setQuery(prevQuery => ({
        ...prevQuery,
        email: user?.email,
      }));
    }
    setQuery(prevQuery => ({
      ...prevQuery,
      newEmail: props?.newEmail,
    }));
  }, [props.newEmail]);

  const handleChangeOTP = e => {
    const { value } = e.target;
    setQuery(prevQuery => ({
      ...prevQuery,
      otp: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      if (query.otp) {
        await userService.verifyEmail(query).then(
          res => {
            setIsLoading(false);
            props?.newEmailToToken(res?.data?.token);
            props?.setCurrentPage(6);
          },
          err => {
            setIsLoading(false);
            setError(err);
          },
        );
      } else {
        setIsLoading(false);
        setError('otp is required');
      }
    } catch (err) {
      setIsLoading(false);
      setError('Error in verifying OTP. Please try again.');
    }
  };

  return (
    <div className="col-md-6 flx-emp-profile-main-form">
      <div
        className="otp-back_button_container pointer"
        onClick={() => props?.setCurrentPage(1)}
      >
        <i className="fa fa-arrow-left"></i>
        <span className="otp-req-back-button">Back</span>
      </div>

      <h2 className="flx-company-info-form-heading">Change Email</h2>
      <p className="flx-company-info-form-sub-text">
        We have sent an OTP to your existing email
      </p>
      <form>
        <label htmlFor="otp" className="form-label company-info-labels">
          OTP
        </label>
        <div className="flx-otp-sections-container d-flex ">
          <input
            type="text"
            className="form-control otp-box-block-email-change flx-recs-change-mail"
            id="otp"
            placeholder=""
            value={query.otp}
            onChange={handleChangeOTP}
          />
          <button
            style={{ background: '#1AC8FF' }}
            type="submit"
            onClick={handleSubmit}
            className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn "
            disabled={isLoading}
          >
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 1.25,
              }}
            >
              {' '}
              {isLoading ? 'Submitting...' : 'Next'}
            </span>
          </button>
        </div>
        {error && (
          <p style={{ color: 'red' }}>
            {error.message
              ? error.message
              : typeof error === 'string'
              ? error
              : 'Please try again after some time'}
          </p>
        )}
      </form>
    </div>
  );
};

export default ExistingEmailOTP;
