import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { experienceService } from '../_services';
export const experienceActions = {
  experienceAdd,
  // experienceRemove,
  experienceList,
  experienceDelete,
  verifyexperience,
};

function experienceAdd(experienceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EXPERIENCE_REQUEST, experienceObj });
      experienceService.experienceAdd(experienceObj).then(
        data => {
          dispatch({
            type: reducerConstants.EXPERIENCE_SUCCESS,
            data,
          });
          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);
            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EXPERIENCE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function verifyexperience(experienceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EXPERIENCE_REQUEST, experienceObj });
      experienceService.verifyexperience(experienceObj).then(
        data => {
          dispatch({
            type: reducerConstants.EXPERIENCE_SUCCESS,
            data,
          });
          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);
            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EXPERIENCE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function experienceRemove(experienceObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({ type: reducerConstants.EXPERIENCE_REQUEST, experienceObj });
//       experienceService.experienceRemove(experienceObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.EXPERIENCE_SUCCESS,
//             data,
//           });
//           if (data.error_code === 0) {
//             resolve(data.data);
//             dispatch(alertActions.success(data.message.toString()));
//           } else {
//             reject(data.data);
//             dispatch(alertActions.error(data.message.toString()));
//           }
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.EXPERIENCE_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

function experienceList(experienceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EXPERIENCE_REQUEST,
        experienceObj,
      });
      experienceService.experienceList(experienceObj).then(
        data => {
          dispatch({
            type: reducerConstants.EXPERIENCE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EXPERIENCE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function experienceDelete(experienceObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EXPERIENCE_REQUEST,
        experienceObj,
      });
      experienceService.experienceDelete(experienceObj).then(
        data => {
          dispatch({
            type: reducerConstants.EXPERIENCE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EXPERIENCE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
