import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { categoryActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, reducerConstants } from '../_constants';

const CategoryAdd = props => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [category, setCategory] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  // load category data if id exist
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllCategory(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllCategory(1, query);
    }
  }, []);
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 3000);
  };
  /**
   * Get all categorys
   * */
  const getAllCategory = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(categoryActions.categoryList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setCategory(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // event handler for state category info
  const handleChange = event => {
    const { name, value } = event.target;
    setError({ common: '' });
    setCategory({
      ...category,
      [name]: value,
    });
  };
  // submit action for save category and validation
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (
      category.name &&
      category.morning_price &&
      category.day_price &&
      category.night_price &&
      category.evening_price &&
      category.holiday_price &&
      category.weekend_day_price &&
      category.minimum_price &&
      category.minimum_employer_price &&
      category.vacancy_percentage &&
      category.weekend_night_price
    ) {
      setIsLoading(true);
      if (category.minimum_employer_price < 10.42) {
        setError({
          common:
            'Minimum employer price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.morning_price) {
        setError({
          common: 'Minimum employer price should be greater than Morning price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.day_price) {
        setError({
          common: 'Minimum employer price should be greater than Day price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.evening_price) {
        setError({
          common: 'Minimum employer price should be greater than Evening price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.night_price) {
        setError({
          common: 'Minimum employer price should be greater than Night price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.holiday_price) {
        setError({
          common: 'Minimum employer price should be greater than Holiday price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.weekend_day_price) {
        setError({
          common:
            'Minimum employer price should be greater than Weekend day price',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_employer_price < category.weekend_night_price) {
        setError({
          common:
            'Minimum employer price should be greater than Weekend night price',
        });
        setIsLoading(false);
        return;
      }
      if (category.morning_price < 10.42) {
        setError({
          common: 'Morning price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (category.day_price < 10.42) {
        setError({
          common: 'Day price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
      }
      if (category.evening_price < 10.42) {
        setError({
          common: 'Evening price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }

      if (category.night_price < 10.42) {
        setError({
          common: 'Night price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (category.holiday_price < 10.42) {
        setError({
          common: 'Holiday price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }

      if (category.weekend_day_price < 10.42) {
        setError({
          common:
            'Weekend day price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (category.weekend_night_price < 10.42) {
        setError({
          common:
            'Weekend night price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (category.minimum_price < 10.42) {
        setError({
          common: 'Minimum price should be greater than  10.42',
        });
        setIsLoading(false);
        return;
      }
      dispatch(categoryActions.categoryAdd(category)).then(
        () => {
          navigate('/categorylist');
          setIsLoading(false);
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    }
  };
  return (
    <div className="CategoryAdd  d-flex justify-content-center">
      <div className="col-md-10  col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <h4 className="flx-company-info-form-heading ml-2 pl-1">
                {category && category._id ? 'Update' : 'Add'} Job role{' '}
              </h4>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Name : </label>
                    <input
                      type="name"
                      className="form-control selectDesign"
                      name="name"
                      placeholder="Name"
                      value={category.name}
                      onChange={handleChange}
                    />
                    {submitted && !category.name && (
                      <div className="help-block">Name is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group' +
                      (submitted && !category.service_type ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Service Sector :</label>
                    <select
                      className="select col-md-12 selectDesign"
                      name="service_type"
                      value={category.service_type}
                      onChange={handleChange}
                    >
                      <option value="" hidden="true">
                        {' '}
                        Select
                      </option>
                      <option value={'Hospitality'}>Hospitality</option>
                      <option value={'Health Care'}>Health Care</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Minimum Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="minimum_employer_price"
                      placeholder="Minimum Employer Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.minimum_employer_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.minimum_employer_price && (
                      <div className="help-block">
                        Minimum Employer Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Morning Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="morning_price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      placeholder="Morning Price"
                      value={category.morning_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.morning_price && (
                      <div className="help-block">
                        Morning Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Day Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="day_price"
                      placeholder="Day Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.day_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.day_price && (
                      <div className="help-block">Day Price is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Evening Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="evening_price"
                      placeholder="Evening Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.evening_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.evening_price && (
                      <div className="help-block">
                        Evening Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Night Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="night_price"
                      placeholder="Night Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.night_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.night_price && (
                      <div className="help-block">Night Price is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Holiday Employer Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="holiday_price"
                      placeholder="Holiday Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.holiday_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.holiday_price && (
                      <div className="help-block">
                        Holiday Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Weekend Employer Day Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="weekend_day_price"
                      placeholder="Weekend Day Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.weekend_day_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.weekend_day_price && (
                      <div className="help-block">
                        Weekend Day Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Weekend Employer Night Price :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="weekend_night_price"
                      placeholder="Weekend Night Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.weekend_night_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.weekend_night_price && (
                      <div className="help-block">
                        Weekend Night Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Vacancy Percentage :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="vacancy_percentage"
                      placeholder="Vacancy Percentage"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.vacancy_percentage}
                      onChange={handleChange}
                    />
                    {submitted && !category.vacancy_percentage && (
                      <div className="help-block">
                        Vacancy Percentage is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Minimum Candidate Pay :</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control selectDesign"
                      name="minimum_price"
                      placeholder="Minimum Price"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      value={category.minimum_price}
                      onChange={handleChange}
                    />
                    {submitted && !category.minimum_price && (
                      <div className="help-block">
                        Minimum Price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group d-flex justify-content-end">
                    <button
                      style={{ borderRadius: '6px' }}
                      onClick={handleSubmit}
                      data-cy="addcategory"
                      className="btn btn-primary btn-wd"
                    >
                      <span
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: 1.25,
                        }}
                      >
                        Save
                      </span>
                      {props.requestStatus ===
                        reducerConstants.CATEGORY_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedCategoryAdd = connect(mapStateToProps)(CategoryAdd);

export { connectedCategoryAdd as CategoryAdd };
