import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { educationActions } from '../_actions';
import { alertConstants } from '../_constants';

const EducationAdd = props => {
  const [isSubmit, setisSubmit] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [education, setEducation] = useState({
    currently_enrolled: 0,
  });
  const { dispatch } = props;
  const [education_id, seteducation_id] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    // if (props?.education) {
    //   setEducation(props.education);
    //   education.from_date = moment(education.from_date).format('DD-MM-YYYY');
    // }
  }, [education]);

  useEffect(() => {
    if (props.education_id) {
      seteducation_id(props.education_id);
      getEducation();
    }
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'currently_enrolled') {
      if (event.target.checked) {
        setEducation({
          ...education,
          [name]: 1,
        });
      } else {
        setEducation({
          ...education,
          [name]: 0,
        });
      }
    } else {
      setEducation({
        ...education,
        [name]: value,
      });
    }
    setdisabled(false);
  };

  const getEducation = () => {
    let education = {};

    setisLoading(true);
    const { dispatch } = props;

    if (props.education_id) {
      education._id = props.education_id;
    }
    dispatch(educationActions.educationList(education)).then(
      res => {
        setisLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setEducation(res?.data?.rows?.[0]);
        }
      },
      () => {
        setisLoading(false);
      },
    );
  };

  const handleSubmit = () => {
    setdisabled(true);
    setisSubmit(true);
    setisLoading(true);
    if (
      education.from_date &&
      education.level_of_education &&
      education.field_of_study &&
      education.college_university &&
      education.location
    ) {
      if (education.from_date && education.to_date) {
        education.currently_enrolled = 0;
      }
      if (education.from_date) {
        let from_date_string = moment(education.from_date).format('YYYY-MM-DD');
        education.from_date_string = from_date_string;
      }
      if (education.currently_enrolled == 0 && !education.to_date) {
        setError({ common: 'choose  to date ' });
        setdisabled(false);
        setisLoading(false);
        return;
      }
      if (education.to_date) {
        const currentDate = new Date().toISOString().split('T')[0];

        if (education.to_date > currentDate) {
          setError({ common: 'choose valid completion date' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        if (education.from_date > currentDate) {
          setError({ common: 'choose valid start date' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        if (education.to_date < education.from_date) {
          setError({ common: 'choose valid dates' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        let to_date_string = moment(education.to_date).format('YYYY-MM-DD');
        education.to_date_string = to_date_string;
      }
      if (education_id) {
        education._id = education_id;
      }
      if (props.candidate_id) {
        education.candidate_id = props.candidate_id;
      }
      dispatch(educationActions.educationAdd(education)).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          // window.location.href = '/profile/educationlist/' + candidate_id;
          props?.hideaddeducationmodal();
          // props?.hideediteducationmodal();

          setisSubmit(false);
        },
        err => {
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          // hideError();
          setisSubmit(false);
          setdisabled(false);
        },
      );
    } else {
      setdisabled(false);
      setisLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-11 col-sm-6 p-0 ">
        <div className="card p-3 m-0">
          <div className="card-body flx-card-body">
            {/* <form name="form"> */}
            <h4 className="card-title m-0 pb-2">
              {education._id ? 'Edit ' : 'Add '} Education
            </h4>
            <div className="row m-0">
              <div className="col-md-6 p-0">
                <div className="form-group currentLocationCardText">
                  <label htmlFor="usr" className="currentLocationCardText">
                    Level of Education :
                    <span className="assign-shift-label-star">*</span>
                  </label>
                  <select
                    aria-labelledby="label-select-54"
                    aria-invalid="false"
                    className="form-control input-alnment "
                    value={education?.level_of_education}
                    onChange={handleChange}
                    id="select-54"
                    data-cy="level_of_education"
                    name="level_of_education"
                  >
                    <option value=""> Select an option </option>
                    {/* <option
                    value="None"
                    selected={education.level_of_education == 'None'}
                  >
                    None
                  </option> */}

                    <option value="Under GCSE">Under GCSE</option>
                    <option value="Secondary(10th Standard)/ GCSE">
                      Secondary(10th Standard)/ GCSE
                    </option>
                    <option value="Higher Secondary/A-Levels">
                      Higher Secondary/A-Levels
                    </option>
                    <option value="T-Levels">T-Levels</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Bachelor’s Degree">Bachelor’s Degree</option>
                    <option value="Post Graduation Diploma">
                      Post Graduation Diploma
                    </option>
                    <option value="Master’s degree">Master’s degree</option>
                    <option value="Doctorate">Doctorate</option>
                    <option value="OTHER_KEY">Other (Box to specify)</option>

                    {/* <option
                    value="Secondary(10th Pass)"
                    selected={
                      education.level_of_education == 'Secondary(10th Pass)'
                    }
                  >
                    Secondary(10th Pass)
                  </option>
                  <option
                    value="Higher Secondary(12th Pass)"
                    selected={
                      education.level_of_education ==
                      'Higher Secondary(12th Pass)'
                    }
                  >
                    Higher Secondary(12th Pass)
                  </option>
                  <option
                    value="Diploma"
                    selected={education.level_of_education == 'Diploma'}
                  >
                    Diploma
                  </option>
                  <option
                    value="Bachelor's"
                    selected={education.level_of_education == "Bachelor's"}
                  >
                    Bachelor`s
                  </option>
                  <option
                    value="Master's"
                    selected={education.level_of_education == "Master's"}
                  >
                    Master`s
                  </option>
                  <option
                    value="Doctorate"
                    selected={education.level_of_education == 'Doctorate'}
                  >
                    Doctorate
                  </option>
                  <option
                    value="OTHER_KEY"
                    selected={education.level_of_education == 'OTHER_KEY'}
                  >
                    Other
                  </option> */}
                  </select>
                  {isSubmit && !education.level_of_education && (
                    <div className="help-block">
                      Level of education is required
                    </div>
                  )}
                </div>
              </div>

              {education.level_of_education == 'OTHER_KEY' && (
                <div className="col-md-6">
                  <div
                    className={
                      'form-group currentLocationCardText' +
                      (isSubmit &&
                      education.level_of_education == 'OTHER_KEY' &&
                      !education.education_type
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="usr" className="currentLocationCardText">
                      Education type :
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="education_type"
                      onChange={handleChange}
                      value={
                        education.education_type ? education.education_type : ''
                      }
                      className="form-control input-alnment"
                      id="usr"
                    />
                    {isSubmit &&
                      !education.education_type &&
                      education.level_of_education == 'OTHER_KEY' && (
                        <div className="help-block">
                          Education type is required
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
            <div className="row m-0">
              <div className="col-md-6 p-0">
                <div
                  className={
                    'form-group currentLocationCardText' +
                    (isSubmit && !education.field_of_study ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Field of study :
                    <span className="assign-shift-label-star">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="field_of_study"
                    onChange={handleChange}
                    value={
                      education.field_of_study ? education.field_of_study : ''
                    }
                    className="form-control input-alnment"
                    id="usr"
                  />
                  {isSubmit && !education.field_of_study && (
                    <div className="help-block">Field of study is required</div>
                  )}
                </div>
              </div>
              <div className="col-md-6 ">
                <div
                  className={
                    'form-group currentLocationCardText ' +
                    (isSubmit && !education.college_university
                      ? ' has-error'
                      : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    College or University :
                    <span className="assign-shift-label-star">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="college_university"
                    onChange={handleChange}
                    value={
                      education.college_university
                        ? education.college_university
                        : ''
                    }
                    className="form-control input-alnment"
                    id="usr"
                  />
                  {isSubmit && !education.college_university && (
                    <div className="help-block">
                      College or University is required
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-6 p-0">
                <div
                  className={
                    'form-group currentLocationCardText' +
                    (isSubmit && !education.location ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Location :<span className="assign-shift-label-star">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="location"
                    onChange={handleChange}
                    value={education.location ? education.location : ''}
                    className="form-control input-alnment"
                    id="usr"
                  />
                  {isSubmit && !education.location && (
                    <div className="help-block">Location is required</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-6 p-0">
                <div className={'form-group'}>
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="m-0"
                      name="currently_enrolled"
                      checked={education.currently_enrolled == 1 ? true : false}
                      onChange={handleChange}
                    />
                    <label className="ml-2 mb-0">Currently Pursuing</label>
                  </div>

                  {/* <span className="checkmark"></span> */}
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-6 p-0">
                <div
                  className={
                    'form-group currentLocationCardText' +
                    (isSubmit && !education.from_date ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Start Date :
                    <span className="assign-shift-label-star">*</span>
                  </label>
                  <input
                    type={'date'}
                    name="from_date"
                    onChange={handleChange}
                    // value={moment(education.from_date).format('YYYY-MM-DD')}
                    value={
                      education.from_date
                        ? moment(education.from_date).format('YYYY-MM-DD')
                        : ''
                    }
                    className="form-control input-alnment"
                  />
                  {isSubmit && !education.from_date && (
                    <div className="help-block">Start Date is required</div>
                  )}
                </div>
              </div>
              {education.currently_enrolled !== 1 && (
                <div className="col-md-6">
                  <div
                    className={
                      'form-group currentLocationCardText datefield' +
                      (isSubmit &&
                      education.currently_enrolled !== 1 &&
                      !education.from_date
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label className="currentLocationCardText">
                      Completion Date :
                      <span className="assign-shift-label-star">*</span>
                    </label>
                    <input
                      type={'date'}
                      name="to_date"
                      onChange={handleChange}
                      // value={moment(education.to_date).format('YYYY-MM-DD')}
                      value={
                        education.to_date
                          ? moment(education.to_date).format('YYYY-MM-DD')
                          : ''
                      }
                      // value={
                      //   education._id
                      //     ? education.to_date_string
                      //     : education.to_date
                      // }
                      className="form-control input-alnment"
                    />
                    {isSubmit &&
                      !education.from_date &&
                      education.currently_enrolled !== 1 && (
                        <div className="help-block">
                          Completion Date is required
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12">
              <span className="color-red">{error.common}</span>
            </div>
            <div className="row m-0">
              <div className="col-md-12 flexend p-0">
                {/* {education._id && (
                <div
                  className="btn skipbutton"
                  onClick={() => {
                    setEducation({}), setisSubmit(false);
                  }}
                  style={{ color: '#000', marginRight: 4 }}
                >
                  cancel
                </div>
              )} */}
                {!disabled ? (
                  <div
                    data-cy="add_education"
                    className=" btn regbutton float-right mr-3 mb-3"
                    onClick={handleSubmit}
                  >
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: 1.25,
                      }}
                    >
                      Save
                    </span>
                  </div>
                ) : (
                  <div className="btn bg-secondary float-right pointer mr-3 mb-3">
                    Saving..
                    {isLoading && (
                      <div className="lds-ring ml-2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}
const connectedEducationAdd = connect(mapStateToProps)(EducationAdd);

export { connectedEducationAdd as EducationAdd };
