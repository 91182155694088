import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';

export const bookingService = {
  bookingAdd,
  bookingList,
  // bookingDelete,
  confirmBooking,
};

function bookingAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addbooking', requestOptions).then(
    miscService.handleResponse,
  );
}

function confirmBooking(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/confirmbooking', requestOptions).then(
    miscService.handleResponse,
  );
}
function bookingList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/bookinglist', requestOptions).then(
    miscService.handleResponse,
  );
}
// function bookingDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/deletebooking', requestOptions).then(
//     handleResponse,
//   );
// }
