import React, { useEffect, useState } from 'react';
import { LoadingIcon, Pagination } from '../../_components';
import { businessunitService } from '../../_services';
import AddNewBusinessUnitModal from './AddNewBusinessUnitModal';
import AllocateModal from './AllocateModal';

const EmployerSettingsBusinessUnits = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showAllocateModal, setShowAllocateModal] = useState(false);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isActivateLoading, setIsActivateLoading] = useState(false);
  const [nodata, setNoData] = useState(false);

  const [employerUsers, setEmployerUsers] = useState([]);
  const [error, setError] = useState(null);
  const [employeesRows, setEmployeesRows] = useState([]);
  const [employeesData, setEmployeesData] = useState({ rows: [] });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [activateQuery, setActivateQuery] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [userSuccessMessage, setUserSuccessMessage] = useState(false);

  useEffect(() => {
    getAllBusinessUnits();
  }, []);

  const handleEditClick = employee => {
    setSelectedEmployee(employee);
    setEditModal(true);
  };

  const callBackBusinessunits = () => {
    getAllBusinessUnits();
  };
  useEffect(() => {
    if (query.business_unit_id) {
      getAllBusinessUnits();
    }
  }, [query.business_unit_id]);

  const handleActivateBusinessUnit = (item, status) => {
    activateQuery.status = status;
    activateQuery.id = item.id;
    activateQuery.employerUserId = item.employerUserId;
    activateQuery.userTableId = item.userTableId;
    setActivateQuery(activateQuery);
    setIsActivateLoading(true);
    businessunitService.changeBusinessUnitStatus(activateQuery).then(
      () => {
        setIsActivateLoading(false);
        getAllBusinessUnits(query.pageVo.pageNo);
      },
      () => {
        setIsActivateLoading(false);
        setError('Error updating the businessunit status. Please try again.');
      },
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'searchKey') {
      query.searchKey = value;
      setQuery(query);
      getAllBusinessUnits();
    }
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = employeesData => {
    let employeesList = [];
    for (
      let j = 0;
      employeesData && employeesData.rows && j < employeesData.rows.length;
      j = j + 1
    ) {
      employeesList.push(
        <tr key={j}>
          <td data-title="User Name" className="td-flx-user-settings-table">
            {employeesData.rows[j].name}
          </td>
          {/* <td data-title="Unit Name">
            <div className="td-flx-user-settings-table bus-unit-name-settings-usr">
              {
                employeesData.rows[j][
                  'Employer_user_permissions.Businessunit.name'
                ]
              }
            </div>
          </td> */}
          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {employeesData.rows[j].postcode}
            </div>
            <div className="user-settings-address">
              {employeesData.rows[j].address}
            </div>
          </td>
          <td data-title="Contact person">
            <div className="flx-settings-user-details-table-container d-flex">
              <div className="flx-email-number">
                <p
                  className="flx-settings-email"
                  style={{ fontWeight: '600', fontSize: '12px' }}
                >
                  {employeesData.rows[j].email}
                </p>
                <p
                  className="flx-settings-number"
                  style={{
                    fontWeight: '600',
                    fontSize: '12px',
                    textAlign: 'left',
                  }}
                >
                  {employeesData.rows[j]['Employer.contact_number']}
                </p>
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {!isActivateLoading ? (
                <div
                  className=""
                  onClick={() =>
                    handleActivateBusinessUnit(
                      {
                        id: employeesData.rows[j]._id,
                        userTableId: employeesData.rows[j].branch_manager_id,
                      },
                      employeesData.rows[j].status === -1 ? 1 : -1,
                    )
                  }
                >
                  <div
                    className={`switchUser ${
                      employeesData.rows[j].status === -1
                        ? 'enable pointer'
                        : 'disable pointer'
                    }`}
                  >
                    <span className="statusText">
                      {employeesData.rows[j].status === -1
                        ? 'Enable'
                        : 'Disable'}
                    </span>
                  </div>
                </div>
              ) : (
                <div>Loading...</div>
              )}

              <a
                onClick={() => handleEditClick(employeesData.rows[j])}
                className="flx-edit-link-settings-user"
              >
                <img
                  className="mt-2"
                  alt="Edit Icon"
                  src="/assets/img/Group 37408.png"
                />
              </a>
            </div>
          </td>
        </tr>,
      );
    }
    setEmployeesRows(employeesList);
    setEmployeesData(employeesData);
  };

  const getAllBusinessUnits = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    queryNew.IsBusinessUnitList = true;
    setError(null);
    (query.employer_id = employerId), setQuery(query);
    setIsLoading(true);
    businessunitService.businessunitList(queryNew).then(
      res => {
        if (res?.data?.rows?.length > 0) {
          setNoData(false);
          setEmployerUsers(res?.data?.rows);
          setListAndPagination(res?.data);
        } else if (res?.data?.rows?.length == 0) {
          setEmployerUsers([]);
          setNoData(true);
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setError('Error fetching business unit list. Please try again.');
      },
    );
  };

  const handleSuccess = () => {
    setShowAllocateModal(false);
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
    }, 5000);
  };

  const handleUserSuccess = actionType => {
    setUserSuccessMessage(true);
    if (actionType === 'create') {
      setUserSuccessMessage('Successfully Created');
    } else if (actionType === 'edit') {
      setUserSuccessMessage('Successfully Edited');
    }
    setTimeout(() => {
      setUserSuccessMessage(false);
    }, 5000);
  };

  return (
    <div className="col-12 flx-emp-profile-main-form">
      <h2 className="flx-company-info-form-heading">Business Unit</h2>

      <div className="flx-emp-profile-settings-container">
        <div className="row mb-3 mt-3 pl-2">
          {/* <div className="col">
            <div className="input-group">
              <span className="input-group-text search-icon-settings-area">
                <i className="fa fa-search flx-settings-search-icon"></i>
              </span>
              <input
                type="text"
                className="form-control input-design flx-search-box"
                name="searchKey"
                onChange={handleChange}
                placeholder="Search"
              />
            </div>
          </div> */}
          <div className=" col-md-6 search-item">
            <div className="input-outer-div d-flex align-items-center input-design1 ">
              <input
                className="search-input-div"
                onChange={handleChange}
                type="text"
                name="searchKey"
                aria-label="Search"
                placeholder="Search Here"
              />
              <a className="search_bar">
                <i className="fa fa-search in_bar" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <div className="pointer flx-alloc-user-bus-unit-container col d-flex justify-content-center">
            <img
              src="/assets/img/alloc-business-unit.svg"
              className="alloc-bus-unit-img d-inline ml-5"
              onClick={() => setShowAllocateModal(true)}
            ></img>
            <span
              className="alloc-bus-unit-text d-inline"
              onClick={() => setShowAllocateModal(true)}
            >
              {' '}
              Allocate User
            </span>

            <button
              className="btn btn-create-user-settings flx-emp-bunit-btn"
              onClick={() => setShowModal(true)}
            >
              {/* <i className="flx-plus-icon-create-user fa fa-plus"></i> */}
              Create Business Unit
            </button>
          </div>
        </div>
        {/* {employerUsers.length != 0 && !nodata && (
          <div className="pagination-top business-unit-list-pagination mt-0">
            <PaginationNew
              pageOnClick={data => {
                getAllBusinessUnits(data);
              }}
              paginationData={employeesData}
            />
          </div>
        )} */}
        {employerUsers.length > 0 && (
          <>
            <div className="flx-table-sett-user-responsive flx-table-border">
              <table
                className="table flx-custom-table"
                style={{ borderRadius: '10px' }}
              >
                <thead>
                  <tr className="fw-bolder fs-1 flx-table-header-clr">
                    <th className="py-2 table-header-ht">Unit Name</th>
                    {/* <th>Location</th> */}
                    <th>Billing Address</th>
                    <th>Contact Details</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{employeesRows}</tbody>
              </table>
            </div>
            {employeesData &&
              employeesData.rows &&
              employeesData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </>
        )}
        {nodata && query.searchKey && employerUsers.length === 0 && (
          <p style={{ color: 'red' }}>No result found.</p>
        )}

        {employerUsers.length == 0 &&
          nodata &&
          !query.searchKey &&
          !isLoading && (
            <div className="flx-emp-profile-settings-container">
              <div
                className="flx-add-bus-unit-text-container pointer"
                onClick={() => setShowModal(true)}
              >
                <i className="flx-plus-business-unit-icon fa fa-plus"></i>
                <p className="flx-text-plus-bus-unit-text">
                  You haven’t added any business unit yet
                </p>
              </div>
            </div>
          )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-popup">
              <LoadingIcon loadingText={'Loading...'} />
            </div>
          </div>
        )}
      </div>
      {error && (
        <p style={{ color: 'red' }}>
          {error.message
            ? error.message
            : typeof error === 'string'
            ? error
            : 'Please try again after some time'}
        </p>
      )}
      {employerUsers.length != 0 && !nodata && (
        <div className="pagination-top user-list-pagination mt-0">
          <Pagination
            pageOnClick={data => {
              getAllBusinessUnits(data);
            }}
            paginationData={employeesData}
          />
        </div>
      )}

      {showModal && (
        <div className="modal-overlay hide-scrollbar">
          <div className="popup">
            <AddNewBusinessUnitModal
              setShowModal={setShowModal}
              callBackBusinessunits={callBackBusinessunits}
              title="Add New Business Unit"
              onSuccess={() => handleUserSuccess('create')}
            />
          </div>
        </div>
      )}
      {userSuccessMessage && (
        <div className="alert alertboxCustomSuccess">
          <span
            className="alert-close"
            onClick={() => setUserSuccessMessage(null)}
          >
            X
          </span>
          <p>{userSuccessMessage}</p>
        </div>
      )}
      {showAllocateModal && (
        <div className="modal-overlay hide-scrollbar">
          <div className="popup">
            <AllocateModal
              title="Allocate User"
              setShowAllocateModal={setShowAllocateModal}
              callBackBusinessunits={callBackBusinessunits}
              onSuccess={handleSuccess}
            />
          </div>
        </div>
      )}
      {successMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>Successfully Allocated</p>
        </div>
      )}
      {editModal && (
        <div className="modal-overlay hide-scrollbar">
          <div className="popup">
            <AddNewBusinessUnitModal
              setShowModal={setEditModal}
              employee={selectedEmployee}
              title="Edit Business Unit"
              callBackBusinessunits={callBackBusinessunits}
              onSuccess={() => handleUserSuccess('edit')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployerSettingsBusinessUnits;
