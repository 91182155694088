import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sectionActions } from '../_actions';
import { LoadingIcon } from '../_components';
import { SectionAdd } from './SectionAdd';

// eslint-disable-next-line no-unused-vars
let sectionRows = [];

const SectionList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionData, setSectionData] = useState({ rows: [] });
  const [sectionRows, setSectionRows] = useState([]);
  const [shiftRow, setShiftRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  const [showmodal, setshowmodal] = useState(false);
  useEffect(() => {
    getAllSection(1);
  }, []);
  // call for sections
  const getAllSection = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    dispatch(sectionActions.sectionList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to open or close modal
  const closeSectionAddModal = value => {
    setshowmodal(value);
    getAllSection();
  };
  // list and pagination section data
  const setListAndPagination = sectionData => {
    let sectionList = [];
    let shiftListWithoutHtml = [];
    for (
      let j = 0;
      sectionData && sectionData.rows && j < sectionData.rows.length;
      j = j + 1
    ) {
      sectionList.push(
        <tr key={j} className="mt-2 ">
          <td
            className={
              j % 2 == 0 ? ' td-blue  pb-0 pt-0' : ' pb-0 pt-0  td-grey '
            }
          >
            {(sectionData.page - 1) * sectionData.limit + j + 1}
          </td>
          <td className={j % 2 == 0 ? ' td-blue  ' : '   td-grey '}>
            {sectionData.rows[j].name}{' '}
          </td>
          <td className={j % 2 == 0 ? ' td-blue  ' : '   td-grey '}>
            {sectionData.rows[j].from_time}{' '}
          </td>
          <td className={j % 2 == 0 ? ' td-blue  ' : '   td-grey '}>
            {sectionData.rows[j].to_time}{' '}
          </td>
        </tr>,
      );
      shiftListWithoutHtml.push(
        ' (' +
          sectionData.rows[j].from_time +
          '-' +
          sectionData.rows[j].to_time +
          ')',
      );
    }
    setSectionRows(sectionList);
    setShiftRows(shiftListWithoutHtml);
    setSectionData(sectionData);
  };
  return (
    <div className="SectionList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          {!props.isSettings && (
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8">
                  <h4> Section List</h4>
                </div>
                <div className="col-md-4">
                  <a
                    className="btn btn-primary float-right"
                    href="/sectionadd/0"
                  >
                    <span> Add Section</span>
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <>
                <div>
                  <table className="table customtable table-hover">
                    <tbody>{sectionRows}</tbody>
                  </table>
                </div>
                <div
                  data-cy="addcustomeshift"
                  className="col d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setshowmodal(true);
                  }}
                >
                  <i
                    className="fa fa-plus-circle"
                    style={{
                      fontSize: '16px',
                      color: ' #11918A',
                      cursor: 'pointer',
                    }}
                  ></i>
                  <p className="ml-3 cgreen">Add custom shift</p>
                </div>
              </>
            )}
            {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            {sectionData &&
              sectionData.rows &&
              sectionData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <SectionAdd
                  closeSectionAddModal={closeSectionAddModal}
                  shiftRow={shiftRow}
                ></SectionAdd>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, sectionData } = state.section;
  return {
    requestStatus,
    sectionData,
  };
}

const connectedSectionList = connect(mapStateToProps)(SectionList);
export { connectedSectionList as SectionList };
