import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const notificationService = {
  // notificationAdd,
  notificationList,
  // notificationDelete,
  sendEmailNotificationSettings
};

// function notificationAdd(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/addnotification', requestOptions).then(
//     miscService.handleResponse,
//   );
// }
function notificationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getnotificationList',
    requestOptions,
  ).then(miscService.handleResponse);
}
// function notificationDelete(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/deletenotification',
//     requestOptions,
//   ).then(handleResponse);
// }

function sendEmailNotificationSettings(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/sendEmailnotificationsettings',
    requestOptions,
  ).then(miscService.handleResponse);
}
