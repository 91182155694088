import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import { PermenentemployerAddd } from '../Employer';
import { MsalProvider } from '@azure/msal-react';
// import { LoadingImg } from '../_components';
import { LoadingIcon } from '../_components';
import { msalInstance, userService } from '../_services';
import GoogleLogin from './GoogleLogin';
import MicrosoftAuth from './MicrosoftAuth';

const LoginPage = () => {
  const [user, setUser] = useState({
    username: '',
    password: '',
    is_temp_candidate: 1,
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // handle change to set email and password
  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  // call for login access to flexirecs
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    const { username, password } = user;
    //const { dispatch } = props;

    if (username && password) {
      setIsLoading(true);
      // dispatch(userActions.login(username, password)).then(
      userService.login(username, password).then(
        function (res) {
          setIsLoading(false);
          // if (res !== 'TODO') {
          //   return;
          // }
          if (res.message === 'NOT ACTIVATED') {
            localStorage.setItem('user', JSON.stringify(res.data));
            window.location.href = '/agencywaitingpage';
            return;
          }
          if (res.role == 4 || res.role == 7) {
            // if (res.permenentemployer) {
            //   dispatch(
            //     employerActions.checkemailExist({
            //       email: res.email,
            //     }),
            //   ).then(
            //     res => {
            //       setIsLoading(false);
            //       if (
            //         res &&
            //         res?.data?.data?.rows &&
            //         res?.data?.data?.rows?.length > 0
            //       ) {
            //         setEmployer(res.data.data.rows[0]);
            //         setupdateemployermodal(true);
            //       }
            //     },
            //     () => {
            //       setIsLoading(false);
            //     },
            //   );
            // } else {
            window.location.href = '/dashboardemployer';
            //  }
          } else {
            window.location.href = '/';
          }
        },
        function (error) {
          setIsLoading(false);
          if (error) {
            error = error.message
              ? error.message
              : typeof error === 'string'
              ? error
              : 'Please try again after some time';
            setError({
              common: error,
            });
          } else {
            setError({
              common: 'Email/Username or password not valid',
            });
          }
        },
      );
    }
  };

  useEffect(() => {
    setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, []);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="row justify-content-center flx-sign-in-container">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/recsy-temp-logo.svg" />
          </div>
        </div>
        <div className="row justify-content-center flx-login-area">
          <div className="col-md-8 col-lg-6  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6 mx-auto">
                <h2
                  className="mb-0"
                  style={{ color: '#000', fontWeight: '500', fontSize: '20px' }}
                >
                  Employer Sign In
                </h2>
                <p className="flx-sec-line-heading">
                  Let&lsquo;s hire your next great candidate
                </p>
                <span className="color-red">
                  {error.common?.message
                    ? error.common.message
                    : typeof error.common === 'string'
                    ? error.common
                    : 'Please try again after some time'}
                </span>
                <form name="form" onSubmit={handleSubmit}>
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.username ? ' has-error' : '')
                    }
                  >
                    <p className="label-login" htmlFor="username">
                      Email / Phone
                    </p>
                    <input
                      type="text"
                      className="flx-common-input-fields form-control"
                      name="username"
                      value={user.username}
                      onChange={handleChange}
                    />
                    {submitted && !user.username && (
                      <div className="help-block">Username is required</div>
                    )}
                  </div>
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.password ? ' has-error' : '')
                    }
                  >
                    <p className="label-login" htmlFor="password">
                      Password
                    </p>
                    <div
                      className="d-flex flex-row bg-white input-group input-wrap-with-btn "
                      style={{ border: '1px solid #8a8a8a' }}
                    >
                      <input
                        style={{ borderRight: 'none' }}
                        type={showPassword ? 'text' : 'password'}
                        className="form-control pl-1 fw-500 signin-input"
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                      />
                      <span
                        onClick={handlePasswordToggle}
                        className="m-auto px-2"
                      >
                        {showPassword ? (
                          <img src="/assets/img/eyeclose.png" />
                        ) : (
                          <img src="/assets/img/eye.png" />
                        )}
                      </span>
                    </div>
                    {submitted && !user.password && (
                      <div className="help-block">Password is required</div>
                    )}
                    <div className="d-flex justify-content-end mt-1">
                      <Link
                        to="/forgotpassword"
                        className="btn btn-link text-right link-black"
                        style={{ textDecoration: 'none' }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="form-group ">
                    <button type="submit" className="send-btn">
                      Sign In
                      {isLoading && <div className="lds-ring ml-2"></div>}
                    </button>
                    {isLoading && <LoadingIcon loadingText={'Loading...'} />}
                  </div>
                  <div className="d-flex justify-content-center mt-1">
                    <Link
                      to="/loginwithemail"
                      className="btn btn-link text-center link-black"
                      style={{ textDecoration: 'none' }}
                    >
                      Sign In With OTP
                    </Link>
                  </div>
                  <div className="text-center">
                    <div className="row flx-continue-sign-with-row">
                      <div className="col">
                        <hr />
                      </div>
                      <div className="col-auto">
                        <span className="flx-continue-sign-with">
                          OR Continue Sign In with
                        </span>
                      </div>
                      <div className="col">
                        <hr />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="flx-other-signup-option-container d-flex align-items-center">
                  <div className="flx-google-sign-in">
                    <GoogleLogin
                      isLogin={true}
                      showError={msg => {
                        setError({
                          ...error,
                          common: msg,
                        });
                      }}
                      showLoading={status => {
                        setIsLoading(status);
                      }}
                    />
                  </div>
                  <div className="flx-microsoft-sign-in">
                    <MsalProvider instance={msalInstance}>
                      <MicrosoftAuth
                        isLogin={true}
                        showError={msg => {
                          setError({
                            ...error,
                            common: msg,
                          });
                        }}
                        showLoading={status => {
                          setIsLoading(status);
                        }}
                      />
                    </MsalProvider>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-3">
                  <span className="flx-general-text">
                    Don&lsquo;t have an account?
                  </span>
                  <Link
                    to="/register"
                    className="btn btn-link text-right"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="flex-sign-up-text">Sign Up</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
