/* eslint-disable no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { AccountIDPrompt, RegPopup } from '../_components';
import { userService } from '../_services';

const GoogleLogin = ({
  isLogin,
  showError,
  isRegister,
  showLoading,
  userType,
}) => {
  const [googleLoginId, setGoogleLoginId] = useState(null);
  const [promptModel, setPromptModel] = useState(false);
  const [promptModelRegistration, setPromptModelRegistration] = useState(false);

  const updateLoadingStatus = status => {
    showLoading(status);
  };

  useEffect(() => {
    return () => {
      updateLoadingStatus(false);
    };
  }, []);

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      GoogleLoginService(codeResponse);
    },
    onError: () => {
      showError('Google Login Failed');
    },
  });

  const GoogleLoginService = async user => {
    // Setting user properties
    setGoogleLoginId(user);
    Object.assign(user, { isLogin, isRegister, userType });
    updateLoadingStatus(true);

    try {
      const res = await userService.GoogleLoginRegistration(user);

      // Handle successful response
      if (res.error_code === 0) {
        // Handle specific Google registration and account linking cases
        if (res.data.GoogleRegistration) {
          setPromptModelRegistration(true);
        } else if (res.data.toLinkWithGoogle) {
          setPromptModel(true);
        } else {
          localStorage.setItem('user', JSON.stringify(res.data));

          if (res.message === 'NOT ACTIVATED') {
            window.location.href = '/agencywaitingpage';
            return;
          }

          // Navigation logic based on user role and status
          const { role, active_status, phone, name, is_signup_completed } =
            res.data;

          if (role === 5) {
            if (active_status === 1) {
              window.location.href = '/dashboardemployer';
            } else if (!phone || phone.length < 1) {
              window.location.href = '/phonenumber';
            } else if (name && active_status === 0) {
              window.location.href = '/agencywaitingpage';
            } else {
              window.location.href = '/login';
            }
          } else if (role === 4) {
            window.location.href = is_signup_completed
              ? '/dashboardemployer'
              : '/phonenumber';
          } else if (role === 6 || role === 7) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/login';
          }
        }
      } else {
        showError(res.message);
      }
    } catch (error) {
      console.error('Error in GoogleLoginService:', error);
      showError(error instanceof Error ? error.message : 'Google Login Failed');
    } finally {
      updateLoadingStatus(false);
      setPromptModel(false);
    }
  };

  // For close the modal
  const closeModal = () => {
    setGoogleLoginId(null);
    setPromptModel(false);
    setPromptModelRegistration(false);
  };

  // Popup for AccountIDPrompt modal in Sign-up
  const cancelPopModal = () => {
    setGoogleLoginId(null);
    window.location.href = '/login';
  };
  // AccountIDPrompt modal to link with Google in Sign-in
  const handleGoogleLoginClick = () => {
    GoogleLoginService({
      ...googleLoginId,
      is_save_google_id: 'true',
    });
  };

  return (
    <>
      <>
        {promptModel && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <AccountIDPrompt
              onCancel={closeModal}
              onGoogleLogin={handleGoogleLoginClick}
            />
          </div>
        )}
      </>

      <>
        {promptModelRegistration && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <RegPopup onCancel={cancelPopModal} onAnotherAction={closeModal} />
          </div>
        )}
      </>

      <button
        className="google-auth-btn"
        onClick={() => {
          login();
        }}
      >
        <img src="/assets/img/google.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Google</span>
      </button>
    </>
  );
};

export default GoogleLogin;
