import React, { useState } from 'react';
import { LoadingIcon } from '../../_components';
import { miscService, userService } from '../../_services';
import EmailChangeConfirmPopup from './EmailChangeConfirmPopup';

const NewEmailChange = props => {
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [query, setQuery] = useState({
    token: props.token,
    newEmail: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'newEmail' && miscService.emailVerify(value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e?.preventDefault();
    setLoading(true);
    if (query.newEmail && validEmail) {
      userService.changEmailRequest(query).then(
        () => {
          setLoading(false);

          props.newEmailToOtp(query.newEmail);
          props?.setCurrentPage(7);
        },
        err => {
          setLoading(false);
          console.error('Error in email change request:', err);
          setError('Error requesting email change. Please try again.');
        },
      );
    } else {
      setError('Email is required');
    }
  };

  const handleShowPopup = () => {
    if (!query.newEmail) {
      setError('Email is required');
      return;
    }
    setIsConfirmPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsConfirmPopupVisible(false);
  };

  return (
    <div className="col-md-6 flx-emp-profile-main-form">
      <div
        className="otp-back_button_container pointer"
        onClick={() => props?.setCurrentPage(4)}
      >
        <i className="fa fa-arrow-left"></i>
        <span className="otp-req-back-button">Back</span>
      </div>

      <h2 className="flx-company-info-form-heading">Change Email</h2>
      <p className="flx-company-info-form-sub-text">Enter your new Email ID</p>

      <form>
        <label htmlFor="new_email" className="form-label company-info-labels">
          Email
        </label>

        <div className="flx-otp-sections-container">
          <input
            type="text"
            className="form-control input-inline"
            id="new_email"
            name="newEmail"
            placeholder="New Email"
            onChange={handleChange}
          />
          <button
            style={{ borderRadius: '6px', background: '#1AC8FF' }}
            className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn flx-email-update-confirm-button float-right"
            onClick={e => {
              e.preventDefault(), handleShowPopup();
            }}
            disabled={!query.newEmail.trim() || !validEmail || loading}
          >
            <span
              style={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 1.25,
              }}
            >
              {' '}
              Confirm Change
            </span>
          </button>
        </div>
        {error && (
          <p style={{ color: 'red' }}>
            {error.message
              ? error.message
              : typeof error === 'string'
              ? error
              : 'Please try again after some time'}
          </p>
        )}
      </form>
      {isConfirmPopupVisible && (
        <div className="request-modal-overlay">
          <div className="popup">
            <EmailChangeConfirmPopup
              setCurrentPage={props?.setCurrentPage}
              handleClosePopup={handleClosePopup}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      )}
      {loading && <LoadingIcon loadingText={'Loading...'} />}
    </div>
  );
};

export default NewEmailChange;
