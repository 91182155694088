import 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { businessunitActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
let employer_id = '';

const DashbordBunitReport = props => {
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [showallshifts, setshowalshifts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bunitData, setBunitData] = useState({ rows: [] });
  const [bunitRows, setBunitRows] = useState([]);
  const [bunit_id, setbunitid] = useState();
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  // effect to get user details and load bunit
  useEffect(() => {
    if (props && props.ishomepage) {
      query.pageVo.noOfItems = 3;
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.employer_id) {
        employer_id = user.employer_id;
        setQuery({ ...query, employer_id: user.employer_id });
      }
      if (user.role == 4) {
        getAllBusinessunit(1);
      }
    }
  }, [query.from_date, query.end_date, query.businessunit]);
  /**
   * Get all Businessunit
   **/
  const getAllBusinessunit = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    dispatch(businessunitActions.dashbordBunitReport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // state bunit
  const setBunit = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = bunitData => {
    let bunitList = [];
    for (
      let j = 0;
      bunitData &&
      bunitData.rows &&
      bunitData.rows.length &&
      j < bunitData.rows.length;
      j = j + 1
    ) {
      bunitList.push(
        <tr key={j} className="rowcenter">
          <td>
            <div className=" d-flex align-items-center txt-start">
              <div className=" border-rad-55  ">
                {bunitData &&
                bunitData.rows[j] &&
                bunitData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={apiConstants.imgUrl + bunitData.rows[j].profileimage}
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className="  w120">
                <p className="emp_name username">{bunitData.rows[j].name}</p>
                <div className=" d-flex align-items-center">
                  <div className=" location-text">
                    <span>{bunitData.rows[j].email}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {bunitData.rows[j].no_of_shifts
              ? bunitData.rows[j].no_of_shifts
              : 0}
          </td>
          <td>
            {' '}
            £
            {bunitData.rows[j].average_price
              ? bunitData.rows[j].average_price
              : 0}
          </td>
          <td>
            {' '}
            £{bunitData.rows[j].flexi_price ? bunitData.rows[j].flexi_price : 0}
          </td>
          <td style={{ color: '#ec5832' }}>
            {' '}
            {bunitData.rows[j].spend ? bunitData.rows[j].spend : 0}
          </td>
          {!props.ishomepage && (
            <td>
              <div
                className="left-right-arrow"
                onClick={() => {
                  setbunitid(bunitData.rows[j]._id), setshowalshifts(true);
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
            </td>
          )}
        </tr>,
      );
    }
    setBunitRows(bunitList);
    setBunitData(bunitData);
  };
  // custom styles for styles
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '45px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#F4F4F4',
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
  };
  return (
    <div className="EmployerList m-2">
      <div className="row">
        <div className="col-md-12 ">
          <div className="card regular-table-with-color ">
            {!props.ishomepage && (
              <div className="row">
                <div className="card-header page-header pl-3">
                  <div className="page-title " style={{ fontSize: 20 }}>
                    BUSINESS UNIT REPORT
                  </div>
                </div>
              </div>
            )}
            {!props.ishomepage && (
              <div className=" filter-row">
                <div className="flex-item3">
                  <div>
                    {' '}
                    <label>From date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-group-50px input-design1"
                      style={{
                        border: 'none',
                        display: 'block',
                        width: '100%',
                        height: '40px',
                        padding: '0.375rem 0.75rem',
                        flexShrink: '0',
                        fontSize: '14px',
                        fontWeight: '500',
                        borderRadius: '6px',
                      }}
                      name="from_date"
                      placeholder="From"
                      value={fromdate}
                      onChange={event => {
                        let { value } = event.target;

                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.from_date = value;
                        setQuery(newquery);
                        setfromdate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>To date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-group-50px input-design1"
                      style={{
                        border: 'none',
                        display: 'block',
                        width: '100%',
                        height: '40px',
                        padding: '0.375rem 0.75rem',
                        flexShrink: '0',
                        fontSize: '14px',
                        fontWeight: '500',
                        borderRadius: '6px',
                      }}
                      name="end_date"
                      placeholder="From"
                      value={todate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.end_date = value;
                        setQuery(newquery);
                        settodate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3 ">
                  <div>
                    <label>Business unit</label>
                  </div>
                  <AsyncSelect
                    styles={customStyles}
                    name="businessunit"
                    onChange={setBunit}
                    placeholder={'select businessunit'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getBunitlist}
                  />
                </div>
              </div>
            )}
            <div
              className={
                !props.isHomepage
                  ? '  table-responsive main-card-body  '
                  : '  table-responsive main-card-body '
              }
              style={{ minHeight: '0px' }}
            >
              {props && props.ishomepage && (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {' '}
                    <label style={{ fontSize: 20 }}>BUSINESS UNIT REPORT</label>
                  </div>
                  {props && props.ishomepage && (
                    <div>
                      <Link to="/dashbordbunitreport">
                        <button className="blackbutton">View All</button>
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {!isLoading && (
                <table className="table  mt-2 flx-table-border">
                  <thead>
                    <tr className="flx-table-header-clr">
                      <th className="dash-head border-top6-left table-header-ht">
                        Name
                      </th>
                      <th>No of Shift</th>
                      <th>Avg Price </th>
                      <th>Flexi Avg Price</th>
                      <th className={props.ishomepage && 'border-top6'}>
                        Spend
                      </th>
                      {!props.ishomepage && <th className="border-top2"></th>}
                    </tr>
                  </thead>
                  <tbody>{bunitRows}</tbody>
                </table>
              )}
              {!!isLoading && !props.ishomepage && (
                <LoadingIcon loadingText={'Loading...'} />
              )}
              {bunitRows && bunitRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.ishomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllBusinessunit(data);
                  }}
                  paginationData={bunitData}
                />
              </div>
            )}
          </div>
        </div>
        {showallshifts && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close-btn p-4">
                      <a onClick={() => setshowalshifts(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <AllShiftsList
                    businessunit={bunit_id}
                    from_date={fromdate}
                    to_date={todate}
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// loader for business unit select box
const getBunitlist = inputvalue =>
  new Promise((resolve, reject) => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            //---add a default all value in filter option   -----
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data.rows);
            resolve(
              newArray.map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
            );
          }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, bunitData } = state.businessunit;
  return {
    requestStatus,
    bunitData,
  };
}

const connectedDashbordBunitReport =
  connect(mapStateToProps)(DashbordBunitReport);
export { connectedDashbordBunitReport as DashbordBunitReport };
