import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { businessunitActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { BusinessunitAdd } from './BusinessunitAdd';

const BusinessunitList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [businessunitData, setBusinessunitData] = useState({ rows: [] });
  const [businessunitRows, setBusinessunitRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  let employer_id = '';
  // effect to load
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.employer_id) {
        employer_id = user.employer_id;
      }
    }
    getAllBusinessunit(1);
  }, []);
  /**
   * Get all businessunits
   **/
  const getAllBusinessunit = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(businessunitActions.businessunitList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // set bunit modal open or close
  const setbunitaddModal = value => {
    setshowmodal(value);
    getAllBusinessunit(1);
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = businessunitData => {
    let businessunitList = [];
    for (
      let j = 0;
      businessunitData &&
      businessunitData.rows &&
      j < businessunitData.rows.length;
      j = j + 1
    ) {
      businessunitList.push(
        <tr key={j} className="mt-2">
          <td
            className={
              j % 2 == 0 ? ' td-blue  pb-0 pt-0' : ' pb-0 pt-0  td-grey '
            }
          >
            {(businessunitData.page - 1) * businessunitData.limit + j + 1}
          </td>
          <td className={j % 2 == 0 ? ' td-blue  ' : '   td-grey '}>
            <div className="d-flex justify-content-between">
              <div>{businessunitData.rows[j].name} </div>
              <div>
                <a
                  data-cy="editbunit"
                  href={'/businessunitadd/' + businessunitData.rows[j]._id}
                >
                  Edit
                </a>
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setBusinessunitRows(businessunitList);
    setBusinessunitData(businessunitData);
  };
  return (
    <div className="BusinessunitList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            {!props.isSettings && (
              <div className="row">
                <div className="col-md-8">
                  <h4> Businessunit List</h4>
                </div>
                <div className="col-md-4">
                  <a
                    data-cy="addbunit"
                    className="btn btn-primary float-right"
                    href="/businessunitadd/0"
                  >
                    <span> Add Businessunit </span>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table customtable table-hover">
                <tbody>{businessunitRows}</tbody>
              </table>
            )}
            <div
              data-cy="addbusinessunit"
              className="col d-flex align-items-center"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setshowmodal(true);
              }}
            >
              <i
                className="fa fa-plus-circle"
                style={{
                  fontSize: '16px',
                  color: ' #11918A',
                  cursor: 'pointer',
                  marginLeft: '20px',
                }}
              ></i>
              <p className="ml-3 cgreen">Add Business unit</p>
            </div>
            {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            {businessunitData &&
              businessunitData.rows &&
              businessunitData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllBusinessunit(data);
              }}
              paginationData={businessunitData}
            />
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <BusinessunitAdd
                  setbunitaddModal={setbunitaddModal}
                ></BusinessunitAdd>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, businessunitData } = state.businessunit;
  return {
    requestStatus,
    businessunitData,
  };
}

const connectedBusinessunitList = connect(mapStateToProps)(BusinessunitList);
export { connectedBusinessunitList as BusinessunitList };
