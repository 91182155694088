import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { invoiceActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { InvoiceAddAgency } from './InvoiceAddAgency';

let user_role = '';
const AgencyInvoiceList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({ rows: [] });
  const [invoiceRows, setInvoiceRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [isAgency, setisAgency] = useState(false);
  const [invoiceid, setinvoiceid] = useState();
  const [selected_id, setselected_id] = useState('');
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [query, setQuery] = useState({
    searchKey: '',
    from: '',
    to: '',
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  let isadmin = false;
  let isstaff = false;

  // effect for get all invoice
  useEffect(() => {
    getAllInvoice(query.pageVo.pageNo);
  }, []);
  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query.searchKey, 500);
  const debouncedSearchFrom = useDebounce(query.from, 500);
  const debouncedSearchTo = useDebounce(query?.to, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch || debouncedSearchFrom || debouncedSearchTo) {
        getAllInvoice(1);
      }
    }
  }, [debouncedSearch, debouncedSearchFrom, debouncedSearchTo]);
  //--- debouncing end ---
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user.role == 1) {
        isadmin = true;
        setisAdmin(true);
      } else if (user.role == 6) {
        isstaff = true;
        setisStaff(true);
      } else if (user.role == 5) {
        setisAgency(true);
      } else {
        if (user && user.employer_id) {
          setQuery(prevState => ({
            ...prevState,
            employer_id: user.employer_id,
          }));
        }
        if (user.role == 4) {
          setisAdmin(false);
        }
      }
    }
  }, [isAdmin]);
  // event handler for filter
  const handleChange = event => {
    const { name, value } = event.target;
    isfirsttime.current = false;
    setQuery(prevState => ({ ...prevState, [name]: value }));
  };
  //-----to show edit invoice modal ----------
  const setEditInvoiceModal = (value, _id) => {
    setselected_id(_id);
    showInvoiceModal(value);
  };
  // call for get all invoices
  const getAllInvoice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4 && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    const { dispatch } = props;
    if (queryNew.from == '') {
      delete queryNew.from;
    }
    if (queryNew.to == '') {
      delete queryNew.to;
    }
    dispatch(invoiceActions.invoiceListAgency(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // show or close invoice
  const showInvoiceModal = value => {
    setshowmodal(value);
    getAllInvoice(query.pageVo.pageNo);
  };
  // handler for sort invoice list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  //---update paid status only for admin -----------
  const paidStatusChange = () => {
    //---update paid status -----
    const { dispatch } = props;
    dispatch(
      invoiceActions.invoiceUpdate({
        _id: invoiceid._id,
        payment_status: 'paid',
      }),
    ).then(() => {
      setdeleteconfirmationmodal(false);
      getAllInvoice(query.pageVo.pageNo);
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = invoiceData => {
    let invoiceList = [];
    for (
      let j = 0;
      invoiceData && invoiceData.rows && j < invoiceData.rows.length;
      j = j + 1
    ) {
      invoiceList.push(
        <tr key={j}>
          <td>INV0{invoiceData.rows[j]._id}</td>
          <td className="p-2">
            <Link
              to={'/employer/' + invoiceData.rows[j]['Employer._id']}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px', // Space between the image and text
                  flexWrap: 'wrap', // Allow wrapping on smaller screens
                  padding: '5px', // Add some padding for better spacing
                }}
              >
                {invoiceData &&
                invoiceData.rows[j] &&
                invoiceData.rows[j].profileimage ? (
                  <img
                    style={{
                      width: '26px',
                      height: '26px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={apiConstants.imgUrl + invoiceData.rows[j].profileimage}
                    alt="Profile"
                  />
                ) : (
                  <img
                    style={{
                      width: '26px',
                      height: '26px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src="/assets/img/recsy-fav-icon.png"
                    alt="Default"
                  />
                )}
                <div
                  style={{
                    flex: '1', // Allow text to take up available space
                    wordBreak: 'break-word', // Prevent text overflow
                    fontSize: '14px', // Adjust font size for readability
                  }}
                >
                  {invoiceData.rows[j]['Employer.company_name']}
                </div>
              </div>
            </Link>
          </td>

          <td>
            {' '}
            {invoiceData?.rows[j]?.updatedAt?.split('T')[0]} &nbsp;-{' '}
            {invoiceData?.rows[j]?.updatedAt?.split('T')[1]?.substr(0, 5)}{' '}
          </td>
          <td>
            {' '}
            {moment(invoiceData.rows[j].from_date_string).format(
              'DD-MMM-YYYY',
            )}{' '}
            &nbsp; to &nbsp;{' '}
            {moment(invoiceData.rows[j].to_date_string).format('DD-MMM-YYYY')}{' '}
          </td>
          <td>{invoiceData.rows[j].total_hours}&nbsp;hrs </td>
          <td>{'£' + ' ' + invoiceData.rows[j].total_amount.toFixed(2)} </td>
          <td>
            <div className="d-flex justify-content-center">
              {user_role == 5 && (
                <div
                  className={
                    invoiceData.rows[j].payment_status == 'paid'
                      ? 'paid_div cursorPoint'
                      : 'notpaid_div cursorPoint'
                  }
                  onClick={() => {
                    if (invoiceData.rows[j].payment_status !== 'paid') {
                      setdeleteconfirmationmodal(true),
                        setinvoiceid({ _id: invoiceData.rows[j]._id });
                    }
                  }}
                >
                  {' '}
                  {invoiceData.rows[j].payment_status}
                </div>
              )}
              {(isAdmin || isStaff || isadmin || isstaff) && (
                <div
                  className={
                    invoiceData.rows[j].payment_status == 'paid'
                      ? 'paid_div cursorPoint'
                      : 'notpaid_div cursorPoint'
                  }
                >
                  {invoiceData.rows[j].payment_status}
                </div>
              )}
            </div>
          </td>
          <td className="eventCell  eventView" data-cy="viewinvoice">
            {' '}
            <a href={'/invoiceagency/' + invoiceData.rows[j]._id}>View</a>{' '}
          </td>

          {user_role == 5 && (
            <td
              className="eventCell event Edit"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEditInvoiceModal(true, invoiceData.rows[j]._id);
              }}
            >
              {/* <div className="editButton">Edit</div> */}

              <img
                className=" "
                src="/assets/img/edit-icon-pending-sign-off.svg"
              />
            </td>
          )}
        </tr>,
      );
    }
    setInvoiceRows(invoiceList);
    setInvoiceData(invoiceData);
  };
  return (
    <div className="InvoiceList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header py-2">
            <div className="row d-flex align-items-center">
              <div className="col-md-8 mb-2 mt-1 pl-3">
                <div className="page-title">Invoice List</div>
              </div>
              {/* {isAgency && (
                <div className="col-md-4 ">
                  <div
                    className="btn btn-primary-blue float-right"
                    onClick={() => {
                      setselected_id(''), setshowmodal(true);
                    }}
                  >
                    <span> Generate Invoice</span>
                  </div>
                </div>
              )} */}
            </div>
            <div className="row ">
              <div className="col-md-3 ml-1 ">
                <p>
                  <div className="input-outer-div input-design1">
                    <input
                      className="search-input-div"
                      value={query.searchKey}
                      onChange={handleChange}
                      type="text"
                      name="searchKey"
                      aria-label="Search"
                      placeholder="Search Here..."
                    />
                    <a className="search_bar">
                      <i className="fa fa-search in_bar" aria-hidden="true"></i>
                    </a>
                  </div>
                </p>
              </div>
              {/* ======FROM DATE PICKER======== */}
              <div className="col-md-2">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2 input-design1"
                    name="from"
                    placeholder="from"
                    value={query.from}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* TO DATE PICKER=== */}
              <div className="col-md-2">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2 input-design1"
                    name="to"
                    placeholder="to"
                    value={query.to}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {isAgency && (
                <div className="col-md-4 d-flex justify-content-end flx-agency-invoice">
                  <div
                    className="btn btn-primary-blue btn-primary-blue1"
                    onClick={() => {
                      setselected_id('');
                      setshowmodal(true);
                    }}
                  >
                    <i className="fas fa-file-invoice pr-1"></i>

                    <span>Generate Invoice</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body mt-2 flx-table-border">
            {!isLoading && (
              <table className="table  flx-custom-table">
                <thead>
                  <tr className="flx-table-header-clr">
                    <th className="border-top1 table-header-ht">Invoice No</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Name</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('name')}
                            />
                          )}
                          {query.pageVo.sort === 'name' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('name')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('name')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('updatedAt')}
                      className="sortHead eventCell  eventView"
                    >
                      <div className="d-flex align-items-center ">
                        <span>Created time</span>

                        <span
                          style={{ width: '20px' }}
                          className="d-flex flex-column align-items-center"
                        >
                          {query.pageVo.sort === 'updatedAt' &&
                          query.pageVo.sort_order !== 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up-black.png"
                              onClick={() => handleSort('updatedAt')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-up.png"
                              onClick={() => handleSort('updatedAt')}
                            />
                          )}
                          {query.pageVo.sort === 'updatedAt' &&
                          query.pageVo.sort_order === 'desc' ? (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down-black.png"
                              onClick={() => handleSort('updatedAt')}
                            />
                          ) : (
                            <img
                              style={{
                                height: 10,
                                width: 10,
                                cursor: 'pointer',
                              }}
                              src="/assets/img/sort-down.png"
                              onClick={() => handleSort('updatedAt')}
                            />
                          )}
                        </span>
                      </div>
                    </th>
                    <th className="eventCell  eventEdit "> From - to </th>
                    <th className="eventCell  eventEdit "> Working hours </th>
                    <th className="eventCell  eventEdit "> Total amount </th>
                    <th className="eventCell  eventEdit "> Payment status </th>
                    <th className="eventCell  eventEdit "> </th>
                    {isAgency && (
                      <th className="eventCell  eventEdit border-top2"> </th>
                    )}
                  </tr>
                </thead>
                <tbody>{invoiceRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
            {invoiceRows && invoiceRows.length === 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
            {/* //--------show invoice add modal view ------------ */}
            {showmodal && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog modal-dialog-large ">
                  <div className="modal-content ">
                    <div className="modal-body">
                      <div className="bag">
                        <InvoiceAddAgency
                          showInvoiceModal={showInvoiceModal}
                          editId={selected_id}
                        ></InvoiceAddAgency>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllInvoice(data);
              }}
              paginationData={invoiceData}
            />
          </div>
        </div>
      </div>
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close"></div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row modal-content-center">
                    <div className="col-md-12">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Update status to paid ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => paidStatusChange()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedAgencyInvoiceList = connect(mapStateToProps)(AgencyInvoiceList);
export { connectedAgencyInvoiceList as AgencyInvoiceList };
