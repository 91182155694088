import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { agencyActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { agencyService } from '../_services/agency.service';

const AgencyList = props => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [agencyData, setAgencyData] = useState({ rows: [] });
  const [agencyRows, setAgencyRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  const { dispatch } = props;
  // to if user == admin
  let isadmin = false;
  let user = JSON.parse(localStorage.getItem('user'));
  if (user.role == 1 || user.role == '1') {
    isadmin = true;
  }
  //--- debouncing feature start ----
  const isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query.searchKey, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch) getAllAgency(1);
    }
  }, [debouncedSearch]);
  //--- debouncing feature end----
  // effect to load all agency
  useEffect(() => {
    getAllAgency(1);
  }, [query.pageVo.sort_order, query.pageVo.sort]);
  // handler for state query
  const handleChange = event => {
    const { name, value } = event.target;
    //-----------search query--------
    isfirsttime.current = false;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
    }
  };
  /**
   * Get all agencys
   **/
  const getAllAgency = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    dispatch(agencyActions.agencyListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const toggleSwitch = (item, active_status) => {
    const updatedQuery = { id: item.id, active_status };
    setIsLoading(true);

    // Call API to update status
    agencyService.changeAgencyStatus(updatedQuery).then(
      () => {
        getAllAgency();
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        console.error('Error updating agency status. Please try again.');
      },
    );
  };

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = agencyData => {
    let agencyList = [];
    for (
      let j = 0;
      agencyData &&
      agencyData.rows &&
      agencyData.rows.length &&
      j < agencyData.rows.length;
      j = j + 1
    ) {
      agencyList.push(
        <tr key={j}>
          {/* --------id starts from 2000 --------- */}
          <td> {2000 + agencyData.rows[j]._id} </td>
          <td>
            <div className="row d-flex align-items-center ml-2">
              <div className=" border-rad-55 ml-2 ">
                {agencyData &&
                agencyData.rows[j] &&
                agencyData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={apiConstants.imgUrl + agencyData.rows[j].profileimage}
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {agencyData.rows[j].company_name || 'Unknown'}
                </p>
                <div className="row d-flex align-items-center">
                  <div className="ml-1 col pr-3">
                    <img className=" pin-image" src="/assets/img/pin.png" />
                    <span>{agencyData.rows[j].city || 'Unknown'}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].shift_completed
              : 0}
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].available_shift
              : 0}
          </td>
          <td>
            {' '}
            {agencyData.rows[j].shift_completed
              ? agencyData.rows[j].upcoming_shifts
              : 0}
          </td>
          {isadmin && (
            <td>
              {' '}
              {agencyData.rows[j].total_amount
                ? '£' + ' ' + agencyData.rows[j].total_amount.toFixed(2)
                : '£' + ' ' + 0}{' '}
            </td>
          )}
          {isadmin && (
            <td
              onClick={() =>
                toggleSwitch(
                  {
                    id: agencyData.rows[j]._id,
                  },
                  agencyData.rows[j].active_status === 1 ? 0 : 1,
                )
              }
            >
              <div
                className={`switchUser ${
                  agencyData.rows[j].active_status === 0
                    ? 'disable pointer'
                    : 'enable pointer'
                }`}
              >
                <span className="statusText">
                  {agencyData.rows[j].active_status === 1
                    ? 'Enable'
                    : 'Disable'}
                </span>
              </div>
            </td>
          )}

          <td className="">
            <a data-cy="viewagency" href={'/agency/' + agencyData.rows[j]._id}>
              View
            </a>
          </td>
        </tr>,
      );
    }
    setAgencyRows(agencyList);
    setAgencyData(agencyData);
  };

  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  return (
    <div className="EmployerList">
      <div className="row m-0">
        <div className="col-md-12 p-0">
          <div className="card regular-table-with-color  mx-4 px-2">
            {!props.isHomepage && (
              <div className="card-header page-header">
                <div className="">
                  <div className="col-md-8 mb-2 p-0">
                    <div className="page-title">Agency</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-md-3 p-0">
                    <p>
                      <div className="input-outer-div input-design1">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search Here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  {/* FROM DATE PICKER======== */}
                  {isadmin && (
                    <div className="col-md-7 p-0">
                      <div className="row d-flex justify-content-end align-items-center m-0">
                        <div className="col-md-3 p-0">
                          <div
                            data-cy="addagency"
                            className="btn btn-primary-blue float-right"
                            onClick={() => {
                              navigate('/agencyadd/0');
                            }}
                          >
                            <i className="flx-assign-user-icon fa fa-user-plus "></i>
                            Add Agency
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* card-body */}
            <div
              className={
                !props.isHomepage
                  ? 'card-body  table-responsive main-card-body'
                  : '  table-responsive main-card-body '
              }
            >
              {!isLoading && (
                <table className="table flx-custom-table mt-3 flx-table-border">
                  <thead>
                    <tr className="flx-table-header-clr">
                      <th className="border-top1 table-header-ht">Id</th>
                      <th className="">
                        <div className="d-flex align-items-center ">
                          <span>Employer name</span>

                          <span
                            style={{ width: '20px' }}
                            className="d-flex flex-column align-items-center"
                          >
                            {query.pageVo.sort === 'company_name' &&
                            query.pageVo.sort_order !== 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up-black.png"
                                onClick={() => handleSort('company_name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up.png"
                                onClick={() => handleSort('company_name')}
                              />
                            )}
                            {query.pageVo.sort === 'company_name' &&
                            query.pageVo.sort_order === 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down-black.png"
                                onClick={() => handleSort('company_name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down.png"
                                onClick={() => handleSort('company_name')}
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th>Shift completed</th>
                      <th>Total Shift </th>
                      <th>Upcoming Shift </th>
                      {isadmin && <th>Payment</th>}
                      {isadmin && <th>Status</th>}

                      {!isadmin && <th> Subscription status </th>}
                      <th className=" border-top2 " />
                    </tr>
                  </thead>
                  <tbody>{agencyRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
              {agencyRows && agencyRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.isHomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllAgency(data);
                  }}
                  paginationData={agencyData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, agencyData } = state.agency;
  return {
    requestStatus,
    agencyData,
  };
}

const connectedAgencyList = connect(mapStateToProps)(AgencyList);
export { connectedAgencyList as AgencyList };
