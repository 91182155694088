import React, { useState, useEffect } from 'react';
import { notificationActions } from '../_actions';
import { connect } from 'react-redux';

const EmailNotificationSettings = props => {
  const [selectedNotifications, setSelectedNotifications] = useState([
    props.profile.notification_settings || [],
  ]);

  useEffect(() => {
    if (Array.isArray(props.profile.notification_settings)) {
      setSelectedNotifications(props.profile.notification_settings);
    }
  }, [props.profile.notification_settings]);

  const handleCheckboxChange = event => {
    const value = parseInt(event.target.value, 10); // Parse the checkbox value as an integer
    if (event.target.checked) {
      setSelectedNotifications(prev => [...prev, value]); // Add the value to the state
    } else {
      setSelectedNotifications(
        prev => prev.filter(item => item !== value), // Remove the value from the state
      );
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { dispatch } = props;
    const payload = {
      user_id: props.id,
      notification_settings: selectedNotifications,
    };

    dispatch(notificationActions.sendEmailNotificationSettings(payload)).then(
      () => {
        // setIsLoading(false);
        window.location.reload();
      },
      err => {
        console.error(err);
      },
    );
  };
  return (
    <div className="notification-settings">
      <h2 className="font-weight-bold">Notification Settings</h2>
      <form>
        <div className="notification-body">
          {props.isStaff && (
            <div>
              <div className="notification-item ">
                <label>
                  <input
                    type="checkbox"
                    value={1}
                    onChange={handleCheckboxChange}
                    checked={selectedNotifications.includes(1)}
                  />
                  Notify me about all job post
                </label>
              </div>
              <div className="notification-item ">
                <label>
                  <input
                    type="checkbox"
                    value={2}
                    onChange={handleCheckboxChange}
                    checked={selectedNotifications.includes(2)}
                  />
                  Notify me about only allocated employer
                </label>
              </div>
            </div>
          )}
          {props.isAgency && (
            <>
              <div className="notification-item ">
                <label>
                  <input
                    type="checkbox"
                    value={3}
                    onChange={handleCheckboxChange}
                    checked={selectedNotifications.includes(3)}
                  />
                  Notify me about new vacancies
                </label>
              </div>
              <div className="notification-item ">
                <label>
                  <input
                    type="checkbox"
                    value={4}
                    onChange={handleCheckboxChange}
                    checked={selectedNotifications.includes(4)}
                  />
                  Notify only for subscribed employer
                </label>
              </div>
            </>
          )}
          {props.isEmployer && (
            <div className="notification-item ">
              <label>
                <input
                  type="checkbox"
                  value={6}
                  onChange={handleCheckboxChange}
                  checked={selectedNotifications.includes(6)}
                />
                Notify me when job applied
              </label>
            </div>
          )}
          {(props.isAgency || props.isEmployer) && (
            <div className="notification-item ">
              <label>
                <input
                  type="checkbox"
                  value={5}
                  onChange={handleCheckboxChange}
                  checked={selectedNotifications.includes(5)}
                />
                Notify me when a shift is canceled
              </label>
            </div>
          )}
          <button
            type="submit"
            className="flx-notification-save-button "
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedEmailNotificationSettings = connect(mapStateToProps)(
  EmailNotificationSettings,
);
export { connectedEmailNotificationSettings as EmailNotificationSettings };
