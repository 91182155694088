import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { blockcandidateService } from '../_services';
export const blockcandidateActions = {
  blockcandidateAdd,
  blockcandidateList,
  // blockcandidateDelete,
  unblockCandidate,
};

function blockcandidateAdd(blockcandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BLOCKCANDIDATE_REQUEST,
        blockcandidateObj,
      });

      blockcandidateService.blockcandidateAdd(blockcandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_SUCCESS,
            data,
          });

          if (data.error_code == 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function unblockCandidate(blockcandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BLOCKCANDIDATE_REQUEST,
        blockcandidateObj,
      });

      blockcandidateService.unblockCandidate(blockcandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function blockcandidateList(blockcandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BLOCKCANDIDATE_REQUEST,
        blockcandidateObj,
      });

      blockcandidateService.blockcandidateList(blockcandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BLOCKCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

// function blockcandidateDelete(blockcandidateObj) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       dispatch({
//         type: reducerConstants.BLOCKCANDIDATE_REQUEST,
//         blockcandidateObj,
//       });

//       blockcandidateService.blockcandidateDelete(blockcandidateObj).then(
//         data => {
//           dispatch({
//             type: reducerConstants.BLOCKCANDIDATE_SUCCESS,
//             data,
//           });
//           resolve(data);
//         },
//         error => {
//           dispatch({
//             type: reducerConstants.BLOCKCANDIDATE_FAILURE,
//             error,
//           });
//           reject(error);
//           dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }
