import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PriceActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let PriceRows = [];
const PriceList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [PriceData, setPriceData] = useState({ rows: [] });
  const [PriceRows, setPriceRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  // effect to load price list
  useEffect(() => {
    getAllPrice(1);
  }, [query]);
  // call for get all price
  const getAllPrice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(PriceActions.PriceList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters and a call for price list
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, [name]: value }));
      getAllPrice();
    }
  };
  // list and pagination for price data
  const setListAndPagination = PriceData => {
    let PriceList = [];
    for (
      let j = 0;
      PriceData && PriceData.rows && j < PriceData.rows.length;
      j = j + 1
    ) {
      PriceList.push(
        <tr key={j}>
          <td>{(PriceData.page - 1) * PriceData.limit + j + 1}</td>
          <td>{PriceData.rows[j].name} </td>
          <td className="eventCell  eventView">
            <a
              style={{ textDecoration: 'none', color: '#3388EB' }}
              href={'/Price/' + PriceData.rows[j]._id}
            >
              view
            </a>
            <a
              style={{
                marginLeft: '25px',
                color: '#3388EB',
                textDecoration: 'none ',
              }}
              href={'/Priceadd/' + PriceData.rows[j]._id}
            >
              Edit
            </a>
          </td>
        </tr>,
      );
    }
    setPriceRows(PriceList);
    setPriceData(PriceData);
  };
  return (
    <div className="PriceList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <div className="page-title">Price List</div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 ">
                <p>
                  <div className="input-outer-div">
                    <input
                      className="search-input-div"
                      value={query.searchKey}
                      onChange={handleChange}
                      type="text"
                      name="searchKey"
                      aria-label="Search"
                      placeholder="Search here..."
                    />
                    <a className="search_bar">
                      <i className="fa fa-search in_bar" aria-hidden="true"></i>
                    </a>
                  </div>
                </p>
              </div>
              {/* FROM DATE PICKER======== */}
              <div className="col-md-6">
                <div className="row d-flex justify-content-end align-items-center">
                  <div className="col-md-3">
                    <a
                      className="btn btn-primary-blue float-right card-body2"
                      href="/Priceadd/0"
                    >
                      {/* <i className="fa fa-envelope" /> */}
                      <p className="button-text"> Add Price</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: '0px' }}>#</th>
                    <th>Name</th>
                    <th
                      className="eventCell  eventView"
                      style={{ width: '250px' }}
                    />
                  </tr>
                </thead>
                <tbody>{PriceRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>}
            {PriceData &&
              PriceData.rows &&
              PriceData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllPrice(data);
              }}
              paginationData={PriceData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, PriceData } = state.price;
  return {
    requestStatus,
    PriceData,
  };
}

const connectedPriceList = connect(mapStateToProps)(PriceList);
export { connectedPriceList as PriceList };
