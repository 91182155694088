import React, { useEffect, useState } from 'react';
import {
  alertConstants,
  apiConstants,
  messageConstant,
} from '../../_constants';
import { userService } from '../../_services/user.service';

const CompanyInfo = props => {
  const [error, setError] = useState({
    common: '',
    phone: '',
    company_name: '',
    postcode: '',
    address: '',
  });
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [companyInfo, setCompanyInfo] = useState({
    company_name:
      // props?.profile['Employers.company_name'] ||
      // props?.profile['Agencies.company_name'],
      props?.profile.name,

    postcode:
      props?.profile['Employers.postcode'] ||
      props?.profile['Agencies.postcode'],
    address:
      props?.profile['Employers.address'] || props?.profile['Agencies.address'],
    contact_person: props?.profile['Employers.contact_person'],
    email: props?.profile?.email,
    // contact_number:
    //   props?.profile['Employers.contact_number'] ||
    //   props?.profile['Agencies.contact_number'],
    contact_number: props?.profile.phone,
    website:
      props?.profile['Employers.website'] || props?.profile['Agencies.website'],
    isBillingAddress:
      props?.profile['Employers.isBillingAddress'] == null
        ? false
        : props?.profile['Employers.isBillingAddress'] == 0
        ? false
        : true,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (companyInfo?.postcode || props?.profile?.postcode) {
      loadaddress();
    }
  }, [companyInfo?.postcode || props?.profile?.postcode]);

  const handleChange = event => {
    setError({ common: '' });
    const { name, value } = event.target;
    if (name === 'company_name' || name === 'contact_person') {
      if (value.length > 64) {
        return; // Prevent updating the state if the name exceeds 10 characters
      }
    }
    if (name === 'contact_number') {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (numericValue.length > 11) {
        return;
      }
      if (numericValue.charAt(0) === '0') {
        if (numericValue.length !== 11) {
          setError(prevState => ({
            ...prevState,
            phone: messageConstant.VALID_PHONE,
          }));
        } else {
          setError(prevState => ({ ...prevState, phone: '' }));
        }
      } else {
        setError(prevState => ({
          ...prevState,
          phone: messageConstant.VALID_PHONE,
        }));
      }
      setCompanyInfo({ ...companyInfo, contact_number: numericValue });
    }
    if (name === 'postcode') {
      if (value.trim() === '') {
        setCompanyInfo({
          ...companyInfo,
          postcode: '',
          address: '',
        });
        setPostcodeObject({});
        return;
      } else if (value.length <= 10) {
        if (!valid_postcode(value)) {
          setError({ postcode: 'InValid Postcode' });
        }
        setPostcodeObject({});
        setCompanyInfo({
          ...companyInfo,
          [name]: value,
        });
      }
    } else {
      setCompanyInfo({
        ...companyInfo,
        [name]: value,
      });
    }
  };

  const handleCheckboxChange = event => {
    const { checked } = event.target;

    // Update the isBillingAddress state
    setCompanyInfo(prevInfo => ({
      ...prevInfo,
      isBillingAddress: checked,
    }));
  };

  const handleKeyPress = event => {
    const charCode = event.charCode ? event.charCode : event.keyCode;
    // Only allow digits (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    var regex =
      /^[A-Za-z]{1,2}[0-9RCHNQrchnq][0-9A-Za-z]?\s?[0-9][ABD-HJLNP-UW-Zabc-hjlnp-z]{2}$/;
    return regex.test(postcode);
  };

  const loadaddress = async () => {
    const res = (companyInfo?.postcode || props?.profile?.postcode)?.replace(
      / /g,
      '',
    );

    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let addresses_rows = [];
        let regex = /\s+,/g;
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
      }
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setError({ ...error, common: '', phone: '' });
    setLoading(true);
    let validationFailed = false;
    if (!companyInfo.company_name) {
      setError(prevState => ({
        ...prevState,
        company_name: 'Name is required',
      }));
      validationFailed = true;
    }

    if (!companyInfo.postcode && !(props.isAdmin || props.isStaff)) {
      setError(prevState => ({
        ...prevState,
        postcode: 'Postcode is required',
      }));
      validationFailed = true;
    }
    if (
      companyInfo.postcode &&
      !companyInfo.address &&
      !(props.isAdmin || props.isStaff)
    ) {
      setError(prevState => ({ ...prevState, address: 'Address is required' }));
      validationFailed = true;
    }
    if (!companyInfo.contact_number) {
      setError(prevState => ({ ...prevState, phone: 'Phone is required' }));
      validationFailed = true;
    }
    if (
      companyInfo?.contact_number &&
      companyInfo?.contact_number?.charAt(0) !== '0'
    ) {
      setError(prevState => ({
        ...prevState,
        phone: messageConstant.VALID_PHONE,
      }));
      validationFailed = true;
    }
    const hasErrors = Object.values(error).some(err => err !== '');
    if (validationFailed || hasErrors) {
      setLoading(false);
      return;
    }
    companyInfo._id = props?.id;

    if (props?.isAgency) {
      companyInfo.role = 5;
    } else if (props?.isStaff) {
      companyInfo.role = 6;
    } else if (props?.isAdmin) {
      companyInfo.role = 1;
    } else {
      companyInfo.role = 4;
    }
    companyInfo.isBillingAddress = companyInfo.isBillingAddress == true ? 1 : 0;
    userService.userAdd(companyInfo).then(
      res => {
        setLoading(false);
        let User = JSON.parse(localStorage.getItem('user'));
        User.name = res?.data?.name;
        localStorage.setItem('user', JSON.stringify(User));
        window.location.reload();
      },
      err => {
        console.error(err);
        setError({
          common:
            err && err.message ? err.message : alertConstants.SERVER_ERROR,
        });
        setLoading(false);
      },
    );
  };

  return (
    <div className="col-md-10 flx-emp-profile-main-form">
      {props.isAdmin || props.isAgency || props.isStaff ? (
        <h2 className="flx-company-info-form-heading">Profile Settings</h2>
      ) : (
        <h2 className="flx-company-info-form-heading">Company Info</h2>
      )}

      <form>
        <div className="mb-3">
          <label
            htmlFor="companyName"
            className="form-label company-info-labels"
          >
            Name :
          </label>
          <input
            // disabled
            type="text"
            className="form-control selectDesign"
            id="companyName"
            name="company_name"
            value={companyInfo?.company_name}
            onChange={handleChange}
            placeholder="Name"
          />
          {error.company_name && (
            <div className="text-danger">{error.company_name}</div>
          )}
        </div>

        {!props.isStaff && !props.isAdmin && (
          <div className="mb-3">
            <label
              htmlFor="postcode"
              className="form-label company-info-labels"
            >
              Postcode :
            </label>
            <input
              type="text"
              className="form-control selectDesign"
              id="postcode"
              name="postcode"
              onChange={handleChange}
              value={companyInfo?.postcode}
              placeholder="Enter Postcode"
            />
            {error.postcode && (
              <div className="text-danger">{error.postcode}</div>
            )}
          </div>
        )}
        {!props.isStaff && !props.isAdmin && (
          <div className="mb-3">
            <div className="col-md-12 px-0">
              <div className="form-group">
                <label
                  htmlFor="postcode"
                  className="form-label company-info-labels"
                >
                  Select Address :
                </label>
                <select
                  className="form-control selectDesign"
                  name="address"
                  placeholder="Select Address"
                  value={companyInfo?.address}
                  onChange={handleChange}
                >
                  <option value={''} hidden="true">
                    Select Address
                  </option>
                  {companyInfo?.postcode ? PostcodeObject.addresses : ''}
                </select>
              </div>
              {error.address && (
                <div className="text-danger">{error.address}</div>
              )}
            </div>
          </div>
        )}
        {!props.isAgency && !props.isStaff && !props.isAdmin && (
          <div className="mb-3 ml-4">
            <input
              className="form-check-input flx-comp-settings-form-control "
              type="checkbox"
              value=""
              checked={companyInfo.isBillingAddress}
              onChange={handleCheckboxChange}
              id="flexCheckDefault"
            />

            <label
              className="form-check-label company-info-labels"
              htmlFor="flexCheckDefault"
            >
              This is my billing address
            </label>
          </div>
        )}
        {!props.isAgency && !props.isStaff && !props.isAdmin && (
          <div className="mb-3">
            <label htmlFor="website" className="form-label company-info-labels">
              Contact Person :
            </label>
            <input
              type="text"
              className="form-control selectDesign"
              id="website"
              name="contact_person"
              value={companyInfo?.contact_person}
              onChange={handleChange}
              placeholder="Full Name"
            />
          </div>
        )}

        <label htmlFor="website" className="form-label company-info-labels">
          Phone :
        </label>
        <div className=" mb-3">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="flx-basic-addon1">
                +44
              </span>
            </div>
            <input
              type="text"
              className="form-control selectDesign"
              placeholder="000"
              name="contact_number"
              value={companyInfo?.contact_number}
              aria-label="Phone number"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              aria-describedby="basic-addon1"
            />
          </div>
          {error.phone && <div className="text-danger">{error.phone}</div>}
        </div>
        {!props.isStaff && !props.isAdmin && (
          <div className="mb-3">
            <label htmlFor="website" className="form-label company-info-labels">
              Website :
            </label>
            <input
              type="text"
              className="form-control selectDesign"
              name="website"
              value={companyInfo?.website}
              onChange={handleChange}
              id="website"
              placeholder="www.company.com"
            />
          </div>
        )}
        <button
          style={{
            background: '#1AC8FF',
            borderRadius: '6px',
            width: '120px',
          }}
          onClick={handleSubmit}
          type="submit"
          className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
          disabled={loading}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: 1.25,
            }}
          >
            {loading ? 'Saving...' : 'Save'}
          </span>
        </button>
        {error.common && <div className="mt-3 text-danger">{error.common}</div>}
      </form>
    </div>
  );
};

export default CompanyInfo;
