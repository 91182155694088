import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { agencyActions } from '../_actions';
import { Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
let employer_id;

const DashbordAgencyReport = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [agencyData, setAgencyData] = useState({ rows: [] });
  const [agencyRows, setAgencyRows] = useState([]);
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [showallshifts, setshowalshifts] = useState(false);
  const [agency_id, setagencyid] = useState();
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  // effect for call agency and having dependency query
  useEffect(() => {
    if (props && props.ishomepage) {
      query.pageVo.noOfItems = 3;
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.employer_id) {
        employer_id = user.employer_id;
      }
    }
    getAllAgency(1);
  }, [query.from_date, query.end_date, query.agency_id]);
  // set agency to query
  const setAgency = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  /**
   * Get all agencys
   **/
  const getAllAgency = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    dispatch(agencyActions.dashbordagencyreport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *  set pagination and list rows
   */
  const setListAndPagination = agencyData => {
    let agencyList = [];
    for (
      let j = 0;
      agencyData &&
      agencyData.rows &&
      agencyData.rows.length &&
      j < agencyData.rows.length;
      j = j + 1
    ) {
      agencyList.push(
        <tr key={j} className="rowcenter">
          <td>
            <div className=" d-flex align-items-center ml-2 txt-start">
              <div className=" border-rad-55 ml-2 ">
                {agencyData &&
                agencyData.rows[j] &&
                agencyData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={apiConstants.imgUrl + agencyData.rows[j].profileimage}
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {agencyData.rows[j].company_name}
                </p>
                <div className=" d-flex align-items-center">
                  <div className="location-text">
                    <span>{agencyData.rows[j].email}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {agencyData.rows[j].no_of_shifts
              ? agencyData.rows[j].no_of_shifts
              : 0}
          </td>
          <td>
            {' '}
            £{agencyData.rows[j].avg_price ? agencyData.rows[j].avg_price : 0}
          </td>
          <td style={{ color: '#ec5832' }}>
            {' '}
            {agencyData.rows[j].spend ? agencyData.rows[j].spend : 0}
          </td>
          {!props.ishomepage && (
            <td>
              <div
                className="left-right-arrow"
                onClick={() => {
                  setagencyid(agencyData.rows[j]._id), setshowalshifts(true);
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
            </td>
          )}
        </tr>,
      );
    }
    setAgencyRows(agencyList);
    setAgencyData(agencyData);
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '45px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      background: '#F4F4F4',
      borderRadius: '12px',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
  };
  return (
    <div className="EmployerList m-2">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color  ">
            {!props.ishomepage && (
              <div className="row">
                <div className="card-header page-header pl-3">
                  <div className="page-title" style={{ fontSize: 20 }}>
                    {' '}
                    Agency Report
                  </div>
                </div>
              </div>
            )}
            {!props.ishomepage && (
              <div className="  filter-row">
                <div className="flex-item3">
                  <div>
                    {' '}
                    <label>From date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-group-50px input-design1"
                      style={{
                        border: 'none',
                        display: 'block',
                        width: '100%',
                        height: '40px',
                        padding: '0.375rem 0.75rem',
                        flexShrink: '0',
                        fontSize: '14px',
                        fontWeight: '500',
                        borderRadius: '6px',
                      }}
                      name="from_date"
                      placeholder="From"
                      value={fromdate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.from_date = value;
                        setQuery(newquery);
                        setfromdate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>To date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-group-50px input-design1"
                      style={{
                        border: 'none',
                        display: 'block',
                        width: '100%',
                        height: '40px',
                        padding: '0.375rem 0.75rem',
                        flexShrink: '0',
                        fontSize: '14px',
                        fontWeight: '500',
                        borderRadius: '6px',
                      }}
                      name="end_date"
                      placeholder="From"
                      value={todate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.end_date = value;
                        setQuery(newquery);
                        settodate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>Agency</label>
                  </div>
                  <AsyncSelect
                    styles={customStyles}
                    name="agency_id"
                    onChange={setAgency}
                    placeholder={'select agency'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getAgencylist}
                  />
                </div>
              </div>
            )}
            <div
              className={
                !props.isHomepage
                  ? '  table-responsive main-card-body '
                  : ' table-responsive main-card-body '
              }
            >
              <div className="d-flex justify-content-between align-items-center">
                {props && props.ishomepage && (
                  <>
                    <div>
                      {' '}
                      <label style={{ fontSize: 20 }}>AGENCY REPORT</label>
                    </div>
                    <div>
                      <Link to="/dashbordagencyreport">
                        <button className="blackbutton">View All</button>
                      </Link>
                    </div>
                  </>
                )}{' '}
              </div>
              {!isLoading && (
                <div className=" page-header">
                  <table className="table  mt-2 flx-table-border">
                    <thead>
                      <tr className="flx-table-header-clr">
                        <th className=" border-top6-left table-header-ht">
                          Name
                        </th>
                        <th>No of Shift</th>
                        <th>Average Price </th>
                        <th className={props.ishomepage && 'border-top6'}>
                          Spend{' '}
                        </th>
                        {!props.ishomepage && <th className="border-top6"></th>}
                      </tr>
                    </thead>
                    <tbody>{agencyRows}</tbody>
                  </table>
                </div>
              )}
              {/* {!!isLoading && <LoadingIcon loadingText={"Loading..."}/>} */}
              {agencyRows && agencyRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.ishomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllAgency(data);
                  }}
                  paginationData={agencyData}
                />
              </div>
            )}
          </div>
        </div>
        {showallshifts && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close-btn p-4">
                      <a onClick={() => setshowalshifts(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <AllShiftsList
                    agency_id={agency_id}
                    from_date={fromdate}
                    to_date={todate}
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getAgencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        select: ['_id', 'company_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, agencyData } = state.agency;
  return {
    requestStatus,
    agencyData,
  };
}

const connectedDashbordAgencyReport =
  connect(mapStateToProps)(DashbordAgencyReport);
export { connectedDashbordAgencyReport as DashbordAgencyReport };
