import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { candidateActions } from '../_actions';
import { LoadingIcon, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';

//TODO:Split the component into smaller components

const CandidateList = props => {
  let navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [TrashChecked, setTrashChecked] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [candidateRows, setCandidateRows] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [candidateid, setcandidateid] = useState(0);
  const [status, setstatus] = useState();
  const [AllSelected, setAllSelected] = useState(false);
  //---csv states -----
  const [shiftidsCount, setshiftidsCount] = useState(0);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [selected_payout_data, setselected_payout_data] = useState([]);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: parseInt(new URLSearchParams(location.search).get('page')) || 1,
      noOfItems: 8,
    },
    searchKey: '',
    // isverified: '',
    city_postcode: '',
    status: 1,
  });
  //--- debounce feature start ---
  const debouncedSearch = useDebounce(query.searchKey, 500);
  const debouncedSearchCityPostcode = useDebounce(query?.city_postcode, 500);
  const debouncedSearchIsverified = useDebounce(query?.isverified, 500);
  const debouncedSearchBranch = useDebounce(query?.branch, 500);
  const debouncedSearchService_type = useDebounce(query?.service_type, 500);
  const debouncedSearchCategory = useDebounce(query?.category, 500);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [franchise_staff, setFranchise_staff] = useState(false);
  useEffect(() => {
    if (
      debouncedSearch ||
      debouncedSearchCityPostcode ||
      debouncedSearchIsverified ||
      debouncedSearchBranch ||
      debouncedSearchService_type ||
      debouncedSearchCategory
    ) {
      getAllCandidate(1, query);
    }
  }, [query]);
  //--- debounce feature end ---
  // effect for load get candidates while change in selected shiftids
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        setIsAdmin(true);
      } else if (user.role == 6 && user.franchise_staff) {
        setFranchise_staff(true);
      }
    }
    getAllCandidate(query.pageVo.pageNo);
  }, [selected_shiftIds.length, IsAdmin]);
  // filter for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // const handleSort = () => {
  //   const sortedData = [...candidateData.rows].sort((a, b) => {
  //     if (isAscending) {
  //       return a.name.localeCompare(b.name);
  //     } else {
  //       return b.name.localeCompare(a.name);
  //     }
  //   });
  //   candidateData.rows = sortedData
  //   setListAndPagination(candidateData)
  //   setCandidateData(candidateData);
  //   setIsAscending(!isAscending);
  // };

  // const handleDateSort = () => {
  //   const sortedData = [...candidateData.rows].sort((a, b) => {
  //     const dateA = moment(a.createdAt, "DD-MMM-YYYY")
  //     const dateB = moment(b.createdAt, "DD-MMM-YYYY")

  //     if (isDateAscending) {
  //       return dateA - dateB;
  //     } else {
  //       return dateB - dateA;
  //     }
  //   });

  //   const updatedCandidateData = {
  //     ...candidateData,
  //     rows: sortedData,
  //   };

  //   setCandidateData(updatedCandidateData);
  //   setIsDateAscending(!isDateAscending);
  // };

  const getCategorylist = inputvalue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcategorylist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({ status: 1, searchKey: inputvalue }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            // add an option in select
            if (data && data.rows && data.rows.length > 0) {
              let AllElement = { _id: 0, name: 'ALL' };
              let newArray = [AllElement, ...data.rows];
              resolve(
                newArray.map(({ _id, name }) => ({ value: _id, label: name })),
              );
            } else {
              resolve([]);
            }
          }),
        )
        .catch(reject);
    });

  // check box selected
  const check_ifSelected = _id => {
    let found = false;
    for (let s = 0; s < selected_shiftIds.length; s++) {
      if (selected_shiftIds[s] == _id) {
        found = true;
      }
    }
    return found;
  };
  // all candiate select check box handle funciton
  const allcheckedhandle = value => {
    let new_selectedShiftIds = [];
    let new_selected_payout_data = [];
    if (value) {
      let newCandi = candidateData.rows;
      for (let c = 0; c < newCandi.length; c++) {
        new_selectedShiftIds.push(newCandi[c]._id);
        let csv_file_data = {
          Name: newCandi[c].name,
          Surname: newCandi[c].last_name ? newCandi[c].last_name : '',
          'Employee NINO': newCandi[c].ni_number,
          Gender: newCandi[c].gender,
          'Date of Birth': newCandi[c].dob,
          'Address Line 1': newCandi[c].address,
          'Address Line 2': newCandi[c].address_line2,
          Postcode: newCandi[c].postcode,
          'Email Address': newCandi[c].email,
          'Phone Number': newCandi[c].contact_number,
          'Hourly Pay Rate': (newCandi[c].total_paid
            ? newCandi[c].total_paid / newCandi[c].total_hours
            : newCandi[c].day_price
          ).toFixed(2),
        };
        new_selected_payout_data.push(csv_file_data);
      }
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    setselected_payout_data(new_selected_payout_data);
    setAllSelected(value);
  };
  // handle select while selcting candidate from the list view
  const handleSelect = (_name, shift_id, payoutdata) => {
    let new_selectedShiftIds = selected_shiftIds;
    let new_selected_payout_data = selected_payout_data;
    let found = false;
    for (let p = 0; p < new_selectedShiftIds.length; p++) {
      if (new_selectedShiftIds[p] == shift_id) {
        new_selectedShiftIds.splice(p, 1);
        found = true;
      }
    }
    let csv_file_data = {
      id: payoutdata._id,
      Name: payoutdata.name,
      Surname: payoutdata.last_name ? payoutdata.last_name : '',
      'Employee NINO': payoutdata.ni_number,
      Gender: payoutdata.gender,
      'Date of Birth': payoutdata.dob,
      'Address Line 1': payoutdata.address,
      'Address Line 2': payoutdata.address_line2,
      Postcode: payoutdata.postcode,
      'Email Address': payoutdata.email,
      'Phone Number': payoutdata.contact_number,
      'Hourly Pay Rate': (payoutdata.total_paid
        ? payoutdata.total_paid / payoutdata.total_hours
        : payoutdata.day_price
      ).toFixed(2),
    };
    let data_found = false;
    for (let s = 0; s < new_selected_payout_data.length; s++) {
      if (new_selected_payout_data[s].id == payoutdata._id) {
        new_selected_payout_data.splice(s, 1);
        data_found = true;
      }
    }
    if (!found) {
      new_selectedShiftIds.push(shift_id);
    }
    if (!data_found) {
      new_selected_payout_data.push(csv_file_data);
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    setselected_payout_data(new_selected_payout_data);
  };
  // delete candidate confirmation modal view
  const deletecandidatemodal = _id => {
    setdeleteconfirmationmodal(true);
    setcandidateid(_id);
  };
  // delete candidate function
  const deletecandidate = () => {
    const { dispatch } = props;
    if (isDeleteLoading) {
      return;
    }
    setIsDeleteLoading(true);
    dispatch(
      candidateActions.candidateDelete({ _id: candidateid, status: 0 }),
    ).then(
      () => {
        setdeleteconfirmationmodal(false);
        setIsDeleteLoading(false);
        getAllCandidate(query.pageVo.pageNo);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };

  const changePage = newPage => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('page', newPage);

    navigate(newUrl.pathname + newUrl.search, { replace: true });
  };
  // get all candidates list
  const getAllCandidate = (pageNo, queryTemp) => {
    if (isLoading) return;
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    changePage(pageNo);
    if (!props.isHomepage) {
      setIsLoading(true);
    }
    const { dispatch } = props;
    if (props.isHomepage) {
      queryNew.isverified = 0;
    }
    if (queryNew.branch == '') {
      delete queryNew.branch;
    }
    if (queryNew?.city_postcode == '') {
      delete queryNew.city_postcode;
    }
    dispatch(candidateActions.candidateListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data.candidate.data);
        setCandidateData(res.data.candidate.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // onclick trash button
  const trash_checked = value => {
    setTrashChecked(value);
    if (value) {
      setQuery({ ...query, status: 0 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };

  const handleChange = event => {
    let { name, value } = event.target;

    setQuery(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  // handle change while category select
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
    if (debouncedSearchCategory) {
      getAllCandidate(1, { ...query, category: selectedOption.value });
    }
  };
  // list and pagination data for candidate data
  const setListAndPagination = candidateData => {
    let candidateList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      candidateList.push(
        <tr key={j}>
          {!props.isHomepage && (
            <td>
              <div
                onClick={() =>
                  handleSelect(
                    'shift_checked',
                    candidateData.rows[j]._id,
                    candidateData.rows[j],
                  )
                }
              >
                {check_ifSelected(candidateData.rows[j]._id) ? (
                  <img className="check_style" src="/assets/img/check1.png" />
                ) : (
                  <img
                    className="check_style"
                    src="/assets/img/un_check1.png"
                  />
                )}
              </div>
            </td>
          )}
          <td>{2000 + candidateData.rows[j]._id}</td>
          <td className="username">
            <div className="d-flex align-items-center">
              {candidateData.rows[j].profileimage ? (
                <img
                  className="round-emp-image"
                  src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
                />
              ) : (
                <img
                  className="round-emp-image"
                  src="/assets/img/un-avatar.png"
                />
              )}
              <p
                className="table-td-name"
                data-cy="viewprofile"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCandidateId(candidateData.rows[j]._id);
                  setstatus(candidateData.rows[j].status);
                  setshowCandidateprofileModal(true);
                }}
              >
                {candidateData.rows[j].name}{' '}
                {candidateData.rows[j].last_name || ''}
              </p>
            </div>
          </td>
          <td>
            {candidateData.rows[j]['Category.name']
              ? candidateData.rows[j]['Category.name']
              : 'Not Provided'}
          </td>
          <td className="text-capitalize">
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${candidateData.rows[j].lat},${candidateData.rows[j].long}`}
            >
              {candidateData.rows[j].city}
            </a>
            <p className="text-uppercase">
              {candidateData.rows[j].postcode || 'Not Provided'}
            </p>
          </td>
          <td>
            {candidateData.rows[j].service_type
              ? candidateData.rows[j].service_type
              : 'Not Provided'}
          </td>
          <td>
            {moment(candidateData.rows[j].createdAt).format('DD-MMM-YYYY')}
          </td>
          {!props.isHomepage && (
            <td>{candidateData.rows[j].total_hours || '0 hrs'}</td>
          )}
          {!props.isHomepage && (
            <td>{`£ ${candidateData.rows[j].total_paid || 0}`}</td>
          )}
          {!TrashChecked && (
            <td className="eventCell eventView">
              <Link to={`/candidate/${candidateData.rows[j]._id}`}>View</Link>
            </td>
          )}
          {!TrashChecked && !props.isHomepage && (
            <td
              style={{
                color:
                  candidateData.rows[j].isverified === 1
                    ? '#008907'
                    : candidateData.rows[j].isverified === 0
                    ? 'rgb(205 27 50)'
                    : candidateData.rows[j].isverified === 2
                    ? 'rgb(255 160 2)'
                    : '#b02739',
              }}
            >
              {candidateData.rows[j].isverified === 1
                ? 'Verified'
                : candidateData.rows[j].isverified === 0
                ? 'Not verified'
                : candidateData.rows[j].isverified === 2
                ? 'Ready for Compliance review'
                : 'Rejected'}
            </td>
          )}
          {/* {!TrashChecked && IsAdmin && !props.isHomepage && (
            <td
              className="eventCell event Edit"
              onClick={() => deletecandidatemodal(candidateData.rows[j]._id)}
            >
              <img src="/assets/img/delete.png" />
            </td>
          )} */}
          {!TrashChecked && !props.isHomepage && !franchise_staff && (
            <td
              className="eventCell event Edit"
              onClick={() => deletecandidatemodal(candidateData.rows[j]._id)}
            >
              <img src="/assets/img/delete.png" />
            </td>
          )}
        </tr>,
      );
    }
    setCandidateRows(candidateList);
  };
  // style for select box
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      // border: '1px solid #ccc',
      border: 'none',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '150px',
      lineHeight: '25px',
      borderRadius: '12px',
      background: '#f4f4f4',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };

  return (
    <div className=" ml-2">
      <div className="row">
        <div className="col-md-12 col-sm-12 p-0">
          <div className="card regular-table-with-color mx-4 px-2">
            {!props.isHomepage && (
              <div className="card-header page-header pb-3">
                <div className="">
                  <div className="col-md-8 pl-0 pb-2">
                    <div className="page-title">Candidate</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="row d-flex align-items-center ml-0">
                    <div className="col-md-2 p-0  pt-1 custom-dashboard-div">
                      <select
                        className="select p-0 pl-2  col-md-11 selectDesign input-design1"
                        style={{ borderRadius: '12px' }}
                        name="visatype"
                        value={query.visatype}
                        onChange={handleChange}
                      >
                        <option value=""> All</option>
                        <option value={'Student'}>Student</option>
                        <option value={'Student Dependent'}>
                          Student Dependent
                        </option>
                        <option value={'Citizen'}>Citizen</option>
                        <option value={'Citizen Dependent'}>
                          Citizen Dependent
                        </option>
                        <option value={' Graduate Visa'}> Graduate Visa</option>
                        <option value={'Graduate Dependent'}>
                          {' '}
                          Graduate Dependent Visa
                        </option>
                        Graduate Dependent
                        <option value={'ILR'}>
                          Indefinite Leave to Remain
                        </option>
                        <option value={'No Valid Work Permit'}>
                          No Valid Work Permit
                        </option>
                        <option value={'PR'}>PR</option>
                        <option value={'Skilled Worker'}>Skilled Worker</option>
                        <option value={'Dependent'}>
                          Skilled Worker Dependent
                        </option>
                      </select>
                    </div>

                    {/* //------------filter section ---------------- */}
                    <div className="col-md-2 custom-dashboard-div pl-0">
                      <select
                        className="select col-md-12 selectdesing2 mt-1 input-design-candidate"
                        name="isverified"
                        value={query.isverified}
                        onChange={handleChange}
                      >
                        <option value=""> ALL</option>
                        <option value={1}> Verified</option>
                        <option value={0}> Not Verified</option>
                        <option value={2}> Ready for compliance review</option>
                        <option value={-1}> Rejected</option>
                      </select>
                    </div>

                    <div className="col-md-2 custom-dashboard-div pl-0">
                      <select
                        className="select col-md-12 selectdesing2 mt-1 input-design-candidate"
                        name="branch"
                        value={query.branch}
                        onChange={handleChange}
                      >
                        <option value="77" hidden={true}>
                          {' '}
                          Select Branch
                        </option>
                        <option value=""> All</option>
                        <option value={'Basildon'}>Basildon</option>
                        <option value={'Wales'}>Wales</option>
                      </select>
                    </div>

                    <div className="col-md-2 custom-dashboard-div pl-0">
                      <select
                        className="select col-md-12 selectdesing2 mt-1 input-design-candidate"
                        name="service_type"
                        value={query.service_type}
                        onChange={handleChange}
                        placeholder={'select service sector'}
                      >
                        <option hidden={true}> select service sector</option>
                        <option value=""> ALL</option>
                        <option value="Hospitality"> Hospitality</option>
                        <option value="Health Care"> Health Care</option>
                      </select>
                    </div>

                    <div className="col-md-2 custom-dashboard-div pl-0">
                      <div className="mt-1" style={{ marginRight: '20px' }}>
                        <AsyncSelect
                          styles={customSelectStyles}
                          name="category"
                          onChange={category_change}
                          placeholder="select category"
                          cacheOptions
                          defaultOptions
                          noOptionsMessage={() => 'No results found'}
                          loadOptions={getCategorylist}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="row m-0 custom-dashboard-div"
                    style={{ justifyContent: 'end' }}
                  >
                    <div
                      style={{ background: '#1AC8FF', borderRadius: '6px' }}
                      className=" btn-primary-blue-nowidth "
                      data-cy="addcandidate"
                      onClick={() => {
                        navigate('/candidateadd/0');
                      }}
                    >
                      <i className="flx-assign-user-icon fa fa-user-plus "></i>
                      <span
                        style={{
                          fontFamily: 'Roboto',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: 1.25,
                        }}
                      >
                        {' '}
                        Add Candidate
                      </span>
                    </div>
                  </div>
                  {/* //==============filter end ---------------- */}
                </div>
                <div className="row d-flex justify-content-between mx-0 pt-1">
                  {/* <div className=" p-0 ">
                    <p>
                      <div className="form-group">
                        <input
                          className="form-control"
                          value={query.dob}
                          onChange={handleChange}
                          type="date"
                          name="dob"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                      </div>
                    </p>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div className="col-md-6 p-0 mr-2">
                      <p className="custom-dashboard-div">
                        <div className="input-outer-div mt-1 input-design1">
                          <input
                            className="search-input-div"
                            value={query.searchKey}
                            onChange={handleChange}
                            type="text"
                            name="searchKey"
                            aria-label="Search"
                            placeholder="Search here..."
                          />
                          <a className="search_bar">
                            <i
                              className="fa fa-search in_bar"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </p>
                    </div>
                    <div className="col-md-6 custom-dashboard-div pl-0">
                      <p>
                        <div className="input-outer-div mt-1 input-design1">
                          <input
                            className="search-input-div"
                            value={query?.city_postcode}
                            onChange={handleChange}
                            type="text"
                            name="city_postcode"
                            aria-label="Search"
                            placeholder="Search Postcode or City"
                          />
                          <a className="search_bar">
                            <i
                              className="fa fa-search in_bar"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                {!props.isHomepage && (
                  <div className="">
                    <div className="row d-flex justify-content-between align-items-center pl-3">
                      {shiftidsCount > 0 ? (
                        <div> {shiftidsCount} items selected</div>
                      ) : (
                        ''
                      )}
                      <div>
                        <div className="d-flex ">
                          {selected_shiftIds.length > 0 && (
                            <div className="row d-flex align-items-center justify-content-center">
                              <CsvDownload
                                style={{
                                  border: 'none',
                                  // padding: '4px',
                                  backgroundColor: '#f7f9fb',
                                }}
                                data={selected_payout_data}
                              >
                                Download CSV
                              </CsvDownload>
                              <div>
                                <img
                                  style={{
                                    height: 15,
                                    width: 15,
                                  }}
                                  src="/assets/img/csv1.png"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-header page-header mr-3 p-0">
                        <div
                          data-cy="trash"
                          className={
                            TrashChecked
                              ? 'trashButtonActive'
                              : 'trashButtonInActive'
                          }
                          onClick={() => {
                            trash_checked(!TrashChecked);
                          }}
                        >
                          Trash
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="card-body  table-responsive main-card-body p-0 ">
              {!isLoading && (
                <table className="table  flx-custom-table">
                  <thead>
                    <tr style={{ height: '40px' }} className="">
                      {!props.isHomepage && (
                        <th className="border-top1 ">
                          <div onClick={() => allcheckedhandle(!AllSelected)}>
                            {AllSelected ? (
                              <img
                                className="check_style"
                                src="/assets/img/check1.png"
                              />
                            ) : (
                              <img
                                className="check_style"
                                src="/assets/img/un_check1.png"
                              />
                            )}
                          </div>
                        </th>
                      )}
                      <th
                        className={props.isHomepage ? 'border-top1' : ''}
                        style={{ width: '100px' }}
                      >
                        Id
                      </th>
                      <th style={{ width: '220px' }} className="sortHead">
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: '5px' }}
                        >
                          Name{' '}
                          <span
                            style={{ width: '20px' }}
                            className="d-flex flex-column"
                          >
                            {query.pageVo.sort === 'name' &&
                            query.pageVo.sort_order !== 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up-black.png"
                                onClick={() => handleSort('name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up.png"
                                onClick={() => handleSort('name')}
                              />
                            )}
                            {query.pageVo.sort === 'name' &&
                            query.pageVo.sort_order === 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down-black.png"
                                onClick={() => handleSort('name')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down.png"
                                onClick={() => handleSort('name')}
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="table-header-ht"
                        style={{ width: '180px' }}
                      >
                        Jobrole
                      </th>
                      <th>Location</th>
                      <th style={{ width: '180px' }}>Service Sector</th>
                      <th className="sortHead">
                        <div
                          className="d-flex flex-wrap"
                          style={{ gap: '5px', width: '80px' }}
                        >
                          Created
                          <span
                            style={{ width: '20px' }}
                            className="d-flex flex-column"
                          >
                            {query.pageVo.sort === 'createdAt' &&
                            query.pageVo.sort_order !== 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up-black.png"
                                onClick={() => handleSort('createdAt')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-up.png"
                                onClick={() => handleSort('createdAt')}
                              />
                            )}
                            {query.pageVo.sort === 'createdAt' &&
                            query.pageVo.sort_order === 'desc' ? (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down-black.png"
                                onClick={() => handleSort('createdAt')}
                              />
                            ) : (
                              <img
                                style={{
                                  height: 10,
                                  width: 10,
                                  cursor: 'pointer',
                                }}
                                src="/assets/img/sort-down.png"
                                onClick={() => handleSort('createdAt')}
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      {!props.isHomepage && (
                        <th style={{ width: '160px' }}>Total Working hrs</th>
                      )}
                      {!props.isHomepage && (
                        <th style={{ width: '120px' }}>Total Paid</th>
                      )}
                      {!TrashChecked && <th></th>}
                      {!TrashChecked &&
                        !props.isHomepage &&
                        !franchise_staff && <th></th>}
                      {!props.isHomepage && !IsAdmin && !TrashChecked && (
                        <th></th>
                      )}
                      {!TrashChecked && IsAdmin && !props.isHomepage && (
                        <th
                          className="eventCell eventView"
                          style={{ width: '60px' }}
                        ></th>
                      )}
                    </tr>
                  </thead>

                  <tbody>{candidateRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingIcon loadingText={'Loading...'} />}
              {candidateRows && candidateRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllCandidate(data);
                }}
                paginationData={candidateData}
              />
            </div>
          </div>
        </div>
        \{/* //---------delete candidate confirmation modal view----------- */}
        {deleteconfirmationmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setdeleteconfirmationmodal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <p className="delete_string" style={{ color: 'black' }}>
                          Are you sure ?{' '}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setdeleteconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            data-cy="deletebutton"
                            className="button_delete_close width_100"
                            onClick={() => deletecandidate()}
                          >
                            {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCandidateprofileModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setshowCandidateprofileModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <CandidateProfile
                    candidate_id={SelectedCandidateId}
                    status={status}
                  ></CandidateProfile>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedCandidateList = connect(mapStateToProps)(CandidateList);
export { connectedCandidateList as CandidateList };
